
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import './passwordreset.css';

function PasswordReset(props) {
    let [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const [editcompleted, setEditcompleted] = useState(false);
    const [uname, setUname] = useState('');
    const [errormessage, setErrorMessage] = useState('-');
    const [password_eye1, setPassword_eye1] = useState(false);
    const [password_eye1_type, setPassword_eye1_type] = useState({
        icon: 'fe-eye',
        type: 'password'
    });
    const [password_eye2, setPassword_eye2] = useState(false);
    const [password_eye2_type, setPassword_eye2_type] = useState({
        icon: 'fe-eye',
        type: 'password'
    });
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    useEffect(() => {
        if (password_eye1 === false) {
            setPassword_eye1_type({
                icon: 'fe-eye',
                type: 'password'
            })
        } else {
            setPassword_eye1_type({
                icon: 'fe-eye-off',
                type: 'text'
            })
        }
    }, [password_eye1]);

    useEffect(() => {
        if (password_eye2 === false) {
            setPassword_eye2_type({
                icon: 'fe-eye',
                type: 'password'
            })
        } else {
            setPassword_eye2_type({
                icon: 'fe-eye-off',
                type: 'text'
            })
        }
    }, [password_eye2]);

    useEffect(() => {
        passwordreset_checkexpire()
    }, []);

    function passwordreset_checkexpire() {
        const act = searchParams.get('act')
        const formData = new FormData();
        formData.append('resetlink', act)

        axios.post(props.app_hostname + "/api/account/auth/checklinkexpire", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + act
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    setEditcompleted(false)
                    setErrorMessage('-')
                    setUname(response.data.username)
                }
                else {
                    setEditcompleted(false)
                    setErrorMessage(response.data.message)
                }
            })
            .catch((err) => {
                setEditcompleted(false)
                setErrorMessage(err.message)
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    function submit_password() {
        document.getElementById('shop_password1').setCustomValidity("");
        document.getElementById('shop_password2').setCustomValidity("");

        var forms = document.getElementById('accesspanel');
        if (forms.checkValidity() === false) {
            forms.classList.add("was-validated");
        } else {
            forms.classList.remove('was-validated');
            var pass1 = document.getElementById('shop_password1').value
            var pass2 = document.getElementById('shop_password2').value
            if (pass1 !== pass2) {
                document.getElementById('shop_password2').setCustomValidity("x");
                forms.classList.add("was-validated");
            } else {
                const act = searchParams.get('act')
                const formData = new FormData();
                formData.append('resetlink', act)
                formData.append('password', pass1)

                axios.post(props.app_hostname + "/api/account/auth/submitchangepwd", formData, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data;',
                        "Authorization": "Bearer " + act
                    },
                })
                    .then((response) => {
                        if (response.data.status === 'OK') {
                            setEditcompleted(true)
                        }
                        else {
                            setEditcompleted(false)
                            setErrorMessage(response.data.message)
                            setModal_popup({
                                modaltype: 'modal-error',
                                headers: 'ผิดพลาด',
                                message: response.data.message,
                                enable: true
                            });
                        }
                    })
                    .catch((err) => {
                        setEditcompleted(false)
                        setErrorMessage(err.message)
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'ผิดพลาด',
                            message: err.message,
                            enable: true
                        });
                    });
            }
        }
    }

    function backhome() {
        navigate("/")
    }
    return (
        <div className='passwordreset-content'>
            <div className='passwordreset-bar'>
                <img src="/images/Meya-logo1.png" alt="Logo" className="logo mb-0" />
                <h1 className="h5 mb-2 mt-1 text-primary font-th">
                    ระบบจัดการด้านความปลอดภัย</h1>
            </div>
            <div className='center-box font-th'>
                {
                    editcompleted === true ?
                        <div className="card" style={{ border: "none" }}>
                            <div className="card-body">
                                <div className="text-center">
                                    <img src="/images/Meya-logo1.png" alt="Logo" className="logo mb-0" />
                                    <h1 className="h4 mb-2 mt-1 text-success">
                                        สำเร็จ</h1>
                                    <label className="mb-3 mt-0">
                                        รหัสผ่านของท่านถูกแก้ไขแล้ว กรุณาล็อกอินเข้าระบบ</label>

                                    <div className="d-grid mt-5">
                                        <button className="btn btn-primary btn-sm text-white" type="button" onClick={() => { backhome() }}>กลับไปยังหน้าหลัก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        errormessage !== "-" ?
                            <h5>{errormessage}</h5>
                            :
                            uname !== "" ?
                                <div className="card" style={{ border: "none" }}>
                                    <div className="card-body">
                                        <div className="text-center">
                                            <img src="/images/Meya-logo1.png" alt="Logo" className="logo mb-0" />
                                            <h1 className="h6 mb-2 mt-1 text-primary">
                                                สวัสดี {uname}</h1>
                                            <label className="mb-3 mt-0">
                                                กรุณาตั้งรหัสผ่านใหม่</label>
                                        </div>
                                        <form className="mt-4" id="accesspanel" action="" method="POST" encType="multipart/form-data">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="shop_password">รหัสผ่านใหม่</label>
                                                    <div className="input-group mb-3 font-th">
                                                        <input type={password_eye1_type.type} className="form-control inp-password text-dark" id="shop_password1" name="shop_password1" autoComplete="new-off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="กรอกรหัสผ่าน" required />
                                                        <div className="d-flex">
                                                            <span className="btn password-toggle-icon" onClick={() => setPassword_eye1(!password_eye1)}><i className={password_eye1_type.icon}></i></span>
                                                        </div>
                                                        <div className="invalid-feedback">อย่างน้อย 8 ตัวอักษร และประกอบไปด้วย ตัวพิมใหญ่อย่างน้อย 1 ตัว, ตัวพิมเล็กอย่างน้อย 1 ตัว, ตัวเลขอย่างน้อย 1 ตัว</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="shop_password">ยืนยันรหัสผ่านใหม่</label>
                                                    <div className="input-group mb-3 font-th">
                                                        <input type={password_eye1_type.type} className="form-control inp-password text-dark" id="shop_password2" name="shop_password2" autoComplete="new-off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="กรอกรหัสผ่าน" required />
                                                        <div className="d-flex">
                                                            <span className="btn password-toggle-icon" onClick={() => setPassword_eye1(!password_eye1)}><i className={password_eye1_type.icon}></i></span>
                                                        </div>
                                                        <div className="invalid-feedback">ต้องเหมือนกับรหัสผ่านด้านบน</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-grid mt-5">
                                                <button className="btn btn-primary btn-sm text-white" type="button" onClick={() => { submit_password() }}>ยืนยันการแก้ไข</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                :
                                null
                }
            </div>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}


export default PasswordReset