import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ModalLoad from '../../../components/modal/ModalLoad';
import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';

import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import './ChatBox.css';

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyShop_Tools_Chat(props) {
    const [messages_history, setMessages_history] = useState([]);
    const [isLoadHistoryCompleted, setisLoadHistoryCompleted] = useState(false);
    const [isConnectionOpen, setConnectionOpen] = useState(false);
    const [loadmore, setloadmore] = useState(true);
    const [scrollblock, setscrollblock] = useState(true);
    const [messageBody, setMessageBody] = useState("");
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    useEffect(() => {
        api_chat_loadhistoryinitial()
    }, [props.permitdata.shop_id]);

    useEffect(() => {
        if (isLoadHistoryCompleted) {
            if (props.messages_chat_buffer.id !== undefined) {
                if (messages_history.length > 0) {
                    const max = messages_history.reduce((prev, current) => {
                        return Number(prev.id) > Number(current.id) ? prev : current;
                    });
                    if (Number(props.messages_chat_buffer.id) > Number(max.id)) {
                        setMessages_history(oldArray => [...oldArray, props.messages_chat_buffer]);
                    }
                } else {
                    setMessages_history(oldArray => [...oldArray, props.messages_chat_buffer]);
                }
                props.notify_ws.current.send(
                    JSON.stringify({
                        from: props.permitdata.shop_id,
                        to: "admin",
                        chat_type: "text",
                        topic: "chat-readed",
                        data: "",
                    })
                );
            }
        }
        setscrollblock(false)
    }, [props.messages_chat_buffer]);


    useEffect(() => {
        setConnectionOpen(props.isConnectionOpen_notify)
    }, [props.isConnectionOpen_notify]);

    const scrollTarget = useRef();
    useEffect(() => {
        if (scrollTarget.current && scrollblock === false) {
            scrollTarget.current.lastElementChild.lastElementChild.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
        }
        setscrollblock(false)
    }, [messages_history.length]);

    const sendMessage = () => {
        if (messageBody) {
            props.notify_ws.current.send(
                JSON.stringify({
                    from: props.permitdata.shop_id,
                    to: "admin",
                    chat_type: "text",
                    topic: "chat",
                    data: messageBody,
                })
            );
            setMessageBody("");
        }
    };

    function api_chat_loadhistoryinitial() {
        setmodal_load({ enable: true, message: "กำลังโหลดประวัติการคุยก่อนหน้า" })

        const formData = new FormData();
        formData.append('shop_id', props.permitdata.shop_id)

        axios.post("https://marketlive.meya.co.th/live/chat/historyinitial", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                setisLoadHistoryCompleted(true)
                setMessages_history(response.data.chat)
                if (response.data.status === 'OK') {

                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ไม่สามารถโหลดข้อมูลการคุยได้',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setisLoadHistoryCompleted(true)
                setMessages_history([])
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ไม่สามารถโหลดข้อมูลการคุยได้',
                    message: err.message,
                    enable: true
                });
            });
    }

    function chat_load_more(chat_id) {
        setscrollblock(true)
        setmodal_load({ enable: true, message: "กำลังโหลดประวัติการคุยก่อนหน้า" })

        const formData = new FormData();
        formData.append('shop_id', props.permitdata.shop_id)
        formData.append('chat_id', chat_id)

        axios.post("https://marketlive.meya.co.th/live/chat/historyextended", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                setMessages_history(response.data.chat.concat(messages_history))
                if (response.data.status === 'OK') {
                    if (response.data.chat.length === 0) {
                        setloadmore(false)
                    }
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ไม่สามารถโหลดข้อมูลการคุยได้',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ไม่สามารถโหลดข้อมูลการคุยได้',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div className="my-0 meya-content-100p">
            <div className='meya-chat-box-message-header font-th'>
                <div className='meya-chat-box-status gap-2'>
                    <div><i className="ec ec-support text-primary"></i> สถานะการเชื่อมต่อ : </div>
                    {isConnectionOpen ? <div className='text-success'>เชื่อมต่อแล้ว</div> : <div className='text-danger'> ไม่เชื่อมต่อ</div>}
                </div>
            </div>
            <div className='meya-chat-box'>
                <div className='meya-chat-box-message-wrapper' ref={scrollTarget}>
                    <div className="chat">
                        <div className="chat-message">
                            {
                                loadmore === true && messages_history.length > 0 ?
                                    <div className='chat-load-more'>
                                        <div className='load-more-btn' onClick={() => { chat_load_more(messages_history[0].id) }}>
                                            โหลดประวัติเพิ่มเติม
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                messages_history.map((data, index0) => (
                                    (data.from === "admin" && data.to === props.permitdata.shop_id) || (data.from === props.permitdata.shop_id && data.to === "admin") ?
                                        <div className="message" key={"msgid-" + index0}>
                                            {
                                                data.from === "admin" ?
                                                    <div className='d-block'>
                                                        <p className="text">{data.data}</p>
                                                        <div className="time">
                                                            {data.time}
                                                        </div>
                                                    </div>
                                                    : data.from === props.permitdata.shop_id ?
                                                        <div className="response">
                                                            <div className='d-block' style={{ float: "right" }}>
                                                                <p className="text">{data.data}</p>
                                                                <div className="response-time">
                                                                    {data.time}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                            }
                                        </div>
                                        :
                                        null
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='meya-chat-box-tool-wrapper'>
                    <textarea type="text" className='writer-message' id="chat-message" placeholder='พิมพ์ข้อความที่นี่' value={messageBody} onChange={(e) => setMessageBody(e.target.value)} />
                    <div className='writer-btn' onClick={(e) => { sendMessage() }}>
                        <i className="fe-send"></i>
                    </div>
                </div>

            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

export default MyShop_Tools_Chat