import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './MarketPlace.css';
import './CardMyCart.css';
import './ModalPayment.css';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';

import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyPayment(props) {
    let [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    const [list_province, setList_province] = useState([]);
    const [list_amphur, setList_amphur] = useState([]);
    const [list_tambol, setList_tambol] = useState([]);

    const [select_province, setSelect_province] = useState('');
    const [select_amphur, setSelect_amphur] = useState('');
    const [select_tambol, setSelect_tambol] = useState('');
    const [select_postcode, setSelect_postcode] = useState('');

    const [courierAPI_status, setCourierAPI_status] = useState(true);
    const [payment_method_arr, setPayment_method_arr] = useState(['cash', 'promtpay', 'creditcard'])
    const [phone_format, setPhone_format] = useState('')

    const [bookbank_info, setBookbank_info] = useState({
        name: "",
        account_no: "",
        payment_id: "",
        payment_total: "",
        payment_expire: ""
    });

    const [cartdata_checksum, setCartdata_checksum] = useState('')
    const [mypayment_orderdata, setMypayment_orderdata] = useState([]);
    const [payment_summary, setPayment_summary] = useState({
        shop_subtotal: "",
        shop_shipping: "",
        shop_discount: "",
        shop_creditcharge: "",
        shop_servicecharge: "",
        shop_total: "",
        shop_checksum: ""
    });
    const [payment_summary_normal, setPayment_summary_normal] = useState({
        shop_subtotal: "",
        shop_shipping: "",
        shop_discount: "",
        shop_creditcharge: "",
        shop_servicecharge: "",
        shop_total: "",
        shop_checksum: ""
    });
    const [payment_summary_credit, setPayment_summary_credit] = useState({
        shop_subtotal: "",
        shop_shipping: "",
        shop_discount: "",
        shop_creditcharge: "",
        shop_servicecharge: "",
        shop_total: "",
        shop_checksum: ""
    });

    const [payment_permit, setPayment_permit] = useState(true)
    const [payment_method, setPayment_method] = useState(0);
    const [editshipping, setEditshipping] = useState(false)
    const [editcourier, setEditcourier] = useState(false)
    const [courier_code, setCourier_code] = useState({
        display: "Flash Express",
        value: "FLE"
    })
    const [shipping, setShipping] = useState({
        name: props.permitdata.shop_firstname + ' ' + props.permitdata.shop_lastname,
        telno: props.permitdata.shop_telno,
        address: props.permitdata.shop_address,
        district: props.permitdata.shop_subdistrict,
        state: props.permitdata.shop_district,
        province: props.permitdata.shop_province,
        postcode: props.permitdata.shop_postcode
    });

    const [editcoupon, setEditcoupon] = useState(false)
    const [coupon_id, setCoupon_id] = useState("");
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false,
        confirmcode: ''
    });

    const [modalbookbank, setModalbookbank] = useState(false);

    function GetProvince() {
        var formData = new FormData()
        formData.append('province', 'all')
        axios.post(props.app_hostname + "/api/account/register/address/getprovince", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    setList_province(response.data.data_province)
                    setList_amphur([])
                    setList_tambol([])
                }
            })
            .catch((err) => {
                setList_province([])
                setList_amphur([])
                setList_tambol([])
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    function GetAmphur() {
        var formData = new FormData()
        formData.append('province', select_province)
        axios.post(props.app_hostname + "/api/account/register/address/getamphure", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    setList_amphur(response.data.data_amphure)
                    setList_tambol([])
                }
            })
            .catch((err) => {
                setList_amphur([])
                setList_tambol([])
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    function GetTambol() {
        var formData = new FormData()
        formData.append('province', select_province)
        formData.append('amphur', select_amphur)
        axios.post(props.app_hostname + "/api/account/register/address/gettambol", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    setList_tambol(response.data.data_tambol)
                }
            })
            .catch((err) => {
                setList_tambol([])
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    useEffect(() => {
        setList_amphur([])
        setList_tambol([])
        if (select_province != '') {
            GetAmphur()
        }
    }, [select_province]);

    useEffect(() => {
        setList_tambol([])
        if (select_amphur != '') {
            GetTambol()
        }
    }, [select_amphur]);

    useEffect(() => {
        if (props.permitdata != undefined) {
            const source = searchParams.get('source')
            const shopitem = searchParams.get('shopitem')
            const item = searchParams.get('item')

            var s_info = {
                name: props.permitdata.shop_firstname + ' ' + props.permitdata.shop_lastname,
                telno: props.permitdata.shop_telno,
                address: props.permitdata.shop_address,
                district: props.permitdata.shop_subdistrict,
                state: props.permitdata.shop_district,
                province: props.permitdata.shop_province,
                postcode: props.permitdata.shop_postcode
            }
            if (source === "cart") {
                api_load_mycart("", "FLE", s_info)
            } else if (source === "product") {
                api_load_byproduct(shopitem, item, "", "FLE", s_info)
            }
        }
        GetProvince()
    }, [props.permitdata]);

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        if (message === 'pay') {
            api_submit_payment()
        } else if (message === 'cancel') {

        }
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    useEffect(() => {
        if (payment_method !== 3) {
            setPayment_summary(payment_summary_normal)
        } else {
            setPayment_summary(payment_summary_credit)
        }
    }, [payment_method, payment_summary_normal, payment_summary_credit]);

    function loadeditshippingx() {
        document.getElementById('shipping_recievename').value = shipping.name
        document.getElementById('shipping_telno').value = shipping.telno
        document.getElementById('shipping_address').value = shipping.address
        document.getElementById('shipping_district').value = shipping.district
        document.getElementById('shipping_state').value = shipping.state
        document.getElementById('shipping_province').value = shipping.province
        document.getElementById('shipping_postcode').value = shipping.postcode
    }

    function saveeditshipping() {
        var formelement = document.getElementById('form-address')
        if (formelement !== null) {
            formelement.classList.remove('was-validated');
            if (formelement.checkValidity() === false) {
                formelement.classList.add("was-validated");
            } else {
                formelement.classList.remove('was-validated');

                var s_info = {
                    name: document.getElementById('shipping_recievename').value,
                    telno: document.getElementById('shipping_telno').value,
                    address: document.getElementById('shipping_address').value,
                    district: document.getElementById('shipping_district').value,
                    state: document.getElementById('shipping_state').value,
                    province: document.getElementById('shipping_province').value,
                    postcode: document.getElementById('shipping_postcode').value
                }
                const source = searchParams.get('source')
                if (source === "product") {
                    const shopitem = searchParams.get('shopitem')
                    const item = searchParams.get('item')
                    api_load_byproduct(shopitem, item, coupon_id, courier_code.value, s_info)
                } else {
                    api_load_mycart(coupon_id, courier_code.value, s_info)
                }
                setShipping(s_info)
                setEditshipping(false)
            }
        }
    }

    useEffect(() => {
        if (editshipping) {
            //  loadeditshipping()
        } else {
            var formelement = document.getElementById('form-address')
            if (formelement !== null) {
                formelement.classList.remove('was-validated');
            }
        }
    }, [editshipping]);

    function fillphone(input) {
        var numbers = input.replace(/[^0-9]/g, '').replace(',', '.').toString()
        var pat = []
        if (numbers.length > 0) {
            pat.push(numbers.substring(0, 3))
        }
        if (numbers.length >= 4) {
            pat.push(numbers.substring(3, 6))
        }
        if (numbers.length >= 7) {
            pat.push(numbers.substring(6, 10))
        }
        if (pat.length > 0) {
            setPhone_format(pat.join('-'))
        } else {
            setPhone_format('')
        }
    }

    function submit_coupon() {
        var couponid = document.getElementById('coupon_id').value
        if (couponid != "") {
            const source = searchParams.get('source')
            if (source === "product") {
                const shopitem = searchParams.get('shopitem')
                const item = searchParams.get('item')
                api_load_byproduct(shopitem, item, couponid, courier_code.value, shipping)
            } else {
                api_load_mycart(couponid, courier_code.value, shipping)
            }
        }

        if (couponid === "") {
            var formelement = document.getElementById('form-coupon')
            if (formelement !== null) {
                formelement.classList.remove('was-validated');
                var formfeedback = document.getElementById('coupon_feedback')
                if (formelement.checkValidity() === false) {
                    formfeedback.innerText = "กรุณากรอดโค๊ดส่วนลด"
                    formelement.classList.add("was-validated");
                } else {

                }
            }
        }
    }

    function cancel_coupon() {
        setEditcoupon(false);
        setCoupon_id("")
        document.getElementById('coupon_id').value = ""

        var formelement = document.getElementById('form-coupon')
        if (formelement !== null) {
            formelement.classList.remove('was-validated');
            var formfeedback = document.getElementById('coupon_feedback')
            formfeedback.innerText = ""
        }

        const source = searchParams.get('source')
        if (source === "product") {
            const shopitem = searchParams.get('shopitem')
            const item = searchParams.get('item')
            api_load_byproduct(shopitem, item, "", courier_code.value, shipping)
        } else {
            api_load_mycart("", courier_code.value, shipping)
        }
    }

    function cancel_bookbank() {
        setModalbookbank(false)
    }

    function open_bookbank() {
        document.getElementById('payslip').value = [];
        setModalbookbank(true)
    }

    async function submit_carrier() {
        var tm = document.getElementById('courier_code').value
        if (tm === "FLE") {
            setCourier_code({
                display: "Flash Express",
                value: "FLE"
            })
        } else if (tm === "DHL") {
            setCourier_code({
                display: "DHL",
                value: "DHL"
            })
        } else {
            setCourier_code({
                display: "SPX Xpress (Shopee)",
                value: "SPX"
            })
        }
        const source = searchParams.get('source')
        if (source === "product") {
            const shopitem = searchParams.get('shopitem')
            const item = searchParams.get('item')
            api_load_byproduct(shopitem, item, coupon_id, tm, shipping)
        } else {
            api_load_mycart(coupon_id, tm, shipping)
        }
        setEditcourier(false)

    }

    useEffect(() => {
        if (editcourier) {
            document.getElementById('courier_code').value = courier_code.value
        }
    }, [editcourier]);

    function getinpuralertstock(selectvalue, expire, stockarray) {
        var res = stockarray.filter(function (item) {
            return item.expire === expire
        })
        if (res.length > 0) {
            if (selectvalue > res[0].stock) {
                return ' inputnumeric-alert'
            } else {
                return ' '
            }
        } else {
            return ' '
        }
    }

    async function api_load_byproduct(shopitem_id, item, coupon, carrier, shipinfo) {
        setmodal_load({ enable: true, message: "ระบบกำลังโหลดข้อมูลสินค้า และคำนวณราคา" })
        await sleep(200)

        const formData = new FormData();
        formData.append('buyer_shopid', props.permitdata.shop_id)
        formData.append('shopitem_id', shopitem_id)
        formData.append('item', item.replaceAll("--", "##"))
        formData.append('coupon_id', coupon)
        formData.append('deliver_req', "enable")
        formData.append('shipping_recievename', shipinfo.name)
        formData.append('shipping_telno', shipinfo.telno)
        formData.append('shipping_address', shipinfo.address)
        formData.append('shipping_district', shipinfo.district)
        formData.append('shipping_state', shipinfo.state)
        formData.append('shipping_province', shipinfo.province)
        formData.append('shipping_postcode', shipinfo.postcode)
        formData.append('courier_code', carrier)
        formData.append('sourcetype', "order")

        axios.post(props.app_hostname + "/api/order/mycart/singlecartlist", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setMypayment_orderdata(response.data.cartdata)
                    setCartdata_checksum(response.data.cartdata_checksum)
                    setPayment_summary_normal(response.data.summary_normal)
                    setPayment_summary_credit(response.data.summary_credit)
                    if (response.data.coupon_msg === "OK") {
                        setCoupon_id(coupon)
                        setEditcoupon(false)
                        var formelement = document.getElementById('form-coupon')
                        if (formelement !== null) {
                            formelement.classList.remove('was-validated');
                        }
                    } else {
                        setEditcoupon(true)
                        var formelement = document.getElementById('form-coupon')
                        if (formelement !== null) {
                            formelement.classList.remove('was-validated');
                            var formfeedback = document.getElementById('coupon_feedback')
                            if (formelement.checkValidity() === false || response.data.coupon_msg !== "OK") {
                                formfeedback.innerText = response.data.coupon_msg
                                document.getElementById('coupon_id').setCustomValidity("x");
                                formelement.classList.add("was-validated");
                            } else {

                            }
                        }
                    }
                    setCourierAPI_status(response.data.shipping_status)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_load_mycart(coupon, carrier, shipinfo) {
        setmodal_load({ enable: true, message: "ระบบกำลังโหลดข้อมูลสินค้า และคำนวณราคา" })
        await sleep(200)

        const formData = new FormData();
        formData.append('buyer_shopid', props.permitdata.shop_id)
        formData.append('coupon_id', coupon)
        formData.append('deliver_req', "enable")
        formData.append('shipping_recievename', shipinfo.name)
        formData.append('shipping_telno', shipinfo.telno)
        formData.append('shipping_address', shipinfo.address)
        formData.append('shipping_district', shipinfo.district)
        formData.append('shipping_state', shipinfo.state)
        formData.append('shipping_province', shipinfo.province)
        formData.append('shipping_postcode', shipinfo.postcode)
        formData.append('courier_code', carrier)
        formData.append('sourcetype', "order")
        if (payment_method_arr[payment_method - 1] !== undefined) {
            formData.append("payment_method", payment_method_arr[payment_method - 1]);
        } else {
            formData.append("payment_method", 'cash');
        }

        axios.post(props.app_hostname + "/api/order/mycart/cartlist", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setMypayment_orderdata(response.data.cartdata)
                    setCartdata_checksum(response.data.cartdata_checksum)
                    setPayment_summary_normal(response.data.summary_normal)
                    setPayment_summary_credit(response.data.summary_credit)
                    if (response.data.coupon_msg === "OK") {
                        setCoupon_id(coupon)
                        setEditcoupon(false)
                        var formelement = document.getElementById('form-coupon')
                        if (formelement !== null) {
                            formelement.classList.remove('was-validated');
                        }
                    } else {
                        setEditcoupon(true)
                        var formelement = document.getElementById('form-coupon')
                        if (formelement !== null) {
                            formelement.classList.remove('was-validated');
                            var formfeedback = document.getElementById('coupon_feedback')
                            if (formelement.checkValidity() === false || response.data.coupon_msg !== "OK") {
                                formfeedback.innerText = response.data.coupon_msg
                                document.getElementById('coupon_id').setCustomValidity("x");
                                formelement.classList.add("was-validated");
                            } else {

                            }
                        }

                    }
                    setCourierAPI_status(response.data.shipping_status)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_submit_payment() {
        setmodal_load({ enable: true, message: "ระบบกำลังสร้างคำสั่งซื้อของท่าน" })
        await sleep(200)

        var shipping_avai = true
        mypayment_orderdata.forEach(order => {
            if (order.shipping_info.available === false) {
                shipping_avai = false
            }
        })

        if (shipping_avai === true) {
            if (props.permitdata.shop_id !== mypayment_orderdata[0].seller_shopid) {
                if (mypayment_orderdata.length > 0) {
                    if (payment_method > 0) {
                        var formData = new FormData();
                        const source = searchParams.get('source')
                        formData.append('source', source)

                        formData.append('buyer_shopid', props.permitdata.shop_id)
                        formData.append('buyer_shopname', props.permitdata.shop_name)
                        formData.append('buyer_shopprovince', props.permitdata.shop_province)

                        formData.append("payment_method", payment_method_arr[payment_method - 1]);

                        formData.append('shipping_recievename', shipping.name)
                        formData.append('shipping_telno', shipping.telno)
                        formData.append('shipping_address', shipping.address)
                        formData.append('shipping_district', shipping.district)
                        formData.append('shipping_state', shipping.state)
                        formData.append('shipping_province', shipping.province)
                        formData.append('shipping_postcode', shipping.postcode)

                        formData.append('summary_subtotal', payment_summary.shop_subtotal)
                        formData.append('summary_shipping', payment_summary.shop_shipping)
                        formData.append('summary_shipping_markup', payment_summary.shop_shipping_markup)
                        formData.append('summary_discount', payment_summary.shop_discount)
                        formData.append('summary_servicecharge', payment_summary.shop_servicecharge)
                        formData.append('summary_creditcharge', payment_summary.shop_creditcharge)
                        formData.append('summary_total', payment_summary.shop_total)
                        formData.append('summary_net', payment_summary.shop_net)
                        formData.append('summary_checksum', payment_summary.shop_checksum)
                        formData.append('order_data_checksum', cartdata_checksum)

                        formData.append('coupon_id', coupon_id)
                        formData.append('courier_code', courier_code.value)

                        formData.append('order_data', JSON.stringify(mypayment_orderdata))

                        axios.post(props.app_hostname + "/api/order/booking/create", formData, {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'multipart/form-data;',
                                "Authorization": "Bearer " + props.permitdata.token
                            },
                        })
                            .then((response) => {
                                setmodal_load({ enable: false, message: "" })
                                if (response.data.status === 'OK') {
                                    if (response.data.payment_method === 'cash') {
                                        setEditcourier(false)
                                        setEditshipping(false)
                                        setEditcoupon(false)
                                        setPayment_permit(false)
                                        setBookbank_info(response.data.bookbank_info)
                                        open_bookbank()
                                    } else if (response.data.payment_method === 'promtpay') {
                                        setEditcourier(false)
                                        setEditshipping(false)
                                        setEditcoupon(false)
                                        setPayment_permit(false)
                                        if (response.data.qrcode_info.PaymentUrl !== "") {
                                            window.open(response.data.qrcode_info.PaymentUrl, '_blank');
                                        }
                                    } else if (response.data.payment_method === 'creditcard') {
                                        if (response.data.creditcard_info.PaymentUrl !== "") {
                                            window.open(response.data.creditcard_info.PaymentUrl, '_blank');
                                        }
                                    }
                                }
                                else {
                                    setModalbookbank(false)
                                    setModal_popup({
                                        modaltype: 'modal-warning',
                                        headers: 'ขออภัย',
                                        message: response.data.message,
                                        enable: true
                                    });
                                }

                                if (response.data.readback !== undefined) {
                                    if (response.data.readback === true) {
                                        props.callback_maindatachange('myorder-array-add', response.data.myorder)
                                    }
                                }
                                if (response.data.cartcount !== undefined) {
                                    if (response.data.cartcount > -1) {
                                        props.callback_mycart("edit", response.data.cartcount)
                                    }
                                }
                            })
                            .catch((err) => {
                                setModalbookbank(false)
                                setmodal_load({ enable: false, message: "" })
                                setModal_popup({
                                    modaltype: 'modal-error',
                                    headers: 'ผิดพลาด',
                                    message: err.message,
                                    enable: true
                                });
                            });
                    } else {
                        setmodal_load({ enable: false, message: "" })
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: 'กรุณาเลือกวิธีการการชำระเงิน',
                            enable: true
                        });
                    }
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: 'กรุณาเลือกสินค้าเพื่อทำการชำระเงิน',
                        enable: true
                    });
                }
            } else {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-warning',
                    headers: 'ขออภัย',
                    message: 'ระบบไม่อนุญาตให้ซื้อสินค้าภายในร้านตัวเอง',
                    enable: true
                });
            }
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: "ตรวจพบปัญหาในการคำนวณราคา กรุณาลองใหม่อีกครั้ง โดยการรีเฟรชหน้าจอ หรือแก้ไขข้อมูล",
                enable: true
            });
        }
    }

    async function api_upload_payslip() {
        setmodal_load({ enable: true, message: "กำลังอัพโหลดหลักฐานการชำระเงิน" })
        await sleep(200)

        var formelement = document.getElementById('form-payslip')
        if (formelement.checkValidity() === false) {
            formelement.classList.add("was-validated");
        } else {
            formelement.classList.remove("was-validated");
            const formData = new FormData(formelement);
            formData.append('payment_id', bookbank_info.payment_id)

            axios.post(props.app_hostname + "/api/order/payment/submitpayslip", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        setModalbookbank(false)
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'สำเร็จ',
                            message: response.data.message,
                            enable: true
                        });
                    }
                    else {
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ผิดพลาด',
                        message: err.message,
                        enable: true
                    });
                });
        }
    }

    return (
        <main id="content" role="main">
            <div className="container">
                <div className="py-2 mt-0">
                    <div className="bg-gray-13 bg-md-transparent">
                        <div className="my-md-0">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble py-0">
                                    <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ซื้อสินค้า</li>
                                    <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ชำระเงิน</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <div className="col d-block">
                    {
                        mypayment_orderdata.length > 0 ?
                            <div className="row mb-xl-14 mb-6 mt-2">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-wd-9 mb-md-1 mb-lg-0">
                                    <div className="row">
                                        <div className="col-md-12 mb-md-2 mb-lg-0">
                                            <div className="card-mycart mb-2">
                                                <div className="card-mycart-header py-2">
                                                    {
                                                        payment_permit === true ?
                                                            <div className="btn-editshipping float-right" onClick={() => { setEditshipping(!editshipping) }}>{editshipping === true ? <i className="fe-x"></i> : <i className="fe-edit-2"></i>} แก้ไข</div>
                                                            :
                                                            null
                                                    }
                                                    <div className="card-mycart-title"><i className="fas fa-map-marker-alt text-primary"></i> ข้อมูลผู้รับสินค้า</div>
                                                </div>
                                                <div className="card-mycart-body">
                                                    <div className="shipping-table">
                                                        <div className="shipping-row">
                                                            <div className="shipping-cell pr-5">ชื่อผู้รับ</div>
                                                            <div className="shipping-cell">{shipping.name}</div>
                                                        </div>
                                                        <div className="shipping-row">
                                                            <div className="shipping-cell pr-5">เบอร์โทรศัพย์</div>
                                                            <div className="shipping-cell">{shipping.telno}</div>
                                                        </div>
                                                        <div className="shipping-row">
                                                            <div className="shipping-cell pr-5">ที่อยู่ในการจัดส่ง</div>
                                                            <div className="shipping-cell">{shipping.address} {shipping.district} {shipping.state} {shipping.province} {shipping.postcode}</div>
                                                        </div>
                                                    </div>

                                                    <div className={"d-none border-top py-3 mt-3 " + `${editshipping === true && " d-block"}`}>
                                                        <form id="form-address">
                                                            <div className="shipping-editbox mb-3">
                                                                <div className="d-block d-xl-flex justify-content-between mb-2">
                                                                    <div className="editname pr-5">ชื่อผู้รับ</div>
                                                                    <div className="form-group py-0 mt-0 mb-0">
                                                                        <input type="text" className="form-control-sm" id="shipping_recievename" placeholder='กรอกชื่อผู้รับสินค้า' required />
                                                                        <div className="invalid-feedback">กรุณากรอกชื่อผู้รับสินค้า</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-block d-xl-flex justify-content-between mb-2">
                                                                    <div className="editname pr-5">เบอร์โทรศัพย์</div>
                                                                    <div className="form-group py-0 mt-0 mb-0">
                                                                        <input type="text" className="form-control-sm" id="shipping_telno" placeholder='กรอกเบอร์โทรศัพย์' value={phone_format} onChange={(e) => { fillphone(e.target.value) }} required />
                                                                        <div className="invalid-feedback">กรุณากรอกเบอร์โทรศัพย์</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-block d-xl-flex justify-content-between mb-2">
                                                                    <div className="editname pr-5">ที่อยู่ในการจัดส่ง</div>
                                                                    <div className="form-group py-0 mt-0 mb-0">
                                                                        <textarea className="form-control-sm font-th" name="shipping_address" id="shipping_address" placeholder='กรอกที่อยู่ในการจัดส่ง' rows="2" cols="50" required />
                                                                        <div className="invalid-feedback">กรุณากรอกที่อยู่ในการจัดส่ง</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-block d-xl-flex justify-content-between mb-2">
                                                                    <label htmlFor="shipping_province" className="editname pr-5">จังหวัด</label>
                                                                    <div className="form-group py-0 mt-0 mb-0">
                                                                        <select id="shipping_province" name="shipping_province" className="form-control-sm" onChange={(e) => { setSelect_province(e.target.value) }} value={select_province} required>
                                                                            <option value="">เลือกจังหวัด</option>
                                                                            {
                                                                                list_province.map(item => (
                                                                                    <option value={item} key={item}>{item}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <div className="invalid-feedback">กรุณาเลือกจังหวัด</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-block d-xl-flex justify-content-between mb-2">
                                                                    <label htmlFor="shipping_state" className="editname pr-5">เขต/อำเภอ</label>
                                                                    <div className="form-group py-0 mt-0 mb-0">
                                                                        <select id="shipping_state" name="shipping_state" className="form-control-sm" onChange={(e) => { setSelect_amphur(e.target.value) }} value={select_amphur} required>
                                                                            <option value="">เลือกเขต/อำเภอ</option>
                                                                            {
                                                                                list_amphur.map(item => (
                                                                                    <option value={item} key={item}>{item}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <div className="invalid-feedback">กรุณาเลือกเขต/อำเภอ</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-block d-xl-flex justify-content-between mb-2">
                                                                    <label htmlFor="shipping_district" className="editname pr-5">แขวง/ตำบล</label>
                                                                    <div className="form-group py-0 mt-0 mb-0">
                                                                        <select id="shipping_district" name="shipping_district" className="form-control-sm" onChange={(e) => { setSelect_tambol(e.target.value) }} value={select_tambol} required>
                                                                            <option value="">เลือกแขวง/ตำบล</option>
                                                                            {
                                                                                list_tambol.map((item, index) => (
                                                                                    <option value={item.name_th} key={item}>{item.name_th}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <div className="invalid-feedback">กรุณาเลือกแขวง/ตำบล</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-block d-xl-flex justify-content-between mb-2 mt-0 mb-0">
                                                                    <label htmlFor="shipping_postcode" className="editname pr-5">รหัสไปรษณีย์</label>
                                                                    <div className="form-group py-0 mt-0 mb-0">
                                                                        <select id="shipping_postcode" name="shipping_postcode" className="form-control-sm" onChange={(e) => { setSelect_postcode(e.target.value) }} value={select_postcode} required>
                                                                            <option value="">เลือกรหัสไปรษณีย์</option>
                                                                            {
                                                                                list_tambol.filter(tambol => tambol.name_th === select_tambol).map(item => (
                                                                                    <option value={item.zip_code}>{item.zip_code}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <div className="invalid-feedback">กรุณาเลือกรหัสไปรษณีย์</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                        <button type="button" className="btn-rectan-small btn-primary text-white font-th" onClick={() => { saveeditshipping() }}><i className="mdi mdi-content-save-move-outline mr-2"></i> ยืนยัน</button>
                                                        <button type="button" className="btn-rectan-small btn-light ml-3 font-th" onClick={() => { setEditshipping(false) }}>ปิด</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mb-md-2 mb-lg-0">
                                            <div className="card-mycart mb-2">
                                                <div className="card-mycart-header py-2">
                                                    {
                                                        payment_permit === true ?
                                                            <div className="btn-editshipping float-right text-primary" onClick={() => { setEditcourier(!editcourier) }}>{editcourier === true ? <i className="fe-x"></i> : <i className="fe-edit-2"></i>} แก้ไข</div>
                                                            :
                                                            null
                                                    }
                                                    <div className="card-mycart-title"><i className="fas fa-truck text-primary"></i> ผู้ให้บริการขนส่ง : {courier_code.display}</div>
                                                </div>
                                                <div className="card-mycart-body py-0">
                                                    <div className={"d-none pb-3 pt-3 " + `${editcourier === true && " d-block"}`}>
                                                        <div className="shipping-editbox mb-3">
                                                            <div className="d-block d-xl-flex justify-content-between">
                                                                <div className="editname pr-5">ผู้ให้บริการ</div>
                                                                <div className="form-group">
                                                                    <select id="courier_code" name="courier_code" className="form-control-sm" required>
                                                                        <option value="FLE">Flash Express</option>
                                                                        <option value="DHL">DHL</option>
                                                                        <option value="SPX">SPX Xpress (Shopee)</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button type="button" className="btn-rectan-small btn-primary text-white font-th" onClick={() => { submit_carrier() }}><i className="mdi mdi-content-save-move-outline mr-2"></i> ยืนยัน</button>
                                                        <button type="button" className="btn-rectan-small btn-light ml-3 font-th" onClick={() => {
                                                            setEditcourier(false)
                                                        }}>ยกเลิก</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        mypayment_orderdata.map((order, index0) => (
                                            <div className="row" key={order.seller_shopname}>
                                                <div className="col-md-12 mb-md-2 mb-lg-0">
                                                    <div className="card-mycart mb-2">
                                                        <div className="card-mycart-header py-2">
                                                            <div className="float-right"> จำนวน {order.cartitem.length} รายการ</div>
                                                            <div className="card-mycart-title"><i className="fas fa-store-alt text-primary"></i> {order.seller_shopname}</div>
                                                            <p className="card-mycart-subtitle">จังหวัด {order.seller_shopprovince}</p>
                                                        </div>
                                                        <div className="card-mycart-body">
                                                            <div className="d-block d-xl-flex justify-content-between py-0">
                                                                <div className="">
                                                                    {
                                                                        order.cartitem.map((orderitem, index1) => (
                                                                            <div className={"d-block d-md-flex py-1 " + (index1 > 0 ? "border-top" : "")} key={orderitem.productitem_name}>
                                                                                <div className="d-flex">
                                                                                    <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + `${orderitem.productitem_id}/` + `${orderitem.productitem_id}` + "-1-" + orderitem.img1_key + ".jpg"} alt="Image Description" /></div>
                                                                                    <div className='d-block'>
                                                                                        <div className="mycart-box-productname py-0">{orderitem.productitem_name}</div>
                                                                                        <div className="mycart-box-productinter py-0">{orderitem.productitem_inter}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mycart-box-pricelist">
                                                                                    <table>
                                                                                        <tbody>
                                                                                            {
                                                                                                orderitem.cartitem_pricelist !== null ?
                                                                                                    orderitem.cartitem_pricelist.map((pricelist, index1) => (
                                                                                                        <tr className={"mycart-table-pricelist-row" + getinpuralertstock(pricelist.select, pricelist.expire, orderitem.on_stock_array)} id={pricelist.pricelist_id} key={pricelist.pricelist_id}>
                                                                                                            <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                                            <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                                            <td className="product-stock">x {pricelist.select}</td>
                                                                                                            <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className='d-block border-top border-xl-top-0' style={{ minWidth: "200px" }}>
                                                                    <div className='d-flex justify-content-between mt-2 mt-xl-0'>
                                                                        <label>มูลค่าสินค้า</label>
                                                                        <label>{Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <label>ค่าขนส่ง</label>
                                                                        <label>{Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <label>รวม</label>
                                                                        <label>{Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <label>ระยะเวลาจัดส่ง</label>
                                                                        <label>{order.shipping_info.estimate_time}</label>
                                                                    </div>
                                                                    {
                                                                        order.shipping_info.available === false ?
                                                                            <div className='d-flex justify-content-between' style={{ color: "red" }}>
                                                                                <label>{order.shipping_info.remark}</label>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        courierAPI_status === false ?
                                                                            <div className='d-flex justify-content-between' style={{ color: "red" }}>
                                                                                <label>ตรวจพบปัญหาระบบขนส่ง</label>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    <div className="row">
                                        <div className="col-md-12 mb-md-2 mb-lg-0">
                                            <div className="card-mycart mb-2">
                                                <div className="card-mycart-header py-2">
                                                    {
                                                        payment_permit === true ?
                                                            <div className="btn-editshipping float-right text-primary" onClick={() => { setEditcoupon(!editcoupon) }}>{editcoupon === true ? <i className="fe-x"></i> : <i className="fe-edit-2"></i>} ใช้โค๊ดส่วนลด</div>
                                                            :
                                                            null
                                                    }
                                                    <div className="card-mycart-title"><i className="fas fa-comment-dollar text-primary"></i> โค๊ดส่วนลด : {coupon_id}</div>
                                                </div>
                                                <div className="card-mycart-body py-0">
                                                    <div className={"d-none pb-3 pt-3" + `${editcoupon === true && " d-block"}`}>
                                                        <div className="shipping-editbox mb-3">
                                                            <div className="d-block d-xl-flex justify-content-between">
                                                                <div className="editname pr-5">โค๊ดส่วนลด</div>
                                                                <form id="form-coupon">
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control-sm mb-2" placeholder='กรอกโค๊ดส่วนลด' id="coupon_id" minLength={9} required />
                                                                        <div className="invalid-feedback" id="coupon_feedback"></div>
                                                                    </div>
                                                                </form >
                                                            </div>
                                                        </div>

                                                        <button type="button" className="btn-rectan-small btn-primary text-white font-th" onClick={() => { submit_coupon() }}><i className="mdi mdi-content-save-move-outline mr-2"></i> ยืนยัน</button>
                                                        <button type="button" className="btn-rectan-small btn-light ml-3 font-th" onClick={() => {
                                                            cancel_coupon()
                                                        }}>ยกเลิกส่วนลด</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-wd-3">
                                    <div className="row">
                                        <div className="col-md-12 mb-md-2 mb-lg-0">
                                            <div className="mycart-summary font-th">
                                                <div className="location-box d-block">
                                                    <h6 className="mycart-summary-location">
                                                        เลือกช่องทางการชำระเงิน
                                                    </h6>
                                                    {
                                                        payment_permit === true || (payment_permit === false && payment_method === 1) ?
                                                            <div className={"payment-method-box mb-2 " + `${payment_method === 1 && "active"}`} onClick={() => { setPayment_method(1) }}>
                                                                <div className={"payment-method-selected " + `${payment_method === 1 && "active"}`}><i className="fa fa-check"></i></div>
                                                                <h5>โอนเงิน</h5>
                                                                <p>ชำระแบบโอนเงิน</p>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        payment_permit === true || (payment_permit === false && payment_method === 2) ?
                                                            <div className={"payment-method-box mb-2 " + `${payment_method === 2 && "active"}`} onClick={() => { setPayment_method(2) }}>
                                                                <div className={"payment-method-selected " + `${payment_method === 2 && "active"}`}><i className="fa fa-check"></i></div>
                                                                <h5>QR PromtPay</h5>
                                                                <p>แสกน QR Code พร้อมเพย์</p>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        payment_permit === true || (payment_permit === false && payment_method === 3) ?
                                                            <div className={"payment-method-box mb-2 " + `${payment_method === 3 && "active"}`} onClick={() => { setPayment_method(3) }}>
                                                                <div className={"payment-method-selected " + `${payment_method === 3 && "active"}`}><i className="fa fa-check"></i></div>
                                                                <h5>บัตรเครดิต / บัตรเดบิต</h5>
                                                                <p>ชำระผ่านบัตรเครดิต / บัตรเดบิต</p>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="summary-box mt-2">
                                                    <h6 className="mycart-summary-location text-primary">ยอดรวมสั่งซื้อ</h6>
                                                    <div className="table-summary">
                                                        <div className="table-summary-row text-gray-15">
                                                            <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                            <div className="table-summary-cell aligh-right">฿{payment_summary.shop_subtotal}</div>
                                                        </div>
                                                        <div className="table-summary-row text-gray-15">
                                                            <div className="table-summary-cell">ค่าขนส่ง</div>
                                                            <div className="table-summary-cell aligh-right">฿{payment_summary.shop_shipping_markup}</div>
                                                        </div>
                                                        <div className="table-summary-row text-gray-15">
                                                            <div className="table-summary-cell">ส่วนลด</div>
                                                            <div className={"table-summary-cell aligh-right " + (payment_summary.shop_discount !== "0.00" ? "text-success" : "")}>฿{payment_summary.shop_discount}</div>
                                                        </div>
                                                        {
                                                            payment_method === 3 ?
                                                                <>
                                                                    <div className="table-summary-row font-size-16">
                                                                        <div className="table-summary-cell">ยอดรวม</div>
                                                                        <div className="table-summary-cell aligh-right">฿{payment_summary.shop_total}</div>
                                                                    </div>
                                                                    <div className="table-summary-row text-gray-15">
                                                                        <div className="table-summary-cell">ค่าธรรมเนียมชำระผ่านบัตร</div>
                                                                        <div className="table-summary-cell aligh-right ">฿{payment_summary.shop_creditcharge}</div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <div className="table-summary-row font-size-16 text-success">
                                                            <div className="table-summary-cell">ยอดที่ต้องชำระ</div>
                                                            <div className="table-summary-cell aligh-right">฿{payment_summary.shop_net}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    payment_permit === true ?
                                                        payment_method > 0 ?
                                                            <button type="button" className="btn btn-primary text-white mb-0 font-weight-normal px-5 px-md-2 px-lg-2 w-100 mt-3" onClick={() => {
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'ยืนยันคำสั่งซื้อ',
                                                                    message: 'ระบบจะสร้างคำสั่งซื้อของท่านหลังจากกดปุ่ม "ดำเนินการต่อ" และแจ้งชำระเงิน',
                                                                    confirmcode: 'pay',
                                                                    enable: true,
                                                                })
                                                            }}>ยืนยันการสั่งซื้อ</button>
                                                            :
                                                            <button type="button" className="btn btn-primary text-white mb-0 font-weight-normal px-5 px-md-2 px-lg-2 w-100 mt-3" onClick={() => {
                                                                setModal_popup({
                                                                    modaltype: 'modal-error',
                                                                    headers: 'เกิดความผิดพลาด',
                                                                    message: 'กรุณาเลือกวิธีการการชำระเงิน',
                                                                    enable: true
                                                                })
                                                            }}>ยืนยันการสั่งซื้อ</button>
                                                        :
                                                        null
                                                }
                                                <button type="button" className="btn btn-primary text-white mb-0 font-weight-normal px-5 px-md-2 px-lg-2 w-100 mt-3" onClick={() => {
                                                    navigate(-1)
                                                }}>กลับไปยังก่อนหน้า</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>

            <div className='payment-bookbank' style={{ display: modalbookbank ? "block" : "none" }}>
                <div className='payment-bookbank-wrapper'>
                    <h6 className="modal-title font-th py-0 mb-0">บัญชีธนาคารสำหรับชำระเงิน</h6>
                    <h6 className="modal-subtitle font-th py-0 mb-0">เลขที่อ้างอิง : {bookbank_info.payment_id}</h6>
                    <div className="font-th mb-3 text-success" style={{ fontSize: "14px" }}>คำสั่งซื้อของท่านสำเร็จ กรุณาชำระเงินพร้อมแนบสลิป</div>

                    <div className="d-flex border-bottom pb-2 mt-2">
                        <div className='payment-bookbank-img-wrapper'>
                            <img src={"/images/KBank-logo.jpg"}></img>
                        </div>
                        <div className="d-block product-detail ml-2">
                            <div className="font-th text-success mb-0" style={{ fontSize: "16px" }}>บัญชีธนาคารกสิกรไทย</div>
                            <div className="font-th mt-0 mb-0" style={{ fontSize: "16px" }}>ชื่อบัญชี {bookbank_info.name}</div>
                            <div className="font-th product-desc mt-0" style={{ fontSize: "16px" }}>เลขที่บัญชี {bookbank_info.account_no}</div>
                            <div className="font-th product-desc mt-2" style={{ fontSize: "16px" }}>*ยอดที่ต้องชำระ <span style={{ color: "red" }}> {bookbank_info.payment_total}</span> บาท</div>
                            <h6 className="font-th text-danger">*กรุณาชำระภายใน {dayjs(new Date(bookbank_info.payment_expire)).format('DD MMM BBBB HH:mm')}</h6>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <form id="form-payslip" action="" method="POST" encType="multipart/form-data">
                                <h6 className="font-th mt-1 text-danger">*กรุณาแนบสลิปโอนเงิน</h6>
                                <input className="form-control mt-2" accept="image/*" type="file" id="payslip" name="payslip" required />
                                <label className="font-th">ท่านสารมารถปิดหน้าต่างนี้ และแนบหลักฐานการชำระเงินได้ในเมนู ร้านค้าของฉัน</label>
                            </form>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className='d-flex mt-4 justify-content-end gap-2'>
                                <button className="btn btn-success text-white font-th" type="button" onClick={() => { api_upload_payslip() }}><i className="fe-save"></i> ยืนยันแนบสลิป</button>
                                <button className="btn btn-light font-th modal-forceclose" type="button" onClick={() => { cancel_bookbank() }}><i className="fe-x"></i> ปิด</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modalbookbank ?
                    <div className='meya-backdrop'></div>
                    :
                    null
            }
        </main>
    )
}

export default MyPayment