import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './MarketPlace.css';
import './CardMyCart.css';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';

import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyCart(props) {
    const navigate = useNavigate();
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams()

    const [remove_id, setRemove_id] = useState('')
    const [mycart_data, setMycart_data] = useState([])
    const [mycart_summary, setMycart_summary] = useState([])
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        submit_deletecart(remove_id)
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        api_query_mycart()
    }, [location]);

    useEffect(() => {
        var temp = []
        mycart_data.map(shop => {
            var sum = 0;
            const nextcartitem = shop.cartitem.map(cartitem => {
                cartitem.cartitem_pricelist.map(cartitem_pricelist => {
                    sum += cartitem_pricelist.subtotal
                })
            })
            temp.push({
                shop_id: shop.seller_shopid,
                shop_name: shop.seller_shopname,
                shop_price: sum,
            })
        })
        setMycart_summary(temp)
    }, [mycart_data]);

    function calitemubtotal(normal, porimotion, select) {
        if (Number(porimotion) > 0) {
            return Number(porimotion) * Number(select)
        } else {
            return Number(normal) * Number(select)
        }
    }

    function selectunit(cart_id, cartitem_id, pricelist_id, exp, unit, value) {
        const nexttableitem = mycart_data.map(shop => {
            if (shop.cart_id !== cart_id) {
                return shop;
            } else {
                const nextcartitem = shop.cartitem.map(cartitem => {
                    if (cartitem.cartitem_id === cartitem_id && cartitem.select_common === true) {
                        const nextpriceitem = cartitem.cartitem_pricelist.map(cartitem_pricelist => {
                            if (cartitem_pricelist.pricelist_id === pricelist_id) {
                                if (value > 0) {
                                    var fnd = cartitem.on_stock_array.find((element) => {
                                        return element.expire === exp;
                                    })
                                    if (fnd !== undefined) {
                                        var new_sum = Number(value) * Number(unit)
                                        if (new_sum <= Number(fnd.stock)) {
                                            return {
                                                ...cartitem_pricelist,
                                                select: value,
                                                subtotal: calitemubtotal(cartitem_pricelist.price, cartitem_pricelist.pricepromotion, value),
                                            };
                                        } else {
                                            return cartitem_pricelist;
                                        }
                                    } else {
                                        return {
                                            ...cartitem_pricelist,
                                            select: 0,
                                            subtotal: 0,
                                        };
                                    }
                                } else {
                                    return {
                                        ...cartitem_pricelist,
                                        select: 0,
                                        subtotal: 0,
                                    };
                                }
                            } else {
                                return cartitem_pricelist;
                            }
                        });
                        return {
                            ...cartitem,
                            cartitem_pricelist: nextpriceitem,
                        };
                    } else {
                        return cartitem;
                    }
                });
                return {
                    ...shop,
                    cartitem: nextcartitem,
                };
            }
        });
        setMycart_data(nexttableitem);
    }

    function stepup(cart_id, cartitem_id, pricelist_id, exp, unit) {
        const nexttableitem = mycart_data.map(shop => {
            if (shop.cart_id !== cart_id) {
                return shop;
            } else {
                const nextcartitem = shop.cartitem.map(cartitem => {
                    if (cartitem.cartitem_id === cartitem_id && cartitem.select_common === true) {
                        const nextpriceitem = cartitem.cartitem_pricelist.map(cartitem_pricelist => {
                            if (cartitem_pricelist.pricelist_id === pricelist_id) {
                                var fnd = cartitem.on_stock_array.find((element) => {
                                    return element.expire === exp;
                                })
                                if (fnd !== undefined) {
                                    var previous_sum = 0;
                                    cartitem.cartitem_pricelist.map(item => {
                                        previous_sum += (Number(item.select) * Number(item.unit))
                                    });
                                    var new_sum = previous_sum + Number(unit)
                                    if (new_sum <= Number(fnd.stock)) {
                                        return {
                                            ...cartitem_pricelist,
                                            select: Number(cartitem_pricelist.select) + 1,
                                            subtotal: calitemubtotal(cartitem_pricelist.price, cartitem_pricelist.pricepromotion, Number(cartitem_pricelist.select) + 1),
                                        };
                                    } else {
                                        return cartitem_pricelist;
                                    }
                                } else {
                                    return {
                                        ...cartitem_pricelist,
                                        select: 0,
                                        subtotal: 0,
                                    };
                                }
                            } else {
                                return cartitem_pricelist;
                            }
                        });
                        return {
                            ...cartitem,
                            cartitem_pricelist: nextpriceitem,
                        };
                    } else {
                        return cartitem;
                    }
                });
                return {
                    ...shop,
                    cartitem: nextcartitem,
                };
            }
        });
        setMycart_data(nexttableitem);
    }

    function stepdown(cart_id, cartitem_id, pricelist_id) {
        const nexttableitem = mycart_data.map(shop => {
            if (shop.cart_id !== cart_id) {
                return shop;
            } else {
                const nextcartitem = shop.cartitem.map(cartitem => {
                    if (cartitem.cartitem_id === cartitem_id && cartitem.select_common === true) {
                        const nextpriceitem = cartitem.cartitem_pricelist.map(cartitem_pricelist => {
                            if (cartitem_pricelist.pricelist_id === pricelist_id) {
                                if (Number(cartitem_pricelist.select) > 0) {
                                    return {
                                        ...cartitem_pricelist,
                                        select: Number(cartitem_pricelist.select) - 1,
                                        subtotal: calitemubtotal(cartitem_pricelist.price, cartitem_pricelist.pricepromotion, Number(cartitem_pricelist.select) - 1),
                                    };
                                } else {
                                    return cartitem_pricelist;
                                }
                            } else {
                                return cartitem_pricelist;
                            }
                        });
                        return {
                            ...cartitem,
                            cartitem_pricelist: nextpriceitem,
                        };
                    } else {
                        return cartitem;
                    }
                });
                return {
                    ...shop,
                    cartitem: nextcartitem,
                };
            }
        });
        setMycart_data(nexttableitem);
    }

    function removepricelist(cart_id, cartitem_id, pricelist_id, cartitem) {
        const nexttableitem = mycart_data.map(shop => {
            if (shop.cart_id !== cart_id) {
                return shop;
            } else {
                const nextcartitem = shop.cartitem.map(cartitem => {
                    if (cartitem.cartitem_id === cartitem_id && cartitem.select_common === true) {
                        const nextpriceitem = cartitem.cartitem_pricelist.filter(function (item) {
                            return item.pricelist_id !== pricelist_id
                        })
                        return {
                            ...cartitem,
                            cartitem_pricelist: nextpriceitem,
                        };
                    } else {
                        return cartitem;
                    }
                });
                const nextitem = nextcartitem.filter(function (item) {
                    return item.cartitem_pricelist.length > 0
                })
                return {
                    ...shop,
                    cartitem: nextitem,
                };
            }
        });
        setMycart_data(nexttableitem);
    }

    function unselect_item(value, cart_id, cartitem_id) {
        const nexttableitem = mycart_data.map(shop => {
            if (shop.cart_id !== cart_id) {
                return shop;
            } else {
                const nextcartitem = shop.cartitem.map(cartitem => {
                    if (cartitem.cartitem_id === cartitem_id) {
                        return {
                            ...cartitem,
                            select_common: value,
                        };
                    } else {
                        return cartitem;
                    }
                });
                return {
                    ...shop,
                    cartitem: nextcartitem,
                };
            }
        });
        setMycart_data(nexttableitem);
        ckeckbox_clearquantity(nexttableitem, value, cart_id, cartitem_id)
    }

    function ckeckbox_clearquantity(data, value, cart_id, cartitem_id) {
        if (value === false) {
            const nexttableitem = data.map(shop => {
                if (shop.cart_id !== cart_id) {
                    return shop;
                } else {
                    const nextcartitem = shop.cartitem.map(cartitem => {
                        if (cartitem.cartitem_id === cartitem_id) {
                            const nextpriceitem = cartitem.cartitem_pricelist.map(cartitem_pricelist => {
                                return {
                                    ...cartitem_pricelist,
                                    select: 0,
                                    subtotal: 0,
                                };
                            });
                            return {
                                ...cartitem,
                                cartitem_pricelist: nextpriceitem,
                            };
                        } else {
                            return cartitem;
                        }
                    });
                    return {
                        ...shop,
                        cartitem: nextcartitem,
                    };
                }
            });
            setMycart_data(nexttableitem);
        }
    }

    function submit_deletecart(cart_id) {
        setMycart_data(mycart_data.filter(function (item) {
            return item.cart_id !== cart_id
        }))
    }

    async function api_query_mycart() {
        setmodal_load({ enable: true, message: "กำลังโหลดตะกร้าสินค้า" })
        await sleep(200)
        const formData = new FormData();
        formData.append('buyer_shopid', props.permitdata.shop_id)
        formData.append('coupon_id', "")
        formData.append('deliver_req', "disable")
        formData.append('sourcetype', "cart")

        axios.post(props.app_hostname + "/api/order/mycart/cartlist", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    if (response.data.cartdata !== undefined) {
                        setMycart_data(response.data.cartdata)
                        props.callback_mycart("edit", response.data.cartdata.length)
                    } else {
                        props.callback_mycart("edit", 0)
                    }
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_savecartpay() {
        if (mycart_data.length > 0) {
            setmodal_load({ enable: true, message: "กำลังบันทึกตะกร้าสินค้า" })
            await sleep(200)

            var formData = new FormData();
            formData.append("cart_data", JSON.stringify(mycart_data));
            formData.append('buyer_shopid', props.permitdata.shop_id)

            axios.post(props.app_hostname + "/api/order/mycart/edit", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        if (response.data.itemselected > 0) {
                            navigate('/marketplace/mypayment?source=cart&shopitem=none&item=none')
                        } else {
                            setModal_popup({
                                modaltype: 'modal-warning',
                                headers: 'ขออภัย',
                                message: 'ขออภัย กรุณาเลือกสินค้าเพื่อชำระเงิน',
                                enable: true
                            });
                        }
                    }
                    else {
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ผิดพลาด',
                        message: err.message,
                        enable: true
                    });
                });
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: 'ไม่มีรายการสินค้าในตะกร้าให้สั่งซื้อ',
                enable: true
            });
        }
    }

    async function api_savecart() {
        if (mycart_data.length > 0 || (mycart_data.length === 0 && props.mycartlen > 0)) {
            setmodal_load({ enable: true, message: "กำลังบันทึกตะกร้าสินค้า" })
            await sleep(200)

            var formData = new FormData();
            formData.append("cart_data", JSON.stringify(mycart_data));
            formData.append('buyer_shopid', props.permitdata.shop_id)

            axios.post(props.app_hostname + "/api/order/mycart/edit", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        props.callback_mycart("edit", response.data.cart_count)
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'สำเร็จ',
                            message: response.data.message,
                            enable: true
                        });
                    }
                    else {
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ผิดพลาด',
                        message: err.message,
                        enable: true
                    });
                });
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: 'ไม่มีรายการสินค้าในตะกร้าให้บันทึก',
                enable: true
            });
        }
    }

    function getinpuralertstock(selectvalue, expire, stockarray) {
        var res = stockarray.filter(function (item) {
            return item.expire === expire
        })
        if (res.length > 0) {
            if (selectvalue > res[0].stock) {
                return ' inputnumeric-alert'
            } else {
                return ' border border-color-1'
            }
        } else {
            return ' border border-color-1'
        }
    }

    return (
        <main id="content" role="main" >
            <div className="container">
                <div className="py-2 mt-0">
                    <div className="bg-gray-13 bg-md-transparent">
                        <div className="my-md-0">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble py-0">
                                    <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ซื้อสินค้า</li>
                                    <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ตะกร้าสินค้าของฉัน</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <div className="col d-block min-height-600">
                    <div className="row mb-xl-14 mb-6 mt-2">
                        <div className="col-12 col-md-12 col-lg-8 col-xl-9 col-wd-9 mb-md-1 mb-lg-0">
                            {
                                mycart_data.length > 0 ?
                                    mycart_data.map((seller, index0) => (
                                        <div className="row" key={seller.seller_shopid}>
                                            <div className="col-md-12 mb-md-2 mb-lg-2">
                                                <div className="card-mycart">
                                                    <div className="card-mycart-header d-flex justify-content-between py-2">
                                                        <div className="d-block">
                                                            <div className="card-mycart-title"><i className="fas fa-store-alt text-primary"></i> {seller.seller_shopname}</div>
                                                            <p className="card-mycart-subtitle">จังหวัด {seller.seller_shopprovince}</p>
                                                        </div>
                                                        <div className="d-block pb-0 mb-0" >
                                                            <div className="text-right"> จำนวน {seller.cartitem.length} รายการ</div>
                                                            <label className="cartremovebutton mt-1" onClick={() => {
                                                                setRemove_id(seller.cart_id)
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านต้องการลบตะกร้าสินค้านี้ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true
                                                                });
                                                            }} style={{ cursor: "pointer" }}>X ลบตะกร้าสินค้านี้</label>
                                                        </div>
                                                    </div>
                                                    <div className="card-mycart-body">
                                                        <div className="d-block d-lg-flex justify-content-between py-0 px-0">
                                                            <div className="col-auto px-0">
                                                                {
                                                                    seller.cartitem.map((cartitems, index1) => (
                                                                        <div className={"d-block d-xl-flex py-2 " + (index1 > 0 ? "border-top" : "")} key={cartitems.cartitem_id}>
                                                                            <div className="d-flex">
                                                                                <div className="mycart-box-check">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input type="checkbox" className="custom-control-input" id={`${cartitems.cartitem_id}`} checked={cartitems.select_common} onChange={(e) => { unselect_item(e.target.checked, cartitems.cart_id, cartitems.cartitem_id) }} />
                                                                                        <label className="custom-control-label font-th" htmlFor={`${cartitems.cartitem_id}`}>
                                                                                            <span className="text-gray-25 font-size-12 font-weight-normal"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + `${cartitems.productitem_id}/` + `${cartitems.productitem_id}` + "-1-" + cartitems.img1_key + ".jpg"} alt="Image Description" /></div>
                                                                                <div className='d-block'>
                                                                                    <div className="mycart-box-productname py-0">{cartitems.productitem_name}</div>
                                                                                    <div className="mycart-box-productinter py-0">{cartitems.productitem_inter}</div>
                                                                                    <label className='mycart-box-productname py-0 font-size-12 mb-0'>จำนวนคงเหลือ</label>
                                                                                    {
                                                                                        cartitems.on_stock_array.map((stockitem, index1) => (
                                                                                            <div className="mycart-box-productinter py-0 mx-0 px-0" key={stockitem.expire}>
                                                                                                <div className="market-avai-table-row font-size-12 px-0 mx-0">
                                                                                                    <div className="market-avai-table-cell font-th text-gray-111 c1 ">Exp. {dayjs(new Date(stockitem.expire)).format('DD MMM BBBB')}</div>
                                                                                                    <div className="market-avai-table-cell font-th text-gray-111 c2">{stockitem.stock}</div>
                                                                                                    <div className="market-avai-table-cell font-th text-gray-111 c2">ชิ้น</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="mycart-box-pricelist">
                                                                                <table>
                                                                                    <tbody>
                                                                                        {
                                                                                            cartitems.cartitem_pricelist.map((pricelist, index1) => (
                                                                                                <tr className="mycart-table-pricelist-row" id={pricelist.pricelist_id} key={pricelist.pricelist_id} >
                                                                                                    <td className="product-unit">{pricelist.unit} ชิ้น / {calitemubtotal(Number(pricelist.price), Number(pricelist.pricepromotion), 1)} บาท</td>
                                                                                                    <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                                    <td> <div className={"rounded-pill py-1 width-122 w-xl-80 px-3 product-quantity" + getinpuralertstock(pricelist.select, pricelist.expire, cartitems.on_stock_array)} >
                                                                                                        <div className="js-quantity row align-items-center">
                                                                                                            <div className="col ">
                                                                                                                <input className="js-result form-control h-auto border-0 rounded p-0 shadow-none" type="text" value={pricelist.select} onChange={(e) => { selectunit(pricelist.cart_id, pricelist.cartitem_id, pricelist.pricelist_id, pricelist.expire, pricelist.unit, e.target.value) }} />
                                                                                                            </div>
                                                                                                            <div className="col-auto pr-1">
                                                                                                                <div className="js-minus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0" onClick={() => { stepdown(pricelist.cart_id, pricelist.cartitem_id, pricelist.pricelist_id) }}>
                                                                                                                    <small className="fas fa-minus btn-icon__inner"></small>
                                                                                                                </div>
                                                                                                                <div className="js-plus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0" onClick={() => { stepup(pricelist.cart_id, pricelist.cartitem_id, pricelist.pricelist_id, pricelist.expire, pricelist.unit) }}>
                                                                                                                    <small className="fas fa-plus btn-icon__inner"></small>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    </td>
                                                                                                    <td className="product-subtotal">
                                                                                                        <span className="">฿ {pricelist.subtotal}</span>
                                                                                                    </td>
                                                                                                    <td className="">
                                                                                                        <div className="removebutton" onClick={() => { removepricelist(pricelist.cart_id, pricelist.cartitem_id, pricelist.pricelist_id, cartitems) }}>X ลบ
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ไม่มีรายการสินค้าในตะกร้าของคุณ</div>
                            }
                        </div>
                        <div className="col-12 col-md-12 col-lg-4 col-xl-3 col-wd-3 mt-sm-2 mt-md-0">
                            <div className="row">
                                <div className="col-md-12 mb-md-2 mb-lg-0">
                                    <div className="mycart-summary font-th">
                                        <div className="summary-box">
                                            <h6 className="mycart-summary-location text-grey-111">จำนวนเงินรวม</h6>
                                            <div className="table-summary border-bottom mb-2 pb-3">
                                                {
                                                    mycart_summary.map((seller, index) => (
                                                        <div className="table-summary-row text-gray-15" key={seller.shop_id}>
                                                            <div className="table-summary-cell">{index + 1}. {seller.shop_name}</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(seller.shop_price).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                    ))
                                                }
                                                <div className="table-summary-row font-size-16 text-success">
                                                    <div className="table-summary-cell">รวม</div>
                                                    <div className="table-summary-cell aligh-right">฿ {Number(mycart_summary.reduce((total, currentValue) => total = total + Number(currentValue.shop_price), 0)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                            </div>
                                            <div className='d-block py-1' >
                                                <p className='mb-0' >ยอดที่แสดง ยังไม่รวมค่าขนส่ง</p>
                                                <p className='mt-0 mb-1' >ค่าขนส่งจะแสดงหลังจากกดปุ่ม "ดำเนินการสั่งซื้อ"</p>
                                            </div>
                                            <div className="mt-3">
                                                <button type="button" className="btn btn-primary text-white mb-0 font-weight-normal px-5 px-md-2 px-lg-2 w-100 mt-3" onClick={() => { api_savecartpay() }}>ดำเนินการสั่งซื้อ</button>
                                                <button type="button" className="btn btn-primary text-white mb-0 font-weight-normal px-5 px-md-2 px-lg-2 w-100 mt-3" onClick={() => { api_savecart() }}>บันทึกตะกร้าสินค้า</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </main>
    )
}

export default MyCart