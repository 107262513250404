import React from 'react';
import SlideHome from '../components/SlideHome';
import CardBuySale from '../components/CardBuySale';
import StepBuySale from '../components/StepBuySale';

function Home(props) {
    return (
        <main id="content" role="main">
            <SlideHome></SlideHome>
            <div className="container">
                <div className="row">
                    <CardBuySale imagepath="/images/welcome-bestbuy.jpg"
                        header="ซื้อสินค้าที่ดีที่สุด"
                        keyword="หาง่าย เลือกได้ ขายไว ชุมชนสำหรับร้านยา และคลินิกสำหรับซื้อขายยาและเวชภัณฑ์"
                        bottomtext="ซื้อสินค้า"
                        pathlink="/marketplace"
                        permitdata={props.permitdata}
                        mode={"buyer"}
                    />
                    <CardBuySale imagepath="/images/welcome-salewithus.png"
                        header="ขายสินค้ากับเรา"
                        keyword="ติดปีกร้านยาให้เป็นร้านค้าส่ง ง่ายแค่ปลายนิ้ว มีของเยอะขายไม่ทัน ปล่อยได้ที่ มียา"
                        bottomtext="เริ่มขายสินค้า"
                        pathlink="/myshop/seller/jobtodo"
                        permitdata={props.permitdata}
                        mode={"seller"}
                    />
                </div>

                <div className="row">
                    <StepBuySale stepname="ขั้นตอนการสั่งซื้อสินค้า"
                        step1={{ iconclass: "text-primary mdi mdi-compare-horizontal font-size-46", header: "COMPARE", text: "เปรียบเทียบราคาสินค้าจากแต่ละร้านค้า" }}
                        step2={{ iconclass: "text-primary mdi mdi-shopping-outline font-size-46", header: "ORDER", text: "สั่งซื้อสินค้าจากร้านค้าที่ดีที่สุด" }}
                        step3={{ iconclass: "text-primary mdi mdi-truck-fast-outline font-size-46", header: "DELIVERY", text: "รอสินค้าที่จะจัดส่งไปให้คุณจากทั่วประเทศ" }}
                        step4={{ iconclass: "text-primary mdi mdi-dresser-outline font-size-46", header: "STOCK", text: "รับสินค้าที่จัดส่งจากร้านค้าและนำเข้าสต็อค" }}
                        button={{ text: "ซื้อสินค้า", pathlink: "/marketplace" }}
                        permitdata={props.permitdata}
                        mode={"buyer"}
                    />
                </div>
                <div className="row">
                    <StepBuySale stepname="ขั้นตอนการขายสินค้า"
                        step1={{ iconclass: "text-primary mdi mdi-store-outline font-size-46", header: "REGISTER", text: "ลงทะเบียนร้านค้าของคุณ" }}
                        step2={{ iconclass: "text-primary mdi mdi-playlist-plus font-size-46", header: "LISTING", text: "ลงขายสินค้าในร้านค้าของคุณ" }}
                        step3={{ iconclass: "text-primary mdi mdi-file-check-outline font-size-46", header: "TAKE ORDER", text: "รับคำสั่งซื้อ" }}
                        step4={{ iconclass: "text-primary mdi mdi-truck-fast-outline font-size-46", header: "DELIVERY", text: "จัดส่งสินค้าตามคำสั่งซื้อ" }}
                        button={{ text: "เริ่มขายสินค้า", pathlink: "/myshop/seller/jobtodo" }}
                        permitdata={props.permitdata}
                        mode={"seller"}
                    />
                </div>
            </div>
        </main>
    )
}

export default Home