import React, { useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";
import axios from 'axios';

import ButtonGoto from '../../components/buttongoto/ButtonGoto';
import './signup.css';
import Policy from './Policy';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import moment from 'moment'

function Login(props) {
    const [remember_me, setRemember_me] = useState({
        rem: false,
        user: '',
        pass: ''
    })

    const [policy_read, setPolicy_read] = useState(false);

    const [phone_format, setPhone_format] = useState('')
    const [otp_phone_format, setOtp_phone_format] = useState('')
    const [otp_code_format, setOtp_code_format] = useState('')
    const [otp_refcode, setOtp_refcode] = useState('')
    const [otp_expire, setOtp_expire] = useState('')

    const [list_province, setList_province] = useState([]);
    const [list_amphur, setList_amphur] = useState([]);
    const [list_tambol, setList_tambol] = useState([]);

    const [select_province, setSelect_province] = useState('');
    const [select_amphur, setSelect_amphur] = useState('');
    const [select_tambol, setSelect_tambol] = useState('');
    const [select_postcode, setSelect_postcode] = useState('');

    const [box_policy, setBox_policy] = useState(false);
    const [box_forgot, setBox_forgot] = useState(false);
    const [box_signin, setBox_signin] = useState(false);
    const [box_signup, setBox_signup] = useState(false);
    const [box_signup_step, setBox_signup_step] = useState(1);
    const [box_signup_doc, setBox_signup_doc] = useState(false);
    const [box_signup_docemail, setBox_signup_docemail] = useState('');
    const [box_signup_docshopid, setBox_signup_docshopid] = useState('');
    const [box_signup_doctoken, setBox_signup_doctoken] = useState('');
    const [box_signup_submit, setBox_signup_submit] = useState(false);
    const [box_signup_next, setBox_signup_next] = useState(true);
    const [box_signup_previous, setBox_signup_previous] = useState(false);
    const [box_signup_haveaccount, setBox_signup_haveaccount] = useState(true);
    const [box_signup_time, setBox_signup_time] = useState(new Date());

    const [box_signup_stylep1, setBox_signup_stylep1] = useState({ display: "block" });
    const [box_signup_stylep2, setBox_signup_stylep2] = useState({ display: "none" });
    const [box_signup_stylep3, setBox_signup_stylep3] = useState({ display: "none" });
    const [box_signup_stylep4, setBox_signup_stylep4] = useState({ display: "none" });
    const [box_signup_stylep5, setBox_signup_stylep5] = useState({ display: "none" });

    const [password_eye1, setPassword_eye1] = useState(false);
    const [password_eye1_type, setPassword_eye1_type] = useState({
        icon: 'fe-eye',
        type: 'password'
    });
    const [password_eye2, setPassword_eye2] = useState(false);
    const [password_eye2_type, setPassword_eye2_type] = useState({
        icon: 'fe-eye',
        type: 'password'
    });

    const [box_signup_progressline, setBox_signup_progressline] = useState({ width: "25%" });
    const signup_stepdetailArray = [
        'ข้อมูลบัญชีผู้ใช้',
        'ข้อมูลบัญชีผู้ใช้',
        'ข้อมูลร้านขายยา',
        'ยืนยัน OTP',
        'ยืนยัน OTP',
        'เอกสารสำคัญ',
    ];

    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        if ((box_signup_step === 4 && modal_popup.modaltype === 'modal-submitdoc') || (box_signup_step === 5 && modal_popup.modaltype === 'modal-success')) {
            setOtp_refcode('')
            setOtp_expire('')
            setOtp_phone_format('')
            setOtp_code_format('')

            setBox_signup_step(0)
            setBox_signin(false)
            setBox_signup(false)
            setBox_signup_doc(false)
        }

        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        signup_next()
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    useEffect(() => {

        const interval = setInterval(() => setBox_signup_time(new Date()), 1000);
        return () => clearInterval(interval);
    }, []);

    function getOTPexpire() {
        var a = moment(box_signup_time);//now
        var b = moment(otp_expire);
        var diff = a.diff(b, 'seconds')
        var countback = 180 - diff
        if (countback < 1) {
            return <div className='text-center mt-2'><p className='text-center text-red mt-2'>OTP ของคุณหมดอายุ กรุณาลองใหม่อีกครั้ง</p><button type="button" className='btn btn-sm btn-warning' onClick={() => { get_smsotp() }}>ขอรหัส OTP อีกครั้ง</button></div>
        }
        return <p className='text-center mt-2'>หมดอายุภายใน <span className="font-weight-bold text-primary">{countback}</span> วินาที</p>

    }

    const getRememberObject = () => {
        const remString = sessionStorage.getItem('meya-rem');
        if (remString !== "undefined" && remString !== null) {
            const userRem = JSON.parse(remString);
            return userRem
        }
        return {
            rem: false,
            user: '',
            pass: ''
        }
    };
    const saveRemember = userRem => {
        sessionStorage.setItem('meya-rem', JSON.stringify(userRem));
    };

    const saveToken = userToken => {
        sessionStorage.setItem('meya-token4', JSON.stringify(userToken));
    };

    useEffect(() => {
        setRemember_me(getRememberObject())
        saveToken({
            shop_id: "-",
            shop_email: "-",
            shop_username: "-",
            shop_firstname: "-",
            shop_lastname: "-",
            shop_name: "-",
            shop_address: "-",
            shop_country: "-",
            shop_province: "-",
            shop_district: "-",
            shop_subdistrict: "-",
            shop_postcode: "-",
            shop_telno: "-",
            status: "-",
            token: "",
            logintime: '',
            role: 'guest',
            messages_chat_unread: 0
        })
        GetProvince()
    }, []);

    function open_signin() {
        setPolicy_read(false)
        setOtp_refcode('')
        setOtp_expire('')
        setPhone_format('')
        setOtp_phone_format('')
        setOtp_code_format('')

        setBox_policy(false)
        setBox_forgot(false)
        setBox_signup(false)
        setBox_signin(true)
        setBox_signup_doc(false)
        setBox_signup_step(1)
        setBox_signup_docemail('')
        setPassword_eye1(false)
        setPassword_eye2(false)
    }

    function open_register() {
        setPolicy_read(false)
        setOtp_refcode('')
        setOtp_expire('')
        setPhone_format('')
        setOtp_phone_format('')
        setOtp_code_format('')

        setBox_signup_step(1)
        setBox_policy(false)
        setBox_forgot(false)
        setBox_signin(false)
        setBox_signup(true)
        setBox_signup_doc(false)
        setBox_signup_docemail('')

        setPassword_eye1(false)
        setPassword_eye2(false)
    }

    function open_forgot() {
        setPolicy_read(false)
        setOtp_refcode('')
        setOtp_expire('')
        setPhone_format('')
        setOtp_phone_format('')
        setOtp_code_format('')

        setBox_policy(false)
        setBox_signup(false)
        setBox_signin(false)
        setBox_forgot(true)
        setBox_signup_doc(false)
        setBox_signup_step(1)
        setBox_signup_docemail('')

        setPassword_eye1(false)
        setPassword_eye2(false)
    }

    useEffect(() => {
        if (props.popup_signin) {
            open_signin()
        }
    }, [props.popup_signin]);

    useEffect(() => {
        if (props.popup_register) {
            open_register()
        }
    }, [props.popup_register]);

    function signup_next() {
        var forms = document.getElementById('form-signup-p' + box_signup_step.toString());
        if (box_signup_step === 1) {
            var password = document.getElementById('shop_password');
            var confirm_password = document.getElementById('shop_confirmpassword');
            if (password.value !== confirm_password.value) {
                confirm_password.setCustomValidity("Passwords Don't Match");
            } else {
                confirm_password.setCustomValidity("");
            }
        }
        if (forms.checkValidity() === false) {
            forms.classList.add("was-validated");
            var shop_email = document.getElementById('shop_email').value
            if (shop_email !== "") {
                check_emailduplicate_holdstep()
            }
        } else {
            if (box_signup_step > 1) {
                forms.classList.remove('was-validated');
                setBox_signup_step(box_signup_step + 1)
            } else {
                check_emailduplicate()
            }
        }
    }

    function signup_previous() {
        setBox_signup_step(box_signup_step - 1)
    }

    useEffect(() => {
        if (box_signup_step > 1 && box_signup_step < 5) {
            setBox_signup_previous(true)
        } else {
            setBox_signup_previous(false)
        }

        if (box_signup_step < 4) {
            setBox_signup_next(true)
            setBox_signup_submit(false)
        } else {
            setBox_signup_next(false)
            setBox_signup_submit(true)
        }

        if (box_signup_step === 1) {
            setBox_signup_haveaccount(true)
        } else {
            setBox_signup_haveaccount(false)
        }

        if (box_signup_step === 1) {
            setBox_signup_stylep1({ display: "block" })
            setBox_signup_stylep2({ display: "none" })
            setBox_signup_stylep3({ display: "none" })
            setBox_signup_stylep4({ display: "none" })
            setBox_signup_stylep5({ display: "none" })
            setBox_signup_progressline({ width: "12.5%" })
        } else if (box_signup_step === 2) {
            setBox_signup_stylep2({ display: "block" })
            setBox_signup_stylep1({ display: "none" })
            setBox_signup_stylep3({ display: "none" })
            setBox_signup_stylep4({ display: "none" })
            setBox_signup_stylep5({ display: "none" })
            setBox_signup_progressline({ width: "37.5%" })
        } else if (box_signup_step === 3) {
            setBox_signup_stylep3({ display: "block" })
            setBox_signup_stylep1({ display: "none" })
            setBox_signup_stylep2({ display: "none" })
            setBox_signup_stylep4({ display: "none" })
            setBox_signup_stylep5({ display: "none" })
            setBox_signup_progressline({ width: "62.5%" })
        } else if (box_signup_step === 4) {
            setBox_signup_stylep4({ display: "block" })
            setBox_signup_stylep1({ display: "none" })
            setBox_signup_stylep2({ display: "none" })
            setBox_signup_stylep3({ display: "none" })
            setBox_signup_stylep5({ display: "none" })
            setBox_signup_progressline({ width: "87.5%" })
        } else if (box_signup_step === 5) {
            setBox_signup_stylep5({ display: "block" })
            setBox_signup_stylep1({ display: "none" })
            setBox_signup_stylep2({ display: "none" })
            setBox_signup_stylep3({ display: "none" })
            setBox_signup_stylep4({ display: "none" })
            setBox_signup_progressline({ width: "100%" })
        }
        var forms = document.getElementById('form-signup-p' + box_signup_step.toString());
        if (forms !== null) {
            forms.classList.remove('was-validated');
        }

        if (box_signup_step === 3) {
            setOtp_refcode('')
            setOtp_code_format('')
        }
        if (box_signup_step === 4 && otp_phone_format !== '') {
            get_smsotp()
        }

        setPassword_eye1(false)
        setPassword_eye2(false)
    }, [box_signup_step]);

    function check_emailduplicate_holdstep() {
        const formData = new FormData();
        formData.append('shop_email', document.getElementById('shop_email').value)

        axios.post(props.app_hostname + "/api/account/register/checkemail", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                'Authorization': 'Basic ' + btoa('Hvqhzd3f_zAXSsK7x2QfslGn0EvBoj')
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    var shop_email = document.getElementById('shop_email')
                    shop_email.setCustomValidity("");
                } else if (response.data.status === 'Duplicate') {
                    var forms = document.getElementById('form-signup-p1');
                    forms.classList.remove('was-validated');
                    var shop_email = document.getElementById('shop_email')
                    shop_email.setCustomValidity("อีเมลนี้มีผู้ใช้งานแล้ว");
                    forms.classList.add("was-validated");
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    function check_emailduplicate() {
        const formData = new FormData();
        formData.append('shop_email', document.getElementById('shop_email').value)

        axios.post(props.app_hostname + "/api/account/register/checkemail", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                'Authorization': 'Basic ' + btoa('Hvqhzd3f_zAXSsK7x2QfslGn0EvBoj')
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {

                    var shop_email = document.getElementById('shop_email')
                    shop_email.setCustomValidity("");
                    var forms = document.getElementById('form-signup-p1');
                    forms.classList.remove('was-validated');
                    setBox_signup_step(box_signup_step + 1)
                } else if (response.data.status === 'Duplicate') {
                    var forms = document.getElementById('form-signup-p1');
                    forms.classList.remove('was-validated');
                    var shop_email = document.getElementById('shop_email')
                    shop_email.setCustomValidity("อีเมลนี้มีผู้ใช้งานแล้ว");
                    forms.classList.add("was-validated");
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    function get_smsotp() {
        setOtp_refcode('')
        var mobileotp = otp_phone_format.replaceAll("-", "")
        const formData = new FormData();
        formData.append('otp_telno', mobileotp)

        axios.post(props.app_hostname + "/api/account/register/otprequest", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                'Authorization': 'Basic ' + btoa('Hvqhzd3f_zAXSsK7x2QfslGn0EvBoj')
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    setOtp_refcode(response.data.data.refcode)
                    setOtp_expire(response.data.data.ontime)
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                    setOtp_refcode('')
                }
            })
            .catch((err) => {
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
                setOtp_refcode('')
            });
    }

    async function signup_submit() {
        var forms = document.getElementById('form-signup-p' + box_signup_step.toString());
        if (forms.checkValidity() === false) {
            forms.classList.add("was-validated");
        } else {
            setmodal_load({ enable: true, message: "กำลังสมัครสมาชิก" })
            await sleep(500)

            forms.classList.remove('was-validated');

            const formData = new FormData();
            var suburl = "/api/account/register/submitaccount"
            if (box_signup_step === 4) {
                suburl = "/api/account/register/submitaccount"
                formData.append('shop_firstname', document.getElementById('shop_firstname').value)
                formData.append('shop_lastname', document.getElementById('shop_lastname').value)
                formData.append('shop_email', document.getElementById('shop_email').value)
                formData.append('shop_username', document.getElementById('shop_username').value)
                formData.append('shop_password', document.getElementById('shop_password').value)
                formData.append('shop_name', document.getElementById('shop_name').value)
                formData.append('shop_address', document.getElementById('shop_address').value)
                formData.append('shop_province', select_province)
                formData.append('shop_district', select_amphur)
                formData.append('shop_subdistrict', select_tambol)
                formData.append('shop_postcode', select_postcode)
                formData.append('shop_telno', document.getElementById('shop_telno').value)

                var mobileotp = otp_phone_format.replaceAll("-", "")
                formData.append('otp_telno', mobileotp)
                formData.append('refcode', otp_refcode)
                formData.append('otp', otp_code_format)
            } else if (box_signup_step === 5) {
                suburl = "/api/account/register/submitdocument"
                formData.append('shop_email', box_signup_docemail)
                formData.append('shop_id', box_signup_docshopid)

                formData.append('file1', document.getElementById('customFile1').files[0]);
                formData.append('file2', document.getElementById('customFile2').files[0]);
                formData.append('file3', document.getElementById('customFile3').files[0]);
                formData.append('file4', document.getElementById('customFile4').files[0]);
            }

            axios.post(props.app_hostname + suburl, formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    'Authorization': 'Basic ' + btoa('Hvqhzd3f_zAXSsK7x2QfslGn0EvBoj')
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        if (box_signup_step === 4) {
                            setBox_signup_docemail(response.data.signin_email)
                            setBox_signup_docshopid(response.data.signin_id)
                            setBox_signup_doctoken(response.data.token)
                            setModal_popup({
                                modaltype: 'modal-submitdoc',
                                headers: 'สำเร็จ',
                                message: response.data.message,
                                enable: true
                            });
                        } else if (box_signup_step === 5) {
                            setModal_popup({
                                modaltype: 'modal-success',
                                headers: 'สำเร็จ',
                                message: response.data.message,
                                enable: true
                            });
                        }
                    } else {
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ผิดพลาด',
                        message: err.message,
                        enable: true
                    });
                });
        }
    }

    async function signup_submit_doc() {
        var forms = document.getElementById('form-submitdoc');
        if (forms.checkValidity() === false) {
            forms.classList.add("was-validated");
        } else {
            setmodal_load({ enable: true, message: "กำลังอัพโหลดเอกสารสำคัญ" })
            await sleep(500)

            forms.classList.remove('was-validated');

            const formData = new FormData();
            formData.append('shop_email', box_signup_docemail)
            formData.append('shop_id', box_signup_docshopid)

            formData.append('file1', document.getElementById('customFile1-resumit').files[0]);
            formData.append('file2', document.getElementById('customFile2-resumit').files[0]);
            formData.append('file3', document.getElementById('customFile3-resumit').files[0]);
            formData.append('file4', document.getElementById('customFile4-resumit').files[0]);

            axios.post(props.app_hostname + "/api/account/register/submitdocument", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + box_signup_doctoken
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        setBox_signup_doc(false)
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'สำเร็จ',
                            message: response.data.message,
                            enable: true
                        });
                    } else {
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ผิดพลาด',
                        message: err.message,
                        enable: true
                    });
                });
        }
    }

    async function handle_forgot() {
        document.getElementById('fotgot_email').setCustomValidity("");
        var formelement = document.getElementById('forgotpanel')
        formelement.classList.remove('was-validated');
        if (formelement.checkValidity() === false) {
            document.getElementById('email-forgot-feedback').innerText = "กรุณากรอกอีเมลที่ท่านได้สมัครไว้"
            formelement.classList.add("was-validated");
        }
        else {
            setmodal_load({ enable: true, message: "ระบบกำลังส่งลิ้งค์ไปยังอีเมล" })
            await sleep(500)

            const formData = new FormData(formelement);
            axios.post(props.app_hostname + "/api/account/auth/passrequest", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    'Authorization': 'Basic ' + btoa('Hvqhzd3f_zAXSsK7x2QfslGn0EvBoj')
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        setBox_forgot(false)
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'สำเร็จ',
                            message: response.data.message,
                            enable: true
                        });
                    } else {
                        var formelement1 = document.getElementById('forgotpanel')
                        formelement1.classList.remove('was-validated');
                        document.getElementById('fotgot_email').setCustomValidity("x");
                        document.getElementById('email-forgot-feedback').innerText = response.data.message
                        formelement1.classList.add("was-validated");
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ผิดพลาด',
                        message: err.message,
                        enable: true
                    });
                });
        }
    }

    function handle_login() {
        var formelement = document.getElementById('accesspanel')
        if (formelement.checkValidity() === false) {
            formelement.classList.add("was-validated");
        }
        else {
            const formData = new FormData(formelement);
            axios.post(props.app_hostname + "/api/account/auth/shop_signin", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    'Authorization': 'Basic ' + btoa('Hvqhzd3f_zAXSsK7x2QfslGn0EvBoj')
                },
            })
                .then((response) => {
                    if (response.data.status === 'OK') {
                        if (remember_me.rem === true) {
                            saveRemember(remember_me)
                        } else {
                            saveRemember({
                                rem: false,
                                user: '',
                                pass: ''
                            })
                        }
                        saveToken(response.data.userinfo)
                        props.callback_signin(
                            response.data.userinfo
                        )
                    } else {
                        saveToken({
                            shop_id: "-",
                            shop_email: "-",
                            shop_username: "-",
                            shop_firstname: "-",
                            shop_lastname: "-",
                            shop_name: "-",
                            shop_address: "-",
                            shop_country: "-",
                            shop_province: "-",
                            shop_district: "-",
                            shop_subdistrict: "-",
                            shop_postcode: "-",
                            shop_telno: "-",
                            status: "-",
                            token: "",
                            logintime: '',
                            role: 'guest',
                            messages_chat_unread: 0
                        })

                        if (response.data.userstatus === 'pending' && response.data.shopdocument === 'pending') {
                            setBox_signup_docemail(response.data.signin_email)
                            setBox_signup_docshopid(response.data.signin_id)
                            setBox_signup_doctoken(response.data.token)
                            setBox_signup_doc(true)
                            setPolicy_read(false)
                            setOtp_refcode('')
                            setOtp_expire('')
                            setPhone_format('')
                            setOtp_phone_format('')
                            setOtp_code_format('')

                            setBox_policy(false)
                            setBox_forgot(false)
                            setBox_signup(false)
                            setBox_signin(false)
                            setBox_signup_step(1)
                        } else {
                            setModal_popup({
                                modaltype: 'modal-warning',
                                headers: 'ขออภัย',
                                message: response.data.message,
                                enable: true
                            });
                        }
                    }
                })
                .catch((err) => {
                    setBox_signup_docemail('')
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ผิดพลาด',
                        message: err.message,
                        enable: true
                    });
                });
        }
    }

    function GetProvince() {
        var formData = new FormData()
        formData.append('province', 'all')
        axios.post(props.app_hostname + "/api/account/register/address/getprovince", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                'Authorization': 'Basic ' + btoa('Hvqhzd3f_zAXSsK7x2QfslGn0EvBoj')
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    setList_province(response.data.data_province)
                    setList_amphur([])
                    setList_tambol([])
                }
            })
            .catch((err) => {
                setList_province([])
                setList_amphur([])
                setList_tambol([])
            });
    }

    function GetAmphur() {
        var formData = new FormData()
        formData.append('province', select_province)
        axios.post(props.app_hostname + "/api/account/register/address/getamphure", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                'Authorization': 'Basic ' + btoa('Hvqhzd3f_zAXSsK7x2QfslGn0EvBoj')
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    setList_amphur(response.data.data_amphure)
                    setList_tambol([])
                }
            })
            .catch((err) => {
                setList_amphur([])
                setList_tambol([])
            });
    }

    function GetTambol() {
        var formData = new FormData()
        formData.append('province', select_province)
        formData.append('amphur', select_amphur)
        axios.post(props.app_hostname + "/api/account/register/address/gettambol", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                'Authorization': 'Basic ' + btoa('Hvqhzd3f_zAXSsK7x2QfslGn0EvBoj')
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    setList_tambol(response.data.data_tambol)
                }
            })
            .catch((err) => {
                setList_tambol([])
            });
    }

    useEffect(() => {
        setList_amphur([])
        setList_tambol([])
        if (select_province !== '') {
            GetAmphur()
        }
    }, [select_province]);

    useEffect(() => {
        setList_tambol([])
        if (select_amphur !== '') {
            GetTambol()
        }
    }, [select_amphur]);

    useEffect(() => {

    }, [select_tambol]);

    function fillphone(input) {
        var numbers = input.replace(/[^0-9]/g, '').replace(',', '.').toString()
        var pat = []
        if (numbers.length > 0) {
            pat.push(numbers.substring(0, 3))
        }
        if (numbers.length >= 4) {
            pat.push(numbers.substring(3, 6))
        }
        if (numbers.length >= 7) {
            pat.push(numbers.substring(6, 10))
        }
        if (pat.length > 0) {
            setPhone_format(pat.join('-'))
        } else {
            setPhone_format('')
        }
    }

    function fillotp_phone(input) {
        var numbers = input.replace(/[^0-9]/g, '').replace(',', '.').toString()
        var pat = []
        if (numbers.length > 0) {
            pat.push(numbers.substring(0, 3))
        }
        if (numbers.length >= 4) {
            pat.push(numbers.substring(3, 6))
        }
        if (numbers.length >= 7) {
            pat.push(numbers.substring(6, 10))
        }
        if (pat.length > 0) {
            setOtp_phone_format(pat.join('-'))
        } else {
            setOtp_phone_format('')
        }
    }

    function fillotp_code(input) {
        var numbers = input.replace(/[^0-9]/g, '').replace(',', '').replace('.', '').toString()
        setOtp_code_format(numbers.substring(0, 6))
    }

    function handlechange_login(id, val) {
        if (id === 'user') {
            setRemember_me((prevState) => ({
                ...prevState,
                user: val,
            }));
        } else if (id === 'pass') {
            setRemember_me((prevState) => ({
                ...prevState,
                pass: val,
            }));
        } else if (id === 'rem') {
            setRemember_me((prevState) => ({
                ...prevState,
                rem: val,
            }));
        }
    }

    useEffect(() => {
        if (password_eye1 === false) {
            setPassword_eye1_type({
                icon: 'fe-eye',
                type: 'password'
            })
        } else {
            setPassword_eye1_type({
                icon: 'fe-eye-off',
                type: 'text'
            })
        }
    }, [password_eye1]);

    useEffect(() => {
        if (password_eye2 === false) {
            setPassword_eye2_type({
                icon: 'fe-eye',
                type: 'password'
            })
        } else {
            setPassword_eye2_type({
                icon: 'fe-eye-off',
                type: 'text'
            })
        }
    }, [password_eye2]);

    return (

        <>
            <div className="welcom_slider" style={{ backgroundImage: "url(/images/meya-cover.png)" }}>
                <img className="presenter1" src="/images/presenter1.png" />
                <img className="presenter2" src="/images/presenter2.png" />
            </div>
            <div id="area-signin">
                {
                    box_signup === true ?
                        <div className="box-signup">
                            <div className="closeicon" onClick={() => { setBox_signup(false) }}><i className="mdi mdi-window-close" ></i></div>
                            <h6 className="text-center">ลงทะเบียน</h6>
                            <p className="text-center">ลงทะเบียนเพื่อเริ่มต้นใช้งานบริการต่างๆของ มียา.com</p>
                            <div>
                                <div className="d-block mb-3">
                                    <div className="progressbar-wrapper d-none d-md-block mb-2">
                                        <div className="f1-steps">
                                            <div className="f1-progress" id="signup-step"><div className="f1-progress-line" data-now-value="12.5" data-number-of-steps="4" style={box_signup_progressline}></div></div>
                                            <div className={"f1-step " + `${box_signup_step === 1 && "active"}`} id="f1-step1"><p>ข้อมูลบัญชีผู้ใช้</p><div className="f1-step-icon"><i className="far fa-user"></i></div></div>
                                            <div className={"f1-step " + `${box_signup_step === 2 && "active"}`} id="f1-step2"><p>ข้อมูลร้านขายยา</p><div className="f1-step-icon"><i className="fas fa-store"></i></div></div>
                                            <div className={"f1-step " + `${(box_signup_step === 3 || box_signup_step === 4) && "active"}`} id="f1-step3"><p>ยืนยัน OTP</p><div className="f1-step-icon"><i className="fa fa-key"></i></div></div>
                                            <div className={"f1-step " + `${box_signup_step === 5 && "active"}`} id="f1-step4"><p>เอกสารสำคัญ</p><div className="f1-step-icon"><i className="far fa-file-alt"></i></div></div>
                                        </div>
                                    </div>
                                    <div className="d-block">
                                        <h5 className="font-size-18 text-dark font-th">ขั้นตอนที่ {box_signup_step} : {signup_stepdetailArray[box_signup_step]}</h5>
                                    </div>
                                </div>
                                <form id="form-signup-p1" className="signup-page mt-2" style={box_signup_stylep1}>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="shop_firstname">ชื่อ</label>
                                                <input type="text" className="form-control text-dark" id="shop_firstname" name="shop_firstname" placeholder="กรอกชื่อ" autoComplete="new-off" minLength="2" required />
                                                <div className="invalid-feedback">อย่างน่อย 2 ตัวอักษร</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="shop_lastname">นามสกุล</label>
                                                <input type="text" className="form-control text-dark" id="shop_lastname" name="shop_lastname" placeholder="กรอกนามสกุล" autoComplete="new-off" minLength="2" required />
                                                <div className="invalid-feedback">อย่างน่อย 2 ตัวอักษร</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="shop_email">อีเมล</label>
                                                <input type="email" className="form-control text-dark" id="shop_email" name="shop_email" autoComplete="new-off" placeholder="กรอกอีเมล" required />
                                                <div className="invalid-feedback">อีเมลไม่ถูกต้องหรือมีผู้ใช้งานแล้ว</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="shop_username">ชื่อบัญชีผู้ใช้</label>
                                                <input type="text" className="form-control text-dark" id="shop_username" name="shop_username" autoComplete="new-off" placeholder="กรอกชื่อบัญชีผู้ใช้" minLength="2" required />
                                                <div className="invalid-feedback">อย่างน่อย 2 ตัวอักษร</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="shop_password">รหัสผ่าน</label>
                                                <div className="input-group mb-3 font-th">
                                                    <input type={password_eye1_type.type} className="form-control inp-password text-dark" id="shop_password" name="shop_password" autoComplete="new-off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="กรอกรหัสผ่าน" required />
                                                    <div className="d-flex">
                                                        <span className="btn password-toggle-icon" onClick={() => setPassword_eye1(!password_eye1)}><i className={password_eye1_type.icon}></i></span>
                                                    </div>
                                                    <div className="invalid-feedback">อย่างน้อย 8 ตัวอักษร และประกอบไปด้วย ตัวพิมใหญ่อย่างน้อย 1 ตัว, ตัวพิมเล็กอย่างน้อย 1 ตัว, ตัวเลขอย่างน้อย 1 ตัว</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="shop_confirmpassword">ยืนยันรหัสผ่าน</label>
                                                <div className="input-group mb-3 font-th">
                                                    <input type={password_eye2_type.type} className="form-control text-dark" id="shop_confirmpassword" name="shop_confirmpassword" autoComplete="new-off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="กรอกรหัสผ่านอีกครั้ง" required />
                                                    <div className="d-flex">
                                                        <span className="btn password-toggle-icon" onClick={() => setPassword_eye2(!password_eye2)}><i className={password_eye2_type.icon}></i></span>
                                                    </div>
                                                    <div className="invalid-feedback">อย่างน้อย 8 ตัวอักษร และประกอบไปด้วย ตัวพิมใหญ่อย่างน้อย 1 ตัว, ตัวพิมเล็กอย่างน้อย 1 ตัว, ตัวเลขอย่างน้อย 1 ตัว</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="agreeterm font-th">
                                        <div className="form-check" onClick={() => { setBox_policy(true) }}>
                                            <input type="checkbox" className="form-check-input" id="agreeterm" name="agreeterm" checked={policy_read} required readOnly />
                                            <label className="form-check-label font-th" htmlFor="agreeterm">ฉันยอมรับ <span>เงื่อนไขการใช้บริการ</span></label>
                                        </div>
                                    </div>
                                </form>

                                <form id="form-signup-p2" className="signup-page" style={box_signup_stylep2} encType="multipart/form-data">
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="shop_name">ชื่อร้าน</label>
                                                <input type="text" className="form-control text-dark" id="shop_name" name="shop_name" placeholder="กรอกชื่อร้าน" autoComplete="new-off" minLength="5" required />
                                                <div className="invalid-feedback">อย่างน่อย 5 ตัวอักษร</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="shop_address">ที่อยู่</label>
                                                <input type="text" className="form-control text-dark" id="shop_address" name="shop_address" placeholder="กรอกที่อยู่" autoComplete="new-off" minLength="5" required />
                                                <div className="invalid-feedback">อย่างน่อย 5 ตัวอักษร</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-4"><div className="form-group">
                                            <label htmlFor="shop_province">จังหวัด</label>
                                            <select id="shop_province" name="shop_province" className="form-control" onChange={(e) => { setSelect_province(e.target.value) }} value={select_province} required>
                                                <option value="">เลือกจังหวัด</option>
                                                {
                                                    list_province.map(item => (
                                                        <option value={item} key={item}>{item}</option>
                                                    ))
                                                }
                                            </select>
                                            <div className="invalid-feedback">กรุณาเลือกจังหวัด</div>
                                        </div></div>
                                        <div className="col-12 col-sm-4"><div className="form-group">
                                            <label htmlFor="shop_district">เขต/อำเภอ</label>
                                            <select id="shop_district" name="shop_district" className="form-control" onChange={(e) => { setSelect_amphur(e.target.value) }} value={select_amphur} required>
                                                <option value="">เลือกเขต/อำเภอ</option>
                                                {
                                                    list_amphur.map(item => (
                                                        <option value={item} key={item}>{item}</option>
                                                    ))
                                                }
                                            </select>
                                            <div className="invalid-feedback">กรุณาเลือกเขต/อำเภอ</div>
                                        </div></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-4"><div className="form-group">
                                            <label htmlFor="shop_subdistrict">แขวง/ตำบล</label>
                                            <select id="shop_subdistrict" name="shop_subdistrict" className="form-control" onChange={(e) => { setSelect_tambol(e.target.value) }} value={select_tambol} required>
                                                <option value="">เลือกแขวง/ตำบล</option>
                                                {
                                                    list_tambol.map((item, index) => (
                                                        <option value={item.name_th} key={item}>{item.name_th}</option>
                                                    ))
                                                }
                                            </select>
                                            <div className="invalid-feedback">กรุณาเลือกแขวง/ตำบล</div>
                                        </div></div>
                                        <div className="col-12 col-sm-4"><div className="form-group">
                                            <label htmlFor="shop_postcode">รหัสไปรษณีย์</label>
                                            <select id="shop_postcode" name="shop_postcode" className="form-control" onChange={(e) => { setSelect_postcode(e.target.value) }} value={select_postcode} required>
                                                <option value="">เลือกรหัสไปรษณีย์</option>
                                                {
                                                    list_tambol.filter(tambol => tambol.name_th === select_tambol).map(item => (
                                                        <option value={item.zip_code}>{item.zip_code}</option>
                                                    ))
                                                }
                                            </select>
                                            <div className="invalid-feedback">กรุณาเลือกรหัสไปรษณีย์</div>
                                        </div></div>
                                        <div className="col-12 col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="shop_telno">เบอร์ร้านขายยา</label>
                                                <input type="text" className="form-control text-dark" id="shop_telno" name="shop_telno" placeholder="กรอกเบอร์ร้านขายยา" value={phone_format} onChange={(e) => { fillphone(e.target.value) }} autoComplete="new-off" minLength="5" required />
                                                <div className="invalid-feedback">กรุณากรอกเบอร์ร้านขายยา</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <form id="form-signup-p3" className="signup-page" style={box_signup_stylep3}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group opt-mobile-input">
                                                <label htmlFor="OTPMobileNo">เบอร์โทรศัพท์</label>
                                                <input type="text" className="form-control text-dark" id="OTPMobileNo" name="OTPMobileNo" placeholder="กรอกเบอร์โทรศัพท์" value={otp_phone_format} onChange={(e) => { fillotp_phone(e.target.value) }} autoComplete="new-off" minLength="12" maxLength="12" required />
                                                <div className="invalid-feedback">กรุณากรอกเบอร์โทรศัพท์</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <form id="form-signup-p4" className="signup-page" style={box_signup_stylep4}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group opt-mobile-input">
                                                <label htmlFor="OTPConfirm">OTP</label>
                                                <input type="text" className="form-control text-dark" id="OTPConfirm" name="OTPConfirm" placeholder="OTP" value={otp_code_format} onChange={(e) => { fillotp_code(e.target.value) }} autoComplete="new-off" minLength="6" maxLength="6" required />
                                                <div className="invalid-feedback">กรุณากรอกรหัส OTP</div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        otp_refcode !== "" ?
                                            <>
                                                <p className='text-center mt-2'>Ref Code : {otp_refcode}</p>
                                                {
                                                    otp_expire !== '' ?
                                                        getOTPexpire()
                                                        :
                                                        null
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </form>

                                <form id="form-signup-p5" className="signup-page" style={box_signup_stylep5}>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="customFile1">ขย.5</label>
                                                <input type="file" className="form-control" id="customFile1" name="customFile1" accept=".png, .jpg, .jpeg, .pdf" required />
                                                <div className="invalid-feedback">กรุณาเลือกไฟล์แนบ</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="customFile2">ทะเบียนพาณิชย์/หนังสือรับรองธุรกิจ</label>
                                                <input type="file" className="form-control" id="customFile2" name="customFile2" accept=".png, .jpg, .jpeg, .pdf" required />
                                                <div className="invalid-feedback">กรุณาเลือกไฟล์แนบ</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="customFile3">บัตรประชาชน</label>
                                                <input type="file" className="form-control" id="customFile3" name="customFile3" accept=".png, .jpg, .jpeg, .pdf" required />
                                                <div className="invalid-feedback">กรุณาเลือกไฟล์แนบ</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="customFile4">บัญชีรับเงิน</label>
                                                <input type="file" className="form-control" id="customFile4" name="customFile4" accept=".png, .jpg, .jpeg, .pdf" required />
                                                <div className="invalid-feedback">กรุณาเลือกไฟล์แนบ</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div >

                            <div className="box-signup-bottom">
                                {
                                    box_signup_submit === true ?
                                        <button type="button" className="btn meya-btn-primary btn-center" id="button-submit" onClick={() => { signup_submit() }}>ลงทะเบียน</button>
                                        : null
                                }
                                {
                                    box_signup_next === true ?
                                        <button type="button" className="btn meya-btn-primary btn-center" id="button-next" onClick={() => { signup_next() }}>ถัดไป</button>
                                        : null
                                }
                                {
                                    box_signup_haveaccount === true ?
                                        <p className="noaccount text-center mt-3" id="haveaccount">มีบัญชีแล้ว? <Link to="" onClick={() => { open_signin() }}>เข้าสู่ระบบ</Link></p>
                                        : null
                                }
                                {
                                    box_signup_previous === true ?
                                        <p className="signup-back text-center mt-3" id="button-back" onClick={() => { signup_previous() }}>ย้อนกลับ</p>
                                        : null
                                }
                            </div>
                        </div >
                        :
                        null
                }
                {
                    box_signup_doc === true ?
                        <div className="box-signup">
                            <div className="closeicon" onClick={() => { setBox_signup_doc(false) }}><i className="mdi mdi-window-close" ></i></div>
                            <h6 className="text-center">เอกสารสำคัญ</h6>
                            <p className="text-center text-danger">บัญชีผู้ใช้งานของท่าน ไม่มีเอกสารสำคัญ กรุณาแนบเอกสารเพื่อขออนุญาตซื้อขายสินค้าในเว็บ มียา.com</p>
                            <form className="mt-5 signup-form" id="form-submitdoc" action="" method="POST" encType="multipart/form-data">
                                <div className="signup-page" style={{ display: "block" }}>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="customFile1-resumit">ขย.5</label>
                                                <input type="file" className="form-control" id="customFile1-resumit" name="customFile1" accept=".png, .jpg, .jpeg, .pdf" required />
                                                <div className="invalid-feedback">กรุณาเลือกไฟล์แนบ</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="customFile2-resumit">ทะเบียนพาณิชย์</label>
                                                <input type="file" className="form-control" id="customFile2-resumit" name="customFile2" accept=".png, .jpg, .jpeg, .pdf" required />
                                                <div className="invalid-feedback">กรุณาเลือกไฟล์แนบ</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="customFile3-resumit">บัตรประชาชน</label>
                                                <input type="file" className="form-control" id="customFile3-resumit" name="customFile3" accept=".png, .jpg, .jpeg, .pdf" required />
                                                <div className="invalid-feedback">กรุณาเลือกไฟล์แนบ</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="customFile4-resumit">บัญชีรับเงิน</label>
                                                <input type="file" className="form-control" id="customFile4-resumit" name="customFile4" accept=".png, .jpg, .jpeg, .pdf" required />
                                                <div className="invalid-feedback">กรุณาเลือกไฟล์แนบ</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form >

                            <div className="box-signup-bottom">
                                <button type="button" className="btn meya-btn-primary btn-center" id="button-submit" onClick={() => { signup_submit_doc() }}>ยืนยัน แนบเอกสาร</button>
                            </div>
                        </div >
                        :
                        null
                }
                {
                    box_signin === true ?
                        <div className="box-signin">
                            <div className="closeicon" onClick={() => { setBox_signin(false) }}><i className="mdi mdi-window-close" ></i></div>
                            <h6 className="">เข้าสู่ระบบ</h6>
                            <p className="">เข้าสู่ระบบเพื่อเริ่มต้นใช้งาน มียา.com</p>
                            <form className="mt-4" id="accesspanel">
                                <div className="form-group">
                                    <label htmlFor="signin_email">อีเมล</label>
                                    <input type="email" className="form-control" id="signin_email" name="signin_email" placeholder="กรอกอีเมล" value={remember_me.user} onChange={(e) => { handlechange_login('user', e.target.value) }} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="signin_password">รหัสผ่าน</label>
                                    <input type="password" className="form-control" id="signin_password" name="signin_password" placeholder="กรอกรหัสผ่าน" value={remember_me.pass} onChange={(e) => { handlechange_login('pass', e.target.value) }} required />
                                </div>
                                <div className="passwordmanage">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="remember" checked={remember_me.rem} onChange={(e) => { handlechange_login('rem', e.target.checked) }} />
                                        <label className="form-check-label" htmlFor="remember">จดจำฉันไว้ในระบบ</label>
                                    </div>
                                    <Link href="#" onClick={() => { open_forgot() }}>ลืมรหัสผ่าน</Link>
                                </div>
                                <button type="button" className="btn meya-btn-primary btn-center" onClick={() => { handle_login() }} style={{ marginTop: "60px" }}>เข้าสู่ระบบ</button>
                            </form>
                            {/* <hr className="hr-text" data-content="หรือ" /> */}
                            {/* <button type="submit" className="btn meya-btn-clear btn-center "><img className="google-logo" src="/images/google.png" /> เข้าสู่ระบบด้วย <span>Google</span></button> */}
                            <p className="noaccount text-center mt-3">ยังไม่มีบัญชี? <Link to="" onClick={() => { open_register() }}>ลงทะเบียน</Link></p>
                        </div>
                        :
                        null
                }
                {
                    box_forgot === true ?
                        <div className="box-forgot">
                            <div className="closeicon" onClick={() => { setBox_forgot(false) }}><i className="mdi mdi-window-close" ></i></div>
                            <h6 className="">ลืมรหัสผ่าน</h6>
                            <p className="">กรุณากรอกอีเมล ที่ท่านได้สมัครไว้</p>
                            <form className="mt-4" id="forgotpanel">
                                <div className="form-group">
                                    <label htmlFor="fotgot_email">อีเมล</label>
                                    <input type="email" className="form-control" id="fotgot_email" name="fotgot_email" placeholder="กรอกอีเมล" required />
                                    <div className="invalid-feedback" id="email-forgot-feedback">กรุณากรอกอีเมลที่ท่านได้สมัครไว้</div>
                                </div>
                            </form>
                            <button type="button" className="btn meya-btn-primary btn-center mt-6" onClick={() => { handle_forgot() }}>ดำเนินการ ขอรีเซ็ตรหัสผ่าน</button>
                        </div>
                        :
                        null
                }
                {
                    box_policy === true ?
                        <div className="box-policy">
                            <div className="closeicon" onClick={() => { setBox_policy(false) }}><i className="mdi mdi-window-close" ></i></div>
                            <h6 className="">เงื่อนไขการใช้บริการ (Terms of Use)</h6>
                            <div className="box-term-wrapper">
                                <Policy></Policy>
                                <div className="d-flex justify-content-between">
                                    <button type="button" className="btn meya-btn-primary btn-center mt-6" onClick={() => { setPolicy_read(true); setBox_policy(false) }}>ยอมรับเงื่อนไข</button>
                                    <button type="button" className="btn meya-btn-warning btn-center mt-6" onClick={() => { setPolicy_read(false); setBox_policy(false) }}>ไม่ยอมรับเงื่อนไข</button>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            <ButtonGoto></ButtonGoto>
        </>
    )
}


export default Login