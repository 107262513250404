import React, { useState, useEffect } from 'react';
import {
    Link,
    useSearchParams,
    useLocation,
} from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import CardProduct from '../../components/card/card-product/CardProduct';
import './MarketPlace.css';
import BtnGogo from '../../components/buttongoto/ButtonGoto';

function ShopSeller(props) {
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams()

    const [dataitem_all, setDataitem_all] = useState([]);
    const [dataitem_filter_single, setDataitem_filter_single] = useState([]);

    const [view_defaultpaginate, setDefaultPaginate] = useState(0);
    const [view_productcategory_name, setView_productcategory_name] = useState('');
    const [view_shop_code, setView_shop_code] = useState('');
    const [view_shop_info, setView_shop_info] = useState({});
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        const shopcode = searchParams.get('shopcode')
        const category = searchParams.get('category')
        if (shopcode !== '') {
            setView_shop_code(shopcode)
            setView_productcategory_name(category)
        }
        setItemOffset_onsell(0)
        setDefaultPaginate(0)
    }, [location]);

    useEffect(() => {
        if (view_shop_code !== '' && dataitem_all.length < 1) {
            api_shopitem_shopseller(view_shop_code)
        }
    }, [view_shop_code])

    async function api_shopitem_shopseller(shop_id) {
        setmodal_load({ enable: true, message: "กำลังโหลดรายการสินค้า" })
        await sleep(200)

        const formData = new FormData();
        formData.append('shop_seller', shop_id)
        axios.post(props.app_hostname + "/api/market/itemlist/byshopid", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    var p1 = response.data.items.filter(item => Number(item.first_pricenew > 0))
                    var p2 = response.data.items.filter(item => Number(item.first_pricenew) === 0 && item.ads === true)
                    var p3 = response.data.items.filter(item => Number(item.first_pricenew) === 0 && item.ads === false)
                    var pr = p1.concat(p2)
                    pr = pr.concat(p3)
                    setDataitem_all(pr)
                    setView_shop_info(response.data.shopinfo)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    useEffect(() => {
        if (view_productcategory_name !== 'รวมสินค้า' && view_productcategory_name !== '') {
            if (view_productcategory_name === 'สินค้าโปรโมชั่น') {
                const filtered_items = dataitem_all.filter(item => Number(item.first_pricenew) > 0);
                setDataitem_filter_single(filtered_items)
            } else if (view_productcategory_name === 'สินค้าแนะนำ') {
                const filtered_items = dataitem_all.filter(item => item.ads === true);
                setDataitem_filter_single(filtered_items)
            } else {
                var findcatid = props.systemdata_productcategorylist.find(item => item.productcategory_name === view_productcategory_name)
                var catid = ''
                if (findcatid !== undefined) {
                    catid = findcatid.productcategory_id
                }
                var filtered_items = dataitem_all.filter(item => item.productcategory_id === catid);
                setDataitem_filter_single(filtered_items)
            }
        }
        if (view_productcategory_name === 'รวมสินค้า') {
            setDataitem_filter_single(dataitem_all)
        }
    }, [view_productcategory_name, props.systemdata_productcategorylist, dataitem_all]);

    const [itemOffset_onsell, setItemOffset_onsell] = useState(0);
    const endOffset_onsell = itemOffset_onsell + 18;
    const currentItems_onsell = dataitem_filter_single.slice(itemOffset_onsell, endOffset_onsell);
    const pageCount_onsell = Math.ceil(dataitem_filter_single.length / 18);
    const handlePageClick_onsell = (event) => {
        const newOffset_onsell = (event.selected * 18) % dataitem_filter_single.length;
        setItemOffset_onsell(newOffset_onsell);
        setDefaultPaginate(event.selected)
    };

    return (
        <main id="content" role="main">
            <div className="container">
                <div className="row mb-8">
                    <div className="d-none d-sm-block marketplace-sidebar px-1">
                        <div className="mb-4 border border-width-1 border-color-3 borders-radius-2">
                            <ul className="list-unstyled mb-0 sidebar-navbar view-all productype-menu">
                                <li><div className="dropdown-title font-th"><i className="fe-package text-primary mr-1"></i> หมวดหมู่สินค้าในร้าน</div></li>
                                <li><Link to={"/marketplace/shopseller?shopcode=" + view_shop_code + `&category=รวมสินค้า`} className={"dropdown-item font-th " + (view_productcategory_name === 'รวมสินค้า' ? "active" : "")} >สินค้าในร้านทั้งหมด<span className="text-gray-25 font-size-12 font-weight-normal"></span></Link></li>
                                <li><Link to={"/marketplace/shopseller?shopcode=" + view_shop_code + `&category=สินค้าโปรโมชั่น`} className={"dropdown-item font-th " + (view_productcategory_name === 'สินค้าโปรโมชั่น' ? "active" : "")} >สินค้าโปรโมชั่น<span className="text-gray-25 font-size-12 font-weight-normal"></span></Link></li>
                                <li><Link to={"/marketplace/shopseller?shopcode=" + view_shop_code + `&category=สินค้าแนะนำ`} className={"dropdown-item font-th " + (view_productcategory_name === 'สินค้าแนะนำ' ? "active" : "")} >สินค้าแนะนำ<span className="text-gray-25 font-size-12 font-weight-normal"></span></Link></li>
                                {
                                    props.systemdata_productcategorylist.map((element) => (
                                        <li key={element.productcategory_id}><Link to={"/marketplace/shopseller?shopcode=" + view_shop_code + "&category=" + element.productcategory_name} className={"dropdown-item font-th " + (view_productcategory_name === element.productcategory_name ? "active" : "")} >{element.productcategory_name}<span className="text-gray-25 font-size-12 font-weight-normal"></span></Link></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="col d-block min-height-600 py-2 mt-0">
                        <div className="bg-gray-13 bg-md-transparent">
                            <div className="my-md-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-3 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble py-0">
                                        <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ซื้อสินค้า</li>
                                        <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ร้านค้า</li>
                                        <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th text-primary">ร้าน {view_shop_info.shop_name}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="d-block shop-banner px-2 px-md-3 py-0">
                            <div className="d-block py-0">
                                <h5 className="font-th text-gray-111 mb-0 animated fadeInUp" style={{ animationDuration: "1000ms" }}>
                                    {view_shop_info.shop_name}
                                </h5>
                                <h6 className="d-none d-md-block font-th text-gray-111 animated fadeInUp mt-0 mb-0" style={{ animationDuration: "1000ms" }}>
                                    ร้านขายยาจากเว็บไซต์ มียา.com เชื่อถือได้และพร้อมจัดส่งทั่วประเทศ</h6>
                                <p className="font-th text-gray-111 animated fadeInUp mt-0 mb-0" style={{ animationDuration: "1000ms" }}>
                                    มีสินค้าในร้าน {dataitem_all.length} รายการ</p>
                            </div>
                        </div>

                        <div className="mb-0  d-xl-block mt-2">
                            <div className="position-relative mt-3">
                                <div className="flex-center-between mb-3 market-group-header">
                                    {
                                        view_productcategory_name === "สินค้าโปรโมชั่น" ?
                                            <h5 className="font-size-16 mb-0 py-0 font-th">สินค้าโปรโมชั่น</h5>
                                            :
                                            view_productcategory_name === "สินค้าแนะนำ" ?
                                                <h5 className="font-size-16 mb-0 py-0 font-th">สินค้าแนะนำ</h5>
                                                :
                                                <h5 className="font-size-16 mb-0 py-0 font-th">หมวดหมู่สินค้า : {view_productcategory_name}</h5>
                                    }
                                    <p className="font-size-14 text-dark mb-0 font-th limit-display">{dataitem_filter_single.length} รายการ</p>
                                </div>
                            </div>
                            <div className='meya-pagination-container'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick_onsell}
                                    pageRangeDisplayed={10}
                                    pageCount={pageCount_onsell}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    forcePage={view_defaultpaginate}
                                />
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="flex-container">
                                        {
                                            currentItems_onsell.map((element) => (
                                                <CardProduct
                                                    key={element.productitem_id}
                                                    app_hostname={props.app_hostname}
                                                    shopitem={element}
                                                    show_stock={true}
                                                    show_shop={false}
                                                ></CardProduct>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                currentItems_onsell.length === 0 && modal_load.enable === false ?
                                    <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ไม่มีรายการสินค้าที่จะแสดง ลองค้นหาใหม่อีกครั้ง</div>
                                    :
                                    null
                            }
                        </div>

                    </div>
                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            <BtnGogo></BtnGogo>
        </main>
    )
}

export default ShopSeller