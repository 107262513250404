import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ModalPopupMessage from '../components/modal/ModalPopupMessage';
import './StepBuySale.css';

function StepBuySale(props) {
    const navigate = useNavigate();
    const [navigate_url, setNavigate_url] = useState("");
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    async function security_protection(mode) {
        if (mode === "seller" || mode === "buyer") {
            if (props.permitdata.token === "" || props.permitdata.token === "-") {
                setModal_popup({
                    modaltype: 'modal-signup',
                    headers: 'กรุณาสมัครสมาชิก',
                    message: 'การซื้อขายสินค้าบน แพล๊ตฟอร์ม มียานั้น ผู้ใช้งานจะต้องลงทะเบียนก่อนเริ่มทำการซื้อขาย',
                    enable: true
                })
            } else {
                if (mode === "seller") {
                    setNavigate_url("/myshop/seller/jobtodo")
                } else {
                    setNavigate_url("/marketplace?category=รวมสินค้า")
                }
            }
        }
    }

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        setNavigate_url("/")
    }

    useEffect(() => {
        if (navigate_url != "") {
            navigate(navigate_url);
        }
    }, [navigate_url]);

    return (
        <div className="col-xl-12">
            <hr className="text-center max-width-450">
            </hr>
            <h6 className="text-center font-weight-bold font-th">{props.stepname}</h6>
            <div
                className="stepbuysale d-flex mb-3 row rounded-lg mx-0 flex-wrap">
                <div
                    className="media px-3 px-xl-4 px-wd-4 flex-shrink-0 flex-xl-shrink-1 py-3 border">
                    <div className="stepno text-primary font-weight-bold">1</div>
                    <div className="u-avatar mr-2">
                        <i className={props.step1.iconclass}></i>
                    </div>
                    <div className="media-body text-center">
                        <span className="d-block font-weight-bold text-dark">{props.step1.header}</span>
                        <div className="text-secondary font-th">{props.step1.text}</div>
                    </div>
                </div>
                <div
                    className="media  px-3 px-xl-4 px-wd-4 flex-shrink-0 flex-xl-shrink-1 py-3 border">
                    <div className="stepno text-primary font-weight-bold">2</div>
                    <div className="u-avatar mr-2">
                        <i className={props.step2.iconclass}></i>
                    </div>
                    <div className="media-body text-center">
                        <span className="d-block font-weight-bold text-dark">{props.step2.header}</span>
                        <div className=" text-secondary font-th">{props.step2.text}</div>
                    </div>
                </div>
                <div
                    className="media px-3 px-xl-4 px-wd-4 flex-shrink-0 flex-xl-shrink-1 py-3 border">
                    <div className="stepno text-primary font-weight-bold">3</div>
                    <div className="u-avatar mr-2">
                        <i className={props.step3.iconclass}></i>
                    </div>
                    <div className="media-body text-center">
                        <span className="d-block font-weight-bold text-dark">{props.step3.header}</span>
                        <div className=" text-secondary font-th">{props.step3.text}</div>
                    </div>
                </div>
                <div
                    className="media px-3 px-xl-4 px-wd-4 flex-shrink-0 flex-xl-shrink-1 py-3 border">
                    <div className="stepno text-primary font-weight-bold">4</div>
                    <div className="u-avatar mr-2">
                        <i className={props.step4.iconclass}></i>
                    </div>
                    <div className="media-body text-center">
                        <span className="d-block font-weight-bold text-dark">{props.step4.header}</span>
                        <div className=" text-secondary font-th">{props.step4.text}</div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <Link to="#"
                    className="btn bg-green transition-3d-hover rounded-lg  py-2 px-3 font-size-16 font-th text-white mb-6 mx-auto d-block btn-fixedwidth-160" onClick={() => { security_protection(props.mode) }}>
                    {props.button.text}
                </Link>
            </div>

            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>

    )
}

export default StepBuySale