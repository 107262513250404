import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ModalLoad from '../../../components/modal/ModalLoad';
import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import {
    orderStatus
} from "../library"
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "../DocumentTemplate.css"
import BtnGogo from '../../../components/buttongoto/ButtonGoto';
dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyShop_Buyer_Payment(props) {
    const [mypayment_list, setPayment_list] = useState([])
    const [myorder_list, setOrder_list] = useState([])
    const [paymentselect, setPaymentselect] = useState({})

    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const [payment_method_arr, setPayment_method_arr] = useState(['cash', 'promtpay', 'creditcard'])

    const [payment_method, setPayment_method] = useState(0);
    const [modal_slip, setModal_slip] = useState(false);

    const [modal_paymentmethod, setModal_paymentmethod] = useState(false);
    const bookbank_info = {
        name: "บรัษัท เมดิเซพชั่น จำกัด",
        account_no: "123-456-789"
    }

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        const unique = props.data_shoporder_buyer_payment.filter(
            (obj, index) =>
                props.data_shoporder_buyer_payment.findIndex((item) => item.payment_id === obj.payment_id) === index
        );
        setPayment_list(unique)
    }, [props.data_shoporder_buyer_payment]);

    useEffect(() => {
        if (props.data_shoporder_buyer_payment !== undefined) {
            setOrder_list(props.data_shoporder_buyer_payment)
        }
    }, [props.data_shoporder_buyer_payment]);

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        setPaymentselect({})
    }

    function showDialogCallback_Confirm(message) {
        if (message === 'payment') {
            api_submit_repayment()
        } else if (message === 'cancel-order') {
            api_cancel_order()
        } else if (message === 'change-method') {
            api_change_method()
        }

        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function clear_slipattach() {
        document.getElementById('payslip').value = [];
    }

    function filteroneorder_deliver(payment_id) {
        var item = myorder_list.find(item => { return item.payment_id === payment_id })
        if (item !== undefined) {
            return <div>
                <div className="d-block text-success mt-1">ที่อยู่ในการจัดส่ง</div>
                <table>
                    <tbody className="d-block mt-0">
                        <tr className="">
                            <td width={"160px"} className="mt-0 mb-0">ผู้รับ</td>
                            <td className="mt-0 mb-0">{item.shipping_recievename}</td>
                        </tr>
                        <tr className="">
                            <td className="mt-0 mb-0">เบอร์โทรศัพย์</td>
                            <td className="mt-0 mb-0">{item.shipping_telno}</td>
                        </tr>
                        <tr className="">
                            <td className="mt-0 mb-0">ที่อยู่ในการจัดส่ง</td>
                            <td className="mt-0 mb-0">{item.shipping_address} {item.shipping_district} {item.shipping_state} {item.shipping_province} {item.shipping_postcode}</td>
                        </tr>
                        <tr className="">
                            <td className="mt-0 mb-0">ขนส่งโดย</td>
                            <td className="mt-0 mb-0">{item.courier_fullname}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        return null
    }

    async function api_cancel_order() {
        setmodal_load({ enable: true, message: "กำลังยกเลิกคำสั่งซื้อ" })
        await sleep(200)

        const formData = new FormData();
        formData.append('payment_id', paymentselect.payment_id)

        var shoplist = []
        myorder_list.forEach(order => {
            if (order.payment_id === paymentselect.payment_id) {
                shoplist.push(order.seller_shopid)
            }
        })
        formData.append('shoplist', shoplist.join("##"))

        axios.post(props.app_hostname + "/api/order/payment/cancelorderbeforepay", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatepayment', response.data.curent_payment)
                    }
                }
                if (response.data.cartcount !== undefined) {
                    if (response.data.cartcount > -1) {
                        props.callback_mycart("edit", response.data.cartcount)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_change_method() {
        setModal_paymentmethod(false)
        setmodal_load({ enable: true, message: "กำลังเปลี่ยนวิธีการชำระเงิน" })
        await sleep(200)

        const formData = new FormData();
        formData.append('payment_id', paymentselect.payment_id)
        formData.append('payment_method', payment_method_arr[payment_method - 1])

        var shoplist = []
        myorder_list.forEach(order => {
            if (order.payment_id === paymentselect.payment_id) {
                shoplist.push(order.seller_shopid)
            }
        })
        formData.append('shoplist', shoplist.join("##"))

        axios.post(props.app_hostname + "/api/order/payment/changemethod", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setPayment_method(0);
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatepayment-method', response.data.curent_payment)
                    }
                }
            })
            .catch((err) => {
                setPayment_method(0);
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_submit_payslip(form_id) {
        var formelement = document.getElementById(form_id)
        if (formelement.checkValidity() === false) {
            formelement.classList.add("was-validated");
        } else {
            setmodal_load({ enable: true, message: "กำลังอัพโหลดหลักฐานการชำระเงิน" })
            await await sleep(200)

            formelement.classList.remove("was-validated");
            const formData = new FormData(formelement);
            formData.append('payment_id', paymentselect.payment_id)

            var shoplist = []
            myorder_list.forEach(order => {
                if (order.payment_id === paymentselect.payment_id) {
                    shoplist.push(order.seller_shopid)
                }
            })
            formData.append('shoplist', shoplist.join("##"))

            axios.post(props.app_hostname + "/api/order/payment/submitpayslip", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_slip(false)
                    if (response.data.status === 'OK') {
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'สำเร็จ',
                            message: response.data.message,
                            enable: true
                        });
                    }
                    else {
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'ผิดพลาด',
                        message: err.message,
                        enable: true
                    });
                });
        }
    }

    async function api_recheck_payment(payment_id) {
        setmodal_load({ enable: true, message: "กำลังตรวจสอบการชำระเงิน" })
        await sleep(200)

        const formData = new FormData();
        formData.append('payment_id', payment_id)

        var shoplist = []
        myorder_list.forEach(order => {
            if (order.payment_id === paymentselect.payment_id) {
                shoplist.push(order.seller_shopid)
            }
        })
        formData.append('shoplist', shoplist.join("##"))

        axios.post(props.app_hostname + "/api/order/payment/recheckpayment", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatepayment', response.data.curent_payment)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }


    async function api_submit_repayment() {
        setmodal_load({ enable: true, message: "กำลังสร้างข้อมูลชำระเงิน" })
        await sleep(200)

        const formData = new FormData();
        formData.append('payment_id', paymentselect.payment_id)

        var shoplist = []
        myorder_list.forEach(order => {
            if (order.payment_id === paymentselect.payment_id) {
                shoplist.push(order.seller_shopid)
            }
        })
        formData.append('shoplist', shoplist.join("##"))

        axios.post(props.app_hostname + "/api/order/payment/submitrepayment", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setPayment_method(0)
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    if (response.data.payment_info !== undefined) {
                        if (response.data.payment_info.PaymentUrl !== "") {
                            window.open(response.data.payment_info.PaymentUrl, '_blank');
                        }
                    }
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatepayment', response.data.curent_payment)
                    }
                }
            })
            .catch((err) => {
                setPayment_method(0)
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div className="my-0">
            <div className="row print-disable">
                {
                    mypayment_list.length > 0 ?
                        mypayment_list.map((mypayment, index0) => (
                            <div className="col-md-12 mb-md-2 mb-lg-0" key={mypayment.payment_id}>
                                <div className="card-mycart mb-2">
                                    <div className="card-mycart-header py-1">
                                        <div className="d-block d-md-flex justify-content-between py-0 flex-wrap">
                                            <div className="d-block">
                                                <div className="card-mycart-title mb-0">หมายเลขชำระ : {mypayment.payment_id}</div>
                                                <div className="text-danger font-size-14 mt-0">ต้องชำระภายใน {dayjs(new Date(mypayment.expiretime)).subtract(7, 'hours').format('DD MMM BBBB HH:mm')}</div>
                                            </div>
                                            <div className="d-block">
                                                {
                                                    mypayment.payment_method === "cash" ?
                                                        <div className="text-danger font-size-14 mt-0">ชำระแบบ โอนเงิน</div>
                                                        : mypayment.payment_method === "promtpay" ?
                                                            <div className="text-danger font-size-14 mt-0">ชำระแบบ PromtPay</div>
                                                            : mypayment.payment_method === "creditcard" ?
                                                                <div className="text-danger font-size-14 mt-0">ชำระแบบ บัตรเครดิต/บัตรเดบิต</div>
                                                                :
                                                                <div className="font-size-14 mt-0">ไม่ทราบ</div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-mycart-body">
                                        <div className="d-block">
                                            {
                                                myorder_list.length > 0 ?
                                                    myorder_list.map((order, index0) => (
                                                        order.payment_id === mypayment.payment_id ?
                                                            <div className={"d-block pt-1 mb-2 " + (index0 > 0 ? "border-top" : "")} key={order.order_id}>
                                                                <div className="d-block d-md-flex justify-content-between py-0 flex-wrap mb-0">
                                                                    <div className="d-block">
                                                                        <div className="card-mycart-title"><i className="font-size-14 fas fa-user-tag text-primary "></i> ผู้ขาย : {order.seller_shopname}</div>
                                                                        <p className="card-mycart-subtitle mb-0">จังหวัด {order.seller_shopprovince}</p>
                                                                    </div>
                                                                    <div className="d-block">
                                                                        <div className="text-left text-md-right  mb-0 pb-0">คำสั่งซื้อ {order.order_id}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-block d-md-flex justify-content-between pt-0 pb-2 flex-wrap ">
                                                                    <div className="d-block">
                                                                        {
                                                                            order.orderitem !== null ?
                                                                                order.orderitem.map((orderitems, index1) => (
                                                                                    <div className={"d-block d-md-flex py-1 " + (index1 > 0 ? "border-top" : "")} key={orderitems.orderitem_id}>
                                                                                        <div className="d-flex">
                                                                                            <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + `${orderitems.productitem_id}/` + `${orderitems.productitem_id}` + "-1-" + orderitems.img1_key + ".jpg"} alt="Image Description" /></div>
                                                                                            <div className='d-block product-detail'>
                                                                                                <div className="mycart-box-productname py-0">{orderitems.productitem_name}</div>
                                                                                                <div className="mycart-box-productinter py-0">{orderitems.productitem_inter}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mycart-box-pricelist">
                                                                                            <table>
                                                                                                <tbody className="d-block mt-0">
                                                                                                    {
                                                                                                        orderitems.orderitem_pricelist !== null ?
                                                                                                            orderitems.orderitem_pricelist.map((pricelist, index1) => (
                                                                                                                <tr className="mycart-table-pricelist-row py-0" key={pricelist.pricelist_id} style={{ paddingTop: "0px" }}>
                                                                                                                    <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                                                    <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                                                    <td className="product-quantity">x {pricelist.select} ชุด</td>
                                                                                                                    <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                                                </tr>
                                                                                                            ))
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>

                                                                    <div className="d-block mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                                                        <div className="d-flex justify-content-between py-0 mt-2">
                                                                            <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                                            <div className="table-summary-cellt">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between py-0">
                                                                            <div className="table-summary-cell">ค่าขนส่ง</div>
                                                                            <div className="table-summary-cell aligh-right">฿ {Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                                            <div className="table-summary-cell">ยอดรวม</div>
                                                                            <div className="table-summary-cell aligh-right">฿ {Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    ))
                                                    :
                                                    null
                                            }
                                            <div className="d-flex justify-content-between gap-2 py-2 border-top border-bottom">
                                                <div className='d-block '>
                                                    <div className="font-th text-danger mb-0" style={{ fontSize: "14px" }}>สรุปยอดที่ต้องชำระ</div>
                                                    {
                                                        mypayment.order_status === 'IV_Req' && mypayment.api_paymentstatus !== 'Success' && mypayment.payment_method === 'cash' ?
                                                            <div className="d-flex pb-2 mt-2">
                                                                <div className="d-block product-detail ml-2">
                                                                    <div className="font-th text-success mb-0" style={{ fontSize: "14px" }}>บัญชีธนาคารกสิกรไทย</div>
                                                                    <div className="font-th mt-0 mb-0" style={{ fontSize: "14px" }}>ชื่อบัญชี {bookbank_info.name}</div>
                                                                    <div className="font-th mt-0" style={{ fontSize: "14px" }}>เลขที่บัญชี {bookbank_info.account_no}</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="d-block mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                                    <div className="d-flex justify-content-between py-0">
                                                        <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                        <div className="table-summary-cellt">฿ {Number(mypayment.summary_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-0">
                                                        <div className="table-summary-cell">ค่าขนส่ง</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-0">
                                                        <div className="table-summary-cell">ส่วนลด</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_discount).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    {
                                                        mypayment.payment_method === "creditcard" ?
                                                            <>
                                                                <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                                    <div className="table-summary-cell">ยอดรวม</div>
                                                                    <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                </div>
                                                                <div className="d-flex justify-content-between py-0">
                                                                    <div className="table-summary-cell">ค่าธรรมเนียมชำระผ่านบัตร</div>
                                                                    <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_creditcharge).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    <div className="d-flex justify-content-between py-0 border-bottom text-danger">
                                                        <div className="table-summary-cell">ยอดที่ต้องชำระ</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_net).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                filteroneorder_deliver(mypayment.payment_id)
                                            }

                                            <div className="d-flex justify-content-between gap-2 mt-4 ">
                                                <div className="d-flex gap-2">
                                                    {
                                                        mypayment.order_status === 'IV_Req' && mypayment.api_paymentstatus !== 'Success' ?
                                                            <button type="button" className="btn-rectan-small btn-danger font-th"
                                                                onClick={() => {
                                                                    setPaymentselect(mypayment);
                                                                    setModal_popup({
                                                                        modaltype: 'modal-confirm',
                                                                        headers: 'กรุณายืนยัน',
                                                                        message: 'ท่านยกเลิกคำสั่งซื้อ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                        confirmcode: "cancel-order",
                                                                        enable: true
                                                                    });
                                                                }}>ยกเลิกคำสั่งซื้อ</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        mypayment.order_status === 'IV_Req' && mypayment.api_paymentstatus !== 'Success' && mypayment.payment_method !== 'cash' ?
                                                            <button type="button" className="btn-rectan-small btn-primary text-white font-th" onClick={() => {
                                                                setPaymentselect(mypayment);
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านต้องการชำระเงิน ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                    confirmcode: "payment",
                                                                    enable: true
                                                                });
                                                            }}>ชำระเงิน</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        mypayment.order_status === 'IV_Req' && mypayment.api_paymentstatus !== 'Success' ?
                                                            <button type="button" className="btn-rectan-small btn-warning text-dark font-th" onClick={() => {
                                                                setPaymentselect(mypayment);
                                                                setModal_paymentmethod(true)
                                                            }}>เปลี่ยนวิธีชำระเงิน</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        mypayment.order_status === 'IV_Req' && mypayment.api_paymentstatus !== 'Success' && mypayment.payment_method === 'cash' ?
                                                            <button type="button" className="btn-rectan-small btn-success text-white font-th" onClick={() => {
                                                                clear_slipattach();
                                                                setPaymentselect(mypayment);
                                                                setModal_slip(true);
                                                            }}>แนบสลิปชำระเงิน</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        mypayment.order_status === 'IV_Req' && mypayment.api_paymentstatus !== 'Success' ?
                                                            <button type="button" className="btn-rectan-small btn-primary text-white font-th" onClick={() => {
                                                                api_recheck_payment(mypayment.payment_id);
                                                            }}>ตรวจสอบการชำระเงิน</button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        null
                }
            </div>

            <div className='payment-bookbank' style={{ display: modal_slip ? "block" : "none" }}>
                <div className='payment-bookbank-wrapper'>
                    <h6 className="modal-subtitle font-th py-0 mb-0">เลขที่อ้างอิง : {paymentselect.payment_id}</h6>

                    <div className="row">
                        <div className="col-md-12">
                            <form id="form-payslip" action="" method="POST" encType="multipart/form-data">
                                <h6 className="font-th mt-1 text-danger">*กรุณาแนบสลิปโอนเงิน</h6>
                                <input className="form-control mt-2" accept="image/*" type="file" id="payslip" name="payslip" required />
                            </form>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className='d-flex mt-4 justify-content-end gap-2'>
                                <button className="btn btn-success text-white font-th" type="button" onClick={() => { api_submit_payslip("form-payslip") }}><i className="fe-save"></i> ยืนยันแนบสลิป</button>
                                <button className="btn btn-light font-th modal-forceclose" type="button" onClick={() => { setModal_slip(false); setPaymentselect({}); }}><i className="fe-x"></i> ปิด</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='repayment-box' style={{ display: modal_paymentmethod ? "block" : "none" }}>
                <div className='repayment-box-wrapper font-th'>
                    <h6 className="modal-subtitle font-th py-0 mb-0">เลือกช่องทางการชำระเงิน</h6>
                    <p className="modal-subtitle font-th py-0 mt-0 mb-2">อ้างอิง {paymentselect.payment_id}</p>

                    {
                        paymentselect.payment_method !== "cash" ?
                            <div className={"payment-method-box mb-2 " + `${payment_method === 1 && "active"}`} onClick={() => { setPayment_method(1) }}>
                                <div className={"payment-method-selected " + `${payment_method === 1 && "active"}`}><i className="fa fa-check"></i></div>
                                <h5>โอนเงิน</h5>
                                <p>ชำระแบบโอนเงิน</p>
                            </div>
                            :
                            null
                    }
                    {
                        paymentselect.payment_method !== "promtpay" ?
                            <div className={"payment-method-box mb-2 " + `${payment_method === 2 && "active"}`} onClick={() => { setPayment_method(2) }}>
                                <div className={"payment-method-selected " + `${payment_method === 2 && "active"}`}><i className="fa fa-check"></i></div>
                                <h5>QR PromtPay</h5>
                                <p>แสกน QR Code พร้อมเพย์</p>
                            </div>
                            :
                            null
                    }
                    {
                        paymentselect.payment_method !== "creditcard" ?
                            <div className={"payment-method-box mb-2 " + `${payment_method === 3 && "active"}`} onClick={() => { setPayment_method(3) }}>
                                <div className={"payment-method-selected " + `${payment_method === 3 && "active"}`}><i className="fa fa-check"></i></div>
                                <h5>บัตรเครดิต / บัตรเดบิต</h5>
                                <p>ชำระผ่านบัตรเครดิต / บัตรเดบิต</p>
                            </div>
                            :
                            null
                    }






                    <div className='d-block d-md-flex gap-2'>
                        <button type="button" className="btn btn-primary text-white mb-3 mb-md-0 font-weight-normal px-5 px-md-2 px-lg-2 w-100 mt-3" onClick={() => {
                            payment_method > 0 ?
                                setModal_popup({
                                    modaltype: 'modal-confirm',
                                    headers: 'กรุณายืนยัน',
                                    message: 'ระบบจะสร้างเลขธุรกรรมอ้างอิงกับผู้ให้บริการชำระเงินใหม่ หากหน้าต่างชำระเงินเดิมเปิดอยู่ ห้ามชำระผิดเป็นอันขาด ท่านต้องการดำเนินการต่อ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                    confirmcode: "change-method",
                                    enable: true
                                })
                                :
                                setModal_popup({
                                    modaltype: 'modal-warning',
                                    headers: 'ขออภัย',
                                    message: "กรุณาเลือกวิธีชำระเงิน",
                                    enable: true
                                });
                        }}>เปลี่ยน</button>
                        <button type="button" className="btn btn-light text-dark mb-3 mb-md-0 font-weight-normal px-5 px-md-2 px-lg-2 w-100 mt-3" onClick={() => {
                            setPayment_method(0);
                            setPaymentselect({})
                            setModal_paymentmethod(false)
                        }}>ยกเลิก</button>
                    </div>
                </div>
            </div>

            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modal_slip || modal_load.enable || modal_paymentmethod ?
                    <div className='meya-backdrop'></div>
                    :
                    null
            }
            <BtnGogo></BtnGogo>
        </div >
    )
}

export default MyShop_Buyer_Payment