import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts'
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class OrderSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const options = {
            title: {
                text: "ภาพรวมงานขาย ปี " + this.props.chart_year,
                fontFamily: 'Kanit',
                fontSize: 16
            },
            legend: {
                fontFamily: "Kanit",
            },
            responsive: true,
            axisY: {
                title: "ยอดเข้าร้าน (บาท)",
                titleFontFamily: 'Kanit',
                titleFontSize: 14,
                labelFontFamily: 'Kanit',
                labelFontSize: 12,
                gridThickness: 0
            },
            toolTip: {
                shared: true,
                reversed: true,
                fontFamily: 'Kanit',
            },
            axisX: {
                labelFontFamily: 'Kanit',
                labelFontSize: 12
            },
            data: [{
                name: "ยอดเข้าร้าน",
                indexLabelFontFamily: 'Kanit',
                color: "#34A9F7",
                type: "stackedColumn",
                showInLegend: true,
                legendMarkerType: "circle",
                indexLabelFontColor: "#fff",
                indexLabelFontSize: 18,
                dataPoints: this.props.chart_data1
            }, {
                name: "ค่าธรรมเนียม",
                indexLabelFontFamily: 'Kanit',
                color: "#2ecc71",
                type: "stackedColumn",
                indexLabelFontSize: 18,
                showInLegend: true,
                legendMarkerType: "circle",
                dataPoints: this.props.chart_data2
            }]
        }

        return (
            <div>
                <CanvasJSChart options={options}
                /* onRef = {ref => this.chart = ref} */
                />
            </div>
        );
    }
}

export default OrderSummary;