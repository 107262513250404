import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './ButtonGoto.css';
function ButtonGoto() {
    const [scrollPosition, setSrollPosition] = useState(0);
    const [scrollshow, setSrollshow] = useState(false);
    const gotoRef = useRef(null);

    const handleVisibleButton = () => {
        const position = window.pageYOffset;
        setSrollPosition(position);
        if (scrollPosition > 10) {
            return setSrollshow(true)
        } else if (scrollPosition < 10) {
            return setSrollshow(false)
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleVisibleButton);
    });

    useEffect(() => {
        if (scrollPosition > 50) {
            const gotobtn = gotoRef.current
            gotobtn.className = "js-go-to u-go-to animated js-animation-was-fired slideInUp d-inline-block"
        } else {
            const gotobtn = gotoRef.current
            gotobtn.className = "js-go-to u-go-to animated d-none"
        }
    }, [scrollPosition]);

    return (
        <div ref={gotoRef} className="js-go-to u-go-to animated" style={{ position: "fixed", bottom: "15px", right: "15px" }} onClick={() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }}>
            <span className="fas fa-arrow-up u-go-to__inner"></span>
        </div>
    )
}

export default ButtonGoto