import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ModalLoad from '../../../components/modal/ModalLoad';
import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import {
    orderStatus
} from "../library"
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import BtnGogo from '../../../components/buttongoto/ButtonGoto';
dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyShop_Seller_Deliver(props) {
    const [myorder_list, setorder_list] = useState([])
    const [orderselect, setorderselect] = useState({})
    const [modaltracking, setModaltracking] = useState(false);
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        if (props.data_shoporder_seller_deliver !== undefined) {
            const existingopen = props.data_shoporder_seller_deliver.find(order => { return order.displayitem === true })
            if (existingopen !== undefined) {
                setorder_list(props.data_shoporder_seller_deliver)
            } else {
                const nextshoporderlist = props.data_shoporder_seller_deliver.map((order, index) => {
                    if (index === 0) {
                        return {
                            ...order,
                            displayitem: true
                        };
                    } else {
                        return order;
                    }
                });
                setorder_list(nextshoporderlist)
            }
        }
    }, [props.data_shoporder_seller_deliver]);

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        if (message === "cancel-order") {
            api_cancel_order()
        } else if (message === "accept-order") {
            api_accpet_order()
        } else if (message === "accept-cancelorder") {
            api_accpet_customercancelorder()
        } else if (message === "booking") {
            api_deliver_booking()
        }
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function Toggleitem(order_id) {
        const nextshoporderlist = myorder_list.map(order => {
            if (order.order_id === order_id) {
                return {
                    ...order,
                    displayitem: !order.displayitem,
                };
            } else {
                return {
                    ...order,
                    displayitem: false,
                };
            }
        });
        setorder_list(nextshoporderlist)
    }

    async function api_cancel_order() {
        setmodal_load({ enable: true, message: "กำลังยกเลิกคำสั่งขาย" })
        await sleep(200)
        const formData = new FormData();
        formData.append('order_id', orderselect.order_id)

        axios.post(props.app_hostname + "/api/order/myshop/seller/cancelorder", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_accpet_order() {
        setmodal_load({ enable: true, message: "กำลังตอบรับคำสั่งซื้อ" })
        await sleep(200)

        const formData = new FormData();
        formData.append('order_id', orderselect.order_id)

        axios.post(props.app_hostname + "/api/order/myshop/seller/acceptorder", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_accpet_customercancelorder() {
        setmodal_load({ enable: true, message: "กำลังตอบรับคำขอยกเลิก" })
        await sleep(200)

        const formData = new FormData();
        formData.append('order_id', orderselect.order_id)

        axios.post(props.app_hostname + "/api/order/myshop/seller/acceptcustomercancel", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_deliver_booking() {
        setmodal_load({ enable: true, message: "กำลังจองระบบขนส่ง" })
        await sleep(200)

        const formData = new FormData();
        formData.append('order_id', orderselect.order_id)
        formData.append('courier_code', orderselect.shipping_courier)
        formData.append('shipping_recievename', orderselect.shipping_recievename)
        formData.append('shipping_telno', orderselect.shipping_telno)
        formData.append('shipping_address', orderselect.shipping_address)
        formData.append('shipping_district', orderselect.shipping_district)
        formData.append('shipping_state', orderselect.shipping_state)
        formData.append('shipping_province', orderselect.shipping_province)
        formData.append('shipping_postcode', orderselect.shipping_postcode)

        formData.append('seller_recievename', props.permitdata.shop_name)
        formData.append('seller_telno', props.permitdata.shop_telno)
        formData.append('seller_address', props.permitdata.shop_address)
        formData.append('seller_district', props.permitdata.shop_subdistrict)
        formData.append('seller_state', props.permitdata.shop_district)
        formData.append('seller_province', props.permitdata.shop_province)
        formData.append('seller_postcode', props.permitdata.shop_postcode)

        formData.append('parcel_weight', orderselect.shipping_parcel_weight)
        formData.append('parcel_width', orderselect.shipping_parcel_width)
        formData.append('parcel_length', orderselect.shipping_parcel_length)
        formData.append('parcel_height', orderselect.shipping_parcel_height)

        axios.post(props.app_hostname + "/api/order/myshop/seller/bookingdeliver", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setModaltracking(false)
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatebooking-seller', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setModaltracking(false)
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div className="my-0">

            <div className="row">
                {
                    myorder_list.map((order, index0) => (
                        <div className="col-md-12 mb-md-2 mb-lg-0" key={order.order_id}>
                            <div className="card-mycart mb-2">
                                <div className="card-mycart-header py-1">
                                    <div className="d-block d-md-flex justify-content-between py-0 flex-wrap">
                                        <div className="d-block">
                                            <div className="card-mycart-title"><i className="font-size-14 fas fa-user-tag text-primary"></i> ผู้ซื้อ : {order.buyer_shopname}</div>
                                            <p className="card-mycart-subtitle">จังหวัด {order.buyer_shopprovince}</p>
                                        </div>
                                        <div className="d-block">
                                            <div className="text-left text-md-right">{order.order_id}</div>
                                            {orderStatus(order, "seller")}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-mycart-body">
                                    <div className={"" + (order.displayitem ? "d-block" : "d-none")}>
                                        <div className="d-block d-md-flex justify-content-between pt-0 pb-2 flex-wrap border-bottom">
                                            <div className="d-block">
                                                {
                                                    order.orderitem !== null ?
                                                        order.orderitem.map((orderitems, index1) => (
                                                            <div className={"d-block d-md-flex py-1 " + (index1 > 0 ? "border-top" : "")} key={orderitems.orderitem_id}>
                                                                <div className="d-flex">
                                                                    <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + `${orderitems.productitem_id}/` + `${orderitems.productitem_id}` + "-1-" + orderitems.img1_key + ".jpg"} alt="Image Description" /></div>
                                                                    <div className='d-block product-detail'>
                                                                        <div className="mycart-box-productname py-0">{orderitems.productitem_name}</div>
                                                                        <div className="mycart-box-productinter py-0">{orderitems.productitem_inter}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="mycart-box-pricelist">
                                                                    <table>
                                                                        <tbody className="d-block mt-0">
                                                                            {
                                                                                orderitems.orderitem_pricelist !== null ?
                                                                                    orderitems.orderitem_pricelist.map((pricelist, index1) => (
                                                                                        <tr className="mycart-table-pricelist-row py-0" key={pricelist.pricelist_id} style={{ paddingTop: "0px" }}>
                                                                                            <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                            <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                            <td className="product-quantity">x {pricelist.select} ชุด</td>
                                                                                            <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="d-block mt-2 mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                                <div className="d-flex justify-content-between py-0">
                                                    <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                    <div className="table-summary-cellt">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0">
                                                    <div className="table-summary-cell">ค่าขนส่ง</div>
                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 border-bottom">
                                                    <div className="table-summary-cell">ยอดรวม</div>
                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 mt-2">
                                                    <div className="table-summary-cell">ค่าธรรมเนียม</div>
                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.shop_servicecharge).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                    <div className="table-summary-cell">ยอดของร้าน</div>
                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.shop_income).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 mt-3">
                                                    <div className="table-summary-cell">พัสดุ</div>
                                                    <div className="table-summary-cell aligh-right">{order.shipping_parcel_name}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 border-bottom">
                                                    <div className="table-summary-cell">ขนส่งโดย</div>
                                                    <div className="table-summary-cell aligh-right">{order.courier_fullname}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-block text-success mt-1">ที่อยู่ในการจัดส่ง</div>
                                        <table>
                                            <tbody className="d-block mt-0">
                                                <tr className="">
                                                    <td width={"160px"} className="mt-0 mb-0">ผู้รับ</td>
                                                    <td className="mt-0 mb-0">{order.shipping_recievename}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="mt-0 mb-0">เบอร์โทรศัพย์</td>
                                                    <td className="mt-0 mb-0">{order.shipping_telno}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="mt-0 mb-0">ที่อยู่ในการจัดส่ง</td>
                                                    <td className="mt-0 mb-0">{order.shipping_address} {order.shipping_district} {order.shipping_state} {order.shipping_province} {order.shipping_postcode}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="d-flex justify-content-between gap-2 mt-4 ">
                                            <div className="d-flex gap-2">
                                                {
                                                    order.order_status === 'IV_Req' && order.api_paymentstatus === 'Success' ?
                                                        <button type="button" className="btn-rectan-small btn-danger font-th"
                                                            onClick={() => {
                                                                setorderselect(order);
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านต้องการปฏิเสธคำสั่งซื้อ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true,
                                                                    confirmcode: "cancel-order"
                                                                });
                                                            }}>ปฏิเสธคำสั่งซื้อ</button>
                                                        :
                                                        null
                                                }
                                                {
                                                    order.order_status === 'PC_Prepare' ?
                                                        <button type="button" className="btn-rectan-small btn-danger font-th"
                                                            onClick={() => {
                                                                setorderselect(order);
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านยกเลิกคำสั่งขาย ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true,
                                                                    confirmcode: "cancel-order"
                                                                });
                                                            }}>ยกเลิกคำสั่งขาย</button>
                                                        :
                                                        null
                                                }

                                                {
                                                    order.order_status === 'IV_Req' && order.api_paymentstatus === 'Success' ?
                                                        <button type="button" className="btn-rectan-small btn-success font-th"
                                                            onClick={() => {
                                                                setorderselect(order);
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านตอบรับตอบรับคำสั่งซื้อ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true,
                                                                    confirmcode: "accept-order"
                                                                });
                                                            }}>ตอบรับคำสั่งซื้อ</button>
                                                        :
                                                        null
                                                }

                                                {
                                                    order.order_status === 'PC_C_Cancel_Req' ?
                                                        <button type="button" className="btn-rectan-small btn-warning font-th"
                                                            onClick={() => {
                                                                setorderselect(order);
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านตอบรับคำขอยกเลิกคำสั่งซื้อ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true,
                                                                    confirmcode: "accept-cancelorder"
                                                                });
                                                            }}>ตอบรับคำขอยกเลิก</button>
                                                        :
                                                        null
                                                }

                                                {
                                                    order.order_status === 'PC_Prepare' ?
                                                        <button type="button" className="btn-rectan-small btn-primary text-white font-th" onClick={() => {
                                                            setorderselect(order);
                                                            setModaltracking(true)
                                                        }}>จองระบบขนส่ง</button>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="ml-1 mouse-pointer" onClick={() => {
                                                Toggleitem(order.order_id);
                                            }} ><i className='fas fa-chevron-up py-0 font-size-12'></i> ย่อรายละเอียด</div>
                                        </div>
                                    </div>
                                    <div className={"" + (order.displayitem === false ? "d-block" : "d-none")}>
                                        <div className="d-flex justify-content-between">
                                            <div className="d-block mt-0">
                                                <div className="table-summary-cell ml-1">สินค้าจำนวน {order.orderitem !== null ? order.orderitem.length : null} รายการ</div>
                                                <p className="table-summary-cell ml-1 py-0 mb-0">วันที่สั่งซื้อ {dayjs(new Date(order.createtime)).subtract(7, 'hours').format('DD MMM BBBB HH:mm')}</p>
                                            </div>
                                            <div className="d-block mt-0">
                                                <div className="ml-1 mouse-pointer" onClick={() => {
                                                    Toggleitem(order.order_id);
                                                }} ><i className='fas fa-chevron-down py-0 font-size-12'></i> แสดงรายละเอียด</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ))
                }
            </div>

            <div className='modal-deliver-booking font-th' style={{ display: modaltracking ? "block" : "none" }}>
                <div className='modal-deliver-booking-wrapper'>
                    <form id="form-delivery">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <h6 className="">ระบบจองการขนส่งสินค้า</h6>
                                    <div className="editname pr-5 text-success">ชื่อที่อยู่ผู้ส่ง</div>
                                    <div className="editname pr-5">รายละเอียด</div>
                                    <div className="text-grey-11 pr-5">{props.permitdata.shop_name} {props.permitdata.shop_telno}</div>
                                    <div className="text-grey-11 pr-5">{props.permitdata.shop_address} {props.permitdata.shop_subdistrict} {props.permitdata.shop_district} {props.permitdata.shop_province} {props.permitdata.shop_postcode}</div>

                                    <div className="editname pr-5  mt-5 text-success">ชื่อที่อยู่ผู้รับ</div>
                                    <div className="editname pr-5">รายละเอียด</div>
                                    <div className="text-grey-11 pr-5">{orderselect.shipping_recievename} {orderselect.shipping_telno}</div>
                                    <div className="text-grey-11 pr-5">{orderselect.shipping_address} {orderselect.shipping_district} {orderselect.shipping_state} {orderselect.shipping_province} {orderselect.shipping_postcode}</div>

                                    <div className="editname pr-5  mt-5 text-success">บริษัทขนส่งและขนาดกล่องพัสดุ</div>
                                    <div className="text-grey-11 pr-5">{orderselect.courier_fullname}</div>
                                    <div className="text-grey-11 pr-5">{orderselect.shipping_parcel_name}</div>

                                    <div className='d-flex justify-content-end mt-5'>
                                        <button className="btn btn-warning btn-sm  font-th modal-forceclose" type="button"
                                            onClick={() => {
                                                setModal_popup({
                                                    modaltype: 'modal-confirm',
                                                    headers: 'กรุณายืนยัน',
                                                    message: 'ท่านต้องการจองบริษัทขนส่ง ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                    enable: true,
                                                    confirmcode: "booking"
                                                });
                                            }}><i className="fe-save"></i> ยืนยันการจอง</button>
                                        <button className="btn btn-light btn-sm  font-th modal-forceclose" type="button"
                                            onClick={() => {
                                                setorderselect({})
                                                setModaltracking(false)
                                            }}><i className="fe-x"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modaltracking ?
                    <div className='meya-backdrop'></div>
                    :
                    null
            }
            <BtnGogo></BtnGogo>
        </div >
    )
}

export default MyShop_Seller_Deliver