import React, { useState, useEffect } from 'react';
import {
    Link,
    useSearchParams,
    useLocation,
} from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import CardProduct from '../../components/card/card-product/CardProduct';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import './MarketPlace.css';
import BtnGogo from '../../components/buttongoto/ButtonGoto';

function MarketPlace(props) {
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams()

    const [dataitem_all, setDataitem_all] = useState([]);
    const [dataitem_filter_single, setDataitem_filter_single] = useState([]);
    const [dataitem_filter_promotion, setDataitem_filter_promotion] = useState([]);
    const [dataitem_filter_bestseller, setDataitem_filter_bestseller] = useState([]);

    const [view_transaction, setView_transaction] = useState(0);
    const [view_defaultpaginate, setDefaultPaginate] = useState(-1);
    const [view_productcategory_name, setView_productcategory_name] = useState('-');
    const [view_searchtype_name, setView_searchtype_name] = useState('');
    const [view_productid_name, setView_productid_name] = useState('');
    const [view_searchtext_name, setView_searchtext_name] = useState('');
    const [view_location_trigger, setView_location_trigger] = useState(0);

    const [limit_promotion, setLimit_promotion] = useState({ max: 5, min: 0 });
    const [limit_bestseller, setLimit_bestseller] = useState({ max: 5, min: 0 });

    const [location_filter, setLocation_filter] = useState({
        p1: false,
        p2: false,
        p3: false,
        p4: false,
        p5: false
    });

    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        const category = searchParams.get('category')
        const searchtype = searchParams.get('searchtype')
        const productid = searchParams.get('productid')
        const detail = searchParams.get('detail')
        const transaction = searchParams.get('transaction')
        if (category != '') {
            setView_productcategory_name(category)
            setView_searchtype_name(searchtype)
            setView_productid_name(productid)
            setView_searchtext_name(detail)
            setView_transaction(transaction)
        }

        setItemOffset_onsell(0)
        setDefaultPaginate(0)
    }, [location]);

    useEffect(() => {
        if (view_productcategory_name != '' && props.systemdata_productcategorylist !== undefined) {
            if (props.systemdata_productcategorylist.length > 0) {
                if (view_productcategory_name === "รวมสินค้า") {
                    api_query_shopitem_commonmarket()
                } else if (view_productcategory_name === "สินค้าโปรโมชั่น") {
                    api_query_shopitem_promotion()
                } else if (view_productcategory_name === "สินค้าแนะนำ") {
                    api_query_shopitem_ads()
                } else if (view_productcategory_name === "ค้นหาสินค้า") {
                    const searchtype = searchParams.get('searchtype')
                    const productid = searchParams.get('productid')
                    const detail = searchParams.get('detail')
                    if (searchtype === "contain") {
                        api_query_shopitem_searchcontain(detail)
                    } else if (searchtype === "productid") {
                        api_query_shopitem_searchproductid(productid)
                    }
                }
                else {
                    var catekey = props.systemdata_productcategorylist.filter(item => item.productcategory_name === view_productcategory_name);
                    if (catekey.length > 0) {
                        api_query_shopitem_byproductcategory(catekey[0].productcategory_id)
                    }
                }
            }
        }
    }, [view_productcategory_name, props.systemdata_productcategorylist, view_searchtype_name, view_productid_name, view_searchtext_name, view_location_trigger, view_transaction]);

    useEffect(() => {
        if (view_productcategory_name === 'รวมสินค้า') {
            //var filtered_items_promotion = dataitem_all.filter(item => Number(item.first_pricenew) > 0);
            //setDataitem_filter_promotion(filtered_items_promotion)
            //var filtered_items_bestseller = dataitem_all.filter((item, index) => item.ads === true);
            //setDataitem_filter_bestseller(filtered_items_bestseller)
            //console.log( filtered_items_promotion )
        }
    }, [dataitem_all, view_productcategory_name]);

    useEffect(() => {
        if (view_productcategory_name != 'รวมสินค้า' && view_productcategory_name != '') {
            if (view_productcategory_name === 'สินค้าโปรโมชั่น') {
                var filtered_items = dataitem_all.filter(item => Number(item.first_pricenew) > 0);
                setDataitem_filter_single(filtered_items)
            } else if (view_productcategory_name === 'สินค้าแนะนำ') {
                var filtered_items = dataitem_all.filter(item => item.ads === true);
                setDataitem_filter_single(filtered_items)
            } else if (view_productcategory_name === 'ค้นหาสินค้า') {
                setDataitem_filter_single(dataitem_all)
            } else {
                var findcatid = props.systemdata_productcategorylist.find(item => item.productcategory_name === view_productcategory_name)
                var catid = ''
                if (findcatid != undefined) {
                    catid = findcatid.productcategory_id
                }
                var filtered_items = dataitem_all.filter(item => item.productcategory_id === catid);
                setDataitem_filter_single(filtered_items)
            }
        }

    }, [view_productcategory_name, view_searchtype_name, view_productid_name, view_searchtext_name, props.systemdata_productcategorylist, dataitem_all]);

    function limit_display(name, direction) {
        if (direction === 'next') {
            if (name === 'promotion') {
                var datamax = dataitem_filter_promotion.length - 1
                var tmpmax = limit_promotion.max + 6
                if (tmpmax <= datamax) {
                    setLimit_promotion({ max: limit_promotion.max + 6, min: limit_promotion.min + 6 })
                } else {
                    var avi = datamax - limit_promotion.max
                    if (avi >= 6) {
                        avi = 6
                    }
                    setLimit_promotion({ max: limit_promotion.max + avi, min: limit_promotion.min + avi })
                }
            } else if (name === 'bestseller') {
                var datamax = dataitem_filter_bestseller.length - 1
                var tmpmax = limit_bestseller.max + 6
                if (limit_bestseller.max === 6) {
                    setLimit_bestseller({ max: limit_bestseller.max + 6, min: limit_bestseller.min + 6 })
                } else {
                    var avi = datamax - limit_bestseller.max
                    if (avi >= 6) {
                        avi = 6
                    }
                    setLimit_bestseller({ max: limit_bestseller.max + avi, min: limit_bestseller.min + avi })
                }
            }
        } else {
            if (name === 'promotion') {
                var tmpmin = limit_promotion.min - 6
                if (tmpmin >= 0) {
                    setLimit_promotion({ max: limit_promotion.max - 6, min: limit_promotion.min - 6 })
                } else {
                    setLimit_promotion({ max: 5, min: 0 })
                }
            } else if (name === 'bestseller') {
                var tmpmin = limit_bestseller.min - 6
                if (tmpmin >= 0) {
                    setLimit_bestseller({ max: limit_bestseller.max - 6, min: limit_bestseller.min - 6 })
                } else {
                    setLimit_bestseller({ max: 5, min: 0 })
                }
            }
        }
    }

    function loacation_change() {

    }

    function loacation_begin_trigger() {
        var filt1 = document.getElementById('filter-location-1').checked
        var filt2 = document.getElementById('filter-location-2').checked
        var filt3 = document.getElementById('filter-location-3').checked
        var filt4 = document.getElementById('filter-location-4').checked
        var filt5 = document.getElementById('filter-location-5').checked
        if (location_filter.p1 !== filt1 || location_filter.p2 !== filt2 || location_filter.p3 !== filt3 || location_filter.p4 !== filt4 || location_filter.p5 !== filt5) {
            setView_location_trigger(view_location_trigger + 1)
        } else {

        }
    }

    async function api_query_shopitem_commonmarket() {
        var formdata = new FormData()
        var temparr = []
        var filt1 = document.getElementById('filter-location-1').checked
        var filt2 = document.getElementById('filter-location-2').checked
        var filt3 = document.getElementById('filter-location-3').checked
        var filt4 = document.getElementById('filter-location-4').checked
        var filt5 = document.getElementById('filter-location-5').checked
        if (filt1 === true) { temparr.push('กรุงเทพมหานคร') }
        if (filt2 === true) { temparr.push('สมุทรปราการ') }
        if (filt3 === true) { temparr.push('นนทบุรี') }
        if (filt4 === true) { temparr.push('นครปฐม') }
        if (filt5 === true) { temparr.push('ปทุมธานี') }
        if (temparr.length > 0) {
            formdata.append('location_enable', 'enable')
            formdata.append('location_filter', temparr.join(","))
        }

        setLocation_filter({
            p1: filt1,
            p2: filt2,
            p3: filt3,
            p4: filt4,
            p5: filt5
        });

        setmodal_load({ enable: true, message: "กำลังโหลดรายการสินค้า", transparent: true })
        await sleep(200)

        axios.post(props.app_hostname + "/api/market/itemlist/multirandom", formdata, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setDataitem_filter_promotion(response.data.items_pro)
                    setDataitem_filter_bestseller(response.data.items_best)
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_query_shopitem_byproductcategory(id) {
        var formdata = new FormData()
        formdata.append('productcategory_id', id)
        var temparr = []
        var filt1 = document.getElementById('filter-location-1').checked
        var filt2 = document.getElementById('filter-location-2').checked
        var filt3 = document.getElementById('filter-location-3').checked
        var filt4 = document.getElementById('filter-location-4').checked
        var filt5 = document.getElementById('filter-location-5').checked
        if (filt1 === true) { temparr.push('กรุงเทพมหานคร') }
        if (filt2 === true) { temparr.push('สมุทรปราการ') }
        if (filt3 === true) { temparr.push('นนทบุรี') }
        if (filt4 === true) { temparr.push('นครปฐม') }
        if (filt5 === true) { temparr.push('ปทุมธานี') }
        if (temparr.length > 0) {
            formdata.append('location_enable', 'enable')
            formdata.append('location_filter', temparr.join(","))
        }

        setLocation_filter({
            p1: filt1,
            p2: filt2,
            p3: filt3,
            p4: filt4,
            p5: filt5
        });

        setmodal_load({ enable: true, message: "กำลังโหลดรายการสินค้า", transparent: true })
        await sleep(200)

        axios.post(props.app_hostname + "/api/market/itemlist/category", formdata, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setDataitem_all(response.data.items)
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_query_shopitem_promotion() {
        var formdata = new FormData()
        var temparr = []
        var filt1 = document.getElementById('filter-location-1').checked
        var filt2 = document.getElementById('filter-location-2').checked
        var filt3 = document.getElementById('filter-location-3').checked
        var filt4 = document.getElementById('filter-location-4').checked
        var filt5 = document.getElementById('filter-location-5').checked
        if (filt1 === true) { temparr.push('กรุงเทพมหานคร') }
        if (filt2 === true) { temparr.push('สมุทรปราการ') }
        if (filt3 === true) { temparr.push('นนทบุรี') }
        if (filt4 === true) { temparr.push('นครปฐม') }
        if (filt5 === true) { temparr.push('ปทุมธานี') }
        if (temparr.length > 0) {
            formdata.append('location_enable', 'enable')
            formdata.append('location_filter', temparr.join(","))
        }

        setLocation_filter({
            p1: filt1,
            p2: filt2,
            p3: filt3,
            p4: filt4,
            p5: filt5
        });

        setmodal_load({ enable: true, message: "กำลังโหลดรายการสินค้า", transparent: true })
        await sleep(200)

        axios.post(props.app_hostname + "/api/market/itemlist/promotion", formdata, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setDataitem_all(response.data.items)
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_query_shopitem_ads() {
        var formdata = new FormData()
        var temparr = []
        var filt1 = document.getElementById('filter-location-1').checked
        var filt2 = document.getElementById('filter-location-2').checked
        var filt3 = document.getElementById('filter-location-3').checked
        var filt4 = document.getElementById('filter-location-4').checked
        var filt5 = document.getElementById('filter-location-5').checked
        if (filt1 === true) { temparr.push('กรุงเทพมหานคร') }
        if (filt2 === true) { temparr.push('สมุทรปราการ') }
        if (filt3 === true) { temparr.push('นนทบุรี') }
        if (filt4 === true) { temparr.push('นครปฐม') }
        if (filt5 === true) { temparr.push('ปทุมธานี') }
        if (temparr.length > 0) {
            formdata.append('location_enable', 'enable')
            formdata.append('location_filter', temparr.join(","))
        }

        setLocation_filter({
            p1: filt1,
            p2: filt2,
            p3: filt3,
            p4: filt4,
            p5: filt5
        });

        setmodal_load({ enable: true, message: "กำลังโหลดรายการสินค้า", transparent: true })
        await sleep(200)

        axios.post(props.app_hostname + "/api/market/itemlist/ads", formdata, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setDataitem_all(response.data.items)
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_query_shopitem_searchcontain(text) {
        var formdata = new FormData()
        formdata.append('findtext', text)

        var temparr = []
        var filt1 = document.getElementById('filter-location-1').checked
        var filt2 = document.getElementById('filter-location-2').checked
        var filt3 = document.getElementById('filter-location-3').checked
        var filt4 = document.getElementById('filter-location-4').checked
        var filt5 = document.getElementById('filter-location-5').checked
        if (filt1 === true) { temparr.push('กรุงเทพมหานคร') }
        if (filt2 === true) { temparr.push('สมุทรปราการ') }
        if (filt3 === true) { temparr.push('นนทบุรี') }
        if (filt4 === true) { temparr.push('นครปฐม') }
        if (filt5 === true) { temparr.push('ปทุมธานี') }
        if (temparr.length > 0) {
            formdata.append('location_enable', 'enable')
            formdata.append('location_filter', temparr.join(","))
        }

        setLocation_filter({
            p1: filt1,
            p2: filt2,
            p3: filt3,
            p4: filt4,
            p5: filt5
        });

        setmodal_load({ enable: true, message: "กำลังโหลดรายการสินค้า", transparent: true })
        await sleep(200)

        axios.post(props.app_hostname + "/api/market/itemlist/search/productcontain", formdata, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setDataitem_all(response.data.items)
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_query_shopitem_searchproductid(id) {
        var formdata = new FormData()
        formdata.append('productitem_id', id)

        var temparr = []
        var filt1 = document.getElementById('filter-location-1').checked
        var filt2 = document.getElementById('filter-location-2').checked
        var filt3 = document.getElementById('filter-location-3').checked
        var filt4 = document.getElementById('filter-location-4').checked
        var filt5 = document.getElementById('filter-location-5').checked
        if (filt1 === true) { temparr.push('กรุงเทพมหานคร') }
        if (filt2 === true) { temparr.push('สมุทรปราการ') }
        if (filt3 === true) { temparr.push('นนทบุรี') }
        if (filt4 === true) { temparr.push('นครปฐม') }
        if (filt5 === true) { temparr.push('ปทุมธานี') }
        if (temparr.length > 0) {
            formdata.append('location_enable', 'enable')
            formdata.append('location_filter', temparr.join(","))
        }

        setLocation_filter({
            p1: filt1,
            p2: filt2,
            p3: filt3,
            p4: filt4,
            p5: filt5
        });

        setmodal_load({ enable: true, message: "กำลังโหลดรายการสินค้า", transparent: true })
        await sleep(200)

        axios.post(props.app_hostname + "/api/market/itemlist/search/productid", formdata, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setDataitem_all(response.data.items)
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    const [itemOffset_onsell, setItemOffset_onsell] = useState(0);
    const endOffset_onsell = itemOffset_onsell + 18;
    const currentItems_onsell = dataitem_filter_single.slice(itemOffset_onsell, endOffset_onsell);
    const pageCount_onsell = Math.ceil(dataitem_filter_single.length / 18);
    const handlePageClick_onsell = (event) => {
        const newOffset_onsell = (event.selected * 18) % dataitem_filter_single.length;
        setItemOffset_onsell(newOffset_onsell);
        setDefaultPaginate(event.selected)
    };

    function generate_token(length) {
        var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        var b = [];
        for (var i = 0; i < length; i++) {
            var j = (Math.random() * (a.length - 1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("");
    }

    return (
        <main id="content" role="main">
            <div className="container">
                <div className="row mb-8">
                    <div className="d-none d-lg-block marketplace-sidebar px-1">
                        <div className="mb-4 border border-width-1 border-color-3 borders-radius-2">
                            <ul className="list-unstyled mb-0 sidebar-navbar view-all productype-menu">
                                <li><div className="dropdown-title font-th"><i className="fe-package text-primary mr-1"></i> หมวดหมู่สินค้า</div></li>
                                <li><Link to={"/marketplace?category=สินค้าโปรโมชั่น&&transaction=" + generate_token(10)} className={"dropdown-item font-th " + `${view_productcategory_name === 'สินค้าโปรโมชั่น' && "active"}`} >สินค้าโปรโมชั่น<span className="text-gray-25 font-size-12 font-weight-normal"></span></Link></li>
                                <li><Link to={"/marketplace?category=สินค้าแนะนำ&&transaction=" + generate_token(10)} className={"dropdown-item font-th " + `${view_productcategory_name === 'สินค้าแนะนำ' && "active"}`} >สินค้าแนะนำ<span className="text-gray-25 font-size-12 font-weight-normal"></span></Link></li>
                                {
                                    props.systemdata_productcategorylist.map((element) => (
                                        <li key={element.productcategory_id}><Link to={"/marketplace?category=" + element.productcategory_name + "&&transaction=" + generate_token(10)} className={"dropdown-item font-th " + `${view_productcategory_name === element.productcategory_name && "active"}`} >{element.productcategory_name}<span className="text-gray-25 font-size-12 font-weight-normal"></span></Link></li>
                                    ))
                                }
                            </ul>
                        </div>

                        <div className="mb-6">
                            <div className="border-bottom border-color-1 mb-3">
                                <h3 className="section-title section-title__sm mb-0 pb-2 font-size-14 font-th">ตัวช่วยคัดกรอง</h3>
                            </div>
                            <div className="border-bottom pb-4 mb-4">
                                <h4 className="font-size-14 mb-3 font-th"><i className="fe-map-pin text-primary mr-1"></i> สถานที่</h4>
                                <div className="form-group d-flex align-items-center justify-content-between mb-2 pb-1">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="filter-location-1" onChange={() => { loacation_change() }} />
                                        <label className="custom-control-label font-th" htmlFor="filter-location-1">กรุงเทพมหานคร
                                            <span className="text-gray-25 font-size-12 font-weight-normal"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group d-flex align-items-center justify-content-between mb-2 pb-1">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="filter-location-2" onChange={() => { loacation_change() }} />
                                        <label className="custom-control-label font-th" htmlFor="filter-location-2">สมุทรปราการ
                                            <span className="text-gray-25 font-size-12 font-weight-normal"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group d-flex align-items-center justify-content-between mb-2 pb-1">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="filter-location-3" onChange={() => { loacation_change() }} />
                                        <label className="custom-control-label font-th" htmlFor="filter-location-3">นนทบุรี
                                            <span className="text-gray-25 font-size-12 font-weight-normal"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group d-flex align-items-center justify-content-between mb-2 pb-1">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="filter-location-4" onChange={() => { loacation_change() }} />
                                        <label className="custom-control-label font-th" htmlFor="filter-location-4">นครปฐม
                                            <span className="text-gray-25 font-size-12 font-weight-normal"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group d-flex align-items-center justify-content-between mb-2 pb-1">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="filter-location-5" onChange={() => { loacation_change() }} />
                                        <label className="custom-control-label font-th" htmlFor="filter-location-5">ปทุมธานี
                                            <span className="text-gray-25 font-size-12 font-weight-normal"></span>
                                        </label>
                                    </div>
                                </div>
                                <button type="button" className="btn px-3 btn-primary text-white py-2 rounded-lg font-th" onClick={() => { loacation_begin_trigger() }}><i className="fe-search mr-2"></i> ค้นหา</button>
                            </div>
                        </div>
                    </div>

                    <div className="col d-block min-height-600 py-2 mt-0 meya-content-100p">
                        <div className="bg-gray-13 bg-md-transparent">
                            <div className="my-md-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-3 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble py-0">
                                        <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ซื้อสินค้า</li>
                                        {
                                            view_productcategory_name === "ค้นหาสินค้า" ?
                                                <>
                                                    <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ค้นหาสินค้า</li>
                                                    {
                                                        view_searchtype_name === "contain" ?
                                                            <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th text-primary">{view_searchtext_name}</li>
                                                            :
                                                            <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1"><div className='font-th mr-2'>{view_productid_name}</div>
                                                                {
                                                                    dataitem_all.length > 0 ?
                                                                        <div className='font-th text-primary'>{dataitem_all[0].productitem_name}</div>
                                                                        :
                                                                        null
                                                                }
                                                            </li>
                                                    }
                                                </>
                                                :
                                                <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th text-primary">{view_productcategory_name}</li>
                                        }
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        {
                            view_productcategory_name === 'รวมสินค้า' ?
                                <div className="mb-0 d-xl-block mt-2">
                                    {
                                        dataitem_filter_promotion.length > 0 ?
                                            <>
                                                <div className="position-relative mt-1">
                                                    <div className="flex-center-between mb-3 market-group-header">
                                                        <h5 className="font-size-16 mb-0 py-0 font-th">สินค้าโปรโมชั่น</h5>
                                                        <Link className="font-size-14 text-primary mb-0 font-th limit-display" to="/marketplace?category=สินค้าโปรโมชั่น">ดูสินค้าเพิ่มเติม</Link>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {
                                                            limit_promotion.min > 0 ?
                                                                <div className="d-none d-md-flex market-icon-slide market-icon-slideback" onClick={() => { limit_display('promotion', 'back') }}><i className="fas fa-arrow-left"></i> </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            (dataitem_filter_promotion.length - 1) > limit_promotion.max > 0 ?
                                                                <div className="d-none d-md-flex market-icon-slide market-icon-slidenext" onClick={() => { limit_display('promotion', 'next') }}><i className="fas fa-arrow-right"></i> </div>
                                                                :
                                                                null
                                                        }
                                                        <div className="flex-container">
                                                            {
                                                                dataitem_filter_promotion.map((element, index) => (
                                                                    index >= limit_promotion.min && index <= limit_promotion.max ?
                                                                        <CardProduct
                                                                            key={element.shopitem_id}
                                                                            shopitem={element}
                                                                            show_stock={false}
                                                                            show_shop={true}
                                                                            app_hostname={props.app_hostname}
                                                                        ></CardProduct>
                                                                        :
                                                                        null
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        dataitem_filter_bestseller.length > 0 ?
                                            <>
                                                <div className="position-relative mt-3">
                                                    <div className="flex-center-between mb-3 market-group-header">
                                                        <h5 className="font-size-16 mb-0 py-0 font-th">สินค้าแนะนำ</h5>
                                                        <Link className="font-size-14 text-primary mb-0 font-th limit-display" to="/marketplace?category=สินค้าแนะนำ">ดูสินค้าเพิ่มเติม</Link>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {
                                                            limit_bestseller.min > 0 ?
                                                                <div className="d-none d-md-flex market-icon-slide market-icon-slideback" onClick={() => { limit_display('bestseller', 'back') }}><i className="fas fa-arrow-left"></i> </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            (dataitem_filter_bestseller.length - 1) > limit_bestseller.max > 0 ?
                                                                <div className="d-none d-md-flex market-icon-slide market-icon-slidenext" onClick={() => { limit_display('bestseller', 'next') }}><i className="fas fa-arrow-right"></i> </div>
                                                                :
                                                                null
                                                        }
                                                        <div className="flex-container">
                                                            {
                                                                dataitem_filter_bestseller.map((element, index) => (
                                                                    index >= limit_bestseller.min && index <= limit_bestseller.max ?
                                                                        <CardProduct
                                                                            key={element.shopitem_id}
                                                                            shopitem={element}
                                                                            show_stock={false}
                                                                            show_shop={true}
                                                                            app_hostname={props.app_hostname}
                                                                        ></CardProduct>
                                                                        :
                                                                        null
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        dataitem_filter_promotion.length === 0 && dataitem_filter_bestseller.length === 0 && modal_load.enable === false ?
                                            <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i>ไม่มีรายการสินค้าที่จะแสดง ลองค้นหาใหม่อีกครั้ง</div>
                                            :
                                            null
                                    }
                                </div>
                                :
                                <>
                                    <div className="mb-0  d-xl-block mt-2">
                                        <div className="position-relative mt-3">
                                            <div className="flex-center-between mb-3 market-group-header">
                                                {
                                                    view_productcategory_name === "สินค้าโปรโมชั่น" ?
                                                        <h5 className="font-size-16 mb-0 py-0 font-th">สินค้าโปรโมชั่น</h5>
                                                        : view_productcategory_name === "สินค้าแนะนำ" ?
                                                            <h5 className="font-size-16 mb-0 py-0 font-th">สินค้าแนะนำ</h5>
                                                            : view_productcategory_name === "ค้นหาสินค้า" ?
                                                                <h5 className="font-size-16 mb-0 py-0 font-th">ผลการค้นหาสินค้า</h5>
                                                                :
                                                                <h5 className="font-size-16 mb-0 py-0 font-th">หมวดหมู่สินค้า : {view_productcategory_name}</h5>
                                                }
                                                <p className="font-size-14 text-dark mb-0 font-th limit-display">{dataitem_filter_single.length === 3000 ? "มากกว่า " : ""} {dataitem_filter_single.length} รายการ</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick_onsell}
                                            pageRangeDisplayed={10}
                                            pageCount={pageCount_onsell}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            forcePage={view_defaultpaginate}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="flex-container">
                                                {
                                                    currentItems_onsell.length > 0 ?
                                                        currentItems_onsell.map((element) => (
                                                            <CardProduct
                                                                key={element.shopitem_id}
                                                                shopitem={element}
                                                                show_stock={false}
                                                                show_shop={true}
                                                                app_hostname={props.app_hostname}
                                                            ></CardProduct>
                                                        ))
                                                        :
                                                        null
                                                }
                                            </div>
                                            {
                                                currentItems_onsell.length === 3000 && pageCount_onsell === (view_defaultpaginate + 1) ?
                                                    <div className='moreproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ยังมีสินค้าอีกมากมาย กรุณาใช้เมนูค้นหา หรือ คลิ๊กที่หมวดหมูสินค้าอีกครั้ง</div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    {
                                        currentItems_onsell.length === 0 && modal_load.enable === false ?
                                            <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ไม่มีรายการสินค้าที่จะแสดง ลองค้นหาใหม่อีกครั้ง</div>
                                            :
                                            null
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
            <ModalLoad
                option={modal_load}
                backdrop={true}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            <BtnGogo></BtnGogo>
        </main>
    )
}

export default MarketPlace