import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ModalLoad from '../../../components/modal/ModalLoad';
import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import {
    orderStatus
} from "../library"
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "../DocumentTemplate.css"
import "../DeliverTimeline.css"
import BtnGogo from '../../../components/buttongoto/ButtonGoto';

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyShop_Seller_Transport(props) {
    const [myorder_list, setorder_list] = useState([])
    const [orderselect, setorderselect] = useState({})
    const [shipping_timeline, setshipping_timeline] = useState({})
    const [modal_delivery_timeline, setmodal_delivery_timeline] = useState(false)
    const [modal_cover, setmodal_cover] = useState(false)
    const [modal_packagelist, setpackagelist] = useState(false)
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        if (props.data_shoporder_seller_transport !== undefined) {
            const existingopen = props.data_shoporder_seller_transport.find(order => { return order.displayitem === true })
            if (existingopen !== undefined) {
                setorder_list(props.data_shoporder_seller_transport)
            } else {
                const nextshoporderlist = props.data_shoporder_seller_transport.map((order, index) => {
                    if (index === 0) {
                        return {
                            ...order,
                            displayitem: true
                        };
                    } else {
                        return order;
                    }
                });
                setorder_list(nextshoporderlist)
            }
        }
    }, [props.data_shoporder_seller_transport]);

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        if (message === "cancel-order") {
            api_cancel_order()
        } else if (message === "accept-cancelorder") {
            api_accpet_customercancelorder()
        } else if (message === "booking-confirm") {
            api_deliver_bookingconfirm()
        } else if (message === "recieve-deliver-return") {
            api_recieve_deliver_return()
        }
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function Toggleitem(order_id) {
        const nextshoporderlist = myorder_list.map(order => {
            if (order.order_id === order_id) {
                return {
                    ...order,
                    displayitem: !order.displayitem,
                };
            } else {
                return {
                    ...order,
                    displayitem: false,
                };
            }
        });
        setorder_list(nextshoporderlist)
    }

    function handle_printcover() {
        var idoc = document.getElementById("content-label").contentWindow;
        idoc.print();
    }

    function handle_printpackage() {
        var html = document.getElementById('packagelist-area').innerHTML;
        var idoc = document.getElementById("packagelist-content").contentWindow;
        idoc.document.body.innerHTML = html
        idoc.document.close();
        idoc.print();
    }

    async function api_cancel_order() {
        setmodal_load({ enable: true, message: "กำลังยกเลิกคำสั่งขาย" })
        await sleep(200)

        const formData = new FormData();
        formData.append('order_id', orderselect.order_id)

        axios.post(props.app_hostname + "/api/order/myshop/seller/cancelorder", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_accpet_customercancelorder() {
        setmodal_load({ enable: true, message: "กำลังตอบรับคำขอยกเลิก" })
        await sleep(200)

        const formData = new FormData();
        formData.append('order_id', orderselect.order_id)

        axios.post(props.app_hostname + "/api/order/myshop/seller/acceptcustomercancel", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_deliver_bookingconfirm() {
        setmodal_load({ enable: true, message: "กำลังเรียกบริษัทขนส่ง" })
        await sleep(200)

        const formData = new FormData();
        formData.append('order_id', orderselect.order_id)
        formData.append('shipping_purchase_id', orderselect.shipping_purchase_id)

        axios.post(props.app_hostname + "/api/order/myshop/seller/confirmdeliver", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatebooking-seller', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_deliver_printlabel(order_id, purchase_id) {
        setmodal_load({ enable: true, message: "กำลังเตรียมใบปะหน้า" })
        await sleep(200)

        const formData = new FormData();
        formData.append('order_id', order_id)
        formData.append('shipping_purchase_id', purchase_id)

        axios.post(props.app_hostname + "/api/order/myshop/seller/printlabeldeliver", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    var idoc = document.getElementById("content-label").contentWindow.document;
                    idoc.write(response.data.html);
                    idoc.close();
                    setmodal_cover(true)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_deliver_timelinetracking(tracking_code) {
        setmodal_load({ enable: true, message: "กำลังเรียกดูข้อมูล" })
        await sleep(200)

        const formData = new FormData();
        formData.append('tracking_code', tracking_code)

        axios.post(props.app_hostname + "/api/order/myshop/common/timelinetracking", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setshipping_timeline(response.data.timeline)
                    setmodal_delivery_timeline(true)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_recieve_deliver_return() {
        setmodal_load({ enable: true, message: "กำลังรับพัสดุตีกลับ" })
        await sleep(200)
        const formData = new FormData();
        formData.append('order_id', orderselect.order_id)

        axios.post(props.app_hostname + "/api/order/myshop/seller/recievedeliverreturn", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }


    return (
        <div className="my-0">
            <div className="row print-disable">
                {
                    myorder_list.map((order, index0) => (
                        <div className="col-md-12 mb-md-2 mb-lg-0" key={order.order_id}>
                            <div className="card-mycart mb-2">
                                <div className="card-mycart-header py-1">
                                    <div className="d-block d-md-flex justify-content-between py-0 flex-wrap">
                                        <div className="d-block">
                                            <div className="card-mycart-title"><i className="font-size-14 fas fa-user-tag text-primary"></i> ผู้ซื้อ : {order.buyer_shopname}</div>
                                            <p className="card-mycart-subtitle">จังหวัด {order.buyer_shopprovince}</p>
                                        </div>
                                        <div className="d-block">
                                            <div className="text-left text-md-right">{order.order_id}</div>
                                            {orderStatus(order, "seller")}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-mycart-body">
                                    <div className={"" + (order.displayitem ? "d-block" : "d-none")}>
                                        <div className="d-block d-md-flex justify-content-between pt-0 pb-2 flex-wrap border-bottom">
                                            <div className="d-block">
                                                {
                                                    order.orderitem !== null ?
                                                        order.orderitem.map((orderitems, index1) => (
                                                            <div className={"d-block d-md-flex py-1 " + (index1 > 0 ? "border-top" : "")} key={orderitems.orderitem_id}>
                                                                <div className="d-flex">
                                                                    <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" alt={orderitems.orderitem_id} src={"https://filestore.meya.co.th/productitem/" + (orderitems.productitem_id) + "/" + (orderitems.productitem_id) + "-1-" + orderitems.img1_key + ".jpg"} /></div>
                                                                    <div className='d-block product-detail'>
                                                                        <div className="mycart-box-productname py-0">{orderitems.productitem_name}</div>
                                                                        <div className="mycart-box-productinter py-0">{orderitems.productitem_inter}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="mycart-box-pricelist">
                                                                    <table>
                                                                        <tbody className="d-block mt-0">
                                                                            {
                                                                                orderitems.orderitem_pricelist !== null ?
                                                                                    orderitems.orderitem_pricelist.map((pricelist, index1) => (
                                                                                        <tr className="mycart-table-pricelist-row py-0" key={pricelist.pricelist_id} style={{ paddingTop: "0px" }}>
                                                                                            <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                            <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                            <td className="product-quantity">x {pricelist.select} ชุด</td>
                                                                                            <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="d-block mt-2 mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                                <div className="d-flex justify-content-between py-0">
                                                    <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                    <div className="table-summary-cellt">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0">
                                                    <div className="table-summary-cell">ค่าขนส่ง</div>
                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 border-bottom">
                                                    <div className="table-summary-cell">ยอดรวม</div>
                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 mt-2">
                                                    <div className="table-summary-cell">ค่าธรรมเนียม</div>
                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.shop_servicecharge).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                    <div className="table-summary-cell">ยอดของร้าน</div>
                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.shop_income).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 mt-3">
                                                    <div className="table-summary-cell">พัสดุ</div>
                                                    <div className="table-summary-cell aligh-right">{order.shipping_parcel_name}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0">
                                                    <div className="table-summary-cell">ขนส่งโดย</div>
                                                    <div className="table-summary-cell aligh-right">{order.courier_fullname}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0">
                                                    <div className="table-summary-cell">Tracking No.</div>
                                                    <div className="table-summary-cell aligh-right">{order.shipping_courier_tracking_code}</div>
                                                </div>
                                                <div className="d-flex justify-content-between py-0 border-bottom">
                                                    <div className="table-summary-cell text-success mouse-pointer" onClick={() => { setorderselect(order); api_deliver_timelinetracking(order.shipping_tracking_code) }}>ดูสถานะขนส่ง</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-block text-success mt-1">ที่อยู่ในการจัดส่ง</div>
                                        <table>
                                            <tbody className="d-block mt-0">
                                                <tr className="">
                                                    <td width={"160px"} className="mt-0 mb-0">ผู้รับ</td>
                                                    <td className="mt-0 mb-0">{order.shipping_recievename}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="mt-0 mb-0">เบอร์โทรศัพย์</td>
                                                    <td className="mt-0 mb-0">{order.shipping_telno}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="mt-0 mb-0">ที่อยู่ในการจัดส่ง</td>
                                                    <td className="mt-0 mb-0">{order.shipping_address} {order.shipping_district} {order.shipping_state} {order.shipping_province} {order.shipping_postcode}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="d-flex justify-content-between gap-2 mt-4 ">
                                            <div className="d-flex gap-2">
                                                {
                                                    order.order_status === 'PC_Book' ?
                                                        <button type="button" className="btn-rectan-small btn-danger font-th"
                                                            onClick={() => {
                                                                setorderselect(order);
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านยกเลิกคำสั่งขาย ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true,
                                                                    confirmcode: "cancel-order"
                                                                });
                                                            }}>ยกเลิกคำสั่งขาย</button>
                                                        :
                                                        null
                                                }
                                                {
                                                    order.order_status === 'PC_C_Book_Cancel_Req' ?
                                                        <button type="button" className="btn-rectan-small btn-warning font-th"
                                                            onClick={() => {
                                                                setorderselect(order);
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านตอบรับคำขอยกเลิกคำสั่งซื้อ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true,
                                                                    confirmcode: "accept-cancelorder"
                                                                });
                                                            }}>ตอบรับคำขอยกเลิก</button>
                                                        :
                                                        null
                                                }
                                                {
                                                    order.order_status === 'PC_Book' ?
                                                        <button type="button" className="btn-rectan-small btn-success text-white font-th" onClick={() => {
                                                            setorderselect(order);
                                                            setModal_popup({
                                                                modaltype: 'modal-confirm',
                                                                headers: 'กรุณายืนยัน',
                                                                message: 'การเรียกบริษัทขนส่งถือเป็นการยินยันเข้ารับพัสดุ และไม่สามารถยกเลิกคำสั่งขายนี้ได้ ท่านจะดำเนินการต่อ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                enable: true,
                                                                confirmcode: "booking-confirm"
                                                            });
                                                        }}>เรียกขนส่งรับพัสดุ</button>
                                                        :
                                                        null
                                                }
                                                {
                                                    order.order_status === 'PC_BookConfirm' ?
                                                        <button type="button" className="btn-rectan-small btn-primary text-white font-th" onClick={() => {
                                                            api_deliver_printlabel(order.order_id, order.shipping_purchase_id);
                                                        }}>พิมพ์ ใบปะหน้า</button>
                                                        :
                                                        null
                                                }
                                                <button type="button" className="btn-rectan-small btn-primary text-white font-th" onClick={() => {
                                                    setorderselect(order);
                                                    setpackagelist(true)
                                                }}>พิมพ์ Packing List</button>
                                                {
                                                    order.order_status === 'PC_BookConfirm' && order.shipping_status === "return" ?
                                                        <button type="button" className="btn-rectan-small btn-warning font-th"
                                                            onClick={() => {
                                                                setorderselect(order);
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านรับรับพัสดุตีกลับ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true,
                                                                    confirmcode: "recieve-deliver-return"
                                                                });
                                                            }}>รับพัสดุตีกลับ</button>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="ml-1 mouse-pointer" onClick={() => {
                                                Toggleitem(order.order_id);
                                            }} ><i className='fas fa-chevron-up py-0 font-size-12'></i> ย่อรายละเอียด</div>
                                        </div>
                                    </div>
                                    <div className={"" + (order.displayitem === false ? "d-block" : "d-none")}>
                                        <div className="d-flex justify-content-between">
                                            <div className="d-block mt-0">
                                                <div className="table-summary-cell ml-1">สินค้าจำนวน {order.orderitem !== null ? order.orderitem.length : null} รายการ</div>
                                                <p className="table-summary-cell ml-1 py-0 mb-0">วันที่สั่งซื้อ {dayjs(new Date(order.createtime)).subtract(7, 'hours').format('DD MMM BBBB HH:mm')}</p>
                                            </div>
                                            <div className="d-block mt-0">
                                                <div className="ml-1 mouse-pointer" onClick={() => {
                                                    Toggleitem(order.order_id);
                                                }} ><i className='fas fa-chevron-down py-0 font-size-12'></i> แสดงรายละเอียด</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ))
                }


            </div>
            <div className="cover-template  print-disable " style={{ display: modal_cover ? "block" : "none" }}>
                <iframe className='cover-template-paper' id="content-label" title="cover-template"></iframe>
                <div className="d-flex gap-2 mt-0 justify-content-center">
                    <button type="button" className="btn-rectan-small btn-primary text-white font-th mt-1 mb-1" onClick={() => { handle_printcover() }}>พิมพ์ ใบปะหน้า</button>
                    <button type="button" className="btn-rectan-small btn-ligth font-th mt-1 mb-1" onClick={() => { setmodal_cover(false) }}>X ปิดหน้าต่าง</button>
                </div>
            </div>

            {
                modal_packagelist ?
                    orderselect.orderitem !== undefined ?
                        <div className='packagelist-template d-block'>
                            <div className='packagelist-template-toolbar'>
                                <button type="button" className="btn-rectan-small btn-primary text-white font-th mt-1 mb-1" onClick={() => { handle_printpackage() }}>พิมพ์ Packing List</button>
                                <button type="button" className="btn-rectan-small btn-ligth font-th mt-1 mb-1" onClick={() => { setorderselect({}); setpackagelist(false) }}>X ปิดหน้าต่าง</button>
                            </div>
                            <div className='packagelist-template-paper-wrapper' id="packagelist-area">
                                <div className='packagelist-template-paper'>
                                    <div className='packagelist-template-paper-header'>
                                        <img className="packagelist-logo" src="/images/Meya-logo1.png" alt="packagelist-logo" />
                                        <div className='packagelist-compapyname'>Packing List Document</div>
                                        <div className='packagelist-system'>meya.co.th</div>
                                    </div>
                                    <div className='packagelist-ref font-th mt-2 text-primary'>อ้างอิง</div>
                                    <div className='packagelist-ref-table border-bottom'>
                                        <div className='packagelist-ref-table-row'>
                                            <div className='packagelist-ref-table-cell-firs'>เลขที่ใบสังซื้อ</div>
                                            <div className='packagelist-ref-table-cell'>{orderselect.order_id}</div>
                                        </div>
                                        <div className='packagelist-ref-table-row'>
                                            <div className='packagelist-ref-table-cell-firs'>วันที่สังซื้อ</div>
                                            <div className='packagelist-ref-table-cell'>{dayjs(new Date(orderselect.createtime)).subtract(7, 'hours').format('DD MMM BBBB HH:mm')}</div>
                                        </div>
                                    </div>
                                    <div className='packagelist-ref font-th mt-3 text-primary'>ตารางรายการสินค้า</div>
                                    <table className="table packagelist-item-table font-th">
                                        <tbody className="">
                                            <tr className='packagelist-item-row-head'>
                                                <td>ชื่อสินค้า</td>
                                                <td>Package</td>
                                                <td>วันหมดอายุ</td>
                                                <td>จำนวนชุด</td>
                                                <td>นับรวมชิ้น</td>
                                            </tr>
                                            {
                                                orderselect.orderitem ?
                                                    orderselect.orderitem.map((orderitems, index1) => (
                                                        orderitems.orderitem_pricelist.map((pricelist, index2) => (
                                                            <tr className='packagelist-item-row' key={pricelist.pricelist_id}>
                                                                {
                                                                    index2 < 1 ?
                                                                        <td className="packagelist-item-name" rowSpan={orderitems.orderitem_pricelist.length}>{orderitems.productitem_name}<p>{orderitems.productitem_inter}</p></td>
                                                                        :
                                                                        null
                                                                }
                                                                <td className="packagelist-item-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                <td className="packagelist-item-exp">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                <td className="packagelist-item-qty">x {pricelist.select}</td>
                                                                <td className="packagelist-item-qty">x {pricelist.select * pricelist.unit}</td>
                                                            </tr>
                                                        ))
                                                    ))
                                                    :
                                                    null
                                            }
                                        </tbody>
                                    </table>
                                    <div className='packagelist-remark font-th'>เอกสารฉบับนี้พิมพ์จาก https://www.meya.co.th</div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    :
                    null
            }
            <iframe title="packagelist" id="packagelist-content" src="/packagelist.html" ></iframe>

            <div className='delivery-timeline-box' style={{ display: modal_delivery_timeline ? "block" : "none" }}>
                <div className='delivery-timeline-box-header'>
                    <div className='d-block'>
                        <div className='timeline-box-header-title'><i className="mdi mdi-truck-fast-outline text-primary font-size-16"></i> สถานะของการขนส่งสินค้า</div>
                        <div className='timeline-box-header-order text-success'>เลขที่คำสั่งซื้อ {orderselect.order_id}</div>
                    </div>
                    <i className="fe-x text-dark mouse-pointer" onClick={() => { setmodal_delivery_timeline(false) }}></i>
                </div>
                {
                    shipping_timeline.states !== undefined ?
                        shipping_timeline.states.length > 0 ?
                            <div className='delivery-timeline-box-wrapper'>
                                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                    {
                                        shipping_timeline.states !== undefined ?
                                            shipping_timeline.states.map((states, index) => (
                                                <div class="vertical-timeline-item vertical-timeline-element">
                                                    <div>
                                                        <span class="vertical-timeline-element-icon bounce-in">
                                                            <i class="badge badge-dot badge-dot-xl badge-primary"> </i>
                                                        </span>
                                                        <div class="vertical-timeline-element-content bounce-in">
                                                            <p>{states.datetime}</p>
                                                            <h4 class="timeline-title">{states.description}</h4>
                                                            <p>{states.location}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            :
                            <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ไม่มีข้อมูลการขนส่งที่จะแสดง</div>
                        :
                        <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ไม่มีข้อมูลการขนส่งที่จะแสดง</div>
                }
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modal_cover || modal_packagelist || modal_delivery_timeline ?
                    <div className='meya-backdrop'></div>
                    :
                    null
            }
            <BtnGogo></BtnGogo>
        </div>
    )
}

export default MyShop_Seller_Transport