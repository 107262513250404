import React, { useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";
import axios from 'axios';
import ModalLoad from '../../../components/modal/ModalLoad';
import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';

import '../../../components/dropdown/SearchProductOption.css';

import moment from 'moment';
import PropTypes from 'prop-types';

import th from "antd/es/date-picker/locale/th_TH";
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { DatePicker } from "antd";
import { useDetectClickOutside } from 'react-detect-click-outside';

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyShop_Seller_NewProduct(props) {

    const [productitemlist, setProductitemlist] = useState([]);
    const [productitemlist_filter, setProductitemlist_filter] = useState([]);
    const [filtertext, setFiltertext] = useState("");
    const [productitem_selected, setProductitem_selected] = useState({});

    const [tableitem, setTableitem] = useState([{ id: moment(new Date()).format('YYYYMMDDHHmmssSSS'), unit: "", price: "", pricepromotion: "", expire: moment(new Date()).format('YYYY-MM-DD') }]);
    const [tablestock, setTablestock] = useState([{ expire: moment(new Date()).format('YYYY-MM-DD'), stock: 0 }]);
    const [searchoption, setSearchoption] = useState(false);
    const [trigerstock, setTrigerstock] = useState(false);
    const [sortdata, setSortdata] = useState(false);
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    const popupref = useDetectClickOutside({ onTriggered: closesearchoption });
    function closesearchoption() {
        setSearchoption(false);
    }

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    const buddhistLocale = {
        ...th,
        lang: {
            ...th.lang,
            fieldDateFormat: "BBBB-MMM-DD",
            fieldDateTimeFormat: "BBBB-MM-DD HH:mm:ss",
            yearFormat: "BBBB",
            cellYearFormat: "BBBB",
        },
    };

    useEffect(() => {
        setProductitemlist(props.systemdata_productitemlist)
        if (props.systemdata_productitemlist.length > 0) {
            setProductitemlist_filter(props.systemdata_productitemlist)
        }
    }, [props.systemdata_productitemlist]);

    useEffect(() => {
        var filtered_itemsx = []
        if (filtertext !== "") {
            filtered_itemsx = productitemlist.filter((item, index) => (item.productitem_name.toLowerCase().includes(filtertext.toLowerCase()) || item.productitem_inter.toLowerCase().includes(filtertext.toLowerCase())));
            setProductitemlist_filter(filtered_itemsx)
            setSearchoption(true)
        } else {
            if (productitemlist.length > 0) {
                setProductitemlist_filter(productitemlist)
            }
        }
    }, [filtertext]);

    function selectstock(expire, val) {
        const nexttableitem = tablestock.map(item => {
            if (item.expire !== expire) {
                return item;
            } else {
                return {
                    ...item,
                    stock: val,
                };
            }
        });
        setTablestock(nexttableitem);
    }

    function selectnumber(id, val, keys) {
        const nexttableitem = tableitem.map(item => {
            if (item.id !== id) {
                return item;
            } else {
                return {
                    ...item,
                    [keys]: val,
                };
            }
        });
        setTableitem(nexttableitem);
        setSortdata(false)
    }

    function selectepiredate(id, date, datestr) {
        if (date !== null) {
            const nexttableitem = tableitem.map(item => {
                if (item.id !== id) {
                    return item;
                } else {
                    return {
                        ...item,
                        expire: moment(new Date(date.$d)).format('YYYY-MM-DD'),
                    };
                }
            });
            setTableitem(nexttableitem);
        }
        setSortdata(false)
    }

    function addpricelist() {
        if (tableitem.length < 10) {
            var id = moment(new Date()).format('YYYYMMDDHHmmssSSS')
            var exp = moment(new Date()).format('YYYY-MM-DD')
            setTableitem(oldArray => [...oldArray, { id: id, unit: "", price: "", pricepromotion: "", expire: exp }]);
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: "ท่านสามารถใส่ราคาย่อยได้สูงสุด 10 รายการ",
                enable: true
            });
        }
        setSortdata(false)
    }

    function removepricelist(id) {
        if (tableitem.length > 1) {
            setTableitem(tableitem.filter(function (item) {
                return item.id !== id
            }))
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: "ตารางราคาต้องมีอย่างน้อย 1 รายการ",
                enable: true
            });
        }
        setSortdata(false)
    }

    useEffect(() => {
        for (const item of tableitem) {
            var fnd = tablestock.find((element) => {
                return element.expire === item.expire;
            })
            if (fnd === undefined) {
                setTablestock(oldArray => [...oldArray, { expire: item.expire, stock: 0 }]);
            }
        }
        setTrigerstock(!trigerstock)
    }, [tableitem]);

    useEffect(() => {
        for (const item of tablestock) {
            var fnd = tableitem.find((element) => {
                return element.expire === item.expire;
            })
            if (fnd === undefined) {
                setTablestock(tablestock.filter(function (itemx) {
                    return itemx.expire !== item.expire
                }))
            }
        }
    }, [trigerstock]);

    function sortitem() {
        setTableitem(tableitem.sort((firstItem, secondItem) => ((Date.parse(new Date(firstItem.expire)) - Date.parse(new Date(secondItem.expire))) || Number(firstItem.unit) - Number(secondItem.unit))))
        setTablestock(tablestock.sort((firstItem, secondItem) => (Date.parse(new Date(firstItem.expire)) - Date.parse(new Date(secondItem.expire)))))
        setSortdata(true)
    }

    async function api_add_shopitem() {
        var formstockelement = document.getElementById('form-stock')
        var formpriceelement = document.getElementById('form-price')
        var filtered_itemtest = productitemlist.filter((item, index) => item.productitem_id === productitem_selected.productitem_id);
        if (formpriceelement.checkValidity() === false || formstockelement.checkValidity() === false || filtered_itemtest.length < 1) {
            formstockelement.classList.add("was-validated");
            formpriceelement.classList.add("was-validated");

            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                enable: true
            });
        }
        else {
            formstockelement.classList.remove("was-validated");
            formpriceelement.classList.remove("was-validated");

            if (tableitem.length > 0) {
                if (sortdata === true || tableitem.length === 1) {
                    var temp = []
                    var proprice_found = false
                    var proprice_error = false
                    var stocksum = 0
                    var on_stock = []
                    var on_sold = ''

                    var firstprice_unit = ''
                    var firstprice_price = ''
                    var firstprice_new = ''
                    var firstprice_expire = ''
                    var firstprice_stock = ''

                    const formData = new FormData();
                    tablestock.forEach((item, index) => {
                        var x = item.expire + '**' + item.stock
                        on_stock.push(x)
                        stocksum += Number(item.stock)
                    })
                    var duplcateref = []
                    var duplcatebit = false
                    tableitem.forEach((item, index) => {
                        var dp = item.unit + '**' + item.expire
                        var fnd_dp = duplcateref.find((element) => {
                            return element === dp;
                        })
                        if (fnd_dp !== undefined) {
                            duplcatebit = true
                        }
                        duplcateref.push(dp)

                        var x = item.unit + '**' + item.price + '**' + item.pricepromotion + '**' + item.expire
                        temp.push(x)
                        on_sold = '0'
                        if (firstprice_unit === '') {
                            firstprice_unit = item.unit
                            firstprice_price = item.price
                            firstprice_new = item.pricepromotion
                            firstprice_expire = item.expire
                            var fnd = tablestock.find((element) => {
                                return element.expire === item.expire;
                            })
                            if (fnd !== undefined) {
                                firstprice_stock = fnd.stock
                            }
                        }

                        if (proprice_found === false && Number(item.pricepromotion) > 0 && Number(item.pricepromotion) < Number(item.price)) {
                            proprice_found = true
                            formData.append('first_priceunit', item.unit)
                            formData.append('first_pricebase', item.price)
                            formData.append('first_pricenew', item.pricepromotion)
                            formData.append('first_expire', item.expire)
                            var fndd = tablestock.find((element) => {
                                return element.expire === item.expire;
                            })
                            if (fndd !== undefined) {
                                formData.append('first_stock', fndd.stock)
                            }
                        }

                        if (Number(item.pricepromotion) > 0 && Number(item.pricepromotion) >= Number(item.price)) {
                            proprice_error = true
                        }

                    })

                    if (proprice_error === true) {
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: "ราคาโปรโมชั่นจะต้องน้อยกว่าราคาขายปกติ",
                            enable: true
                        });
                    } else {
                        if (duplcatebit === false) {
                            var pricestring = temp.join('##')
                            formData.append('shop_id', props.permitdata.shop_id)
                            formData.append('shop_name', props.permitdata.shop_name)
                            formData.append('shop_province', props.permitdata.shop_province)
                            var filtered_item = productitemlist.filter((item, index) => item.productitem_id === productitem_selected.productitem_id);
                            if (filtered_item.length > 0) {
                                formData.append('productitem_id', filtered_item[0].productitem_id)
                                formData.append('productitem_name', filtered_item[0].productitem_name)
                                formData.append('productitem_inter', filtered_item[0].productitem_inter)
                                formData.append('img1_key', filtered_item[0].img1_key)
                            } else {
                                formData.append('productitem_id', "")
                                formData.append('productitem_name', "")
                                formData.append('productitem_inter', "")
                                formData.append('img1_key', "")
                            }

                            if (proprice_found === false) {
                                formData.append('first_priceunit', firstprice_unit)
                                formData.append('first_pricebase', firstprice_price)
                                formData.append('first_pricenew', firstprice_new)
                                formData.append('first_expire', firstprice_expire)
                                formData.append('first_stock', firstprice_stock)
                                formData.append('ispro', 'False')
                            } else {
                                formData.append('ispro', 'True')
                            }
                            formData.append('ads', 'False')
                            formData.append('status', 'OnSell')
                            if (stocksum > 0) {
                                formData.append('isblank', 'False')
                            } else {
                                formData.append('isblank', 'True')
                            }

                            formData.append('pricetable', pricestring)
                            formData.append('on_sold', on_sold)
                            formData.append('on_stock', on_stock.join('##'))
                            formData.append('username', props.permitdata.shop_username)

                            setmodal_load({ enable: true, message: "กำลังลงขายสินค้า" })
                            await sleep(200)
                            axios.post(props.app_hostname + "/api/product/shopitem/add", formData, {
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'multipart/form-data;',
                                    "Authorization": "Bearer " + props.permitdata.token
                                },
                            })
                                .then((response) => {
                                    setmodal_load({ enable: false, message: "" })
                                    if (response.data.status === 'OK') {
                                        props.callback_datachanged('shopitem-add', response.data.items)
                                        setModal_popup({
                                            modaltype: 'modal-success',
                                            headers: 'สำเร็จ',
                                            message: response.data.message,
                                            enable: true
                                        });
                                    }
                                    else {
                                        setModal_popup({
                                            modaltype: 'modal-warning',
                                            headers: 'ขออภัย',
                                            message: response.data.message,
                                            enable: true
                                        });
                                    }
                                })
                                .catch((err) => {
                                    setmodal_load({ enable: false, message: "" })
                                    setModal_popup({
                                        modaltype: 'modal-error',
                                        headers: 'ผิดพลาด',
                                        message: err.message,
                                        enable: true
                                    });
                                });
                        } else {
                            setModal_popup({
                                modaltype: 'modal-warning',
                                headers: 'ขออภัย',
                                message: "ตรวจพบจำนวนชิ้นต่อชุดซ้ำกัน (แยกตามวันหมดอายุ)",
                                enable: true
                            });
                        }
                    }
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: 'กรุณากดปุ่ม "เรียงตารางราคาใหม่"',
                        enable: true
                    });
                }
            } else {
                setModal_popup({
                    modaltype: 'modal-warning',
                    headers: 'ขออภัย',
                    message: 'ไม่พบตารางราคา',
                    enable: true
                });
            }
        }
    }

    return (
        <div className="my-0">
            <div className="row">
                <div className="col-12 pl-3">
                    <label className="font-th" htmlFor="productitem_name"><i className='fas fa-search'></i> ค้นหาสินค้า
                    </label>
                    <div className="form-group" ref={popupref}>
                        <input type="text" className="form-control-sm font-th" name="productitem_name" id="productitem_name" placeholder='กรอกชื่อสินค้าภาษาไทยหรือภาษาอังกฤษที่ต้องการ' autoComplete='new-off' value={filtertext} onChange={(e) => { setFiltertext(e.target.value); }} onClick={() => { setSearchoption(true) }} required />
                        {
                            searchoption === true ?
                                <div className='product-option'>
                                    <div className="meya-table-console-wrapper" >
                                        <table className="table meya-table-console font-th">
                                            <thead>
                                                <tr>
                                                    <th width="50px">#</th>
                                                    <th>ชื่อสินค้าภาษาไทย/อังกฤษ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productitemlist_filter.map((product, index) => (
                                                    <tr className={"meya-table-console-row search-item " + (product.productitem_id === productitem_selected.productitem_id ? "active" : "")} onClick={() => { setProductitem_selected(product); setSearchoption(false) }} key={product.productitem_id}>
                                                        <td>{index + 1}</td>
                                                        <td className="py-1 mb-0">{product.productitem_name}<p className="py-0 mb-0">{product.productitem_inter}</p></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 mb-1 pl-3">
                    <label className="font-th" htmlFor="productitem_name">สินค้าที่ท่านเลือก</label>
                    <div className="new-product d-flex border-bottom pb-3">
                        <div className='img-wrapper'>
                            {
                                productitem_selected.productitem_id !== undefined ?
                                    <img src={"https://filestore.meya.co.th/productitem/" + productitem_selected.productitem_id + "/" + productitem_selected.productitem_id + "-1-" + productitem_selected.img1_key + ".jpg"} alt="product-img"></img>
                                    :
                                    <img src="/images/medicine/blank.png" alt="product-img"></img>
                            }
                        </div>
                        <div className="d-block product-detail">
                            <div className="font-th text-success mb-0">{productitem_selected.productitem_id}</div>
                            <div className="font-th mt-0 mb-0">{productitem_selected.productitem_name}</div>
                            <div className="product-desc font-en mt-0">{productitem_selected.productitem_inter}</div>
                            <div className="product-desc font-th mt-2">รายละเอียดของสินค้า</div>
                            <div className="product-desc font-th mt-0">{productitem_selected.productitem_desc}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6 col-md-6 mb-4 mb-3 pl-3">
                    <div className="d-block">
                        <label className="font-th text-gray-111">ตารางจำนวนคงเหลือ</label>
                    </div>
                    <form id="form-stock" action="" method="POST" encType="multipart/form-data" onSubmit={e => { e.preventDefault(); }}>
                        <div className="price-table font-th px-0" id="pricetable">
                            <div className="price-table-row px-0">
                                <div className="price-table-cell" style={{ maxWidth: "100px" }}>วันหมดอายุ</div>
                                <div className="price-table-cell">คงเหลือ (ชิ้น)</div>
                            </div>
                            {
                                tablestock.map((item) => (
                                    < div className="price-table-row" key={item.expire}>
                                        <div className="price-table-cell" style={{ maxWidth: "100px" }}>
                                            {dayjs(new Date(item.expire)).format('DD MMM BBBB')}
                                        </div>
                                        <div className="price-table-cell">
                                            <input type="number" className="form-control-sm font-th" name="pricelist-price" placeholder="จำนวน" min="0" value={item.stock} onChange={(e) => { selectstock(item.expire, e.target.value) }} autoComplete="off" required />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </form>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 mb-4 mb-3 pl-3">
                    <div className="d-flex justify-content-between">
                        <label className="font-th text-gray-111">ตารางราคา สกุลเงินบาท</label>
                        <label className="font-th btn-addprice" onClick={() => { addpricelist() }}><i className="font-size-12 fas fa-plus mr-0"></i> เพิ่มรายการราคา</label>
                    </div>
                    <form id="form-price" action="" method="POST" encType="multipart/form-data" onSubmit={e => { e.preventDefault(); }}>
                        <div className="price-table font-th px-0" id="pricetable">
                            <div className="price-table-row px-0">
                                <div className="price-table-cell">จำนวนชิ้น</div>
                                <div className="price-table-cell">ราคารวม ปกติ</div>
                                <div className="price-table-cell">ราคารวม โปรโมชั่น</div>
                                <div className="price-table-cell">วันหมดอายุ</div>
                                <div className="price-table-cell "></div>
                            </div>
                            {
                                tableitem.map((item) => (
                                    < div className="price-table-row" key={item.id}>
                                        <div className="price-table-cell">
                                            <input type="number" className="form-control-sm font-th" name="pricelist-unit" placeholder="จำนวนชิ้นต่อชุด" min="1" value={item.unit} onChange={(e) => { selectnumber(item.id, e.target.value, 'unit') }} autoComplete="off" required />
                                        </div>
                                        <div className="price-table-cell">
                                            <input type="number" className="form-control-sm font-th" name="pricelist-price" placeholder="ราคาปกติ" min="1" value={item.price} onChange={(e) => { selectnumber(item.id, e.target.value, 'price') }} autoComplete="off" required />
                                        </div>
                                        <div className="price-table-cell">
                                            <input type="number" className="form-control-sm font-th" name="pricelist-pricepromotion" placeholder="ราคาโปรโมชั่น" min="0" value={item.pricepromotion} onChange={(e) => { selectnumber(item.id, e.target.value, 'pricepromotion') }} autoComplete="off" required />
                                        </div>
                                        <div className="price-table-cell">
                                            <DatePicker
                                                allowClear={false}
                                                locale={buddhistLocale}
                                                onChange={(date, dateString) => selectepiredate(item.id, date, dateString)}
                                                className={"form-control-sm"}
                                                defaultValue={dayjs(new Date())}
                                            />
                                        </div>
                                        <div className="price-table-cell" style={{ minWidth: "50px" }}>
                                            <Link className="btn-meya-action btn-danger" onClick={() => { removepricelist(item.id) }}><i className="fe-x"></i> ลบ</Link>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </form>
                </div>

                <div className="col-md-12 mb-1 pl-3">
                    <div className="d-flex align-items-end mb-3">
                        <div className="">
                            <div className="btn btn-rectan-small px-3 btn-primary font-th text-white cursor-pointer" onClick={() => { sortitem() }}>เรียงตารางราคา</div>
                        </div>
                        <div className="ml-3">
                            <div className="btn btn-rectan-small px-3 btn-warning font-th text-dark cursor-pointer" onClick={() => { api_add_shopitem() }}>ลงขายสินค้า</div>
                        </div>
                    </div>
                </div>

                <ModalLoad
                    option={modal_load}
                ></ModalLoad>
                <ModalPopupMessage
                    callback_general={message => { showDialogCallback_General(message) }}
                    callback_confirm={message => { showDialogCallback_Confirm(message) }}
                    options={modal_popup}
                />
            </div >
        </div >
    )
}

MyShop_Seller_NewProduct.propTypes = {
    callback_datachanged: PropTypes.func,
};

export default MyShop_Seller_NewProduct