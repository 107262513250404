import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ReactPaginate from 'react-paginate';
import ModalLoad from '../../../components/modal/ModalLoad';
import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import moment from 'moment';
import BtnGogo from '../../../components/buttongoto/ButtonGoto';
import {
    orderStatus
} from "../library"
import { DatePicker } from "antd";
import dayjs from "dayjs";

function MyShop_Seller_History(props) {
    const [selected_month, setSelected_month] = useState(moment(new Date()).format('YYYY-MMM'))
    const [myorder_list, setorder_list] = useState([])
    const [orderselect, setorderselect] = useState({})
    const [shipping_timeline, setshipping_timeline] = useState({})
    const [modal_delivery_timeline, setmodal_delivery_timeline] = useState(false)

    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }


    const onChangemonth = (date, dateString) => {
        if (dateString !== "") {
            setSelected_month(dateString)
        }
    };

    useEffect(() => {
        if (selected_month !== "") {
            api_query_history(selected_month)
        }
    }, [selected_month]);

    const [itemOffset_onsell, setItemOffset_onsell] = useState(0);
    const endOffset_onsell = itemOffset_onsell + 18;
    const currentItems_onsell = myorder_list.slice(itemOffset_onsell, endOffset_onsell);
    const pageCount_onsell = Math.ceil(myorder_list.length / 18);
    const handlePageClick_onsell = (event) => {
        const newOffset_onsell = (event.selected * 18) % myorder_list.length;
        setItemOffset_onsell(newOffset_onsell);
    };

    async function api_query_history() {
        setmodal_load({ enable: true, message: "กำลังโหลดประวัติการขายย้อนหลัง" })
        await sleep(200)
        var start_time = selected_month + '-01 00:00:00'
        var stop_time = moment(new Date(start_time)).add(1, 'month').format('YYYY-MMM-DD 00:00:00')

        const formData = new FormData();
        formData.append('start_time', start_time)
        formData.append('stop_time', stop_time)
        formData.append('shop_id', props.permitdata.shop_id)
        formData.append('shop_side', 'buyer_shopid')

        axios.post(props.app_hostname + "/api/order/myshop/common/historyquery", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setorder_list(response.data.order)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_deliver_timelinetracking(tracking_code) {
        setmodal_load({ enable: true, message: "กำลังเรียกดูข้อมูล" })
        await sleep(200)

        const formData = new FormData();
        formData.append('tracking_code', tracking_code)

        axios.post(props.app_hostname + "/api/order/myshop/common/timelinetracking", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setshipping_timeline(response.data.timeline)
                    setmodal_delivery_timeline(true)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div className="my-0">
            <div className="row px-3">
                <div className="form-group" style={{ minWidth: "200px" }}>
                    <label className="font-th" htmlFor="chart_year">เลือกเดือน
                    </label>
                    <DatePicker
                        allowClear={false}
                        picker="month"
                        onChange={onChangemonth}
                        format={"YYYY-MMM"}
                        className={"form-control-sm"}
                        defaultValue={dayjs(new Date(selected_month + '-01'))}
                    />
                </div>
            </div>
            <div className="d-flex">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick_onsell}
                    pageRangeDisplayed={10}
                    pageCount={pageCount_onsell}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                />
            </div>
            <div className="row">
                {
                    currentItems_onsell.map((order, index0) => (
                        <div className="col-md-12 mb-md-2 mb-lg-0" key={order.order_id}>
                            <div className="card-mycart mb-2">
                                <div className="card-mycart-header py-1">
                                    <div className="d-block d-md-flex justify-content-between py-0 flex-wrap">
                                        <div className="d-block">
                                            <div className="card-mycart-title"><i className="font-size-14 fas fa-user-tag text-primary"></i> ผู้ซื้อ : {order.buyer_shopname}</div>
                                            <p className="card-mycart-subtitle">จังหวัด {order.buyer_shopprovince}</p>
                                        </div>
                                        <div className="d-block">
                                            <div className="text-left text-md-right">{order.order_id}</div>
                                            {orderStatus(order, "seller")}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-mycart-body">
                                    <div className="d-block d-md-flex justify-content-between pt-0 pb-2 flex-wrap">
                                        <div className="d-block">
                                            {
                                                order.orderitem !== null ?
                                                    order.orderitem.map((orderitems, index1) => (
                                                        <div className={"d-block " + (order.order_status === "PC_RTN_RCV" ? "d-xl-flex " : " d-md-flex ") + (index1 > 0 ? "border-top py-1" : "py-1")} key={orderitems.orderitem_id}>
                                                            <div className="d-flex">
                                                                <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + (orderitems.productitem_id) + "/" + (orderitems.productitem_id) + "-1-" + orderitems.img1_key + ".jpg"} alt={orderitems.orderitem_id} /></div>
                                                                <div className='d-block product-detail'>
                                                                    <div className="mycart-box-productname py-0">{orderitems.productitem_name}</div>
                                                                    <div className="mycart-box-productinter py-0">{orderitems.productitem_inter}</div>
                                                                </div>
                                                            </div>
                                                            <div className="mycart-box-pricelist">
                                                                <table>
                                                                    <tbody className="d-block mt-0">
                                                                        {
                                                                            orderitems.orderitem_pricelist !== null ?
                                                                                orderitems.orderitem_pricelist.map((pricelist, index1) => (
                                                                                    <tr className="mycart-table-pricelist-row py-0" key={pricelist.pricelist_id} style={{ paddingTop: "0px" }}>
                                                                                        <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                        <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                        <td className="product-quantity">x {pricelist.select} ชุด</td>
                                                                                        <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                        {
                                                                                            order.order_status === "PC_RTN_RCV" ?
                                                                                                <>
                                                                                                    <td className="product-quantity text-danger">คืน x {pricelist.return_select} ชิ้น</td>
                                                                                                    <td className="product-subtotal text-danger">฿ {pricelist.return_subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </tr>
                                                                                ))
                                                                                :
                                                                                null
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="d-block mt-2 mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                            <div className="d-flex justify-content-between py-0">
                                                <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                <div className="table-summary-cellt">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                            </div>
                                            <div className="d-flex justify-content-between py-0">
                                                <div className="table-summary-cell">ค่าขนส่ง</div>
                                                <div className="table-summary-cell aligh-right">฿ {Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                            </div>
                                            <div className="d-flex justify-content-between py-0 border-bottom">
                                                <div className="table-summary-cell">ยอดรวม</div>
                                                <div className="table-summary-cell aligh-right">฿ {Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                            </div>

                                            {
                                                order.order_status === "PC_RTN_RCV" ?
                                                    <>
                                                        <div className="d-flex justify-content-between py-0 mt-2 text-danger">
                                                            <div className="table-summary-cell">ยอดคืนสินค้ารวม</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(order.return_shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between py-0">
                                                            <div className="table-summary-cell">ยอดหลังคืนสินค้า</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(order.return_shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between py-0">
                                                            <div className="table-summary-cell">ค่าธรรมเนียม</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(order.return_shop_servicecharge).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                            <div className="table-summary-cell">ยอดของร้าน</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(order.return_shop_income).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                        {
                                                            order.income_paid === true ?
                                                                <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                                    <div className="table-summary-cell">โอนจ่ายแล้ว</div>
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                order.order_status === "PC_RCV" ?
                                                    <>
                                                        <div className="d-flex justify-content-between py-0 mt-2">
                                                            <div className="table-summary-cell">ค่าธรรมเนียม</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(order.shop_servicecharge).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                            <div className="table-summary-cell">ยอดของร้าน</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(order.shop_income).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                        {
                                                            order.income_paid === true ?
                                                                <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                                    <div className="table-summary-cell">โอนจ่ายแล้ว</div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                    :
                                                    null}
                                        </div>
                                    </div>
                                    {
                                        order.order_status === "PC_RTN_RCV" ?
                                            <div className="d-block pt-0 pb-2 flex-wrap border-top">
                                                <div className="d-block  mt-2">
                                                    <div className="editname pr-5 text-danger">เหตุผลในการขอคืนสินค้า</div>
                                                    <div className="editname pr-5">{order.return_reason}</div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='delivery-timeline-box' style={{ display: modal_delivery_timeline ? "block" : "none" }}>
                <div className='delivery-timeline-box-header'>
                    <div className='d-block'>
                        <div className='timeline-box-header-title'><i className="mdi mdi-truck-fast-outline text-primary font-size-16"></i> สถานะของการขนส่งสินค้า</div>
                        <div className='timeline-box-header-order text-success'>เลขที่คำสั่งซื้อ {orderselect.order_id}</div>
                    </div>
                    <i className="fe-x text-dark mouse-pointer" onClick={() => { setmodal_delivery_timeline(false) }}></i>
                </div>
                {
                    shipping_timeline.states !== undefined ?
                        shipping_timeline.states.length > 0 ?
                            <div className='delivery-timeline-box-wrapper'>
                                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                    {
                                        shipping_timeline.states !== undefined ?
                                            shipping_timeline.states.map((states, index) => (
                                                <div class="vertical-timeline-item vertical-timeline-element">
                                                    <div>
                                                        <span class="vertical-timeline-element-icon bounce-in">
                                                            <i class="badge badge-dot badge-dot-xl badge-primary"> </i>
                                                        </span>
                                                        <div class="vertical-timeline-element-content bounce-in">
                                                            <p>{states.datetime}</p>
                                                            <h4 class="timeline-title">{states.description}</h4>
                                                            <p>{states.location}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            :
                            <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ไม่มีข้อมูลการขนส่งที่จะแสดง</div>
                        :
                        <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ไม่มีข้อมูลการขนส่งที่จะแสดง</div>
                }
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            <BtnGogo></BtnGogo>
        </div>
    )
}

export default MyShop_Seller_History