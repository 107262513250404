import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ModalLoad from '../../../components/modal/ModalLoad';
import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import OrderSummaryChart from '../../../components/chart/OrderSummary';
import moment from 'moment';
import { DatePicker } from "antd";
import dayjs from "dayjs";

function MyShop_Seller_BusinessOverview(props) {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    const [chart_data1, setChart_data1] = useState([
        { label: "มกราคม", y: 0, indexLabel: "0" },
        { label: "กุมภาพันธ์", y: 0, indexLabel: "0" },
        { label: "มีนาคม", y: 0, indexLabel: "0" },
        { label: "เมษายน", y: 0, indexLabel: "0" },
        { label: "พฤษภาคม", y: 0, indexLabel: "0" },
        { label: "มิถุนายน", y: 0, indexLabel: "0" },
        { label: "กรกฎาคม", y: 0, indexLabel: "0" },
        { label: "สิงหาคม", y: 0, indexLabel: "0" },
        { label: "กันยายน", y: 0, indexLabel: "0" },
        { label: "ตุลาคม", y: 0, indexLabel: "0" },
        { label: "พฤศจิกายน", y: 0, indexLabel: "0" },
        { label: "ธันวาคม", y: 0, indexLabel: "0" }])
    const [chart_data2, setChart_data2] = useState([
        { label: "มกราคม", y: 0, indexLabel: "0" },
        { label: "กุมภาพันธ์", y: 0, indexLabel: "0" },
        { label: "มีนาคม", y: 0, indexLabel: "0" },
        { label: "เมษายน", y: 0, indexLabel: "0" },
        { label: "พฤษภาคม", y: 0, indexLabel: "0" },
        { label: "มิถุนายน", y: 0, indexLabel: "0" },
        { label: "กรกฎาคม", y: 0, indexLabel: "0" },
        { label: "สิงหาคม", y: 0, indexLabel: "0" },
        { label: "กันยายน", y: 0, indexLabel: "0" },
        { label: "ตุลาคม", y: 0, indexLabel: "0" },
        { label: "พฤศจิกายน", y: 0, indexLabel: "0" },
        { label: "ธันวาคม", y: 0, indexLabel: "0" }])

    const [chart_year, setChart_year] = useState(moment(new Date()).format('YYYY'))

    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    const onChangeYear = (date, dateString) => {
        if (dateString !== "") {
            setChart_year(dateString)
        }
    };

    useEffect(() => {
        if (chart_year !== "") {
            api_business_overview(chart_year)
        }
    }, [chart_year]);

    async function api_business_overview(year) {
        setmodal_load({ enable: true, message: "กำลังค้นข้อมูลการขาย" })
        await sleep(200)

        var formData = new FormData();
        formData.append('shop_id', props.permitdata.shop_id)
        formData.append('year', year)
        axios.post(props.app_hostname + "/api/order/myshop/seller/businesschart", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    if (response.data.plot.length > 0) {
                        response.data.plot.map(itemx => {
                            const nexttableitem = chart_data1.map((item, index) => {
                                if (index !== (itemx.monthno - 1)) {
                                    return item;
                                } else {
                                    return {
                                        ...item,
                                        y: itemx.shop_income,
                                        indexLabel: itemx.shop_income.toString(),
                                    };
                                }
                            });
                            setChart_data1(nexttableitem);
                        });
                        response.data.plot.map(itemx => {
                            const nexttableitem = chart_data2.map((item, index) => {
                                if (index !== (itemx.monthno - 1)) {
                                    return item;
                                } else {
                                    return {
                                        ...item,
                                        y: itemx.shop_servicecharge,
                                        indexLabel: itemx.shop_servicecharge.toString(),
                                    };
                                }
                            });
                            setChart_data2(nexttableitem);
                        });
                    } else {
                        setChart_data1([{ label: "มกราคม", y: 0, indexLabel: "0" },
                        { label: "กุมภาพันธ์", y: 0, indexLabel: "0" },
                        { label: "มีนาคม", y: 0, indexLabel: "0" },
                        { label: "เมษายน", y: 0, indexLabel: "0" },
                        { label: "พฤษภาคม", y: 0, indexLabel: "0" },
                        { label: "มิถุนายน", y: 0, indexLabel: "0" },
                        { label: "กรกฎาคม", y: 0, indexLabel: "0" },
                        { label: "สิงหาคม", y: 0, indexLabel: "0" },
                        { label: "กันยายน", y: 0, indexLabel: "0" },
                        { label: "ตุลาคม", y: 0, indexLabel: "0" },
                        { label: "พฤศจิกายน", y: 0, indexLabel: "0" },
                        { label: "ธันวาคม", y: 0, indexLabel: "0" }])
                        setChart_data2([{ label: "มกราคม", y: 0, indexLabel: "0" },
                        { label: "กุมภาพันธ์", y: 0, indexLabel: "0" },
                        { label: "มีนาคม", y: 0, indexLabel: "0" },
                        { label: "เมษายน", y: 0, indexLabel: "0" },
                        { label: "พฤษภาคม", y: 0, indexLabel: "0" },
                        { label: "มิถุนายน", y: 0, indexLabel: "0" },
                        { label: "กรกฎาคม", y: 0, indexLabel: "0" },
                        { label: "สิงหาคม", y: 0, indexLabel: "0" },
                        { label: "กันยายน", y: 0, indexLabel: "0" },
                        { label: "ตุลาคม", y: 0, indexLabel: "0" },
                        { label: "พฤศจิกายน", y: 0, indexLabel: "0" },
                        { label: "ธันวาคม", y: 0, indexLabel: "0" }])
                    }
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div className="my-0">
            <div className="row">
                <div className="col-12">
                    <div className="form-group" style={{ maxWidth: "200px" }}>
                        <label className="font-th" htmlFor="chart_year">เลือกปีการค้า
                        </label>
                        <DatePicker
                            allowClear={false}
                            picker="year"
                            onChange={onChangeYear}
                            format={"YYYY"}
                            className={"form-control-sm"}
                            defaultValue={dayjs(new Date(chart_year + '-01-01'))}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <OrderSummaryChart
                        chart_data1={chart_data1}
                        chart_data2={chart_data2}
                        chart_year={chart_year}
                    >
                    </OrderSummaryChart>

                    <div className=''>
                        <div className="font-th mt-3">หมายเหตุ : ยอดขายที่แสดง คือยอดขายที่คำนวณจาก งานขายที่สำเร็จ, ยกเลิก, คืนเงิน/คืนสินค้าเท่านั้น ไม่รวมการขายที่อยู่ในระหว่างดำเนินการ</div>
                    </div>
                </div>
            </div>

            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

export default MyShop_Seller_BusinessOverview