import React,
{
    useRef,
    useState,
    useEffect,
} from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';
import PropTypes from 'prop-types';
import './Sidebar.css';

function Sidebar(props) {
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams()
    const sidebarRef = useRef(null);
    const [sidebar_firstload, setSidebar_firstload] = useState(true)
    const [sidebar_selected, setSidebar_selected] = useState('')
    const [sidebar_sub1, setSidebar_sub1] = useState('')
    const [sidebar_sub2, setSidebar_sub2] = useState('')
    const sidebar1ref = useDetectClickOutside({ onTriggered: closesearchoption });
    function closesearchoption(e) {
        if (props.sidebar_toggle && (e.target.className !== 'u-hamburger__box' && e.target.className !== 'u-hamburger__inner') && e.target.className.search('navbar-toggler') < 0) {
            props.callback_closebar()
        }
    }

    useEffect(() => {
        create_rootpath()
        if (sidebar_firstload === false) {
            props.callback_closebar()
        }
    }, [location]);

    useEffect(() => {
        if (props.sidebar_toggle) {
            const sidebar = sidebarRef.current
            sidebar.className = "u-sidebar u-sidebar--left u-unfold--css-animation fadeInLeft"
            setSidebar_firstload(false)
        } else {
            if (sidebar_firstload === false) {
                const sidebar = sidebarRef.current
                sidebar.className = "u-sidebar u-sidebar--left u-unfold--css-animation fadeOutLeft"
            } else {
                const sidebar = sidebarRef.current
                sidebar.className = "u-sidebar u-sidebar--left u-unfold--css-animation u-unfold--hidden"
            }
        }

        create_rootpath()
    }, [props.sidebar_toggle]);

    function SbarClose() {
        if (props.sidebar_toggle === false) {
            const sidebar = sidebarRef.current
            sidebar.className = "u-sidebar u-sidebar--left u-unfold--css-animation u-unfold--hidden"
        }
    }

    function create_rootpath() {
        if (location.pathname.search("/home") > -1) {
            setSidebar_selected("home")
        }
        if (location.pathname.search("/marketplace") > -1) {
            setSidebar_selected("marketplace")
            const category = searchParams.get('category')
            setSidebar_sub1(category)
        }
        if (location.pathname.search("/myshop") > -1) {
            setSidebar_selected("myshop")
            var path = location.pathname
            const words = path.split('/');
            if (words.length > 2) {
                setSidebar_sub1(words[2])
            }
            if (words.length > 3) {
                setSidebar_sub2(words[2] + "/" + words[3])
            }
        }
        if (location.pathname.search("/about") > -1) {
            setSidebar_selected("about")
        }
    }

    return (
        <>
            <aside id="sidebarHeader1" ref={sidebarRef} className="u-sidebar u-sidebar--left u-unfold--css-animation " aria-labelledby="sidebarHeaderInvoker" style={{ animationDuration: "500ms", left: " 0px", zIndex: "6000" }} onAnimationEnd={() => SbarClose()}>
                <div className="u-sidebar__scroller" ref={sidebar1ref}>
                    <div className="u-sidebar__container">
                        <div className="u-header-sidebar__footer-offset">

                            <div className="position-absolute top-0 right-0 z-index-2 pt-4 pr-4 bg-white">
                                <button type="button" className="close ml-auto target-of-invoker-has-unfolds active" aria-controls="sidebarHeader" aria-haspopup="true" aria-expanded="true" onClick={() => { props.callback_closebar() }}>
                                    <span aria-hidden="true"><i className="ec ec-close-remove text-gray-90 font-size-20"></i></span>
                                </button>
                            </div>

                            <div className="js-scrollbar u-sidebar__body  " style={{ position: "relative", overflow: "visible" }}>
                                <div id="mCSB_1" className="" style={{ maxheight: "none" }} tabIndex="0">
                                    <div id="mCSB_1_container" className="mCSB_containerx" style={{ position: "relative", top: "0px", left: "0px", dir: "ltr" }}>
                                        <div id="headerSidebarContent" className="u-sidebar__content u-header-sidebar__content">

                                            <Link className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center mb-3">
                                                <img className="d-block" src="/images/Meya-logo1.png"
                                                    style={{ height: "60px", width: "auto" }} />
                                                <h5 className="text-primary my-0 font-th">มียา.com</h5>
                                            </Link>

                                            <ul id="headerSidebarList" className="u-header-collapse__nav font-th">
                                                <li className="">
                                                    <Link className={"u-header-collapse__nav-link " + (sidebar_selected === "home" ? "active" : "")} to="/home">หน้าแรก</Link>
                                                </li>

                                                <li className="u-has-submenu u-header-collapse__submenu">
                                                    <div className={"u-header-collapse__nav-link u-header-collapse__nav-pointer " + (sidebar_selected === "marketplace" ? "active" : "")} data-target="#headerSidebarMarketCollapse" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="headerSidebarMarketCollapse" onClick={() => { sidebar_selected === "marketplace" ? setSidebar_selected('') : setSidebar_selected('marketplace') }}>
                                                        ซื้อสินค้า
                                                    </div>

                                                    <div id="headerSidebarMarketCollapse" className={"collapse " + (sidebar_selected === "marketplace" ? "show" : "")} data-parent="#headerSidebarContent" >
                                                        <ul className="u-header-collapse__nav-list">
                                                            <li className=""><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "สินค้าโปรโมชั่น" ? "active" : "")} to="/marketplace?category=สินค้าโปรโมชั่น">สินค้าโปรโมชั่น</Link></li>
                                                            <li className=""><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "สินค้าแนะนำ" ? "active" : "")} to="/marketplace?category=สินค้าแนะนำ">สินค้าแนะนำ</Link></li>
                                                            {
                                                                props.islogin === false ?
                                                                    props.systemdata_productcategorylist.map((element, index) => (
                                                                        <li className="" key={element.productcategory_id}><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === element.productcategory_name ? "active" : "")} to={"/marketplace?category=" + (element.productcategory_name)}>{element.productcategory_name}</Link></li>
                                                                    ))
                                                                    :
                                                                    null
                                                            }
                                                        </ul>
                                                    </div>
                                                </li>

                                                <li className="u-has-submenu u-header-collapse__submenu">
                                                    <div className={"u-header-collapse__nav-link u-header-collapse__nav-pointer " + (sidebar_selected === "myshop" ? "active" : "")} data-target="#headerSidebarMyshopCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarMyshopCollapse" onClick={() => { sidebar_selected === "myshop" ? setSidebar_selected('') : setSidebar_selected('myshop') }} >
                                                        ร้านค้าของฉัน
                                                    </div>

                                                    <div id="headerSidebarMyshopCollapse" className={"collapse " + (sidebar_selected === "myshop" ? "show" : "")} data-parent="#headerSidebarContent">
                                                        <ul className="u-header-collapse__nav-list ">
                                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                                <div className={"u-header-collapse__nav-link u-header-collapse__nav-pointer " + (sidebar_sub1 === "seller" ? "active" : "")} data-target="#headerSidebarMyshop_SellCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarMyshopCollapse" onClick={() => { setSidebar_sub1('seller') }}>
                                                                    ระบบขายสินค้า
                                                                </div>
                                                                <div id="headerSidebarMyshop_SellCollapse" className={"collapse " + (sidebar_sub1 === "seller" ? "show" : "")} data-parent="#headerSidebarMyshopCollapse">
                                                                    <ul className="u-header-collapse__nav-list border-none ">
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "seller" && sidebar_sub2 === "seller/jobtodo" ? "active" : "")} to="/myshop/seller/jobtodo">สรุปงานที่ต้องทำ</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "seller" && sidebar_sub2 === "seller/businessoverview" ? "active" : "")} to="/myshop/seller/businessoverview">ภาพรวมงานขาย</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "seller" && sidebar_sub2 === "seller/payment" ? "active" : "")} to="/myshop/seller/payment">ที่ลูกค้าต้องชำระ</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "seller" && sidebar_sub2 === "seller/deliver" ? "active" : "")} to="/myshop/seller/deliver">ที่ต้องจัดส่ง</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "seller" && sidebar_sub2 === "seller/transport" ? "active" : "")} to="/myshop/seller/transport">กำลังจัดส่ง</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "seller" && sidebar_sub2 === "seller/return" ? "active" : "")} to="/myshop/seller/return">การคืนเงิน/คืนสินค้า</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "seller" && sidebar_sub2 === "seller/history" ? "active" : "")} to="/myshop/seller/history">ประวัติการขายย้อนหลัง</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "seller" && sidebar_sub2 === "seller/myproduct" ? "active" : "")} to="/myshop/seller/myproduct">สินค้าของฉัน</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "seller" && sidebar_sub2 === "seller/newproduct" ? "active" : "")} to="/myshop/seller/newproduct">เพิ่มสินค้าใหม่</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                                <div className={"u-header-collapse__nav-link u-header-collapse__nav-pointer " + (sidebar_sub1 === "buyer" ? "active" : "")} data-target="#headerSidebarMyshop_BuyCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarMyshopCollapse" onClick={() => { setSidebar_sub1('buyer') }}>
                                                                    ระบบซื้อสินค้า
                                                                </div>
                                                                <div id="headerSidebarMyshop_BuyCollapse" className={"collapse " + (sidebar_sub1 === "buyer" ? "show" : "")} data-parent="#headerSidebarMyshopCollapse">
                                                                    <ul className="border-none u-header-collapse__nav-list border-none">
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "buyer" && sidebar_sub2 === "buyer/payment" ? "active" : "")} to="/myshop/buyer/payment">ที่ต้องชำระ</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "buyer" && sidebar_sub2 === "buyer/deliver" ? "active" : "")} to="/myshop/buyer/deliver">ที่ต้องจัดส่ง</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "buyer" && sidebar_sub2 === "buyer/recieve" ? "active" : "")} to="/myshop/buyer/recieve">ที่ต้องได้รับ</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "buyer" && sidebar_sub2 === "buyer/return" ? "active" : "")} to="/myshop/buyer/return">การคืนเงิน/คืนสินค้า</Link></li>
                                                                        <li><Link className={"u-header-collapse__submenu-nav-link " + (sidebar_sub1 === "buyer" && sidebar_sub2 === "buyer/history" ? "active" : "")} to="/myshop/buyer/history">ประวัติการซื้อย้อนหลัง</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>

                                                <li className="">
                                                    <Link className={"u-header-collapse__nav-link " + (sidebar_selected === "about" ? "active" : "")} to="/about">เกี่ยวกับเรา</Link>
                                                </li>

                                                {
                                                    props.islogin === false && props.permitdata !== null ?
                                                        props.permitdata.role !== 'guest' && props.permitdata.token !== '' ?
                                                            <li className="">
                                                                <Link className="u-header-collapse__nav-link" to="/">ออกจากระบบ</Link>
                                                            </li>
                                                            :
                                                            <li className="">
                                                                <Link className="u-header-collapse__nav-link" to="/">ลงทะเบียน / เข้าสู่ระบบ</Link>
                                                            </li>
                                                        :
                                                        null
                                                }
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sidebar-word font-th'>ขอบคุณที่ใช้บริการ meya.co.th</div>
                </div>
            </aside>
            <div className='sidebar-backdrop' style={{ display: props.sidebar_toggle ? "block" : "none" }}></div>
        </>
    )
}

Sidebar.propTypes = {
    callback_closebar: PropTypes.func,
};


export default Sidebar