import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ModalLoad from '../../../components/modal/ModalLoad';
import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import ReactPaginate from 'react-paginate';
import th from "antd/es/date-picker/locale/th_TH";
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { DatePicker } from "antd";
import moment from 'moment';
import Tabs from "../../../components/tabs/Tabs";
import BtnGogo from '../../../components/buttongoto/ButtonGoto';

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyShop_Seller_MyProduct(props) {
    const [forcepage_onsell, setForcepage_onsell] = useState(0);
    const [forcepage_blank, setForcepage_blank] = useState(0);
    const [forcepage_promotion, setForcepage_promotion] = useState(0);

    const [shopitemlist_searchfind, setShopitemlist_searchfind] = useState('');

    const [shopitemlist_all, setShopitemlist_all] = useState([]);
    const [shopitemlist_onsell, setShopitemlist_onsell] = useState([]);
    const [shopitemlist_onpromotion, setShopitemlist_onpromotion] = useState([]);
    const [shopitemlist_onblank, setShopitemlist_onblank] = useState([]);

    const [shopitemedit, setShopitemedit] = useState({});
    const [tableitem, setTableitem] = useState([]);
    const [tablestock, setTablestock] = useState([]);
    const [trigerstock, setTrigerstock] = useState(false);
    const [sortdata, setSortdata] = useState(false);
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_editprice, setModal_editprice] = useState(false);

    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const buddhistLocale = {
        ...th,
        lang: {
            ...th.lang,
            fieldDateFormat: "BBBB-MMM-DD",
            fieldDateTimeFormat: "BBBB-MMM-DD HH:mm:ss",
            yearFormat: "BBBB",
            cellYearFormat: "BBBB",
        },
    };

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        if (shopitemlist_searchfind !== '') {
            setShopitemlist_all(props.data_shopitemlist.filter(items => items.productitem_name.toLowerCase().includes(shopitemlist_searchfind) || items.productitem_inter.toLowerCase().includes(shopitemlist_searchfind)))
        } else {
            setShopitemlist_all(props.data_shopitemlist)
        }
    }, [shopitemlist_searchfind]);

    useEffect(() => {
        setShopitemlist_all(props.data_shopitemlist)
    }, [props.data_shopitemlist]);

    useEffect(() => {
        setShopitemlist_onsell(shopitemlist_all.filter(items => items.status === "OnSell" && Number(items.first_pricenew < 1)))
        setShopitemlist_onpromotion(shopitemlist_all.filter(items => items.status === "OnSell" && Number(items.first_pricenew > 0)))
        setShopitemlist_onblank(shopitemlist_all.filter(items => items.status === "OnSell" && items.isblank === true))
    }, [shopitemlist_all]);

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        api_delete_shopitem(shopitemedit)
    }

    const cfilter = (shopitem) => {
        var pricedata = []
        var rows = shopitem.pricetable.split("##")
        if (rows.length > 0) {
            rows.forEach((rowitem, index) => {
                var items = rowitem.split("**")
                if (items.length >= 4) {
                    pricedata.push(
                        {
                            pricelist_id: shopitem.shopitem_id + '-' + index.toString(),
                            unit: items[0],
                            price: items[1],
                            pricepromotion: items[2],
                            expire: items[3],
                        }
                    )
                }
            })
        }
        return pricedata
    };

    function selectstock(expire, val) {
        const nexttableitem = tablestock.map(item => {
            if (item.expire !== expire) {
                return item;
            } else {
                return {
                    ...item,
                    stock: val,
                };
            }
        });
        setTablestock(nexttableitem);
        setSortdata(false)
    }

    function selectnumber(id, val, keys) {
        const nexttableitem = tableitem.map(item => {
            if (item.pricelist_id !== id) {
                return item;
            } else {
                if (Number(val) > -1) {
                    return {
                        ...item,
                        [keys]: val,
                    };
                } else {
                    return {
                        ...item,
                        [keys]: 0,
                    };
                }
            }
        });
        setTableitem(nexttableitem);
        setSortdata(false)
    }

    function selectepiredate(id, date, datestr) {
        if (date !== null) {
            const nexttableitem = tableitem.map(item => {
                if (item.pricelist_id !== id) {
                    return item;
                } else {
                    return {
                        ...item,
                        expire: moment(new Date(date.$d)).format('YYYY-MM-DD'),
                    };
                }
            });
            setTableitem(nexttableitem);
        }
        setSortdata(false)
    }

    function addpricelist() {
        if (tableitem.length < 10) {
            var id = moment(new Date()).format('YYYYMMDDHHmmssSSS')
            var exp = moment(new Date()).format('YYYY-MM-DD')
            setTableitem(oldArray => [...oldArray, { pricelist_id: id, unit: "", price: "", pricepromotion: "", expire: exp }]);
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: "ท่านสามารถใส่ราคาย่อยได้สูงสุด 10 รายการ",
                enable: true
            });
        }
        setSortdata(false)
    }

    function removepricelist(id) {
        if (tableitem.length > 1) {
            setTableitem(tableitem.filter(function (item) {
                return item.pricelist_id !== id
            }))
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: "ตารางราคาต้องมีอย่างน้อย 1 รายการ",
                enable: true
            });
        }
        setSortdata(false)
    }

    useEffect(() => {
        if (shopitemedit.pricetable !== undefined) {
            var stockdata = []
            var pricedata = []
            var rows = shopitemedit.pricetable.split("##")
            var stock = shopitemedit.on_stock.split("##")
            stock.forEach((rowitem, index) => {
                var stockitems = rowitem.split("**")
                stockdata.push({
                    expire: stockitems[0],
                    stock: Number(stockitems[1])
                })
            })
            setTablestock(stockdata)

            if (rows.length > 0) {
                rows.forEach((rowitem, index) => {
                    var items = rowitem.split("**")
                    if (items.length >= 4) {
                        var id = moment(new Date()).format('YYYYMMDDHHmmssSSS') + '-' + index.toString()
                        pricedata.push(
                            {
                                pricelist_id: id,
                                unit: items[0],
                                price: items[1],
                                pricepromotion: items[2],
                                expire: items[3],
                            }
                        )
                    }
                })
            }
            setTableitem(pricedata)
        }
    }, [shopitemedit]);

    useEffect(() => {
        for (const item of tableitem) {
            var fnd = tablestock.find((element) => {
                return element.expire === item.expire;
            })
            if (fnd === undefined) {
                setTablestock(oldArray => [...oldArray, { expire: item.expire, stock: 0 }]);
            }
        }
        setTrigerstock(!trigerstock)
    }, [tableitem]);

    useEffect(() => {
        for (const item of tablestock) {
            var fnd = tableitem.find((element) => {
                return element.expire === item.expire;
            })
            if (fnd === undefined) {
                setTablestock(tablestock.filter(function (itemx) {
                    return itemx.expire !== item.expire
                }))
            }
        }
    }, [trigerstock]);

    function cancel_edit() {
        setModal_editprice(false)
        setTableitem([])
        setShopitemedit({})
    }

    function openedit_modal() {
        setModal_editprice(true)
    }

    function sortitem() {
        setTableitem(tableitem.sort((firstItem, secondItem) => ((Date.parse(new Date(firstItem.expire)) - Date.parse(new Date(secondItem.expire))) || Number(firstItem.unit) - Number(secondItem.unit))))
        setTablestock(tablestock.sort((firstItem, secondItem) => (Date.parse(new Date(firstItem.expire)) - Date.parse(new Date(secondItem.expire)))))
        setSortdata(true)
    }

    async function api_load_shopitem_stock(item) {
        setmodal_load({ enable: true, message: "กำลังโหลดข้อมูลสินค้า" })
        await sleep(200)

        const formData = new FormData();
        formData.append('shopitem_id', item.shopitem_id)
        formData.append('shop_id', item.shop_id)
        formData.append('productitem_id', item.productitem_id)

        axios.post(props.app_hostname + "/api/product/shopitem/getproductbyid", formData, {
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    var tmp = item
                    tmp.isblank = response.data.item.isblank
                    tmp.ispro = response.data.item.ispro
                    tmp.on_stock = response.data.item.on_stock
                    tmp.on_sold = response.data.item.on_sold
                    setShopitemedit(tmp)
                    props.callback_datachanged('shopitem-update-stock', response.data.item)
                    openedit_modal()
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setShopitemedit({})
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_edit_shopitem() {
        var formstockelement = document.getElementById('form-stock')
        var formpriceelement = document.getElementById('form-price')

        if (formpriceelement.checkValidity() === false || formstockelement.checkValidity() === false) {
            formstockelement.classList.add("was-validated");
            formpriceelement.classList.add("was-validated");

            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                enable: true
            });
        }
        else {
            formstockelement.classList.remove("was-validated");
            formpriceelement.classList.remove("was-validated");

            if (tableitem.length > 0) {
                if (sortdata === true || tableitem.length === 1) {
                    var temp = []
                    var proprice_found = false
                    var proprice_error = false
                    var stocksum = 0
                    var on_stock = []
                    var on_sold = ''

                    var firstprice_unit = ''
                    var firstprice_price = ''
                    var firstprice_new = ''
                    var firstprice_expire = ''
                    var firstprice_stock = ''

                    const formData = new FormData();
                    tablestock.forEach((item, index) => {
                        var x = item.expire + '**' + item.stock
                        on_stock.push(x)
                        stocksum += Number(item.stock)
                    })
                    var duplcateref = []
                    var duplcatebit = false
                    tableitem.forEach((item, index) => {
                        var dp = item.unit + '**' + item.expire
                        var fnd_dp = duplcateref.find((element) => {
                            return element === dp;
                        })
                        if (fnd_dp !== undefined) {
                            duplcatebit = true
                        }
                        duplcateref.push(dp)

                        var x = item.unit + '**' + item.price + '**' + item.pricepromotion + '**' + item.expire
                        temp.push(x)
                        on_sold = '0'
                        if (firstprice_unit === '') {
                            firstprice_unit = item.unit
                            firstprice_price = item.price
                            firstprice_new = item.pricepromotion
                            firstprice_expire = item.expire
                            var fnd = tablestock.find((element) => {
                                return element.expire === item.expire;
                            })
                            if (fnd !== undefined) {
                                firstprice_stock = fnd.stock
                            }
                        }

                        if (proprice_found === false && Number(item.pricepromotion) > 0 && Number(item.pricepromotion) < Number(item.price)) {
                            proprice_found = true
                            formData.append('first_priceunit', item.unit)
                            formData.append('first_pricebase', item.price)
                            formData.append('first_pricenew', item.pricepromotion)
                            formData.append('first_expire', item.expire)
                            const fnd = tablestock.find((element) => {
                                return element.expire === item.expire;
                            })
                            if (fnd !== undefined) {
                                formData.append('first_stock', fnd.stock)
                            }
                        }

                        if (Number(item.pricepromotion) > 0 && Number(item.pricepromotion) >= Number(item.price)) {
                            proprice_error = true
                        }

                    })

                    if (proprice_error === true) {
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: "ราคาโปรโมชั่นจะต้องน้อยกว่าราคาขายปกติ",
                            enable: true
                        });
                    } else {
                        if (duplcatebit === false) {
                            var pricestring = temp.join('##')
                            formData.append('shopitem_id', shopitemedit.shopitem_id)
                            formData.append('shop_id', props.permitdata.shop_id)
                            formData.append('shop_name', props.permitdata.shop_name)
                            formData.append('shop_province', props.permitdata.shop_province)
                            formData.append('productitem_id', shopitemedit.productitem_id)
                            formData.append('productitem_name', shopitemedit.productitem_name)
                            formData.append('productitem_inter', shopitemedit.productitem_inter)
                            formData.append('img1_key', shopitemedit.img1_key)

                            if (proprice_found === false) {
                                formData.append('first_priceunit', firstprice_unit)
                                formData.append('first_pricebase', firstprice_price)
                                formData.append('first_pricenew', firstprice_new)
                                formData.append('first_expire', firstprice_expire)
                                formData.append('first_stock', firstprice_stock)
                                formData.append('ispro', 'False')
                            } else {
                                formData.append('ispro', 'True')
                            }
                            formData.append('ads', shopitemedit.ads)
                            formData.append('status', 'OnSell')
                            if (stocksum > 0) {
                                formData.append('isblank', 'False')
                            } else {
                                formData.append('isblank', 'True')
                            }

                            formData.append('pricetable', pricestring)
                            formData.append('on_sold', on_sold)
                            formData.append('on_stock', on_stock.join('##'))
                            formData.append('username', props.permitdata.shop_username)

                            setmodal_load({ enable: true, message: "กำลัแก้ไขข้อมูลสินค้า" })
                            await sleep(200)
                            axios.post(props.app_hostname + "/api/product/shopitem/edit", formData, {
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'multipart/form-data;',
                                    "Authorization": "Bearer " + props.permitdata.token
                                },
                            })
                                .then((response) => {
                                    setmodal_load({ enable: false, message: "" })
                                    if (response.data.status === 'OK') {
                                        props.callback_datachanged('shopitem-edit', response.data.items)
                                        setModal_editprice(false)
                                        setModal_popup({
                                            modaltype: 'modal-success',
                                            headers: 'สำเร็จ',
                                            message: response.data.message,
                                            enable: true
                                        });
                                    }
                                    else {
                                        setModal_popup({
                                            modaltype: 'modal-warning',
                                            headers: 'ขออภัย',
                                            message: response.data.message,
                                            enable: true
                                        });
                                    }
                                })
                                .catch((err) => {
                                    setmodal_load({ enable: false, message: "" })
                                    setModal_popup({
                                        modaltype: 'modal-error',
                                        headers: 'ผิดพลาด',
                                        message: err.message,
                                        enable: true
                                    });
                                });
                        } else {
                            setModal_popup({
                                modaltype: 'modal-warning',
                                headers: 'ขออภัย',
                                message: "ตรวจพบจำนวนชิ้นต่อชุดซ้ำกัน (แยกตามวันหมดอายุ)",
                                enable: true
                            });
                        }
                    }
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: 'กรุณากดปุ่ม "เรียงตารางราคาใหม่"',
                        enable: true
                    });
                }
            } else {
                setModal_popup({
                    modaltype: 'modal-warning',
                    headers: 'ขออภัย',
                    message: 'ไม่พบตารางราคา',
                    enable: true
                });
            }
        }
    }

    async function api_delete_shopitem(item) {
        setmodal_load({ enable: true, message: "กำลังลบสินค้าออกจากร้าน" })
        await sleep(200)

        const formData = new FormData();
        formData.append('shopitem_id', item.shopitem_id)
        formData.append('shop_id', item.shop_id)
        formData.append('productitem_id', item.productitem_id)
        axios.post(props.app_hostname + "/api/product/shopitem/delete", formData, {
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                setShopitemedit({})
                if (response.data.status === 'OK') {
                    props.callback_datachanged('shopitem-delete', response.data.id)
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setShopitemedit({})
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    const [itemOffset_onsell, setItemOffset_onsell] = useState(0);
    const endOffset_onsell = itemOffset_onsell + 20;
    const currentItems_onsell = shopitemlist_onsell.slice(itemOffset_onsell, endOffset_onsell);
    const pageCount_onsell = Math.ceil(shopitemlist_onsell.length / 20);
    const handlePageClick_onsell = (event) => {
        setForcepage_onsell(event.selected)
        const newOffset_onsell = (event.selected * 20) % shopitemlist_onsell.length;
        setItemOffset_onsell(newOffset_onsell);
    };

    const [itemOffset_onpromotion, setItemOffset_onpromotion] = useState(0);
    const endOffset_onpromotion = itemOffset_onpromotion + 20;
    const currentItems_onpromotion = shopitemlist_onpromotion.slice(itemOffset_onpromotion, endOffset_onpromotion);
    const pageCount_onpromotion = Math.ceil(shopitemlist_onpromotion.length / 20);
    const handlePageClick_onpromotion = (event) => {
        setForcepage_promotion(event.selected)
        const newOffset_onpromotion = (event.selected * 20) % shopitemlist_onpromotion.length;
        setItemOffset_onpromotion(newOffset_onpromotion);
    };

    const [itemOffset_onblank, setItemOffset_onblank] = useState(0);
    const endOffset_onblank = itemOffset_onblank + 20;
    const currentItems_onblank = shopitemlist_onblank.slice(itemOffset_onblank, endOffset_onblank);
    const pageCount_onblank = Math.ceil(shopitemlist_onblank.length / 20);
    const handlePageClick_onblank = (event) => {
        setForcepage_blank(event.selected)
        const newOffset_onblank = (event.selected * 20) % shopitemlist_onblank.length;
        setItemOffset_onblank(newOffset_onblank);
    };

    return (
        <div className="my-0">
            <div className="row">
                <div className="col-12 pl-3">
                    <label className="font-th" htmlFor="productitem_name"><i className='fas fa-search'></i> ค้นหาสินค้า
                    </label>
                    <div className="input-group mb-3 font-th">
                        <input type="text" className={"form-control-sm" + (shopitemlist_searchfind.length > 0 ? " active" : "")} name="text" autoComplete="off" placeholder="ค้นหา : ชื่อสินค้าภาษาไทย/อังกฤษ" value={shopitemlist_searchfind} onChange={(e) => { setShopitemlist_searchfind(e.target.value) }} required />
                        {
                            shopitemlist_searchfind.length > 0 ?
                                <div className="input-group-icon" onClick={() => { setShopitemlist_searchfind('') }}> <i className="fe-x icon"></i></div> :
                                null
                        }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 pl-3">
                    <Link to="/myshop/seller/newproduct" title="เพิ่มสินค้า"><i className="font-size-12 fas fa-plus mr-0 mb-3"></i> เพิ่มสินค้า</Link>
                    <div>
                        <Tabs callback_tabclick={label => { }}
                            border_r={true}
                        >
                            <div label='ราคาปกติ'>
                                <div className='meya-pagination-container'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick_onsell}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount_onsell}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        forcePage={forcepage_onsell}
                                    />
                                </div>

                                <table className="table myshop-table font-th">
                                    <thead className='responsive-block'>
                                        <tr>
                                            <th>ชื่อสินค้า</th>
                                            <th width="100px">จำนวนชิ้น</th>
                                            <th width="100px">ราคา </th>
                                            <th width="100px">วันหมดอายุ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentItems_onsell.map((shopitem, index) => (
                                                <tr key={shopitem.shopitem_id} className='responsive-block'>
                                                    <td className='responsive-block'>
                                                        <div className="column-img">
                                                            <div className='img-wrapper'>
                                                                <img src={"https://filestore.meya.co.th/productitem/" + shopitem.productitem_id + "/" + shopitem.productitem_id + "-1-" + shopitem.img1_key + ".jpg"} alt={shopitem.shopitem_id}></img>
                                                            </div>
                                                            <div className="d-block product-detail">
                                                                <div className="font-th text-success mb-0">{shopitem.productitem_id} {
                                                                    shopitem.ads === true ?
                                                                        <span className='badge badge-pill badge-success'>Ads</span>
                                                                        :
                                                                        null
                                                                }</div>
                                                                {/* <div className="product-desc font-th mt-0 text-secondary">ขายแล้ว {shopitem.on_sold} ชิ้น</div> */}
                                                                <div className="font-th mt-0 mb-0">{shopitem.productitem_name}</div>
                                                                <div className="product-desc font-en mt-0">{shopitem.productitem_inter}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td colSpan={3} className='responsive-block'>
                                                        <table>
                                                            <tbody>
                                                                <tr className="pricelist-row subprice-table-header">
                                                                    <td width="100px">จำนวนชิ้น</td>
                                                                    <td width="100px">ราคา</td>
                                                                    <td width="100px">วันหมดอายุ</td>
                                                                </tr>
                                                                {
                                                                    cfilter(shopitem).map((pricelist, index1) => (
                                                                        <tr className="pricelist-row" key={pricelist.pricelist_id}>
                                                                            <td width="100px">{pricelist.unit}</td>
                                                                            <td width="100px">{pricelist.price}</td>
                                                                            <td width="100px">{dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td className="font-th responsive-block responsive-block-right">
                                                        <button className="btn-meya-action btn-warning" onClick={() => {
                                                            api_load_shopitem_stock(shopitem)
                                                        }}><i className="font-size-14 mdi mdi-file-edit-outline"></i> แก้ไข</button>
                                                        <button className="btn-meya-action btn-danger ml-1"
                                                            onClick={() => {
                                                                setShopitemedit(shopitem)
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านต้องการลบรายการสินค้านี้ใช่หรือไม่ หากใช่ให้กดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true
                                                                });
                                                            }}><i className="font-size-14 mdi mdi-eraser"></i> ลบ</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                                <div className='meya-pagination-container'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick_onsell}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount_onsell}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        forcePage={forcepage_onsell}
                                    />
                                </div>
                            </div>
                            <div label='ลดราคา'>
                                <div className='meya-pagination-container'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick_onpromotion}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount_onpromotion}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        forcePage={forcepage_promotion}
                                    />
                                </div>
                                <table className="table myshop-table font-th">
                                    <thead>
                                        <tr>
                                            <th>ชื่อสินค้า</th>
                                            <th width="100px">จำนวนชิ้น</th>
                                            <th width="100px">ราคาปกติ/โปร</th>
                                            <th width="100px">วันหมดอายุ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentItems_onpromotion.map((shopitem, index) => (
                                                <tr key={shopitem.shopitem_id} className='responsive-block'>
                                                    <td className='responsive-block'>
                                                        <div className="column-img">
                                                            <div className='img-wrapper'>
                                                                <img src={"https://filestore.meya.co.th/productitem/" + shopitem.productitem_id + "/" + shopitem.productitem_id + "-1-" + shopitem.img1_key + ".jpg"} alt={shopitem.shopitem_id}></img>
                                                            </div>
                                                            <div className="d-block product-detail">
                                                                <div className="font-th text-success mb-0">{shopitem.productitem_id} {
                                                                    shopitem.ads === true ?
                                                                        <span className='badge badge-pill badge-success'>Ads</span>
                                                                        :
                                                                        null
                                                                }</div>
                                                                {/* <div className="product-desc font-th mt-0 text-secondary">ขายแล้ว {shopitem.on_sold} ชิ้น</div> */}
                                                                <div className="font-th mt-0 mb-0">{shopitem.productitem_name}</div>
                                                                <div className="product-desc font-en mt-0">{shopitem.productitem_inter}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td colSpan={3} className='responsive-block'>
                                                        <table>
                                                            <tbody>
                                                                <tr className="pricelist-row subprice-table-header">
                                                                    <td width="100px">จำนวนชิ้น</td>
                                                                    <td width="100px">ราคาปกติ/โปร</td>
                                                                    <td width="100px">วันหมดอายุ</td>
                                                                </tr>
                                                                {
                                                                    cfilter(shopitem).map((pricelist, index1) => (
                                                                        <tr className="pricelist-row" key={pricelist.pricelist_id}>
                                                                            <td width="100px">{pricelist.unit}</td>
                                                                            <td width="100px">{pricelist.price} / {pricelist.pricepromotion}</td>
                                                                            <td width="100px">{dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td className="font-th responsive-block responsive-block-right">
                                                        <button className="btn-meya-action btn-warning" onClick={() => {
                                                            api_load_shopitem_stock(shopitem)
                                                        }}><i className="font-size-14 mdi mdi-file-edit-outline"></i> แก้ไข</button>
                                                        <button className="btn-meya-action btn-danger ml-1"
                                                            onClick={() => {
                                                                setShopitemedit(shopitem)
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านต้องการลบรายการสินค้านี้ใช่หรือไม่ หากใช่ให้กดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true
                                                                });
                                                            }}><i className="font-size-14 mdi mdi-eraser"></i> ลบ</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='meya-pagination-container'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick_onpromotion}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount_onpromotion}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        forcePage={forcepage_promotion}
                                    />
                                </div>
                            </div>
                            <div label='สินค้าหมด'>
                                <div className='meya-pagination-container'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick_onblank}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount_onblank}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        forcePage={forcepage_blank}
                                    />
                                </div>
                                <table className="table myshop-table font-th">
                                    <thead>
                                        <tr>
                                            <th>ชื่อสินค้า</th>
                                            <th width="100px">จำนวนชิ้น</th>
                                            <th width="100px">ราคาปกติ/โปร</th>
                                            <th width="100px">วันหมดอายุ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentItems_onblank.map((shopitem, index) => (
                                                <tr key={shopitem.shopitem_id} className='responsive-block'>
                                                    <td className='responsive-block'>
                                                        <div className="column-img">
                                                            <div className='img-wrapper'>
                                                                <img src={"https://filestore.meya.co.th/productitem/" + shopitem.productitem_id + "/" + shopitem.productitem_id + "-1-" + shopitem.img1_key + ".jpg"} alt={shopitem.shopitem_id}></img>
                                                            </div>
                                                            <div className="d-block product-detail">
                                                                <div className="font-th text-success mb-0">{shopitem.productitem_id} {
                                                                    shopitem.ads === true ?
                                                                        <span className='badge badge-pill badge-success'>Ads</span>
                                                                        :
                                                                        null
                                                                }</div>
                                                                {/* <div className="product-desc font-th mt-0 text-secondary">ขายแล้ว {shopitem.on_sold} ชิ้น</div> */}
                                                                <div className="font-th mt-0 mb-0">{shopitem.productitem_name}</div>
                                                                <div className="product-desc font-en mt-0">{shopitem.productitem_inter}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td colSpan={3} className='responsive-block'>
                                                        <table>
                                                            <tbody>
                                                                <tr className="pricelist-row subprice-table-header">
                                                                    <td width="100px">จำนวนชิ้น</td>
                                                                    <td width="100px">ราคาปกติ/โปร</td>
                                                                    <td width="100px">วันหมดอายุ</td>
                                                                </tr>
                                                                {
                                                                    cfilter(shopitem).map((pricelist, index1) => (
                                                                        <tr className="pricelist-row" key={pricelist.pricelist_id}>
                                                                            <td width="100px">{pricelist.unit}</td>
                                                                            <td width="100px">{pricelist.price} / {pricelist.pricepromotion}</td>
                                                                            <td width="100px">{dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td className="font-th responsive-block responsive-block-right">
                                                        <button className="btn-meya-action btn-warning" onClick={() => {
                                                            api_load_shopitem_stock(shopitem)
                                                        }}><i className="font-size-14 mdi mdi-file-edit-outline"></i> แก้ไข</button>
                                                        <button className="btn-meya-action btn-danger ml-1"
                                                            onClick={() => {
                                                                setShopitemedit(shopitem)
                                                                setModal_popup({
                                                                    modaltype: 'modal-confirm',
                                                                    headers: 'กรุณายืนยัน',
                                                                    message: 'ท่านต้องการลบรายการสินค้านี้ใช่หรือไม่ หากใช่ให้กดปุ่ม "ดำเนินการต่อ"',
                                                                    enable: true
                                                                });
                                                            }}><i className="font-size-14 mdi mdi-eraser"></i> ลบ</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='meya-pagination-container'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick_onblank}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount_onblank}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        forcePage={forcepage_blank}
                                    />
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div >
            </div >

            <div className="modal meya-overide " style={{ display: modal_editprice ? "block" : "none" }}>
                <div className="modal-dialog modal-lg  modal-dialog-centered" >
                    <div className="modal-content" style={{ overflowY: "scroll", maxHeight: "90vh" }}>
                        <div className="modal-header">
                            <h5 className="modal-title font-th py-0 mb-0"><i className="fa fa-pen text-warning font-size-14"></i> แก้ไขตารางราคาสินค้า</h5>
                        </div>
                        <div className="modal-body font-th">
                            <div className="d-flex border-bottom pb-3">
                                <div className='img-wrapper'>
                                    <img src={"https://filestore.meya.co.th/productitem/" + shopitemedit.productitem_id + "/" + shopitemedit.productitem_id + "-1-" + shopitemedit.img1_key + ".jpg"} alt="popup-select"></img>
                                </div>
                                <div className="d-block product-detail">
                                    <div className="font-th text-success mb-0">{shopitemedit.productitem_id}</div>
                                    <div className="product-desc font-th mt-0 text-secondary">ขายแล้ว {shopitemedit.on_sold} ชิ้น</div>
                                    <div className="font-th mt-0 mb-0">{shopitemedit.productitem_name}</div>
                                    <div className="product-desc font-en mt-0">{shopitemedit.productitem_inter}</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6 mb-4 mb-3">
                                    <div className="d-block">
                                        <label className="font-th">ตารางจำนวนคงเหลือ</label>
                                    </div>
                                    <form id="form-stock" action="" method="POST" encType="multipart/form-data" onSubmit={e => { e.preventDefault(); }}>
                                        <div className="price-table font-th px-0" id="pricetable">
                                            <div className="price-table-row px-0">
                                                <div className="price-table-cell" style={{ maxWidth: "100px" }}>วันหมดอายุ</div>
                                                <div className="price-table-cell">คงเหลือ (ชิ้น)</div>
                                            </div>
                                            {
                                                tablestock.map((item) => (
                                                    < div className="price-table-row" key={item.expire}>
                                                        <div className="price-table-cell" style={{ maxWidth: "100px" }}>
                                                            {dayjs(new Date(item.expire)).format('DD MMM BBBB')}
                                                        </div>
                                                        <div className="price-table-cell">
                                                            <input type="number" className="form-control-sm font-th" name="pricelist-price" placeholder="จำนวน" min="0" value={item.stock} onChange={(e) => { selectstock(item.expire, e.target.value) }} autoComplete="off" required />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <label className="font-th">ตารางราคา</label>
                                        <label className="font-th text-gray-111 btn-addprice" onClick={() => { addpricelist() }}><i className="font-size-12 fas fa-plus mr-0"></i> เพิ่มรายการราคา</label>
                                    </div>
                                    <form id="form-price" action="" method="POST" encType="multipart/form-data" onSubmit={e => { e.preventDefault(); }}>
                                        <div className="price-table " id="pricetable">
                                            <div className="price-table-row">
                                                <div className="price-table-cell">จำนวนชิ้น</div>
                                                <div className="price-table-cell">ราคา ปกติ</div>
                                                <div className="price-table-cell">ราคา โปรโมชั่น</div>
                                                <div className="price-table-cell">วันหมดอายุ</div>
                                                <div className="price-table-cell"></div>
                                            </div>
                                            {
                                                tableitem.map((item) => (
                                                    < div className="price-table-row" key={item.pricelist_id}>
                                                        <div className="price-table-cell">
                                                            <input type="number" className="form-control-sm font-th" name="pricelist-unit" placeholder="จำนวนชิ้นต่อชุด" min="1" value={item.unit} onChange={(e) => { selectnumber(item.pricelist_id, e.target.value, 'unit') }} autoComplete="off" required />
                                                        </div>
                                                        <div className="price-table-cell">
                                                            <input type="number" className="form-control-sm font-th" name="pricelist-price" placeholder="ราคาต่อหน่วย" min="1" value={item.price} onChange={(e) => { selectnumber(item.pricelist_id, e.target.value, 'price') }} autoComplete="off" required />
                                                        </div>
                                                        <div className="price-table-cell">
                                                            <input type="number" className="form-control-sm font-th" name="pricelist-pricepromotion" placeholder="ราคาต่อหน่วย" min="0" value={item.pricepromotion} onChange={(e) => { selectnumber(item.pricelist_id, e.target.value, 'pricepromotion') }} autoComplete="off" required />
                                                        </div>
                                                        <div className="price-table-cell">
                                                            <DatePicker
                                                                allowClear={false}
                                                                locale={buddhistLocale}
                                                                defaultValue={dayjs(new Date(item.expire))}
                                                                onChange={(date, dateString) => selectepiredate(item.pricelist_id, date, dateString)}
                                                                className={"form-control-sm"}
                                                            />
                                                        </div>
                                                        <div className="price-table-cell">
                                                            <Link className="btn-meya-action btn-danger" onClick={() => { removepricelist(item.pricelist_id) }}><i className="fe-x"></i> ลบ</Link>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className='d-flex col-12 px-0 justify-content-between'>
                                <button className="btn btn-primary text-white font-th" type="button" onClick={() => { sortitem() }}><i className="fas fa-sort-alpha-down"></i> เรียงตารางราคา</button>
                                <div>
                                    <button className="btn btn-warning text-dark font-th" type="button" onClick={() => { api_edit_shopitem() }}><i className="fe-save"></i> ยืนยันแก้ไข</button>
                                    <button className="btn btn-light font-th modal-forceclose" type="button" onClick={() => { cancel_edit() }}><i className="fe-x"></i> ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />

            {
                modal_editprice ?
                    <div className='meya-overide-backdrop'></div>
                    :
                    null
            }
            <BtnGogo></BtnGogo>
        </div >
    )
}

MyShop_Seller_MyProduct.propTypes = {
    callback_datachanged: PropTypes.func,
};

export default MyShop_Seller_MyProduct