import React, { useState, useEffect } from 'react';

import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import {
    orderStatus
} from "../library"
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import BtnGogo from '../../../components/buttongoto/ButtonGoto';

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyShop_Seller_Payment(props) {
    const [myorder_list, setorder_list] = useState([])
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    useEffect(() => {
        setorder_list(props.data_shoporder_seller_payment)
    }, [props.data_shoporder_seller_payment]);


    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    return (
        <div className="my-0">
            <div className="row">
                {
                    myorder_list.map((order, index0) => (
                        <div className="col-md-12 mb-md-2 mb-lg-0 " key={order.order_id}>
                            <div className="card-mycart mb-2 meya-shadow-active">
                                <div className="card-mycart-header py-1">
                                    <div className="d-block d-md-flex justify-content-between py-0 flex-wrap">
                                        <div className="d-block">
                                            <div className="card-mycart-title"><i className="font-size-14 fas fa-user-tag text-primary"></i> ผู้ซื้อ : {order.buyer_shopname}</div>
                                            <p className="card-mycart-subtitle">จังหวัด {order.buyer_shopprovince}</p>
                                        </div>
                                        <div className="d-block">
                                            <div className="text-left text-md-right">{order.order_id}</div>
                                            {orderStatus(order, "seller")}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-mycart-body">
                                    <div className="d-block d-md-flex justify-content-between pt-0 pb-2 flex-wrap">
                                        <div className="d-block">
                                            {
                                                order.orderitem !== null ?
                                                    order.orderitem.map((orderitems, index1) => (
                                                        <div className={"d-block d-md-flex py-1 " + (index1 > 0 ? "border-top" : "")} key={orderitems.orderitem_id}>
                                                            <div className="d-flex">
                                                                <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + (orderitems.productitem_id) + "/" + (orderitems.productitem_id) + "-1-" + orderitems.img1_key + ".jpg"} alt={orderitems.orderitem_id} /></div>
                                                                <div className='d-block product-detail'>
                                                                    <div className="mycart-box-productname py-0">{orderitems.productitem_name}</div>
                                                                    <div className="mycart-box-productinter py-0">{orderitems.productitem_inter}</div>
                                                                </div>
                                                            </div>
                                                            <div className="mycart-box-pricelist">
                                                                <table >
                                                                    <tbody className="d-block mt-0">
                                                                        {
                                                                            orderitems.orderitem_pricelist !== null ?
                                                                                orderitems.orderitem_pricelist.map((pricelist, index1) => (
                                                                                    <tr className="mycart-table-pricelist-row py-0" key={pricelist.pricelist_id} style={{ paddingTop: "0px" }}>
                                                                                        <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                        <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                        <td className="product-quantity">x {pricelist.select} ชุด</td>
                                                                                        <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                    </tr>
                                                                                ))
                                                                                :
                                                                                null
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="d-block mt-2 mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                            <div className="d-flex justify-content-between py-0 border-bottom text-danger">
                                                <div className="table-summary-cell">ต้องชำระภายใน</div>
                                                <div className="table-summary-cell aligh-right">{dayjs(new Date(order.expiretime)).subtract(7, 'hours').format('DD MMM BBBB HH:mm')}</div>
                                            </div>
                                            <div className="d-flex justify-content-between py-0 mt-2">
                                                <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                <div className="table-summary-cellt">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                            </div>
                                            <div className="d-flex justify-content-between py-0">
                                                <div className="table-summary-cell">ค่าขนส่ง</div>
                                                <div className="table-summary-cell aligh-right">฿ {Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                            </div>
                                            <div className="d-flex justify-content-between py-0 border-bottom">
                                                <div className="table-summary-cell">ยอดที่ต้องชำระ</div>
                                                <div className="table-summary-cell aligh-right">฿ {Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                            </div>
                                            <div className="d-flex justify-content-between py-0 mt-2">
                                                <div className="table-summary-cell">ค่าธรรมเนียม</div>
                                                <div className="table-summary-cell aligh-right">฿ {Number(order.shop_servicecharge).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                            </div>
                                            <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                <div className="table-summary-cell">ยอดของร้าน</div>
                                                <div className="table-summary-cell aligh-right">฿ {Number(order.shop_income).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            <BtnGogo></BtnGogo>
        </div>
    )
}

export default MyShop_Seller_Payment