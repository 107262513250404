import React, { useState, useEffect } from 'react';

import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import {
    orderStatus
} from "../library"
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "../DocumentTemplate.css"
import BtnGogo from '../../../components/buttongoto/ButtonGoto';
dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyShop_Buyer_Refund(props) {
    const [myorder_list, setorder_list] = useState([])
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    useEffect(() => {
        if (props.data_shoporder_buyer_refund !== undefined) {
            const existingopen = props.data_shoporder_buyer_refund.find(order => { return order.displayitem === true })
            if (existingopen !== undefined) {
                setorder_list(props.data_shoporder_buyer_refund)
            } else {
                const nextshoporderlist = props.data_shoporder_buyer_refund.map((order, index) => {
                    if (index === 0) {
                        return {
                            ...order,
                            displayitem: true
                        };
                    } else {
                        return order;
                    }
                });
                setorder_list(nextshoporderlist)
            }
        }
    }, [props.data_shoporder_buyer_refund]);

    function Toggleitem(order_id) {
        const nextshoporderlist = myorder_list.map(order => {
            if (order.order_id === order_id) {
                return {
                    ...order,
                    displayitem: !order.displayitem,
                };
            } else {
                return {
                    ...order,
                    displayitem: false,
                };
            }
        });
        setorder_list(nextshoporderlist)
    }

    return (
        <div className="my-0">
            <div className="row print-disable">
                {
                    myorder_list.length > 0 ?
                        myorder_list.map((order, index0) => (
                            <div className="col-md-12 mb-md-2 mb-lg-0" key={order.order_id}>
                                <div className="card-mycart mb-2">
                                    <div className="card-mycart-header py-1">
                                        <div className="d-block d-md-flex justify-content-between py-0 flex-wrap">
                                            <div className="d-block">
                                                <div className="card-mycart-title"><i className="font-size-14 fas fa-user-tag text-primary"></i> ผู้ขาย : {order.seller_shopname}</div>
                                                <p className="card-mycart-subtitle">จังหวัด {order.seller_shopprovince}</p>
                                            </div>
                                            <div className="d-block">
                                                <div className="text-left text-md-right">{order.order_id}</div>
                                                {orderStatus(order, "buyer")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-mycart-body">
                                        <div className={"" + (order.displayitem ? "d-block" : "d-none")}>
                                            <div className="d-block d-md-flex justify-content-between pt-0 pb-2 flex-wrap border-bottom">
                                                <div className="d-block">
                                                    {
                                                        order.orderitem !== null ?
                                                            order.orderitem.map((orderitems, index1) => (
                                                                <div className={"d-block d-xl-flex py-1 " + (index1 > 0 ? "border-top" : "")} key={orderitems.orderitem_id}>
                                                                    <div className="d-flex">
                                                                        <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + orderitems.productitem_id + `/` + orderitems.productitem_id + "-1-" + orderitems.img1_key + ".jpg"} alt="product Items" /></div>
                                                                        <div className='d-block product-detail'>
                                                                            <div className="mycart-box-productname py-0">{orderitems.productitem_name}</div>
                                                                            <div className="mycart-box-productinter py-0">{orderitems.productitem_inter}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mycart-box-pricelist">
                                                                        <table>
                                                                            <tbody className="d-block mt-0">
                                                                                {
                                                                                    orderitems.orderitem_pricelist !== null ?
                                                                                        orderitems.orderitem_pricelist.map((pricelist, index1) => (
                                                                                            <tr className="mycart-table-pricelist-row py-0" key={pricelist.pricelist_id} style={{ paddingTop: "0px" }}>
                                                                                                <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                                <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                                <td className="product-quantity">x {pricelist.select} ชุด</td>
                                                                                                <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                                {
                                                                                                    order.order_status === "PC_RTN_RCV" ?
                                                                                                        <>
                                                                                                            <td className="product-quantity text-danger">คืน x {pricelist.return_select} ชิ้น</td>
                                                                                                            <td className="product-subtotal text-danger">฿ {pricelist.return_subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </tr>
                                                                                        ))
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="d-block mt-2 mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                                    <div className="d-flex justify-content-between py-0">
                                                        <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                        <div className="table-summary-cellt">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-0">
                                                        <div className="table-summary-cell">ค่าขนส่ง</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-0 border-bottom">
                                                        <div className="table-summary-cell">ยอดรวม</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>

                                                    {
                                                        order.order_status === "PC_RTN_RCV" ?
                                                            <>
                                                                <div className="d-flex justify-content-between py-0 mt-2 text-danger">
                                                                    <div className="table-summary-cell">ยอดคืนสินค้ารวม</div>
                                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.return_shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                </div>
                                                                {
                                                                    order.refund_paid === true ?
                                                                        <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                                            <div className="table-summary-cell">โอนจ่ายแล้ว</div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>

                                            {
                                                order.order_status === "PC_RTN_RCV" ?
                                                    <div className="d-block pt-0 pb-2 flex-wrap border-top">
                                                        <div className="d-block  mt-2">
                                                            <div className="editname pr-5 text-danger">เหตุผลในการขอคืนสินค้า</div>
                                                            <div className="editname pr-5">{order.return_reason}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            <div className="d-flex justify-content-between gap-2 mt-4 ">
                                                <div className="d-flex gap-2">

                                                </div>
                                                <div className="ml-1 mouse-pointer" onClick={() => {
                                                    Toggleitem(order.order_id);
                                                }} ><i className='fas fa-chevron-up py-0 font-size-12'></i> ย่อรายละเอียด</div>
                                            </div>
                                        </div>
                                        <div className={"" + (order.displayitem === false ? "d-block" : "d-none")}>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-block mt-0">
                                                    <div className="table-summary-cell ml-1">สินค้าจำนวน {order.orderitem !== null ? order.orderitem.length : "-"} รายการ</div>
                                                    <p className="table-summary-cell ml-1 py-0 mb-0">วันที่สั่งซื้อ {dayjs(new Date(order.createtime)).subtract(7, 'hours').format('DD MMM BBBB HH:mm')}</p>
                                                </div>
                                                <div className="d-block mt-0">
                                                    <div className="ml-1 mouse-pointer" onClick={() => {
                                                        Toggleitem(order.order_id);
                                                    }} ><i className='fas fa-chevron-down py-0 font-size-12'></i> แสดงรายละเอียด</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))
                        :
                        null
                }
            </div>

            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            <BtnGogo></BtnGogo>
        </div>
    )
}

export default MyShop_Buyer_Refund