import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ModalLoad from '../../../components/modal/ModalLoad';
import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import {
    orderStatus
} from "../library"
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "../DocumentTemplate.css"
import BtnGogo from '../../../components/buttongoto/ButtonGoto';
dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MyShop_Buyer_Return(props) {
    const [myorder_list, setorder_list] = useState([])
    const [orderselect, setorderselect] = useState({})
    const [shipping_timeline, setshipping_timeline] = useState({})
    const [modal_delivery_timeline, setmodal_delivery_timeline] = useState(false)
    const [modal_cover, setmodal_cover] = useState(false)
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        if (props.data_shoporder_buyer_return !== undefined) {
            const existingopen = props.data_shoporder_buyer_return.find(order => { return order.displayitem === true })
            if (existingopen !== undefined) {
                setorder_list(props.data_shoporder_buyer_return)
            } else {
                const nextshoporderlist = props.data_shoporder_buyer_return.map((order, index) => {
                    if (index === 0) {
                        return {
                            ...order,
                            displayitem: true
                        };
                    } else {
                        return order;
                    }
                });
                setorder_list(nextshoporderlist)
            }
        }
    }, [props.data_shoporder_buyer_return])

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        setorderselect({})
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        if (message === "recieve-item") {
            api_recieve_item()
        } else if (message === "return-item") {
            api_return_req()
        } else if (message === "deliver-booking") {
            api_deliver_bookingconfirm()
        }
    }

    function Toggleitem(order_id) {
        const nextshoporderlist = myorder_list.map(order => {
            if (order.order_id === order_id) {
                return {
                    ...order,
                    displayitem: !order.displayitem,
                };
            } else {
                return {
                    ...order,
                    displayitem: false,
                };
            }
        });
        setorder_list(nextshoporderlist)
    }

    function handle_printcover() {
        var idoc = document.getElementById("content-label").contentWindow;
        idoc.print();
    }

    function calitemubtotal(old_unit, old_subtotal, old_select, price_ea, select) {
        var eq = old_unit * old_select
        if (eq === select) {
            return old_subtotal
        } else {
            if (price_ea > 0) {
                var priceunit = Number(price_ea)
                return priceunit * select
            } else {
                return 0
            }
        }
    }

    function edit_return_reason(order_id, value) {
        const nexttableitem = myorder_list.map(order => {
            if (order.order_id !== order_id || order.order_status !== "PC_RTN_Prepare") {
                return order;
            } else {
                return {
                    ...order,
                    return_reason: value,
                };
            }
        });
        setorder_list(nexttableitem);
    }

    function selectunit(order_id, orderitem_id, pricelist_id, value) {
        const nexttableitem = myorder_list.map(order => {
            if (order.order_id !== order_id || order.order_status !== "PC_RTN_Prepare") {
                return order;
            } else {
                var sum = 0
                const nextcartitem = order.orderitem.map(orderitem => {
                    if (orderitem.orderitem_id === orderitem_id) {
                        const nextpriceitem = orderitem.orderitem_pricelist.map(orderitem_pricelist => {
                            if (orderitem_pricelist.pricelist_id === pricelist_id) {
                                var need = value
                                var max = orderitem_pricelist.select * orderitem_pricelist.unit
                                if (need >= 0 && need <= max) {
                                    var calc = calitemubtotal(orderitem_pricelist.unit, orderitem_pricelist.subtotal, orderitem_pricelist.select, orderitem_pricelist.price_ea, value)
                                    sum += calc
                                    return {
                                        ...orderitem_pricelist,
                                        return_select: value,
                                        return_subtotal: calc,
                                    };
                                } else {
                                    sum += orderitem_pricelist.return_subtotal
                                    return orderitem_pricelist;
                                }
                            } else {
                                sum += orderitem_pricelist.return_subtotal
                                return orderitem_pricelist;
                            }
                        });
                        return {
                            ...orderitem,
                            orderitem_pricelist: nextpriceitem,
                        };
                    } else {
                        orderitem.orderitem_pricelist.map(orderitem_pricelist => {
                            sum += orderitem_pricelist.return_subtotal
                            return orderitem_pricelist;
                        });
                        return orderitem;
                    }
                });
                return {
                    ...order,
                    orderitem: nextcartitem,
                    return_shop_subtotal: sum,
                };
            }
        });
        setorder_list(nexttableitem);
    }

    function stepup(order_id, orderitem_id, pricelist_id) {
        const nexttableitem = myorder_list.map(order => {
            if (order.order_id !== order_id || order.order_status !== "PC_RTN_Prepare") {
                return order;
            } else {
                var sum = 0
                const nextcartitem = order.orderitem.map(orderitem => {
                    if (orderitem.orderitem_id === orderitem_id) {
                        const nextpriceitem = orderitem.orderitem_pricelist.map(orderitem_pricelist => {
                            if (orderitem_pricelist.pricelist_id === pricelist_id) {
                                var need = Number(orderitem_pricelist.return_select) + 1
                                var max = orderitem_pricelist.select * orderitem_pricelist.unit
                                if (need <= max) {
                                    var calc = calitemubtotal(orderitem_pricelist.unit, orderitem_pricelist.subtotal, orderitem_pricelist.select, orderitem_pricelist.price_ea, Number(orderitem_pricelist.return_select) + 1)
                                    sum += calc
                                    return {
                                        ...orderitem_pricelist,
                                        return_select: Number(orderitem_pricelist.return_select) + 1,
                                        return_subtotal: calc,
                                    };
                                } else {
                                    sum += orderitem_pricelist.return_subtotal
                                    return orderitem_pricelist;
                                }
                            } else {
                                sum += orderitem_pricelist.return_subtotal
                                return orderitem_pricelist;
                            }
                        });
                        return {
                            ...orderitem,
                            orderitem_pricelist: nextpriceitem,
                        };
                    } else {
                        orderitem.orderitem_pricelist.map(orderitem_pricelist => {
                            sum += orderitem_pricelist.return_subtotal
                            return orderitem_pricelist;
                        });
                        return orderitem;
                    }
                });
                return {
                    ...order,
                    orderitem: nextcartitem,
                    return_shop_subtotal: sum,
                };
            }
        });

        setorder_list(nexttableitem);
    }

    function stepdown(order_id, orderitem_id, pricelist_id) {
        const nexttableitem = myorder_list.map(order => {
            if (order.order_id !== order_id || order.order_status !== "PC_RTN_Prepare") {
                return order;
            } else {
                var sum = 0
                const nextcartitem = order.orderitem.map(orderitem => {
                    if (orderitem.orderitem_id === orderitem_id) {
                        const nextpriceitem = orderitem.orderitem_pricelist.map(orderitem_pricelist => {
                            if (orderitem_pricelist.pricelist_id === pricelist_id) {
                                var need = Number(orderitem_pricelist.return_select) - 1
                                if (need > -1) {
                                    var clac = calitemubtotal(orderitem_pricelist.unit, orderitem_pricelist.subtotal, orderitem_pricelist.select, orderitem_pricelist.price_ea, Number(orderitem_pricelist.return_select) - 1)
                                    sum += clac
                                    return {
                                        ...orderitem_pricelist,
                                        return_select: Number(orderitem_pricelist.return_select) - 1,
                                        return_subtotal: clac,
                                    };
                                } else {
                                    sum += orderitem_pricelist.return_subtotal
                                    return orderitem_pricelist;
                                }
                            } else {
                                sum += orderitem_pricelist.return_subtotal
                                return orderitem_pricelist;
                            }
                        });
                        return {
                            ...orderitem,
                            orderitem_pricelist: nextpriceitem,
                        };
                    } else {
                        orderitem.orderitem_pricelist.map(orderitem_pricelist => {
                            sum += orderitem_pricelist.return_subtotal
                            return orderitem_pricelist;
                        });
                        return orderitem;
                    }
                });
                return {
                    ...order,
                    orderitem: nextcartitem,
                    return_shop_subtotal: sum,
                };
            }
        });
        setorder_list(nexttableitem);
    }

    const InputFileChange = (e, order_id) => {
        const nexttableitem = myorder_list.map(order => {
            if (order.order_id !== order_id || order.order_status !== "PC_RTN_Prepare") {
                return order;
            } else {
                let images = [];
                let images_files = [];
                for (let i = 0; i < e.target.files.length; i++) {
                    let file = e.target.files[i];
                    images.push(URL.createObjectURL(file));
                    images_files.push(file)
                }

                return {
                    ...order,
                    return_image: images,
                    return_image_files: images_files,
                };
            }
        });
        setorder_list(nexttableitem);
    }

    async function api_recieve_item() {
        setmodal_load({ enable: true, message: "กำลังตอบรับสินค้า" })
        await sleep(200)

        const formData = new FormData();
        formData.append('order_id', orderselect.order_id)

        axios.post(props.app_hostname + "/api/order/myshop/buyer/acceptitemsrecieve", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setorderselect({})
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setorderselect({})
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_return_req() {
        if (orderselect.order_id !== undefined) {
            if (orderselect.return_shop_subtotal > 0) {
                if (orderselect.return_reason !== "" && orderselect.return_reason !== null) {
                    setmodal_load({ enable: true, message: "กำลังส่งคำขอคืนสินค้า" })
                    await sleep(200)

                    const formData = new FormData();
                    formData.append('order_id', orderselect.order_id)
                    formData.append('return_reason', orderselect.return_reason)

                    myorder_list.map(order => {
                        if (order.order_id === orderselect.order_id) {
                            if (order.return_image_files !== undefined && order.return_image_files !== null) {
                                order.return_image_files.map((file, index) => {
                                    formData.append('file' + index, file)
                                    return file;
                                })
                                formData.append('file_len', order.return_image_files.length)
                            } else {
                                formData.append('file_len', "0")
                            }
                        }
                        return order;
                    })

                    orderselect.return_image = null
                    orderselect.return_image_files = null

                    formData.append("order_data", JSON.stringify(orderselect));
                    axios.post(props.app_hostname + "/api/order/myshop/buyer/returnreq", formData, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data;',
                            "Authorization": "Bearer " + props.permitdata.token
                        },
                    })
                        .then((response) => {
                            setmodal_load({ enable: false, message: "" })
                            if (response.data.status === 'OK') {
                                setModal_popup({
                                    modaltype: 'modal-success',
                                    headers: 'สำเร็จ',
                                    message: response.data.message,
                                    enable: true
                                });
                            }
                            else {
                                setModal_popup({
                                    modaltype: 'modal-warning',
                                    headers: 'ขออภัย',
                                    message: response.data.message,
                                    enable: true
                                });
                            }
                            if (response.data.reload !== undefined) {
                                if (response.data.reload === true) {
                                    if (response.data.status === 'OK') {
                                        orderselect.order_status = response.data.curent_order.order_status
                                        orderselect.shipping_status = response.data.curent_order.shipping_status
                                        orderselect.api_paymentstatus = response.data.curent_order.api_paymentstatus
                                        orderselect.displayitem = true
                                        props.callback_datachanged('shoporder-updatefullstatus-fullrecord', orderselect)
                                    } else {
                                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                                    }
                                }
                            }
                        })
                        .catch((err) => {
                            setmodal_load({ enable: false, message: "" })
                            setModal_popup({
                                modaltype: 'modal-error',
                                headers: 'ผิดพลาด',
                                message: err.message,
                                enable: true
                            });
                        });
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: "กรุณากรอกเหตุผลในการขอคืนสินค้า",
                        enable: true
                    });
                }
            } else {
                setModal_popup({
                    modaltype: 'modal-warning',
                    headers: 'ขออภัย',
                    message: "กรุณาเลือกรายการสินค้าที่ต้องการคืน",
                    enable: true
                });
            }
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: "ข้อมูลมีปัญหา ไม่สามารถส่งคำขอคืนสินค้าได้",
                enable: true
            });
        }
    }

    async function api_deliver_printlabel(order_id, purchase_id) {
        setmodal_load({ enable: true, message: "กำลังเตรียมใบปะหน้า" })
        await sleep(200)

        const formData = new FormData();
        formData.append('order_id', order_id)
        formData.append('return_purchase_id', purchase_id)

        axios.post(props.app_hostname + "/api/order/myshop/buyer/printlabeldeliver", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    var idoc = document.getElementById("content-label").contentWindow.document;
                    idoc.write(response.data.html);
                    idoc.close();
                    setmodal_cover(true)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                setorderselect({})
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatefullstatus', response.data.curent_order)
                    }
                }
            })
            .catch((err) => {
                setorderselect({})
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_deliver_bookingconfirm() {
        setmodal_load({ enable: true, message: "กำลังเรียกบริษัทขนส่ง" })
        await sleep(200)

        const formData = new FormData();
        formData.append("order_data", JSON.stringify(orderselect));

        formData.append('order_id', orderselect.order_id)
        formData.append('courier_code', orderselect.shipping_courier)

        formData.append('seller_shopid', orderselect.seller_shopid)

        formData.append('buyer_recievename', props.permitdata.shop_name)
        formData.append('buyer_telno', props.permitdata.shop_telno)
        formData.append('buyer_address', props.permitdata.shop_address)
        formData.append('buyer_district', props.permitdata.shop_subdistrict)
        formData.append('buyer_state', props.permitdata.shop_district)
        formData.append('buyer_province', props.permitdata.shop_province)
        formData.append('buyer_postcode', props.permitdata.shop_postcode)

        axios.post(props.app_hostname + "/api/order/myshop/buyer/bookingdeliver", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'สำเร็จ',
                        message: response.data.message,
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        props.callback_datachanged('shoporder-updatebooking-buyer', response.data.curent_order)
                    }
                }
                setorderselect({})
            })
            .catch((err) => {
                setorderselect({})
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_deliver_timelinetracking(tracking_code) {
        setmodal_load({ enable: true, message: "กำลังเรียกดูข้อมูล" })
        await sleep(200)

        const formData = new FormData();
        formData.append('tracking_code', tracking_code)

        axios.post(props.app_hostname + "/api/order/myshop/common/timelinetracking", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setshipping_timeline(response.data.timeline)
                    setmodal_delivery_timeline(true)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setorderselect({})
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div className="my-0">
            <div className="row print-disable">
                {
                    myorder_list.length > 0 ?
                        myorder_list.map((order, index0) => (
                            <div className="col-md-12 mb-md-2 mb-lg-0" key={order.order_id}>
                                <div className="card-mycart mb-2">
                                    <div className="card-mycart-header py-1">
                                        <div className="d-block d-md-flex justify-content-between py-0 flex-wrap">
                                            <div className="d-block">
                                                <div className="card-mycart-title"><i className="font-size-14 fas fa-user-tag text-primary"></i> ผู้ขาย : {order.seller_shopname}</div>
                                                <p className="card-mycart-subtitle">จังหวัด {order.seller_shopprovince}</p>
                                            </div>
                                            <div className="d-block">
                                                <div className="text-left text-md-right">{order.order_id}</div>
                                                {orderStatus(order, "buyer")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-mycart-body">
                                        <div className={"" + (order.displayitem ? " d-block" : " d-none")}>
                                            <div className="d-block d-md-flex justify-content-between pt-0 pb-2 flex-wrap border-bottom">
                                                <div className="d-block">
                                                    {
                                                        order.order_status === "PC_RTN_Prepare" ?
                                                            <label className='text-danger'>*กรุณาเลือกรายการสินค้าที่ต้องการคืน กรอกจำนวณเป็นชิ้น</label>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        order.orderitem !== null ?
                                                            order.orderitem.map((orderitems, index1) => (
                                                                <div className={"d-block d-xl-flex py-1 " + (index1 > 0 ? "border-top" : "")} key={orderitems.orderitem_id}>
                                                                    <div className="d-flex" >
                                                                        <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + orderitems.productitem_id + `/` + orderitems.productitem_id + "-1-" + orderitems.img1_key + ".jpg"} alt="product Items" /></div>
                                                                        <div className='d-block product-detail '>
                                                                            <div className="mycart-box-productname py-0">{orderitems.productitem_name}</div>
                                                                            <div className="mycart-box-productinter py-0">{orderitems.productitem_inter}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mycart-box-pricelist">
                                                                        <table>
                                                                            <tbody className="d-block mt-0">
                                                                                {
                                                                                    orderitems.orderitem_pricelist !== null ?
                                                                                        orderitems.orderitem_pricelist.map((pricelist, index1) => (
                                                                                            <tr className="mycart-table-pricelist-row py-0" key={pricelist.pricelist_id} style={{ paddingTop: "0px" }}>
                                                                                                <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                                <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                                <td className="product-quantity">x {pricelist.select} ชุด</td>
                                                                                                <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                                {
                                                                                                    order.order_status === "PC_RTN_Prepare" ?
                                                                                                        <React.Fragment>
                                                                                                            <td>
                                                                                                                <div className="rounded-pill py-1 width-122 w-xl-80 px-3 product-quantity  border border-danger">
                                                                                                                    <div className="js-quantity row align-items-center">
                                                                                                                        <div className="col ">
                                                                                                                            <input className="js-result form-control h-auto border-0 rounded p-0 shadow-none" type="text" value={pricelist.return_select} onChange={(e) => { selectunit(order.order_id, orderitems.orderitem_id, pricelist.pricelist_id, e.target.value) }} />
                                                                                                                        </div>
                                                                                                                        <div className="col-auto pr-1">
                                                                                                                            <div className="js-minus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0" onClick={() => { stepdown(order.order_id, orderitems.orderitem_id, pricelist.pricelist_id) }}>
                                                                                                                                <small className="fas fa-minus btn-icon__inner"></small>
                                                                                                                            </div>
                                                                                                                            <div className="js-plus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0" onClick={() => { stepup(order.order_id, orderitems.orderitem_id, pricelist.pricelist_id) }}>
                                                                                                                                <small className="fas fa-plus btn-icon__inner"></small>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="product-subtotal">
                                                                                                                <span className="">฿ {Number(pricelist.return_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                        null
                                                                                                }

                                                                                                {
                                                                                                    order.order_status === "PC_RTN_Req" || order.order_status === "PC_RTN_AC" || order.order_status === "PC_RTN_Book" || order.order_status === "PC_RTN_BookConfirm" ?
                                                                                                        <React.Fragment>
                                                                                                            <td className="product-quantity text-danger">คืน x {pricelist.return_select} ชิ้น</td>
                                                                                                            <td className="product-subtotal text-danger">฿ {pricelist.return_subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </tr>
                                                                                        ))
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="d-block mt-2 mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                                    <div className="d-flex justify-content-between py-0">
                                                        <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                        <div className="table-summary-cellt">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-0">
                                                        <div className="table-summary-cell">ค่าขนส่ง</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-0 border-bottom">
                                                        <div className="table-summary-cell">ยอดรวม</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-0 mt-2 border-bottom text-danger">
                                                        <div className="table-summary-cell">ยอดคืนสินค้ารวม</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(order.return_shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    {
                                                        order.order_status === "PC_RTN_Book" || order.order_status === "PC_RTN_BookConfirm" ?
                                                            <>
                                                                <div className="d-flex justify-content-between py-0 mt-2">
                                                                    <div className="table-summary-cell">ขนส่งคืนโดย</div>
                                                                    <div className="table-summary-cell aligh-right">{order.return_courier_fullname}</div>
                                                                </div>
                                                                <div className="d-flex justify-content-between py-0">
                                                                    <div className="table-summary-cell">Tracking No.</div>
                                                                    <div className="table-summary-cell aligh-right">{order.return_courier_tracking_code}</div>
                                                                </div>
                                                                <div className="d-flex justify-content-between py-0 border-bottom">
                                                                    <div className="table-summary-cell text-success mouse-pointer" onClick={() => { setorderselect(order); api_deliver_timelinetracking(order.return_tracking_code) }}>ดูสถานะขนส่ง</div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                {
                                                    order.order_status === "PC_RTN_Book" || order.order_status === "PC_RTN_BookConfirm" ?
                                                        <div className="d-block mt-2 mb-5 mb-md-2" >
                                                            <div className="d-block text-success mt-1">ที่อยู่ในการจัดส่งคืนผู้ขาย</div>
                                                            <table>
                                                                <tbody className="d-block mt-0">
                                                                    <tr className="">
                                                                        <td width={"160px"} className="mt-0 mb-0">ผู้รับ</td>
                                                                        <td className="mt-0 mb-0">{order.return_recievename}</td>
                                                                    </tr>
                                                                    <tr className="">
                                                                        <td className="mt-0 mb-0">เบอร์โทรศัพย์</td>
                                                                        <td className="mt-0 mb-0">{order.return_telno}</td>
                                                                    </tr>
                                                                    <tr className="">
                                                                        <td className="mt-0 mb-0">ที่อยู่ในการจัดส่ง</td>
                                                                        <td className="mt-0 mb-0">{order.return_address}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="d-block pt-0 pb-2 flex-wrap border-bottom">
                                                {
                                                    order.order_status === "PC_RTN_Prepare" ?
                                                        <div className="d-block d-xl-flex mt-2">
                                                            <div className="editname pr-5">เหตุผลในการขอคืนสินค้า</div>
                                                            <div className="form-group py-0 mt-0 mb-0">
                                                                <textarea className="form-control-sm font-th" name="return_reason" placeholder='กรอกเหตุผลในการขอคืนสินค้า' rows="3" cols="50" value={order.return_reason} onChange={(e) => { edit_return_reason(order.order_id, e.target.value) }} required />
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    order.order_status !== "PC_RTN_Prepare" ?
                                                        <div className="d-block  mt-2">
                                                            <div className="editname pr-5 text-danger">เหตุผลในการขอคืนสินค้า</div>
                                                            <div className="editname pr-5">{order.return_reason}</div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            {
                                                order.order_status === "PC_RTN_Prepare" ?
                                                    <div className="d-block pt-0 pb-2 flex-wrap border-bottom">
                                                        <div className="d-block d-md-flex mt-2">
                                                            <div className="editname pr-5">รูปประกอบการคืนสินค้า</div>
                                                            <div className='return-attach-input-wrapper'>
                                                                <input type="file" id="return_upload" className='' accept=".jpg, .jpeg, .png" multiple onChange={(e) => InputFileChange(e, order.order_id)} />
                                                                <div className="return-attach-btn text-danger cursor-pointer"><i className='fas fa-paperclip'></i> แนบรูป</div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-wrap gap-2 mt-2">
                                                            {
                                                                order.return_image !== undefined && order.return_image !== null ?
                                                                    order.return_image.map((imgx, index) => (
                                                                        <img src={imgx} key={"preview-return-" + index} className='return-attach-preview' alt={"preview-return-" + index}></img>
                                                                    ))
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            <div className="d-flex justify-content-between gap-2 mt-4 ">
                                                <div className="d-flex flex-wrap gap-2">
                                                    {
                                                        order.order_status === 'PC_RTN_Prepare' || order.order_status === 'PC_RTN_Req' ?
                                                            <button type="button" className="btn-rectan-small btn-success font-th"
                                                                onClick={() => {
                                                                    setorderselect(order);
                                                                    setModal_popup({
                                                                        modaltype: 'modal-confirm',
                                                                        headers: 'กรุณายืนยัน',
                                                                        message: 'ท่านยินดีรับสินค้านี้ ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                        confirmcode: "recieve-item",
                                                                        enable: true
                                                                    });
                                                                }}>เปลี่ยนใจรับสินค้า</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        order.order_status === 'PC_RTN_Prepare' ?
                                                            <button type="button" className="btn-rectan-small btn-danger font-th"
                                                                onClick={() => {
                                                                    setorderselect(order);
                                                                    setModal_popup({
                                                                        modaltype: 'modal-confirm',
                                                                        headers: 'กรุณายืนยัน',
                                                                        message: 'ท่านต้องการคืนสินค้า ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                        confirmcode: "return-item",
                                                                        enable: true
                                                                    });
                                                                }}>ส่งคำขอคืนสินค้า</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        order.order_status === 'PC_RTN_AC' ?
                                                            <button type="button" className="btn-rectan-small btn-primary text-white font-th"
                                                                onClick={() => {
                                                                    setorderselect(order);
                                                                    setModal_popup({
                                                                        modaltype: 'modal-confirm',
                                                                        headers: 'กรุณายืนยัน',
                                                                        message: 'ท่านต้องการเรียกบริษัทขนส่ง ใช่หรือไม่ หากใช่ กรุณากดปุ่ม "ดำเนินการต่อ"',
                                                                        confirmcode: "deliver-booking",
                                                                        enable: true
                                                                    });
                                                                }}>เรียกขนส่งเข้ารับพัสดุ</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        order.order_status === 'PC_RTN_BookConfirm' ?
                                                            <button type="button" className="btn-rectan-small btn-primary text-white font-th" onClick={() => {
                                                                api_deliver_printlabel(order.order_id, order.return_purchase_id);
                                                            }}>พิมพ์ ใบปะหน้า</button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="ml-1 mouse-pointer" onClick={() => {
                                                    Toggleitem(order.order_id);
                                                }} ><i className='fas fa-chevron-up py-0 font-size-12'></i> ย่อรายละเอียด</div>
                                            </div>
                                        </div>
                                        <div className={"" + (order.displayitem === false ? "d-block" : "d-none")}>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-block mt-0">
                                                    <div className="table-summary-cell ml-1">สินค้าจำนวน {order.orderitem !== null ? order.orderitem.length : "-"} รายการ</div>
                                                    <p className="table-summary-cell ml-1 py-0 mb-0">วันที่สั่งซื้อ {dayjs(new Date(order.createtime)).subtract(7, 'hours').format('DD MMM BBBB HH:mm')}</p>
                                                </div>
                                                <div className="d-block mt-0">
                                                    <div className="ml-1 mouse-pointer" onClick={() => {
                                                        Toggleitem(order.order_id);
                                                    }} ><i className='fas fa-chevron-down py-0 font-size-12'></i> แสดงรายละเอียด</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        null
                }
            </div>

            <div className='delivery-timeline-box' style={{ display: modal_delivery_timeline ? "block" : "none" }}>
                <div className='delivery-timeline-box-header'>
                    <div className='d-block'>
                        <div className='timeline-box-header-title'><i className="mdi mdi-truck-fast-outline text-primary font-size-16"></i> สถานะของการขนส่งสินค้า</div>
                        <div className='timeline-box-header-order text-success'>เลขที่คำสั่งซื้อ {orderselect.order_id}</div>
                    </div>
                    <i className="fe-x text-dark mouse-pointer" onClick={() => { setmodal_delivery_timeline(false); setorderselect({}) }}></i>
                </div>
                {
                    shipping_timeline.states !== undefined ?
                        shipping_timeline.states.length > 0 ?
                            <div className='delivery-timeline-box-wrapper'>
                                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                    {
                                        shipping_timeline.states !== undefined ?
                                            shipping_timeline.states.map((states, index) => (
                                                <div class="vertical-timeline-item vertical-timeline-element">
                                                    <div>
                                                        <span class="vertical-timeline-element-icon bounce-in">
                                                            <i class="badge badge-dot badge-dot-xl badge-primary"> </i>
                                                        </span>
                                                        <div class="vertical-timeline-element-content bounce-in">
                                                            <p>{states.datetime}</p>
                                                            <h4 class="timeline-title">{states.description}</h4>
                                                            <p>{states.location}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            :
                            <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ไม่มีข้อมูลการขนส่งที่จะแสดง</div>
                        :
                        <div className='noproductdisplay'><i className='fas fa-info-circle text-primary mr-2'></i> ไม่มีข้อมูลการขนส่งที่จะแสดง</div>
                }
            </div>

            <div className="cover-template  print-disable " style={{ display: modal_cover ? "block" : "none" }}>
                <iframe className='cover-template-paper' id="content-label" title="cover-template"></iframe>
                <div className="d-flex gap-2 mt-0 justify-content-center">
                    <button type="button" className="btn-rectan-small btn-primary text-white font-th mt-1 mb-1" onClick={() => { handle_printcover() }}>พิมพ์ ใบปะหน้า</button>
                    <button type="button" className="btn-rectan-small btn-ligth font-th mt-1 mb-1" onClick={() => { setmodal_cover(false) }}>X ปิดหน้าต่าง</button>
                </div>
            </div>

            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modal_cover || modal_load.enable ?
                    <div className='meya-backdrop'></div>
                    :
                    null
            }
            <BtnGogo></BtnGogo>
        </div>
    )
}

export default MyShop_Buyer_Return