import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ModalPopupMessage from '../components/modal/ModalPopupMessage';
import './CardBuySale.css';

function CardBuySale(props) {
    const navigate = useNavigate();
    const [navigate_url, setNavigate_url] = useState("");
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    async function security_protection(mode) {
        if (mode === "seller" || mode === "buyer") {
            if (props.permitdata.token === "" || props.permitdata.token === "-") {
                setModal_popup({
                    modaltype: 'modal-signup',
                    headers: 'กรุณาสมัครสมาชิก',
                    message: 'การซื้อขายสินค้าบน แพล๊ตฟอร์ม มียานั้น ผู้ใช้งานจะต้องลงทะเบียนก่อนเริ่มทำการซื้อขาย',
                    enable: true
                })
            } else {
                if (mode === "seller") {
                    setNavigate_url("/myshop/seller/jobtodo")
                } else {
                    setNavigate_url("/marketplace?category=รวมสินค้า")
                }
            }
        }
    }

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        setNavigate_url("/")
    }

    useEffect(() => {
        if (navigate_url != "") {
            navigate(navigate_url);
        }
    }, [navigate_url]);


    return (
        <div className="col-sm-6 col-md-6 col-lg-6">
            <div className="card-buysale card-buysale-vertical border">
                <div className="card-buysale-img">
                    <img src={props.imagepath} alt="" />
                </div>
                <div className="card-buysale-content">
                    <div
                        className="text-primary mb-0 pb-1 font-size-18 font-weight-bold text-ls-n1 text-lh-23 font-th">
                        {props.header}
                    </div>
                    <div className="mb-2 pb-1 font-size-15 font-weight-light text-ls-n1 text-lh-23 font-th text-secondary">
                        {props.keyword}
                    </div>
                    <Link className="link text-gray-90 font-weight-bold font-size-15 font-th" onClick={() => { security_protection(props.mode) }}>
                        {props.bottomtext}
                        <span className="link__icon ml-1">
                            <span className="link__icon-inner"><i className="ec ec-arrow-right-categproes"></i></span>
                        </span>
                    </Link>
                </div>
            </div>

            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

export default CardBuySale