import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './MarketPlace.css';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';

import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function MarketPlace(props) {
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const [tableitem, setTableitem] = useState([]);
    const [tablestock, setTablestock] = useState([]);
    const [PreviewImage1, setPreviewImage1] = useState("/images/medicine/blank.png");
    const [PreviewImage2, setPreviewImage2] = useState("/images/medicine/blank.png");
    const [PreviewImage3, setPreviewImage3] = useState("/images/medicine/blank.png");
    const [PreviewIndex, setPreviewIndex] = useState(1);
    const [productview, setProductview] = useState({});
    const [slidefade, setSlidefade] = useState('fadeInRight');
    const [product_compare, setProduct_compare] = useState([]);
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    useEffect(() => {
        const shopitem = searchParams.get('shopitem')
        const productitem = searchParams.get('productitem')
        api_query_productview(shopitem, productitem)
    }, [location]);

    async function api_query_productview(shopitem, productitem) {
        setmodal_load({ enable: true, message: "กำลังโหลดรายละเอียดสินค้า" })
        await sleep(500)

        const formData = new FormData();
        formData.append('shop_id', props.permitdata.shop_id)
        formData.append('shopitem_id', shopitem)
        formData.append('productitem_id', productitem)

        axios.post(props.app_hostname + "/api/market/productview", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setProductview(response.data.items[0])
                    setProduct_compare(response.data.items_com)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    useEffect(() => {
        if (productview.pricetable !== undefined) {
            setPreviewImage1("https://filestore.meya.co.th/productitem/" + productview.productitem_id + `/` + productview.productitem_id + "-1-" + productview.img1_key + ".jpg")
            setPreviewImage2("https://filestore.meya.co.th/productitem/" + productview.productitem_id + `/` + productview.productitem_id + "-2-" + productview.img1_key + ".jpg")
            setPreviewImage3("https://filestore.meya.co.th/productitem/" + productview.productitem_id + `/` + productview.productitem_id + "-3-" + productview.img1_key + ".jpg")


            var stockdata = []
            var stock = productview.on_stock.split("##")
            stock.forEach((rowitem, index) => {
                var stockitems = rowitem.split("**")
                stockdata.push({
                    expire: stockitems[0],
                    stock: Number(stockitems[1])
                })
            })
            setTablestock(stockdata)

            var pricedata = []
            var rows = productview.pricetable.split("##")

            if (rows.length > 0) {
                rows.forEach((rowitem, index) => {
                    var items = rowitem.split("**")
                    if (items.length >= 4) {
                        if (Number(items[1]) > 0) {
                            pricedata.push(
                                {
                                    pricelist_id: productview.shopitem_id + '-' + index.toString(),
                                    unit: items[0],
                                    price: items[1],
                                    pricepromotion: items[2],
                                    expire: items[3],
                                    stock: stock[index],
                                    select: 0
                                }
                            )
                        }
                    }
                })
            }
            setTableitem(pricedata)
        }
    }, [productview]);

    function preview_back() {
        if (PreviewIndex > 1) {
            setSlidefade('fadeInLeft')
            setPreviewIndex(PreviewIndex - 1)
        }
    }

    function preview_next() {
        if (PreviewIndex < 3) {
            setSlidefade('fadeInRight')
            setPreviewIndex(PreviewIndex + 1)
        }
    }

    function preview_fix(index) {
        setSlidefade('fadeInRight')
        setPreviewIndex(index)
    }

    function selectunit(id, exp, unit, value) {
        if (value > 0) {
            var fnd = tablestock.find((element) => {
                return element.expire === exp;
            })
            if (fnd !== undefined) {
                var new_sum = Number(value) * Number(unit)
                if (new_sum <= Number(fnd.stock)) {
                    const nexttableitem = tableitem.map(item => {
                        if (item.pricelist_id !== id) {
                            return item;
                        } else {
                            return {
                                ...item,
                                select: value,
                            };
                        }
                    });
                    setTableitem(nexttableitem);
                } else {
                    const nexttableitem = tableitem.map(item => {
                        return item;
                    });
                    setTableitem(nexttableitem);
                }
            } else {
                const nexttableitem = tableitem.map(item => {
                    if (item.pricelist_id !== id) {
                        return item;
                    } else {
                        return {
                            ...item,
                            select: 0,
                        };
                    }
                });
                setTableitem(nexttableitem);
            }
        } else {
            const nexttableitem = tableitem.map(item => {
                if (item.pricelist_id !== id) {
                    return item;
                } else {
                    return {
                        ...item,
                        select: 0,
                    };
                }
            });
            setTableitem(nexttableitem);
        }
    }

    function stepup(id, exp, unit) {
        var fnd = tablestock.find((element) => {
            return element.expire === exp;
        })
        if (fnd !== undefined) {
            var previous_sum = 0;
            const nextsum = tableitem.map(item => {
                previous_sum += (Number(item.select) * Number(item.unit))
                return item
            });
            var new_sum = previous_sum + Number(unit)
            if (new_sum <= Number(fnd.stock)) {
                const nexttableitem = tableitem.map(item => {
                    if (item.pricelist_id !== id) {
                        return item;
                    } else {
                        return {
                            ...item,
                            select: Number(item.select) + 1,
                        };
                    }
                });
                setTableitem(nexttableitem);
            } else {
                const nexttableitem = tableitem.map(item => {
                    return item;
                });
                setTableitem(nexttableitem);
            }
        } else {
            const nexttableitem = tableitem.map(item => {
                if (item.pricelist_id !== id) {
                    return item;
                } else {
                    return {
                        ...item,
                        select: 0,
                    };
                }
            });
            setTableitem(nexttableitem);
        }
    }

    function stepdown(id, exp) {
        const nexttableitem = tableitem.map(item => {
            if (item.pricelist_id !== id) {
                return item;
            } else {
                if (Number(item.select) > 0) {
                    return {
                        ...item,
                        select: Number(item.select) - 1,
                    };
                } else {
                    return item;
                }
            }
        });
        setTableitem(nexttableitem);
    }

    async function api_addcard() {
        if (props.permitdata.role !== 'guest' && props.permitdata.token !== '') {
            if (props.permitdata.shop_id !== productview.shop_id) {
                var temp = []
                const formData = new FormData();
                tableitem.forEach((item, index) => {
                    if (Number(item.select) > 0) {
                        var x = item.unit + '**' + item.expire + '**' + item.select
                        temp.push(x)
                    }
                })

                if (temp.length > 0) {
                    setmodal_load({ enable: true, message: "กำลังเพิ่มสินค้าลงในตะกร้า" })
                    await sleep(200)

                    var pricestring = temp.join('##')
                    formData.append('buyer_shopid', props.permitdata.shop_id)
                    formData.append('seller_shopid', productview.shop_id)
                    formData.append('shopitem_id', productview.shopitem_id)
                    formData.append('select_item', pricestring)
                    formData.append('select_common', 'True')

                    axios.post(props.app_hostname + "/api/order/mycart/add", formData, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data;',
                            "Authorization": "Bearer " + props.permitdata.token
                        },
                    })
                        .then((response) => {
                            setmodal_load({ enable: false, message: "" })
                            if (response.data.status === 'OK') {
                                props.callback_mycart("add")
                                setModal_popup({
                                    modaltype: 'modal-success',
                                    headers: 'สำเร็จ',
                                    message: response.data.message,
                                    enable: true
                                });
                            }
                            else {
                                setModal_popup({
                                    modaltype: 'modal-warning',
                                    headers: 'ขออภัย',
                                    message: response.data.message,
                                    enable: true
                                });
                            }
                        })
                        .catch((err) => {
                            setmodal_load({ enable: false, message: "" })
                            setModal_popup({
                                modaltype: 'modal-error',
                                headers: 'ผิดพลาด',
                                message: err.message,
                                enable: true
                            });
                        });
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: 'กรุณากรอกจำนวนสินค้า',
                        enable: true
                    });
                }
            } else {
                setModal_popup({
                    modaltype: 'modal-warning',
                    headers: 'ขออภัย',
                    message: 'ระบบไม่อนุญาตให้ซื้อสินค้าภายในร้านตัวเอง',
                    enable: true
                });
            }
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: 'กรุณาเข้าสู่ระบบก่อนทำการสั่งซื้อหรือเพิ่มสินค้าลงในตะกร้า',
                enable: true
            });
        }
    }

    async function api_buynow() {
        if (props.permitdata.role !== 'guest' && props.permitdata.token !== '') {
            if (props.permitdata.shop_id !== productview.shop_id) {
                var temp = []
                var overstock = false
                tableitem.forEach((item, index1) => {
                    if (Number(item.select) > 0) {
                        var x = item.unit + '**' + item.expire + '**' + item.select
                        temp.push(x)
                    }

                    if (Number(item.select) > Number(item.stock)) {
                        overstock = true
                    }
                })

                if (overstock === false) {
                    if (temp.length > 0) {
                        navigate('/marketplace/mypayment?source=product&shopitem=' + productview.shopitem_id + '&item=' + temp.join('--'))
                    } else {
                        setModal_popup({
                            modaltype: 'modal-warning',
                            headers: 'ขออภัย',
                            message: 'กรุณาเลือกสินค้าเพื่อทำการชำระเงิน',
                            enable: true
                        });
                    }
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: 'ท่านกรอกข้อมูลสินค้าเกินกว่าจำนวนสินค้าคงเหลือ',
                        enable: true
                    });
                }
            } else {
                setModal_popup({
                    modaltype: 'modal-warning',
                    headers: 'ขออภัย',
                    message: 'ระบบไม่อนุญาตให้ซื้อสินค้าภายในร้านตัวเอง',
                    enable: true
                });
            }
        } else {
            setModal_popup({
                modaltype: 'modal-warning',
                headers: 'ขออภัย',
                message: 'กรุณาเข้าสู่ระบบก่อนทำการสั่งซื้อหรือเพิ่มสินค้าลงในตะกร้า',
                enable: true
            });
        }
    }

    return (
        <main id="content" role="main">
            <div className="container">
                <div className="mb-xl-14 mb-6 mt-3">
                    <div className="bg-gray-13 bg-md-transparent">
                        <div className="my-md-0">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-3 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble py-0">
                                    <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ซื้อสินค้า</li>
                                    <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">รายละเอียดของสินค้า</li>
                                    <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th text-primary">{productview.productitem_name}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 mb-4 mb-md-0">
                            <div className="meya-slide-container">
                                <div className="meya-slick-carousel mb-2">
                                    <div className={"js-slide u-unfold--css-animation " + slidefade + " " + (PreviewIndex === 1 ? "active" : "")} style={{ animationDuration: "800ms", left: "0px" }}>
                                        <img className="img-fluid" src={PreviewImage1} alt="product1" />
                                        <span className={"stamp product-blank " + (productview.isblank ? "d-block" : "d-none")} >สินค้าหมด</span>
                                    </div>
                                    <div className={"js-slide u-unfold--css-animation " + slidefade + " " + (PreviewIndex === 2 ? "active" : "")} style={{ animationDuration: "800ms", left: "0px" }}>
                                        <img className="img-fluid" src={PreviewImage2} alt="product2" />
                                        <span className={"stamp product-blank " + (productview.isblank ? "d-block" : "d-none")} >สินค้าหมด</span>
                                    </div>
                                    <div className={"js-slide u-unfold--css-animation " + slidefade + " " + (PreviewIndex === 3 ? "active" : "")} style={{ animationDuration: "800ms", left: "0px" }}>
                                        <img className="img-fluid" src={PreviewImage3} alt="product3" />
                                        <span className={"stamp product-blank " + (productview.isblank ? "d-block" : "d-none")} >สินค้าหมด</span>
                                    </div>
                                    <div className="icon-slide icon-slideback" onClick={() => { preview_back() }}><i className="fas fa-arrow-left"></i> </div>
                                    <div className="icon-slide icon-slidenext" onClick={() => { preview_next() }}><i className="fas fa-arrow-right"></i> </div>
                                </div>
                                <div className="meya-slick-carousel-small">
                                    <div className={"js-slide " + (PreviewIndex === 1 ? "active" : "")} style={{ cursor: "pointer" }}>
                                        <img className="img-fluid " src={PreviewImage1} alt="product4" onClick={() => { preview_fix(1) }} />
                                    </div>
                                    <div className={"js-slide " + (PreviewIndex === 2 ? "active" : "")} style={{ cursor: "pointer" }}>
                                        <img className="img-fluid " src={PreviewImage2} alt="product5" onClick={() => { preview_fix(2) }} />
                                    </div>
                                    <div className={"js-slide " + (PreviewIndex === 3 ? "active" : "")} style={{ cursor: "pointer" }}>
                                        <img className="img-fluid " src={PreviewImage3} alt="product6" onClick={() => { preview_fix(3) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 mb-md-6 mb-lg-0">
                            <div className="mb-2">
                                <div className="border-bottom mb-3 pb-md-1 pb-3">
                                    <h4 className="font-size-20 text-lh-1dot2 font-th">{productview.productitem_name}</h4>
                                    <p>{productview.productitem_inter}</p>

                                    <label className='font-th'>จำนวนคงเหลือ แยกตามวันหมดอายุ</label>
                                    <div className="market-avai-table">
                                        {
                                            tablestock.map((item, index) => (
                                                <div className="market-avai-table-row" key={item.expire}>
                                                    <div className="market-avai-table-cell font-th text-gray-111 c1">Exp. {dayjs(new Date(item.expire)).format('DD MMM BBBB')}</div>
                                                    <div className="market-avai-table-cell font-th text-gray-111 c2">{item.stock}</div>
                                                    <div className="market-avai-table-cell font-th text-gray-111 c2">ชิ้น</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <p className=" mt-2 mb-0"><i className="text-primary mdi mdi-store-outline font-size-16"></i> {productview.shop_name} <Link to={"/marketplace/shopseller?shopcode=" + productview.shop_id + `&category=รวมสินค้า`} className="text-success mt-2 mb-0 font-th">ไปที่ร้านค้า</Link></p>
                                    {
                                        productview.on_sold > 0 ?
                                            <p className="font-size-14 text-lh-1dot2 mt-1 mb-0 font-th text-success">ขายแล้ว {productview.on_sold} ชิ้น</p>
                                            :
                                            null
                                    }
                                </div>
                                <div className="mb-2 border-bottom ">
                                    <h6 className="font-th">รายละเอียดของสินค้า</h6>
                                    <p className="font-th">{productview.productitem_desc}</p>
                                    <p className="font-th mb-0"><span className="text-warning">**</span> ในกรณีที่เป็นสินค้าลดราคา ราคาที่แสดงจะเป็นราคาโปรโมชั่น</p>
                                </div>
                                <div className="mb-4">
                                    <div className="market-price-table">
                                        <div className="market-price-table-row border-bottom">
                                            <div className="market-price-table-cell ">
                                                <h6 className="font-th">จำนวนชิ้น</h6>
                                            </div>
                                            <div className="market-price-table-cell">
                                                <h6 className="font-th">ราคา (บาท)</h6>
                                            </div>
                                            <div className="market-price-table-cell">
                                                <h6 className="font-th">วันหมดอายุ</h6>
                                            </div>
                                            <div className="market-price-table-cell">
                                                <h6 className="font-th">จำนวน</h6>
                                            </div>
                                        </div>
                                        {
                                            tableitem.map((item, index) => (
                                                <div className="market-price-table-row" key={item.unit}>
                                                    <div className="market-price-table-cell"><h6 className="font-th">{item.unit} ชิ้น {
                                                        item.pricepromotion > 0 ?
                                                            <span className='badge badge-pill badge-success'>Pro</span>
                                                            :
                                                            null
                                                    }</h6></div>
                                                    <div className="market-price-table-cell"><h6 className="font-th">{
                                                        item.pricepromotion > 0 ?
                                                            item.pricepromotion
                                                            :
                                                            item.price
                                                    }</h6></div>
                                                    <div className="market-price-table-cell"><h6 className="font-th">{dayjs(new Date(item.expire)).format('DD MMM BBBB')}</h6></div>
                                                    <div className="market-price-table-cell qty">
                                                        <div className="border rounded-pill py-1 width-122 w-xl-80 px-3 border-color-1">
                                                            <div className="row align-items-center">
                                                                <div className="col">
                                                                    <input className="form-control h-auto border-0 p-0 shadow-none none-arrow" type="number" min="0" value={item.select} onChange={(e) => { selectunit(item.pricelist_id, item.expire, item.unit, e.target.value) }} required />
                                                                </div>
                                                                <div className="col-auto pr-1">
                                                                    <div className="js-minus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0" onClick={() => { stepdown(item.pricelist_id, item.expire, item.unit) }}>
                                                                        <small className="fas fa-minus btn-icon__inner"></small>
                                                                    </div>
                                                                    <div className="js-plus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0" onClick={() => { stepup(item.pricelist_id, item.expire, item.unit) }}>
                                                                        <small className="fas fa-plus btn-icon__inner"></small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                <div className="d-flex gap-3 flex-wrap mb-3">
                                    <button className="btn btn-sm px-5 btn-primary text-light transition-3d-hover font-th cursor-pointer" onClick={() => api_addcard()}><i className="ec ec-add-to-cart mr-2 font-size-20"></i> เพิ่มลงตะกร้า</button>
                                    <button className="btn btn-sm px-5 btn-primary text-light transition-3d-hover font-th cursor-pointer" onClick={() => api_buynow()}><i className="ec ec-shopping-bag mr-2 font-size-20"></i> ซื้อสินค้า</button>
                                    <button className="btn btn-sm px-5 btn-light transition-3d-hover font-th cursor-pointer" onClick={() => navigate(-1)}><i className="fas fa-arrow-left mr-2 font-size-14"></i> ย้อนกลับ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="mb-2 border-bottom ">
                        <h6 className="font-th"><i className='ec ec-compare text-primary'></i> เปรียบเทียบราคาสินค้า</h6>
                        <div className='row mb-3'>
                            {
                                product_compare.map((item, index) => (
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-4 mb-md-0 " key={item.shop_id}>
                                        <Link to={"/marketplace/productview?shopitem=" + item.shopitem_id + "&productitem=" + item.productitem_id}>
                                            <div className='card-compare font-th'>
                                                <div className='card-compare-shopname'><i className='mdi mdi-storefront-outline text-primary'></i> ร้าน {item.shop_name}</div>
                                                <div className='card-compare-province'>{item.shop_province}</div>
                                                <div className='card-compare-expire'>วันหมดอายุ {dayjs(new Date(item.first_expire)).format('DD MMM BBBB')}</div>
                                                <div className='card-compare-price mt-2'>
                                                    <div>{item.first_priceunit} <span>ชิ้น</span></div>
                                                    <div className='text-success'>{item.postprice} <span>บาท</span></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </main>
    )
}

export default MarketPlace