import React, { useEffect, useState } from 'react';
import {
    Link,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";

import Tabs from "../../components/tabs/Tabs";
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import { useDetectClickOutside } from 'react-detect-click-outside';

import MyShop_Seller_BusinessOverview from './seller/MyShop_Seller_BusinessOverview';
import MyShop_Seller_JobToDo from './seller/MyShop_Seller_JobToDo';
import MyShop_Seller_Payment from './seller/MyShop_Seller_Payment';
import MyShop_Seller_Deliver from './seller/MyShop_Seller_Deliver';
import MyShop_Seller_Transport from './seller/MyShop_Seller_Transport';
import MyShop_Seller_Return from './seller/MyShop_Seller_Return';
import MyShop_Seller_Income from './seller/MyShop_Seller_Income';
import MyShop_Seller_History from './seller/MyShop_Seller_History';
import MyShop_Seller_MyProduct from './seller/MyShop_Seller_MyProduct';
import MyShop_Seller_NewProduct from './seller/MyShop_Seller_NewProduct';

import MyShop_Buyer_Payment from './buyer/MyShop_Buyer_Payment';
import MyShop_Buyer_Deliver from './buyer/MyShop_Buyer_Deliver';
import MyShop_Buyer_Recieve from './buyer/MyShop_Buyer_Recieve';
import MyShop_Buyer_Return from './buyer/MyShop_Buyer_Return';
import MyShop_Buyer_Refund from './buyer/MyShop_Buyer_Refund';
import MyShop_Buyer_History from './buyer/MyShop_Buyer_History';

import MyShop_Tools_Chat from './tools/MyShop_Tools_Chat';

import PropTypes from 'prop-types';
import './MyShopStyle.css';
import './Admin.css';
import './ConsoleProductItems.css';
import './SubPriceTable.css';

function MyShopPage(props) {
    const location = useLocation();

    const [menu_root, setmenu_root] = useState('');
    const [submenu_root, setSubmenu_root] = useState('');

    const [force_tab, setForce_tab] = useState('');
    const [submenu_mode, setSubmenu_mode] = useState('usersconfig');
    const [data_shoporderlist, setData_shoporderlist] = useState([]);
    const [data_shopitemlist, setData_shopitemlist] = useState([]);

    const [data_shoporder_seller_payment, setData_shoporder_seller_payment] = useState([]);
    const [data_shoporder_seller_deliver, setData_shoporder_seller_deliver] = useState([]);
    const [data_shoporder_seller_transport, setData_shoporder_seller_transport] = useState([]);
    const [data_shoporder_seller_return, setData_shoporder_seller_return] = useState([]);
    const [data_shoporder_seller_income, setData_shoporder_seller_income] = useState([]);

    const [data_shoporder_buyer_payment, setData_shoporder_buyer_payment] = useState([]);
    const [data_shoporder_buyer_deliver, setData_shoporder_buyer_deliver] = useState([]);
    const [data_shoporder_buyer_recieve, setData_shoporder_buyer_recieve] = useState([]);
    const [data_shoporder_buyer_return, setData_shoporder_buyer_return] = useState([]);
    const [data_shoporder_buyer_refund, setData_shoporder_buyer_refund] = useState([]);

    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    useEffect(() => {
        if (location.pathname.search("/seller") > -1) {
            setmenu_root('ระบบขายสินข้า')
        }
        if (location.pathname.search("/buyer") > -1) {
            setmenu_root('ระบบซื้อสินข้า')
        }
        if (location.pathname.search("/tools") > -1) {
            setmenu_root('เครื่องมือช่วยเหลือ')
        }

        if (location.pathname.search("/seller/businessoverview") > -1) {
            setSubmenu_mode('seller/businessoverview')
            setSubmenu_root('สรุปงานที่ต้องทำ')
        }
        if (location.pathname.search("/seller/jobtodo") > -1) {
            setSubmenu_mode('seller/jobtodo')
            setSubmenu_root('ภาพรวมงานขาย')
        }
        if (location.pathname.search("/seller/payment") > -1) {
            setSubmenu_mode('seller/payment')
            setSubmenu_root('ที่ลูกค้าต้องชำระ')
        }
        if (location.pathname.search("/seller/deliver") > -1) {
            setSubmenu_mode('seller/deliver')
            setSubmenu_root('ที่ต้องจัดส่ง')
        }
        if (location.pathname.search("/seller/transport") > -1) {
            setSubmenu_mode('seller/transport')
            setSubmenu_root('กำลังจัดส่ง')
        }
        if (location.pathname.search("/seller/return") > -1) {
            setSubmenu_mode('seller/return')
            setSubmenu_root('การคืนสินค้า')
        }
        if (location.pathname.search("/seller/income") > -1) {
            setSubmenu_mode('seller/income')
            setSubmenu_root('รอยอดโอนเข้าร้าน')
        }
        if (location.pathname.search("/seller/history") > -1) {
            setSubmenu_mode('seller/history')
            setSubmenu_root('ประวัติการซื้อย้อนหลัง')
        }
        if (location.pathname.search("/seller/myproduct") > -1) {
            setSubmenu_mode('seller/myproduct')
            setSubmenu_root('สินค้าของฉัน')
        }
        if (location.pathname.search("/seller/newproduct") > -1) {
            setSubmenu_mode('seller/newproduct')
            setSubmenu_root('เพิ่มสินค้าใหม่')
        }

        if (location.pathname.search("buyer/payment") > -1) {
            setSubmenu_mode('buyer/payment')
            setSubmenu_root('ที่ต้องชำระ')
        }
        if (location.pathname.search("buyer/deliver") > -1) {
            setSubmenu_mode('buyer/deliver')
            setSubmenu_root('ที่ต้องจัดส่ง')
        }
        if (location.pathname.search("buyer/recieve") > -1) {
            setSubmenu_mode('buyer/recieve')
            setSubmenu_root('ที่ต้องได้รับ')
        }
        if (location.pathname.search("buyer/return") > -1) {
            setSubmenu_mode('buyer/return')
            setSubmenu_root('คืนสินค้า')
        }
        if (location.pathname.search("buyer/refund") > -1) {
            setSubmenu_mode('buyer/refund')
            setSubmenu_root('รอโอนเงินคืน')
        }
        if (location.pathname.search("buyer/history") > -1) {
            setSubmenu_mode('buyer/history')
            setSubmenu_root('ประวัติการซื้อย้อนหลัง')
        }

        if (location.pathname.search("tools/chat") > -1) {
            setSubmenu_mode('tools/chat')
            setSubmenu_root('พูดคุยกับมียา')
        }

        if (location.pathname.search("seller") > -1) {
            setForce_tab('ระบบขาย')
        } else if (location.pathname.search("buyer") > -1) {
            setForce_tab('ระบบซื้อ')
        } else if (location.pathname.search("tools") > -1) {
            setForce_tab('เครื่องมือ')

        }

        document.getElementById("nav-sale").className = "nav-item hs-has-mega-menu u-header__nav-item"
        document.getElementById("nav-sale-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"
        document.getElementById("nav-buy").className = "nav-item hs-has-mega-menu u-header__nav-item"
        document.getElementById("nav-buy-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"
        document.getElementById("nav-help").className = "nav-item hs-has-mega-menu u-header__nav-item"
        document.getElementById("nav-help-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"
    }, [location]);

    useEffect(() => {
    }, [force_tab, submenu_mode]);

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    useEffect(() => {
        setData_shoporderlist(props.systemdata_shoporderlist)
    }, [props.systemdata_shoporderlist]);

    useEffect(() => {
        setData_shopitemlist(props.systemdata_shopitemlist)
    }, [props.systemdata_shopitemlist]);

    function callback_datachanged(mode, data) {
        if (mode === 'shopitem-add') {
            props.callback_maindatachange('shopitem-add', data)
        } else if (mode === 'shopitem-edit') {
            props.callback_maindatachange('shopitem-edit', data)
        } else if (mode === 'shopitem-delete') {
            props.callback_maindatachange('shopitem-delete', data)
        } else if (mode === 'shopitem-update-stock') {
            props.callback_maindatachange('shopitem-update-stock', data)
        } else if (mode === 'shoporder-updatebooking-seller') {
            props.callback_maindatachange('shoporder-updatebooking-seller', data)
        } else if (mode === 'shoporder-updatebooking-buyer') {
            props.callback_maindatachange('shoporder-updatebooking-buyer', data)
        } else if (mode === 'shoporder-updatefullstatus') {
            props.callback_maindatachange('shoporder-updatefullstatus', data)
        } else if (mode === 'shoporder-updatefullstatus-fullrecord') {
            props.callback_maindatachange('shoporder-updatefullstatus-fullrecord', data)
        } else if (mode === 'shoporder-updatefullstatus-summary') {
            props.callback_maindatachange('shoporder-updatefullstatus-summary', data)
        } else if (mode === 'shoporder-updatepayment') {
            props.callback_maindatachange('shoporder-updatepayment', data)
        } else if (mode === 'shoporder-updatepayment-method') {
            props.callback_maindatachange('shoporder-updatepayment-method', data)
        }
    }

    useEffect(() => {
        setData_shoporder_seller_payment(
            data_shoporderlist.filter(
                item => item.seller_shopid === props.permitdata.shop_id && item.order_status === 'IV_Req' && item.api_paymentstatus !== 'Success' && item.api_paymentstatus !== 'Cancel'
            )
        )
        setData_shoporder_seller_deliver(
            data_shoporderlist.filter(
                item => item.seller_shopid === props.permitdata.shop_id &&
                    ((item.order_status === 'IV_Req' && item.api_paymentstatus === 'Success') || item.order_status === 'PC_Prepare' || item.order_status === 'PC_C_Cancel_Req')
            )
        )
        setData_shoporder_seller_transport(
            data_shoporderlist.filter(item => item.seller_shopid === props.permitdata.shop_id
                && (item.order_status === 'PC_Book' || item.order_status === 'PC_BookConfirm' || item.order_status === 'PC_C_Book_Cancel_Req'))
        )
        setData_shoporder_seller_return(
            data_shoporderlist.filter(
                item =>
                (item.seller_shopid === props.permitdata.shop_id && (item.order_status === 'PC_RTN_Prepare' || item.order_status === 'PC_RTN_Req' || item.order_status === 'PC_RTN_AC'
                    || item.order_status === 'PC_RTN_Book' || item.order_status === 'PC_RTN_BookConfirm'))
            )
        )
        setData_shoporder_seller_income(
            data_shoporderlist.filter(
                item =>
                (item.seller_shopid === props.permitdata.shop_id && ((item.order_status === 'PC_RCV' && item.income_paid === false)
                    || (item.order_status === 'PC_RTN_RCV' && item.return_shop_income > 0 && item.income_paid === false)))
            )
        )

        setData_shoporder_buyer_payment(
            data_shoporderlist.filter(
                item => item.buyer_shopid === props.permitdata.shop_id && item.order_status === 'IV_Req' && item.api_paymentstatus !== 'Success' && item.api_paymentstatus !== 'Cancel'
            )
        )
        setData_shoporder_buyer_deliver(
            data_shoporderlist.filter(
                item => item.buyer_shopid === props.permitdata.shop_id &&
                    ((item.order_status === 'IV_Req' && item.api_paymentstatus === 'Success') || item.order_status === 'PC_Prepare' ||
                        item.order_status === 'PC_Book' || item.order_status === 'PC_C_Cancel_Req' || item.order_status === 'PC_C_Book_Cancel_Req')
            )
        )
        setData_shoporder_buyer_recieve(
            data_shoporderlist.filter(item => item.buyer_shopid === props.permitdata.shop_id && item.order_status === 'PC_BookConfirm')
        )
        setData_shoporder_buyer_return(
            data_shoporderlist.filter(
                item =>
                (item.buyer_shopid === props.permitdata.shop_id &&
                    (item.order_status === 'PC_RTN_Prepare' || item.order_status === 'PC_RTN_Req' || item.order_status === 'PC_RTN_AC' || item.order_status === 'PC_RTN_Book' || item.order_status === 'PC_RTN_BookConfirm'))
            )
        )
        setData_shoporder_buyer_refund(
            data_shoporderlist.filter(
                item =>
                (item.buyer_shopid === props.permitdata.shop_id &&
                    ((item.order_status === 'IV_C_Cancel' || item.order_status === 'IV_V_Cancel' || item.order_status === 'PC_C_Cancel_AC' || item.order_status === 'PC_V_Cancel' || item.order_status === 'PC_RTN_RCV') && item.refund_paid === false))
            )
        )
    }, [data_shoporderlist]);

    function callback_tabclick(label) {

    }

    function nav_shop(nav_id) {
        if (document.getElementById(nav_id).classList.contains("hs-mega-menu-opened")) {
            document.getElementById(nav_id).className = "nav-item hs-has-mega-menu u-header__nav-item"
            document.getElementById(nav_id + "-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"
        } else {
            document.getElementById("nav-sale").className = "nav-item hs-has-mega-menu u-header__nav-item"
            document.getElementById("nav-sale-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"
            document.getElementById("nav-buy").className = "nav-item hs-has-mega-menu u-header__nav-item"
            document.getElementById("nav-buy-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"
            document.getElementById("nav-help").className = "nav-item hs-has-mega-menu u-header__nav-item"
            document.getElementById("nav-help-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"

            document.getElementById(nav_id).className = "nav-item hs-has-mega-menu u-header__nav-item hs-mega-menu-opened"
            document.getElementById(nav_id + "-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left slideInUp d-block"
        }
    }

    const sidebar1ref = useDetectClickOutside({ onTriggered: closeshopbar });
    function closeshopbar() {
        document.getElementById("nav-sale").className = "nav-item hs-has-mega-menu u-header__nav-item"
        document.getElementById("nav-sale-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"
        document.getElementById("nav-buy").className = "nav-item hs-has-mega-menu u-header__nav-item"
        document.getElementById("nav-buy-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"
        document.getElementById("nav-help").className = "nav-item hs-has-mega-menu u-header__nav-item"
        document.getElementById("nav-help-pop").className = "hs-mega-menu w-100 u-header__sub-menu animated hs-position-left fadeOut d-none"
    }

    return (
        <main id="content" role="main">
            <div className="container">
                <div className="flex-container mt-0 py-0">
                    <div className="d-none d-md-block myshop-sidebar px-0 pt-2">
                        <div className="myshop-sidebar-wrapper">
                            <Tabs callback_tabclick={label => { callback_tabclick(label) }} force_tab={force_tab}>
                                <div label="ระบบขาย" >
                                    <ul className="list-unstyled mb-0 view-all admin-menu font-th">
                                        <li><div className="dropdown-subtitle font-weight-bold">ระบบขายสินค้า</div></li>
                                        <li><div className="dropdown-subtitle"><i className="fe-home"></i> หน้าหลัก</div></li>
                                        <li><Link to="/myshop/seller/jobtodo" className={"dropdown-item " + (submenu_mode === "seller/jobtodo" ? "active" : "")}>สรุปงานที่ต้องทำ</Link ></li>
                                        <li><Link to="/myshop/seller/businessoverview" className={"dropdown-item " + (submenu_mode === "seller/businessoverview" ? "active" : "")}>ภาพรวมงานขาย</Link ></li>
                                        <li><div className="dropdown-subtitle"><i className="fe-shopping-bag"></i> คำสั่งซื้อ</div></li>
                                        <li><Link to="/myshop/seller/payment" className={"dropdown-item " + (submenu_mode === "seller/payment" ? "active" : "")}>ที่ลูกค้าต้องชำระ {data_shoporder_seller_payment.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_payment.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/seller/deliver" className={"dropdown-item " + (submenu_mode === "seller/deliver" ? "active" : "")}>ที่ต้องจัดส่ง {data_shoporder_seller_deliver.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_deliver.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/seller/transport" className={"dropdown-item " + (submenu_mode === "seller/transport" ? "active" : "")}>กำลังจัดส่ง {data_shoporder_seller_transport.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_transport.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/seller/return" className={"dropdown-item " + (submenu_mode === "seller/return" ? "active" : "")}>การคืนสินค้า {data_shoporder_seller_return.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_return.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/seller/income" className={"dropdown-item " + (submenu_mode === "seller/income" ? "active" : "")}>รอยอดโอนเข้าร้าน {data_shoporder_seller_income.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_income.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/seller/history" className={"dropdown-item " + (submenu_mode === "seller/history" ? "active" : "")}>ประวัติการขายย้อนหลัง</Link ></li>
                                        <li><div className="dropdown-subtitle"><i className="fe-package"></i> สินค้า</div></li>
                                        <li><Link to="/myshop/seller/myproduct" className={"dropdown-item " + (submenu_mode === "seller/myproduct" ? "active" : "")}>สินค้าของฉัน</Link ></li>
                                        <li><Link to="/myshop/seller/newproduct" className={"dropdown-item " + (submenu_mode === "seller/newproduct" ? "active" : "")}>เพิ่มสินค้าใหม่</Link ></li>
                                    </ul>
                                </div>
                                <div label="ระบบซื้อ" >
                                    <ul className="list-unstyled mb-0 view-all admin-menu font-th ">
                                        <li><div className="dropdown-subtitle font-weight-bold">ระบบซื้อสินค้า</div></li>
                                        <li><Link to="/myshop/buyer/payment" className={"dropdown-item px-2 " + (submenu_mode === "buyer/payment" ? "active" : "")}>ที่ต้องชำระ {data_shoporder_buyer_payment.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_payment.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/buyer/deliver" className={"dropdown-item px-2 " + (submenu_mode === "buyer/deliver" ? "active" : "")}>ที่ต้องจัดส่ง {data_shoporder_buyer_deliver.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_deliver.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/buyer/recieve" className={"dropdown-item px-2 " + (submenu_mode === "buyer/recieve" ? "active" : "")}>ที่ต้องได้รับ {data_shoporder_buyer_recieve.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_recieve.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/buyer/return" className={"dropdown-item px-2 " + (submenu_mode === "buyer/return" ? "active" : "")}>คืนสินค้า {data_shoporder_buyer_return.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_return.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/buyer/refund" className={"dropdown-item px-2 " + (submenu_mode === "buyer/refund" ? "active" : "")}>รอโอนเงินคืน {data_shoporder_buyer_refund.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_refund.length}</span> : null}</Link ></li>
                                        <li><Link to="/myshop/buyer/history" className={"dropdown-item px-2 " + (submenu_mode === "buyer/history" ? "active" : "")}>ประวัติการซื้อย้อนหลัง</Link ></li>
                                    </ul>
                                </div>
                                <div label="เครื่องมือ" >
                                    <ul className="list-unstyled mb-0 view-all admin-menu font-th ">
                                        <li><div className="dropdown-subtitle font-weight-bold">เครื่องมือช่วยเหลือ</div></li>
                                        <li><Link to="/myshop/tools/chat" className={"dropdown-item px-2 " + (submenu_mode === "tools/chat" ? "active" : "")}>พูดคุยกับมียา</Link ></li>
                                    </ul>
                                </div>
                            </Tabs>
                        </div>
                    </div>

                    <div className="col d-block min-height-600 pt-2 mt-0">
                        <div className="bg-gray-13 bg-md-transparent mt-1">
                            <div className="my-md-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-2 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble py-0">
                                        <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">ร้านค้าของฉัน</li>
                                        <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">{menu_root}</li>
                                        <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 font-th">{submenu_root}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        <div className="js-mega-menu navbar navbar-expand u-header__navbar u-header__navbar--no-space font-th mb-4 d-md-none border-bottom">
                            <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse">
                                <ul className="navbar-nav u-header__navbar-nav" ref={sidebar1ref}>
                                    <li className="nav-item hs-has-mega-menu u-header__nav-item" id="nav-sale">
                                        <div className="pl-0 nav-link u-header__nav-link u-header__nav-link-toggle pb-0" onClick={() => { nav_shop("nav-sale") }}>ระบบขาย</div>

                                        <div className="hs-mega-menu w-100 u-header__sub-menu animated hs-position-left" style={{ animationDuration: "300ms" }} id="nav-sale-pop" >
                                            <div className="row mb-0 pb-0">
                                                <div className="col-12 mb-0 pb-0">
                                                    <label className='mt-2 ml-3 mb-0 pb-0'>ระบบขายสินค้า</label>
                                                </div>
                                            </div>
                                            <div className="row u-header__mega-menu-wrapper mt-0 pt-2">
                                                <div className="col-md-3">
                                                    <span className="u-header__sub-menu-title">หน้าหลัก</span>
                                                    <ul className="u-header__sub-menu-nav-group">
                                                        <li><Link to="/myshop/seller/jobtodo" className="nav-link u-header__sub-menu-nav-link">สรุปงานที่ต้องทำ</Link></li>
                                                        <li><Link to="/myshop/seller/businessoverview" className="nav-link u-header__sub-menu-nav-link">ภาพรวมงานขาย</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3">
                                                    <span className="u-header__sub-menu-title">คำสั่งซื้อ</span>
                                                    <ul className="u-header__sub-menu-nav-group mb-3">
                                                        <li><Link to="/myshop/seller/payment" className="nav-link u-header__sub-menu-nav-link">ที่ลูกค้าต้องชำระ {data_shoporder_seller_payment.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_payment.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/seller/deliver" className="nav-link u-header__sub-menu-nav-link">ที่ต้องจัดส่ง {data_shoporder_seller_deliver.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_deliver.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/seller/transport" className="nav-link u-header__sub-menu-nav-link">กำลังจัดส่ง {data_shoporder_seller_transport.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_transport.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/seller/return" className="nav-link u-header__sub-menu-nav-link">การคืนสินค้า  {data_shoporder_seller_return.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_return.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/seller/income" className="nav-link u-header__sub-menu-nav-link">รอยอดโอนเข้าร้าน {data_shoporder_seller_income.length > 0 ? <span className="myshop-indicator">{data_shoporder_seller_income.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/seller/history" className="nav-link u-header__sub-menu-nav-link">ประวัติการขายย้อนหลัง</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3">
                                                    <span className="u-header__sub-menu-title">สินค้า</span>
                                                    <ul className="u-header__sub-menu-nav-group mb-3">
                                                        <li><Link to="/myshop/seller/myproduct" className="nav-link u-header__sub-menu-nav-link">สินค้าของฉัน</Link></li>
                                                        <li><Link to="/myshop/seller/newproduct" className="nav-link u-header__sub-menu-nav-link">เพิ่มสินค้าใหม่</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item u-header__nav-item" id="nav-buy">
                                        <div className="nav-link u-header__nav-link u-header__nav-link-toggle pb-0" onClick={() => { nav_shop("nav-buy") }}>ระบบซื้อ</div>
                                        <div className="hs-mega-menu w-100 u-header__sub-menu" style={{ animationDuration: "300ms" }} id="nav-buy-pop" >
                                            <div className="row mb-0 pb-0">
                                                <div className="col-12 mb-0 pb-0">
                                                    <label className='mt-2 ml-3 mb-0 pb-0'>ระบบซื้อสินค้า</label>
                                                </div>
                                            </div>
                                            <div className="row u-header__mega-menu-wrapper mt-0 pt-2">
                                                <div className="col-md-3">
                                                    <ul className="u-header__sub-menu-nav-group mb-3">
                                                        <li><Link to="/myshop/buyer/payment" className="nav-link u-header__sub-menu-nav-link">ที่ต้องชำระ {data_shoporder_buyer_payment.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_payment.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/buyer/deliver" className="nav-link u-header__sub-menu-nav-link">ที่ต้องจัดส่ง {data_shoporder_buyer_deliver.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_deliver.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/buyer/recieve" className="nav-link u-header__sub-menu-nav-link">ที่ต้องได้รับ {data_shoporder_buyer_recieve.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_recieve.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/buyer/return" className="nav-link u-header__sub-menu-nav-link">คืนสินค้า {data_shoporder_buyer_return.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_return.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/buyer/refund" className="nav-link u-header__sub-menu-nav-link">รอโอนเงินคืน {data_shoporder_buyer_refund.length > 0 ? <span className="myshop-indicator">{data_shoporder_buyer_refund.length}</span> : null}</Link></li>
                                                        <li><Link to="/myshop/buyer/history" className="nav-link u-header__sub-menu-nav-link">ประวัติการซื้อย้อนหลัง</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item u-header__nav-item" id="nav-help">
                                        <div className="nav-link u-header__nav-link u-header__nav-link-toggle pb-0" onClick={() => { nav_shop("nav-help") }}>เครื่องมือ</div>
                                        <div className="hs-mega-menu w-100 u-header__sub-menu" style={{ animationDuration: "300ms" }} id="nav-help-pop" >
                                            <div className="row mb-0 pb-0">
                                                <div className="col-12 mb-0 pb-0">
                                                    <label className='mt-2 ml-3 mb-0 pb-0'>เครื่องมือช่วยเหลือ</label>
                                                </div>
                                            </div>
                                            <div className="row u-header__mega-menu-wrapper mt-0 pt-2">
                                                <div className="col-md-3">
                                                    <ul className="u-header__sub-menu-nav-group">
                                                        <li><Link to="/myshop/tools/chat" className="nav-link u-header__sub-menu-nav-link">พูดคุยกับมียา</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <Routes>
                            <Route path="/seller/jobtodo"
                                element={<MyShop_Seller_JobToDo
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporderlist={data_shoporderlist}
                                    data_shopitemlist={data_shopitemlist}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                >
                                </MyShop_Seller_JobToDo>} />
                            <Route path="/seller/businessoverview"
                                element={<MyShop_Seller_BusinessOverview
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                ></MyShop_Seller_BusinessOverview>} />
                            <Route path="/seller/payment"
                                element={<MyShop_Seller_Payment
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_seller_payment={data_shoporder_seller_payment}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Seller_Payment>} />
                            <Route path="/seller/deliver"
                                element={<MyShop_Seller_Deliver
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_seller_deliver={data_shoporder_seller_deliver}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Seller_Deliver>} />
                            <Route path="/seller/transport"
                                element={<MyShop_Seller_Transport
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_seller_transport={data_shoporder_seller_transport}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Seller_Transport>} />
                            <Route path="/seller/return"
                                element={<MyShop_Seller_Return
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_seller_return={data_shoporder_seller_return}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Seller_Return>} />
                            <Route path="/seller/income"
                                element={<MyShop_Seller_Income
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_seller_return={data_shoporder_seller_income}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Seller_Income>} />
                            <Route path="/seller/history"
                                element={<MyShop_Seller_History
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                ></MyShop_Seller_History>} />
                            <Route path="/seller/myproduct"
                                element={<MyShop_Seller_MyProduct
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    systemdata_productitemlist={props.systemdata_productitemlist}
                                    data_shopitemlist={data_shopitemlist}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Seller_MyProduct>} />
                            <Route path="/seller/newproduct"
                                element={<MyShop_Seller_NewProduct
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    systemdata_productitemlist={props.systemdata_productitemlist}
                                    systemdata_productcategorylist={props.systemdata_productcategorylist}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Seller_NewProduct>} />

                            <Route path="/buyer/payment"
                                element={<MyShop_Buyer_Payment
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_buyer_payment={data_shoporder_buyer_payment}
                                    callback_mycart={(mode, data) => { props.callback_mycart(mode, data) }}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Buyer_Payment>} />
                            <Route path="/buyer/deliver"
                                element={<MyShop_Buyer_Deliver
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_buyer_deliver={data_shoporder_buyer_deliver}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Buyer_Deliver>} />
                            <Route path="/buyer/recieve"
                                element={<MyShop_Buyer_Recieve
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_buyer_recieve={data_shoporder_buyer_recieve}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Buyer_Recieve>} />
                            <Route path="/buyer/return"
                                element={<MyShop_Buyer_Return
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_buyer_return={data_shoporder_buyer_return}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Buyer_Return>} />
                            <Route path="/buyer/refund"
                                element={<MyShop_Buyer_Refund
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                    data_shoporder_buyer_refund={data_shoporder_buyer_refund}
                                    callback_datachanged={(mode, data) => { callback_datachanged(mode, data) }}
                                ></MyShop_Buyer_Refund>} />
                            <Route path="/buyer/history"
                                element={<MyShop_Buyer_History
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}
                                ></MyShop_Buyer_History>} />

                            <Route path="/tools/chat"
                                element={<MyShop_Tools_Chat
                                    app_hostname={props.app_hostname}
                                    permitdata={props.permitdata}

                                    messages_chat_buffer={props.messages_chat_buffer}
                                    isConnectionOpen_notify={props.isConnectionOpen_notify}
                                    notify_ws={props.notify_ws}
                                ></MyShop_Tools_Chat>} />
                        </Routes>
                    </div>
                </div>
            </div>

            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </main>
    )
}

MyShopPage.propTypes = {
    callback_maindatachange: PropTypes.func,
};


export default MyShopPage