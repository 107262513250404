import React, { useState, useEffect } from 'react';

import ModalPopupMessage from '../../../components/modal/ModalPopupMessage';
import PropTypes from 'prop-types';

function MyShop_Seller_JobToDo(props) {
    const [summary_job, setSummary_job] = useState({
        waitaccept: 0,
        mustdeliver: 0,
        ondeliver: 0,
        cancelreq: 0,
        returnreq: 0,
        income: 0,
        itemblank: 0,
        itempromotion: 0
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    useEffect(() => {
        setSummary_job({
            waitaccept: props.data_shoporderlist.filter(item => item.seller_shopid === props.permitdata.shop_id && item.order_status === 'IV_Req' && item.api_paymentstatus === 'Success').length,

            mustdeliver: props.data_shoporderlist.filter(item => item.seller_shopid === props.permitdata.shop_id &&
                ((item.order_status === 'IV_Req' && item.api_paymentstatus === 'Success') || item.order_status === 'PC_Prepare')).length,

            ondeliver: props.data_shoporderlist.filter(item => item.seller_shopid === props.permitdata.shop_id &&
                (((item.order_status === 'PC_Book' || item.shipping_status === 'PC_BookConfirm') && (item.shipping_status === "complete" || item.shipping_status === "return")) ||
                    (item.order_status === 'PC_Book' || item.shipping_purchase_id !== '-'))).length,

            cancelreq: props.data_shoporderlist.filter(item => item.seller_shopid === props.permitdata.shop_id && item.order_status === 'PC_C_Cancel_Req').length,

            returnreq: props.data_shoporderlist.filter(item => item.seller_shopid === props.permitdata.shop_id &&
                (item.order_status === 'PC_RTN_Prepare' || item.order_status === 'PC_RTN_Req' || item.order_status === 'PC_RTN_AC' || item.order_status === 'PC_RTN_Book' || item.order_status === 'PC_RTN_BookConfirm')).length,

            income: props.data_shoporderlist.filter(item => (item.seller_shopid === props.permitdata.shop_id &&
                ((item.order_status === 'PC_RCV' && item.income_paid === false)
                    || (item.order_status === 'PC_RTN_RCV' && item.return_shop_income > 0 && item.income_paid === false)))).length,

            itemblank: props.data_shopitemlist.filter(item => item.shop_id === props.permitdata.shop_id && item.status === "OnSell" && item.isblank === true).length,

            itempromotion: props.data_shopitemlist.filter(item => item.shop_id === props.permitdata.shop_id && item.status === "OnSell" && Number(item.promotion_pricenew) > 0).length,
        });

    }, [props.data_shoporderlist, props.data_shopitemlist]);

    return (
        <div className="my-0">
            <div className="row">
                <div className="col-md-12 mb-md-2 mb-lg-0">
                    <div className="card-mycart mb-2">
                        <div className="card-mycart-header">
                            <div className="card-mycart-title"><i className='far fa-list-alt'></i> สรุปงานขายที่ต้องทำ</div>
                        </div>
                        <div className="card-mycart-body">
                            <div className="row">
                                <div className="col-6 col-sm-3 col-md-3">
                                    <div className="d-block text-center border-right">
                                        <h5 className={"font-size-32 mb-2 " + (summary_job.waitaccept > 0 && "text-success")}>{summary_job.waitaccept}</h5>
                                        <p className="mt-0">คำสั่งซื้อรอตอบรับ</p>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-3">
                                    <div className="d-block text-center border-right">
                                        <h5 className={"font-size-32 mb-2 " + (summary_job.mustdeliver > 0 && "text-warning")}>{summary_job.mustdeliver}</h5>
                                        <p className="mt-0">ที่ต้องจัดส่ง</p>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-3">
                                    <div className="d-block text-center border-right">
                                        <h5 className={"font-size-32 mb-2 " + (summary_job.ondeliver > 0 && "text-success")}>{summary_job.ondeliver}</h5>
                                        <p className="mt-0">กำลังจัดส่ง</p>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-3">
                                    <div className="d-block text-center">
                                        <h5 className={"font-size-32 mb-2 " + (summary_job.cancelreq > 0 && "text-danger")}>{summary_job.cancelreq}</h5>
                                        <p className="mt-0">คำขอยกเลิก</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-3 col-md-3">
                                    <div className="d-block text-center border-right">
                                        <h5 className={"font-size-32 mb-2 " + (summary_job.returnreq > 0 && "text-danger")}>{summary_job.returnreq}</h5>
                                        <p className="mt-0">การคืนสินค้า</p>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-3">
                                    <div className="d-block text-center border-right">
                                        <h5 className={"font-size-32 mb-2 " + (summary_job.income > 0 && "text-warning")}>{summary_job.income}</h5>
                                        <p className="mt-0">รอยอดโอนเข้าร้าน</p>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-3">
                                    <div className="d-block text-center border-right">
                                        <h5 className={"font-size-32 mb-2 " + (summary_job.itemblank > 0 && "text-danger")}>{summary_job.itemblank}</h5>
                                        <p className="mt-0">สินค้าหมด</p>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-3">
                                    <div className="d-block text-center">
                                        <h5 className={" font-size-32 mb-2 " + (summary_job.itempromotion > 0 && "text-primary")}>{summary_job.itempromotion}</h5>
                                        <p className="mt-0">สินค้าที่ลดราคา</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

MyShop_Seller_JobToDo.propTypes = {
    callback_datachanged: PropTypes.func,
};

export default MyShop_Seller_JobToDo