import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ModalPopupMessage from '../modal/ModalPopupMessage';
import './Footer.css';

function Footer(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [navlink, setnavlink] = useState('')

    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    useEffect(() => {
        if (navlink != '') {
            var tmp = navlink
            setnavlink('')
            navigate(tmp)
        }

    }, [navlink]);

    async function security_protection(mode) {
        if (mode === "seller" || mode === "buyer") {
            if (props.permitdata.token === "") {
                if (location.pathname === "" || location.pathname === "/") {
                    setModal_popup({
                        modaltype: 'modal-info',
                        headers: 'กรุณาสมัครสมาชิก',
                        message: 'การซื้อขายสินค้าบน แพล๊ตฟอร์ม มียานั้น ผู้ใช้งานจะต้องลงทะเบียนก่อนเริ่มทำการซื้อขาย',
                        enable: true
                    })
                } else {
                    setModal_popup({
                        modaltype: 'modal-signup',
                        headers: 'กรุณาสมัครสมาชิก',
                        message: 'การซื้อขายสินค้าบน แพล๊ตฟอร์ม มียานั้น ผู้ใช้งานจะต้องลงทะเบียนก่อนเริ่มทำการซื้อขาย',
                        enable: true
                    })
                }
            } else {
                if (mode === "seller") {
                    setnavlink("/myshop/seller/jobtodo")
                } else {
                    setnavlink("/marketplace?category=รวมสินค้า")
                }
            }
        }
    }

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        navigate("/")
    }

    return (
        <div className="footer mt-0">
            <div className="pt-2 pb-2 bg-gray-13 mt-0">
                <div className="container">
                    <div className="d-block d-md-flex justify-content-between">
                        <div className="d-block">
                            <div className="mb-2 footer-word">
                                <h5 className="mb-1 text-primary font-th">มียา <strong
                                    className="">MEYA</strong>
                                </h5>
                                <h5 className="font-size-14 text-gray-90 font-th">
                                    พื้นที่แลกเปลี่ยนสินค้าสำหรับผู้ประกอบการธุรกิจ
                                    ด้านสุขภาพ ที่ได้รับใบอนุญาต เช่น ร้านขายยา คลินิก
                                    สถานพยาบาล โรงพยาบาล คลินิกสัตว์แพทย์
                                    คลินิกทันตกรรม</h5>
                            </div>
                        </div>

                        <div className="d-block">
                            <div className="footer-right-box justify-content-between">
                                <div className="footer-navigate">
                                    <h6 className="mb-1 font-weight-bold font-th">เกี่ยวกับเรา</h6>
                                    <ul
                                        className="list-group list-group-flush list-group-borderless mb-0 list-group-transparent">
                                        <li><Link className="list-group-item list-group-item-action font-th"
                                            to="/home">หน้าแรก</Link></li>
                                        <li><Link className="list-group-item list-group-item-action font-th"
                                            onClick={() => { security_protection('buyer') }}>ซื้อสินค้า</Link></li>
                                        {
                                            props.islogin === false && props.permitdata !== null ?
                                                props.permitdata.role != 'guest' && props.permitdata.token != '' ?
                                                    <li><Link className="list-group-item list-group-item-action font-th"
                                                        onClick={() => { security_protection('seller') }}>ขายสินค้ากับเรา</Link>
                                                    </li>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                        <li><Link className="list-group-item list-group-item-action font-th"
                                            to="/about">เกี่ยวกับเรา</Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="footer-contact">
                                    <h6 className="mb-1 font-weight-bold font-th">ติดต่อเรา</h6>
                                    <ul
                                        className="list-group list-group-flush list-group-borderless mb-0 list-group-transparent">
                                        <li><Link className="list-group-item list-group-item-action font-th"
                                            to="#"><i className="text-dark ec ec-map-pointer mr-1"></i> บริษัท มียามียา จำกัด</Link></li>
                                        <li><Link className="list-group-item list-group-item-action font-th"
                                            to="#"><i className="text-dark ec ec-mail mr-1"></i> contact@mediception.net</Link></li>
                                        <li><Link className="list-group-item list-group-item-action font-th"
                                            to="#"><i className="text-dark ec ec-phone mr-1"></i> 097-996-6908</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-gray-14 py-2">
                <div className="container">
                    <div className="flex-center-between d-block d-md-flex">
                        <div className="mb-md-0">© 2023 Copyright : <Link to="/home"
                            className="font-weight-bold text-gray-90">MEYA</Link></div>
                    </div>
                </div>
            </div>

            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

export default Footer