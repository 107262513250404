import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import ModalPopupMessage from '../modal/ModalPopupMessage';
import { useDetectClickOutside } from 'react-detect-click-outside';
import PropTypes from 'prop-types';
import Sidebar from '../sidebar/Sidebar';
import './Navbar.css'

function Navbar(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [sidebar_toggle, setSidebar_toggle] = useState(false)
    const [topbar_selected, setTopbar_selected] = useState(0)

    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        navigate("/")
    }


    useEffect(() => {
        if (location.pathname.search("/home") > -1) {
            setTopbar_selected(1)
        } else if (location.pathname.search("/marketplace") > -1) {
            setTopbar_selected(2)
        } else if (location.pathname.search("/myshop") > -1) {
            setTopbar_selected(3)
        } else if (location.pathname.search("/about") > -1) {
            setTopbar_selected(4)
        } else {
            setTopbar_selected(0)
        }
    }, [location]);

    function welcometext(sname) {
        if (sname !== null) {
            if (props.permitdata.shop_name !== '-' && props.permitdata.shop_name !== undefined) {
                return <Link to="/home"
                    className="font-size-13 u-header-topbar__nav-link font-th">ยินดีต้อนรับ {sname.shop_name} เข้าสู่
                    <strong className="text-primary"> มียา.com</strong></Link>
            }
        }
        return <Link to="/home"
            className="font-size-13 u-header-topbar__nav-link font-th">ยินดีต้อนรับเข้าสู่<strong className="text-primary"> มียา.com</strong></Link>
    }

    function callback_security(mode) {
        if (mode === "same") {
            setModal_popup({
                modaltype: 'modal-info',
                headers: 'กรุณาสมัครสมาชิก',
                message: 'การซื้อขายสินค้าบน แพล๊ตฟอร์ม มียานั้น ผู้ใช้งานจะต้องลงทะเบียนก่อนเริ่มทำการซื้อขาย',
                enable: true
            })
        } else {
            setModal_popup({
                modaltype: 'modal-signup',
                headers: 'กรุณาสมัครสมาชิก',
                message: 'การซื้อขายสินค้าบน แพล๊ตฟอร์ม มียานั้น ผู้ใช้งานจะต้องลงทะเบียนก่อนเริ่มทำการซื้อขาย',
                enable: true
            })
        }
    }
    return (
        <>
            <header id="header" className="u-header u-header-left-aligned-nav">
                <div className="u-header__section ">
                    <div className="u-header-topbar py-2 d-block ">
                        <div className="container">
                            <div className="d-flex align-items-center">
                                <div className="topbar-left d-none d-sm-flex">
                                    {welcometext(props.permitdata)}
                                </div>
                                <div className="topbar-left d-flex d-sm-none">
                                    <strong className="text-primary font-th"> มียา.com</strong>
                                </div>
                                {
                                    props.islogin === false && props.permitdata !== null ?
                                        <div className="topbar-right ml-auto">
                                            <ul className="list-inline mb-0">
                                                {
                                                    props.permitdata.role !== 'guest' && props.permitdata.token !== '' && props.permitdata.token !== '-' ?
                                                        <li
                                                            className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                                                            <Link className="u-header-topbar__nav-link font-th" to="/">
                                                                <i className="ec ec-user mr-1 text-warning"></i> ออกจากระบบ
                                                            </Link>
                                                        </li>
                                                        :
                                                        <li
                                                            className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                                                            <Link className="u-header-topbar__nav-link font-th" to="/">
                                                                <i className="ec ec-user mr-1 text-warning"></i> ลงทะเบียน / เข้าสู่ระบบ
                                                            </Link>
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    props.islogin === true ?
                                        <div className="topbar-right ml-auto d-flex d-md-none">
                                            <div className="font-th mr-4" onClick={props.callback_popup_register}>ลงทะเบียน
                                            </div>
                                            <div className="font-th" onClick={props.callback_popup_signin}>เข้าสู่ระบบ
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div >

                    <div className="py-0 py-xl-0">
                        <div className="container my-0dot5 my-xl-0">
                            <div className="row align-items-center border-bottom border-xl-bottom-0">
                                <div className="col-auto">
                                    <nav
                                        className="navbar navbar-expand u-header__navbar py-0 justify-content-xl-between max-width-270 min-width-270c py-xl-0 px-0">
                                        <Link className="order-1 order-xl-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                                            to="/home" >
                                            <img className="d-none d-xl-block" src="/images/Meya-logo1.png" alt="meya-logo"
                                                style={{ height: "60px", width: "auto" }} />
                                            <h6 className="d-none d-sm-block d-xl-none text-dark my-0 font-th font-weight-bold">มียา.com</h6>
                                        </Link>
                                        {
                                            props.permitdata !== null ?
                                                props.permitdata.role !== 'guest' && props.permitdata.token !== '' && props.permitdata.token !== '-' ?
                                                    <button id="sidebarHeaderInvokerMenu" type="button" onClick={() => { setSidebar_toggle(true) }}
                                                        className="navbar-toggler d-block btn u-hamburger mr-3 mr-xl-0">
                                                        <span id="hamburgerTriggerMenu" className="u-hamburger__box">
                                                            <span className="u-hamburger__inner"></span>
                                                        </span>
                                                    </button>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </nav>

                                </div>
                                <NavbarMenu
                                    islogin={props.islogin}
                                    permitdata={props.permitdata}
                                    topbar_selected={topbar_selected}
                                    callback_security={message => {
                                        callback_security(message)
                                    }}
                                >
                                </NavbarMenu>

                                <NavbarSupport
                                    islogin={props.islogin}
                                    permitdata={props.permitdata}
                                    callback_popup_register={props.callback_popup_register}
                                    callback_popup_signin={props.callback_popup_signin}
                                ></NavbarSupport>
                                {
                                    props.islogin === false ?
                                        props.permitdata !== null ?
                                            props.permitdata.role !== 'guest' && props.permitdata.token !== '' && props.permitdata.token !== '-' ?
                                                <NavbarSearchTablet
                                                    permitdata={props.permitdata}
                                                    islogin={props.islogin}
                                                    mycartlen={props.mycartlen}
                                                    messages_chat_unread={props.messages_chat_unread}
                                                    myorderlen={props.myorderlen}
                                                    productitemlist={props.systemdata_productitemlist}
                                                >
                                                </NavbarSearchTablet>
                                                :
                                                null
                                            :
                                            null
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                    {
                        props.islogin === false ?
                            props.permitdata !== null ?
                                props.permitdata.role !== 'guest' && props.permitdata.token !== '' && props.permitdata.token !== '-' ?
                                    <NavbarSearch
                                        permitdata={props.permitdata}
                                        islogin={props.islogin}
                                        mycartlen={props.mycartlen}
                                        messages_chat_unread={props.messages_chat_unread}
                                        myorderlen={props.myorderlen}
                                        productitemlist={props.systemdata_productitemlist}
                                    ></NavbarSearch>
                                    :
                                    null
                                :
                                null
                            :
                            null
                    }
                </div>
            </header>
            <Sidebar
                sidebar_toggle={sidebar_toggle}
                islogin={props.islogin}
                permitdata={props.permitdata}
                systemdata_productcategorylist={props.systemdata_productcategorylist}
                callback_closebar={message => { setSidebar_toggle(false) }}
            ></Sidebar>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </>
    )
}


function NavbarMenu(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [navlink, setnavlink] = useState('')

    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    useEffect(() => {
        if (navlink !== '') {
            var tmp = navlink
            setnavlink('')
            navigate(tmp)
        }
    }, [navlink]);

    async function security_protection(mode) {
        if (mode === "seller" || mode === "buyer") {
            if (props.permitdata !== null) {
                if (props.permitdata.token === "" || props.permitdata.token === "-") {
                    if (location.pathname === "" || location.pathname === "/") {
                        props.callback_security("same")
                    } else {
                        props.callback_security("redirect")
                    }
                } else {
                    if (mode === "seller") {
                        setnavlink("/myshop/seller/jobtodo")
                    } else {
                        setnavlink("/marketplace?category=รวมสินค้า")
                    }
                }
            } else {
                if (location.pathname === "" || location.pathname === "/") {
                    props.callback_security("same")
                } else {
                    props.callback_security("redirect")
                }
            }
        }
    }

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        navigate("/")
    }

    return (
        <div className="col-auto">
            <nav className="navbar navbar-expand u-header__navbar u-header__navbar--no-space">
                <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse">
                    <ul className="navbar-nav u-header__navbar-nav ">
                        <li className={"nav-item u-header__nav-item " + (props.topbar_selected === 1 ? "active" : "")}>
                            <Link className="nav-link u-header__nav-link font-th" to="/home">หน้าแรก</Link>
                        </li>


                        <li className={"nav-item u-header__nav-item " + (props.topbar_selected === 2 ? "active" : "")}>
                            <Link className="nav-link u-header__nav-link font-th"
                                onClick={() => { security_protection('buyer') }}>ซื้อสินค้า</Link>
                        </li>
                        {
                            props.islogin === false && props.permitdata !== null ?
                                props.permitdata.role !== 'guest' && props.permitdata.token !== '' ?
                                    <li className={"nav-item u-header__nav-item " + (props.topbar_selected === 3 ? "active" : "")}>
                                        <Link className="nav-link u-header__nav-link font-th"
                                            to="/myshop/seller/jobtodo">ร้านค้าของฉัน</Link>
                                    </li>
                                    :
                                    null
                                :
                                null
                        }
                        <li className={"nav-item u-header__nav-item " + (props.topbar_selected === 4 ? "active" : "")}>
                            <Link className="nav-link u-header__nav-link font-th"
                                to="/about">เกี่ยวกับเรา</Link>
                        </li>
                    </ul>
                </div>
            </nav>

            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

function NavbarSupport(props) {
    return (
        <div className="col d-block">
            {
                props.islogin === false ?
                    <div className="d-none d-xl-block col-md-auto float-right">
                        <div className="d-flex">
                            <i className="ec ec-support font-size-50 text-primary"></i>
                            <div className="ml-2">
                                <div className="phone">
                                    <strong className="text-primary">Support</strong> <Link to="tel:800856800604"
                                        className="text-gray-90">097-996-6908</Link>
                                </div>
                                <div className="email">
                                    E-mail: <Link to="mailto:info@electro.com?subject=Help Need"
                                        className="text-gray-90">contact@mediception.net</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-auto float-right mx-0">
                        <div className="d-none d-md-flex">
                            <button className="btn btn-primary btn-sm text-white font-th mr-2" type="button" onClick={props.callback_popup_register}>ลงทะเบียน</button>
                            <button className="btn btn-primary btn-sm text-white font-th" type="button" onClick={props.callback_popup_signin}>เข้าสู่ระบบ</button>
                        </div>
                    </div>
            }
        </div>
    )
}

function NavbarSearch(props) {
    const navigate = useNavigate();
    const [navigate_url, setNavigate_url] = useState("");
    const [filtertext, setFiltertext] = useState("");
    const [searchoption, setSearchoption] = useState(false);
    const [pitems, setpitems] = useState([])

    const popupref = useDetectClickOutside({ onTriggered: closesearchoption });
    function closesearchoption() {
        setSearchoption(false);
    }
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
    }

    useEffect(() => {
        if (filtertext !== "") {
            setSearchoption(true);
        }
    }, [filtertext]);

    function begin_search(mode, type, id, detail) {
        if (filtertext !== "") {
            if (mode === "shop") {
                getShopID(filtertext)
            } else if (mode === "product") {
                if (type === "contain") {
                    setNavigate_url("/marketplace?category=ค้นหาสินค้า&searchtype=contain&productid=none&detail=" + filtertext)
                } else if (type === "productid") {
                    setNavigate_url("/marketplace?category=ค้นหาสินค้า&searchtype=productid&productid=" + id + "&detail=" + filtertext)
                }
            }
            setSearchoption(false);
        }
    }

    useEffect(() => {
        if (props.productitemlist !== undefined) {
            var filtered_itemsx = []
            if (filtertext !== "") {
                filtered_itemsx = props.productitemlist.filter((item, index) => (item.productitem_name.toLowerCase().includes(filtertext.toLowerCase()) || item.productitem_inter.toLowerCase().includes(filtertext.toLowerCase())));
                setpitems(filtered_itemsx)
            } else {
                if (props.productitemlist.length > 0) {
                    setpitems(props.productitemlist)
                }
            }
        }
    }, [filtertext]);

    useEffect(() => {
        if (navigate_url !== "") {
            return navigate(navigate_url);
        }
    }, [navigate_url]);

    function getShopID(text) {
        var formdata = new FormData()
        formdata.append('shop_text', text)

        axios.post("https://api.meya.co.th/api/market/help/getshopidbyname", formdata, {
            headers: {
                Accept: 'application/json',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    if (response.data.shop_info.shop_id !== "") {
                        setNavigate_url("/marketplace/shopseller?shopcode=" + response.data.shop_info.shop_id + "&category=รวมสินค้า")
                    }
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    function searchenter(e, mode, type, id, detail) {
        if (e.key === 'Enter') {
            begin_search(mode, type, id, detail)
        }
    }

    function generate_token(length) {
        var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        var b = [];
        for (var i = 0; i < length; i++) {
            var j = (Math.random() * (a.length - 1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("");
    }

    return (
        <div className="d-none d-xl-block bg-primary">
            <div className="container">
                <div className="row align-items-stretch min-height-50">
                    <div className="col-md-auto d-none d-xl-flex align-items-end">
                        <div className="max-width-200 min-width-200">
                        </div>
                    </div>

                    <div className="col align-self-center" ref={popupref}>
                        <div className="js-focus-state">
                            <label className="sr-only" htmlFor="searchProduct">Search</label>
                            <div className="input-group">
                                <input type="email"
                                    className="form-control py-2 pl-5 font-size-15 border-0 height-40 rounded-left-pill font-th"
                                    name="email" id="searchProduct"
                                    placeholder="ค้นหา : ชื่อสินค้าภาษาไทย/อังกฤษ ชื่อร้านค้า"
                                    aria-label="Search for Products" aria-describedby="searchProduct1" value={filtertext} onKeyDown={(e) => { searchenter(e, "product", "contain") }} onChange={(e) => { setFiltertext(e.target.value) }} onClick={() => { setSearchoption(true) }} required />
                                <div className="input-group-append">
                                    <div className="btn btn-white height-40 py-2 px-3 rounded-right-pill"
                                        type="button" id="searchProduct1" onClick={() => { begin_search("product", "contain") }} >
                                        <span className="ec ec-search font-size-24"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            searchoption === true && props.islogin === false ?
                                <div className="search-bar-auto">
                                    <div className="search-bar-auto-wrapper" >
                                        {
                                            filtertext !== "" ?
                                                <>
                                                    <label className='font-th'>ค้นหาร้านค้า</label>
                                                    <div className="search-bar-auto-item" onClick={() => { begin_search("shop") }}>
                                                        <i className='fas fa-store-alt text-primary mr-2'></i> คุณกำลังค้นหา ร้าน <span>{filtertext}</span> ใช่หรือไม่
                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                        <label className='font-th'>ค้นหาสินค้า</label>
                                        {
                                            filtertext !== "" ?
                                                pitems.map((item, index) => {
                                                    return (
                                                        <div className="search-bar-auto-item" onClick={() => { begin_search("product", "productid", item.productitem_id, item.productitem_name) }} key={index}>
                                                            <i className="fas fa-capsules text-grey-15 mr-2"></i>{item.productitem_name}
                                                        </div>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="col-md-auto align-self-center">
                        {
                            props.permitdata !== null ?
                                props.permitdata.role !== 'guest' && props.permitdata.token !== '' && props.permitdata.token !== '-' ?
                                    <div className="d-flex">
                                        <ul className="d-flex list-unstyled mb-0">
                                            <li className="col pr-1">
                                                <Link to={"/marketplace/mycart?cart=" + generate_token(20)} className="text-white position-relative d-flex "
                                                    data-toggle="tooltip" data-placement="top" title="ตะกร้าสินค้าของฉัน">
                                                    <i className="font-size-22 ec ec-add-to-cart"></i>
                                                    {props.mycartlen > 0 ?
                                                        <span
                                                            className="width-22 height-22 bg-warning position-absolute flex-content-center text-dark rounded-circle left-12 top-8 font-weight-bold font-size-12">{props.mycartlen}</span>
                                                        :
                                                        null
                                                    }
                                                </Link>
                                            </li>
                                            <li className="col pr-1">
                                                <Link to="/myshop/seller/jobtodo" className="text-white position-relative d-flex"
                                                    data-toggle="tooltip" data-placement="top" title="ร้านค้าของฉัน">
                                                    <i className="font-size-22 ec ec-user"></i>
                                                    {props.myorderlen > 0 ?
                                                        <span
                                                            className="width-22 height-22 bg-warning position-absolute flex-content-center text-dark rounded-circle left-12 top-8 font-weight-bold font-size-12">{props.myorderlen}</span>
                                                        :
                                                        null
                                                    }
                                                </Link></li>
                                            <li className="col pr-0">
                                                <Link to="/myshop/tools/chat" className="text-white position-relative d-flex"
                                                    data-toggle="tooltip" data-placement="top" title="พูดคุยกับมียา">
                                                    <i className="font-size-22 ec ec-comment"></i>
                                                    {props.messages_chat_unread > 0 ?
                                                        <span
                                                            className="width-22 height-22 bg-warning position-absolute flex-content-center text-dark rounded-circle left-12 top-8 font-weight-bold font-size-12">{props.messages_chat_unread}</span>
                                                        :
                                                        null
                                                    }
                                                </Link></li>
                                        </ul>
                                    </div>
                                    :
                                    null
                                :
                                null
                        }
                    </div>
                </div>
            </div>

            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

function NavbarSearchTablet(props) {
    const searchbarRef = useRef(null);
    const [searchbar_toggle, setSearchbar_toggle] = useState(false)
    const [searchbar_firstload, setSearchbar_firstload] = useState(true)

    const navigate = useNavigate();
    const [navigate_url, setNavigate_url] = useState("");
    const [filtertext, setFiltertext] = useState("");
    const [searchoption, setSearchoption] = useState(false);
    const [pitems, setpitems] = useState([])

    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
    }

    useEffect(() => {
        if (searchbar_toggle) {
            const searchbar = searchbarRef.current
            searchbar.className = "dropdown-menu dropdown-unfold dropdown-menu-right left-0 mx-2 u-unfold--css-animation slideInUp"
            setSearchbar_firstload(false)
        } else {
            if (searchbar_firstload === false) {
                const searchbar = searchbarRef.current
                searchbar.className = "dropdown-menu dropdown-unfold dropdown-menu-right left-0 mx-2 u-unfold--css-animation fadeOut"
            } else {
                const searchbar = searchbarRef.current
                searchbar.className = "dropdown-menu dropdown-unfold dropdown-menu-right left-0 mx-2 u-unfold--css-animation u-unfold--hidden"
            }
        }
    }, [searchbar_toggle]);

    function searchbarClose() {
        if (searchbar_toggle === false) {
            const searchbar = searchbarRef.current
            searchbar.className = "dropdown-menu dropdown-unfold dropdown-menu-right left-0 mx-2 u-unfold--css-animation u-unfold--hidden fadeOut"
        }
    }

    useEffect(() => {
        if (filtertext !== "") {
            setSearchoption(true);
        }
    }, [filtertext]);

    function begin_search(mode, type, id, detail) {
        if (filtertext !== "") {
            if (mode === "shop") {
                getShopID(filtertext)
            } else if (mode === "product") {
                if (type === "contain") {
                    setNavigate_url("/marketplace?category=ค้นหาสินค้า&searchtype=contain&productid=none&detail=" + filtertext)
                } else if (type === "productid") {
                    setNavigate_url("/marketplace?category=ค้นหาสินค้า&searchtype=productid&productid=" + id + "&detail=" + filtertext)
                }
            }
            setSearchoption(false);
        }
    }

    useEffect(() => {
        if (props.productitemlist !== undefined) {
            var filtered_itemsx = []
            if (filtertext !== "") {
                filtered_itemsx = props.productitemlist.filter((item, index) => (item.productitem_name.toLowerCase().includes(filtertext.toLowerCase()) || item.productitem_inter.toLowerCase().includes(filtertext.toLowerCase())));
                setpitems(filtered_itemsx)
            } else {
                if (props.productitemlist.length > 0) {
                    setpitems(props.productitemlist)
                }
            }
        }
    }, [filtertext]);

    useEffect(() => {
        if (navigate_url !== "") {
            return navigate(navigate_url);
        }
    }, [navigate_url]);

    function getShopID(text) {
        var formdata = new FormData()
        formdata.append('shop_text', text)

        axios.post("https://api.meya.co.th/api/market/help/getshopidbyname", formdata, {
            headers: {
                Accept: 'application/json',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                if (response.data.status === 'OK') {
                    if (response.data.shop_info.shop_id !== "") {
                        setNavigate_url("/marketplace/shopseller?shopcode=" + response.data.shop_info.shop_id + "&category=รวมสินค้า")
                    }
                } else {
                    setModal_popup({
                        modaltype: 'modal-warning',
                        headers: 'ขออภัย',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'ผิดพลาด',
                    message: err.message,
                    enable: true
                });
            });
    }

    function searchenter(e, mode, type, id, detail) {
        if (e.key === 'Enter') {
            begin_search(mode, type, id, detail)
        }
    }

    function generate_token(length) {
        var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        var b = [];
        for (var i = 0; i < length; i++) {
            var j = (Math.random() * (a.length - 1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("");
    }

    return (
        <div className="d-xl-none col col-xl-auto text-right text-xl-left pl-0 pl-xl-0 position-static">
            <div className="d-inline-flex">
                <ul className="d-flex list-unstyled mb-0 align-items-center">
                    <li className="col d-xl-none px-1 px-sm-2 position-static">
                        <div id="searchClassicInvoker"
                            className="font-size-22 text-dark text-lh-1 btn-text-secondary"
                            role="button" data-toggle="tooltip" data-placement="top"
                            title="ค้นหา" aria-controls="searchClassic" aria-haspopup="true"
                            aria-expanded="false" onClick={() => { setSearchbar_toggle(!searchbar_toggle) }} >
                            <span className="ec ec-search" style={{ cursor: "pointer" }}></span>
                        </div>

                        <div id="searchClassic" ref={searchbarRef} onMouseLeave={() => setSearchbar_toggle(false)}
                            className="dropdown-menu dropdown-unfold dropdown-menu-right left-0 mx-2 u-unfold--css-animation slideInUp" style={{ animationDuration: "500ms", left: " 0px" }} onAnimationEnd={() => { searchbarClose() }}
                            aria-labelledby="searchClassicInvoker">
                            <form className="js-focus-state input-group px-3">
                                <input className="form-control font-th" type="search"
                                    placeholder="ค้นหา : ชื่อสินค้าภาษาไทย/อังกฤษ ชื่อร้านค้า" value={filtertext} onKeyDown={(e) => { searchenter(e, "product", "contain") }} onChange={(e) => { setFiltertext(e.target.value) }} onClick={() => { setSearchoption(true) }} />
                                <div className="input-group-append">
                                    <button className="btn btn-primary px-3 text-white" type="button" onClick={() => { begin_search("product", "contain") }} ><i
                                        className="font-size-18 ec ec-search"></i></button>
                                </div>
                            </form>
                            <div className="px-3">
                                {
                                    searchoption === true && props.islogin === false ?
                                        <div className="search-bar-auto">
                                            <div className="search-bar-auto-wrapper" >
                                                {
                                                    filtertext !== "" ?
                                                        <>
                                                            <label className='font-th'>ค้นหาร้านค้า</label>
                                                            <div className="search-bar-auto-item" onClick={() => { begin_search("shop") }}>
                                                                <i className='fas fa-store-alt text-primary mr-2'></i> คุณกำลังค้นหา ร้าน <span>{filtertext}</span> ใช่หรือไม่
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                <label className='font-th'>ค้นหาสินค้า</label>
                                                {
                                                    filtertext !== "" ?
                                                        pitems.map((item, index) => {
                                                            return (
                                                                <div className="search-bar-auto-item" onClick={() => { begin_search("product", "productid", item.productitem_id, item.productitem_name) }} key={index}>
                                                                    <i className="fas fa-capsules text-grey-15 mr-2"></i>{item.productitem_name}
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </li>
                    {
                        props.permitdata !== null ?
                            props.permitdata.role !== 'guest' && props.permitdata.token !== '' ?
                                <>
                                    <li className="col d-xl-none px-1 px-sm-2">
                                        <Link to={"/marketplace/mycart?cart=" + generate_token(20)} className="text-dark position-relative d-flex "
                                            data-toggle="tooltip" data-placement="top" title="ตะกร้าสินค้าของฉัน">
                                            <i className="font-size-22 ec ec-add-to-cart"></i>
                                            {props.mycartlen > 0 ?
                                                <span className="width-22 height-22 bg-warning position-absolute d-flex align-items-center justify-content-center rounded-circle left-12 top-8 font-weight-bold font-size-12 text-dark">{props.mycartlen}</span>
                                                :
                                                null
                                            }
                                        </Link>
                                    </li>
                                    <li className="col d-xl-none pr-xl-0 px-1 px-sm-2">
                                        <Link to="/myshop/seller/jobtodo"
                                            className="text-dark position-relative d-flex" data-toggle="tooltip" data-placement="top"
                                            title="ร้านค้าของฉัน"><i className="font-size-22 ec ec-user"></i>
                                            {props.myorderlen > 0 ?
                                                <span
                                                    className="width-22 height-22 bg-warning position-absolute flex-content-center text-dark rounded-circle left-12 top-8 font-weight-bold font-size-12">{props.myorderlen}</span>
                                                :
                                                null
                                            }
                                        </Link>
                                    </li>
                                    <li className="col d-xl-none pr-xl-0 px-1 px-sm-2">
                                        <Link to="/myshop/tools/chat"
                                            className="text-dark position-relative d-flex" data-toggle="tooltip" data-placement="top"
                                            title="พูดคุยกับมียา"><i className="font-size-22 ec ec-comment"></i>
                                            {props.messages_chat_unread > 0 ?
                                                <span
                                                    className="width-22 height-22 bg-warning position-absolute flex-content-center text-dark rounded-circle left-12 top-8 font-weight-bold font-size-12">{props.messages_chat_unread}</span>
                                                :
                                                null
                                            }
                                        </Link>
                                    </li>
                                </>
                                :
                                null
                            :
                            null
                    }
                </ul>
            </div>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

Navbar.propTypes = {
    callback_popup_register: PropTypes.func,
    callback_popup_signin: PropTypes.func
};

NavbarSupport.propTypes = {
    callback_popup_register: PropTypes.func,
    callback_popup_signin: PropTypes.func
};

export default Navbar