import React from 'react';
import { Link } from 'react-router-dom';
import './SlideHome.css';

function SlideHome() {
    return (
        <div className="mb-4">
            <div className="bg-img-homeslide"
                style={{ backgroundImage: "linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.30) 100%),url(/images/meya-cover2.png)" }}>
                <div className="container overflow-hidden">
                    <div className="bg-img-homeslide-wrapper">
                        <div className="homeslide-textbox text-right ">
                            <div className="font-weight-bold font-th text-white text-bannner-poster poster-title mb-0 animated fadeInUp" style={{ animationDuration: "1000ms" }}>
                                มียา<strong className="font-weight-bold font-en">.com</strong>
                            </div>
                            <div className="font-weight-bold font-th text-white text-bannner-poster poster-subtitle animated fadeInUp" style={{ animationDuration: "1000ms" }}>
                                บริการช่วยเหลือการซื้อขายสินค้าระหว่างร้านขายยา<br />ให้การหาสินค้าเข้าร้านเป็นเรื่องง่าย<br />ร่วมเป็นคู่ค้าไปกับเรา</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideHome