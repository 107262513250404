
import React, { useEffect, useState, useRef } from 'react';


function About(props) {
    const [data_shopitemlist, setData_shopitemlist] = useState([]);

    return (
        <main id="content" role="main">
            <div className="container">
                <div className="flex-container mt-3 py-0">

                </div>
            </div>
        </main>
    )
}

export default About